import { FormId, ProjectId, RecordId, RecordSetId } from "../types/IdTypes";

import { generatePath } from "react-router";

export interface urlParams {
  projectId: string;
  formId: string;
  recordSetId: string;
  recordId: string;
}

const buildPath = (
  route: string,
  params?:
    | {
        [paramName: string]: string | number | boolean | undefined;
      }
    | undefined,
  fullUrl?: boolean
) => {
  const path = generatePath(route, params);

  if (fullUrl) {
    const parsedUrl = new URL(window.location.href);
    parsedUrl.pathname = path;
    return parsedUrl.toString();
  } else {
    return path;
  }
};

export const BASE_PROJECT_ROUTE = "/p/:projectId";

export const buildProjectPath = (projectId: ProjectId) => {
  return buildPath(BASE_PROJECT_ROUTE, {
    projectId: projectId,
  });
};

export const PROJECT_SETTINGS_ROUTE = `${BASE_PROJECT_ROUTE}/settings`;
export const PROJECT_ADMIN_ROUTE = `${BASE_PROJECT_ROUTE}/admin`;
export const PROJECT_PREFERENCES_ROUTE = `${BASE_PROJECT_ROUTE}/preferences`;
export const PROJECT_ADMIN_SETTINGS_ROUTE = `${PROJECT_ADMIN_ROUTE}/settings`;
export const PROJECT_ADMIN_MEMBERS_ROUTE = `${PROJECT_ADMIN_ROUTE}/members`;
export const PROJECT_ADMIN_GROUPS_ROUTE = `${PROJECT_ADMIN_ROUTE}/groups`;
export const PROJECT_ADMIN_BILLING_ROUTE = `${PROJECT_ADMIN_ROUTE}/billing`;
export const PROJECT_PREFERENCES_LOCALE_ROUTE = `${PROJECT_PREFERENCES_ROUTE}/locale`;
export const PROJECT_PREFERENCES_THEMES_ROUTE = `${PROJECT_PREFERENCES_ROUTE}/themes`;

export const buildProjectSettingsPath = (projectId: ProjectId) => {
  return buildPath(PROJECT_SETTINGS_ROUTE, {
    projectId: projectId,
  });
};
export const buildProjectAdminSettingsPath = (projectId: ProjectId) => {
  return buildPath(PROJECT_ADMIN_SETTINGS_ROUTE, {
    projectId: projectId,
  });
};

export const buildProjectAdminMembersPath = (projectId: ProjectId) => {
  return buildPath(PROJECT_ADMIN_MEMBERS_ROUTE, {
    projectId: projectId,
  });
};
export const buildProjectAdminGroupsPath = (projectId: ProjectId) => {
  return buildPath(PROJECT_ADMIN_GROUPS_ROUTE, {
    projectId: projectId,
  });
};
export const buildProjectAdminBillingPath = (projectId: ProjectId) => {
  return buildPath(PROJECT_ADMIN_BILLING_ROUTE, {
    projectId: projectId,
  });
};

export const buildProjectPreferencesLocalePath = (projectId: ProjectId) => {
  return buildPath(PROJECT_PREFERENCES_LOCALE_ROUTE, {
    projectId: projectId,
  });
};
export const buildProjectPreferencesThemesPath = (projectId: ProjectId) => {
  return buildPath(PROJECT_PREFERENCES_THEMES_ROUTE, {
    projectId: projectId,
  });
};

export const FORMS_ROUTE = `${BASE_PROJECT_ROUTE}/forms`;
export const FORMS_FILLER_ROUTE = `${FORMS_ROUTE}/fill/:formId/:recordSetId/:recordId?`;
export const FORMS_CREATE_ROUTE = `${FORMS_ROUTE}/design`;
export const FORMS_DESIGN_ROUTE = `${FORMS_ROUTE}/design/:formId`;
export const FORMS_FILLER_PREVIEW_ROUTE = `${FORMS_ROUTE}/preview/:formId`;

export const buildFormsListPath = (projectId: ProjectId) => {
  return buildPath(FORMS_ROUTE, { projectId });
};

export const buildFormCreatePath = (projectId: ProjectId) => {
  return buildPath(FORMS_CREATE_ROUTE, { projectId: projectId });
};

export const buildFormDesignPath = (projectId: ProjectId, formId: FormId) => {
  return buildPath(FORMS_DESIGN_ROUTE, {
    projectId: projectId,
    formId: formId,
  });
};

export const buildFormFillerPath = (
  projectId: ProjectId,
  formId: FormId,
  recordSetId: RecordSetId,
  fullUrl?: boolean
) => {
  return buildPath(
    FORMS_FILLER_ROUTE,
    {
      projectId: projectId,
      formId: formId,
      recordSetId: recordSetId,
    },
    fullUrl
  );
};

export const buildFormFillerForRecordPath = (
  projectId: ProjectId,
  formId: FormId,
  recordSetId: RecordSetId,
  recordId: RecordId,
  fullUrl?: boolean
) => {
  return buildPath(
    FORMS_FILLER_ROUTE,
    {
      projectId: projectId,
      formId: formId,
      recordSetId: recordSetId,
      recordId: recordId,
    },
    fullUrl
  );
};

export const buildFormPreviewPath = (projectId: ProjectId, formId: FormId) => {
  return buildPath(FORMS_FILLER_PREVIEW_ROUTE, {
    projectId: projectId,
    formId: formId,
  });
};

export const LOGOUT_ROUTE = "/logout";
export const SIGNIN_ROUTE = "/signin";

const PROJECTS_EDITOR_PATH = "/projects";
export const PROJECTS_CREATE_ROUTE = `${PROJECTS_EDITOR_PATH}/create`;
export const PROJECTS_CREATE_DETAIL_ROUTE = `${PROJECTS_CREATE_ROUTE}/:projectId`;
export const PROJECTS_CREATE_INVITE_ROUTE = `${PROJECTS_CREATE_ROUTE}/:projectId/invite`;

export const buildProjectCreateDetailPath = (projectId: ProjectId) => {
  return buildPath(PROJECTS_CREATE_DETAIL_ROUTE, { projectId: projectId });
};

export const buildProjectCreateInvitePath = (projectId: ProjectId) => {
  return buildPath(PROJECTS_CREATE_INVITE_ROUTE, {
    projectId: projectId,
  });
};

export const RECORDS_ROUTE = `${BASE_PROJECT_ROUTE}/records`;
export const RECORDS_LIST_ROUTE = `${RECORDS_ROUTE}/:recordSetId`;
export const RECORD_ID_ROUTE = `${RECORDS_LIST_ROUTE}/record/:recordId`;
export const RECORD_EDIT_ROUTE = `${RECORDS_LIST_ROUTE}/edit/:recordId`;

export const buildRecordsListPath = (
  projectId: ProjectId,
  recordSetId: RecordSetId
) => {
  return buildPath(RECORDS_LIST_ROUTE, {
    projectId: projectId,
    recordSetId: recordSetId,
  });
};

export const buildRecordPath = (
  projectId: ProjectId,
  recordSetId: RecordSetId,
  recordId: RecordId,
  fullUrl?: boolean
) => {
  return buildPath(
    RECORD_ID_ROUTE,
    {
      projectId: projectId,
      recordSetId: recordSetId,
      recordId: recordId,
    },
    fullUrl
  );
};

export const buildRecordEditPath = (
  projectId: ProjectId,
  recordSetId: RecordSetId,
  recordId: RecordId,
  fullUrl?: boolean
) => {
  return buildPath(
    RECORD_EDIT_ROUTE,
    {
      projectId: projectId,
      recordSetId: recordSetId,
      recordId: recordId,
    },
    fullUrl
  );
};

export const REMINDERS_ROUTE = `${BASE_PROJECT_ROUTE}/tasks`;

export const buildRemindersPath = (projectId: ProjectId) => {
  return buildPath(REMINDERS_ROUTE, {
    projectId: projectId,
  });
};

export const DASHBOARD_ROUTE = `${BASE_PROJECT_ROUTE}/dashboard`;

export const buildDashboardPath = (projectId: ProjectId) => {
  return buildPath(DASHBOARD_ROUTE, {
    projectId: projectId,
  });
};

export const REPORTS_ROUTE = `${BASE_PROJECT_ROUTE}/reports`;

export const buildReportsPath = (projectId: ProjectId) => {
  return buildPath(REPORTS_ROUTE, { projectId: projectId });
};

export const ACCOUNT_ROUTE = `/account/:page?`;

export const buildAccountsSettingsPath = (page?: "settings") => {
  return buildPath(ACCOUNT_ROUTE, { page: page });
};
