import React, { FunctionComponent, Fragment } from "react";

// UserAvatar is a component that generates an avatar for a given user.
// TODO: add profile image as a prop
export interface UserAvatarProps {
  //   avatarURL?: string;
  name: string;
  size: string; // sm | md | lg
}

export const UserAvatar: FunctionComponent<UserAvatarProps> = (props) => {
  const initials = props.name.split(" ").map((n) => n[0]);

  let avatar;

  if (props.size == "sm") {
    avatar = (
      <div className="flex h-8 w-8 items-center justify-center leading-none rounded border text-xs font-semibold bg-pink-300 text-pink-700 border-pink-900">
        {initials}
      </div>
    );
  } else if (props.size == "md") {
    avatar = (
      <div className="flex h-12 w-12 items-center justify-center leading-none rounded border text-sm font-semibold bg-purple-300 text-purple-700 border-purple-900">
        {initials}
      </div>
    );
  } else if (props.size == "lg") {
    avatar = (
      <div className="flex h-16 w-16 items-center justify-center leading-none rounded border text-lg font-semibold bg-teal-300 text-teal-700 border-teal-900">
        {initials}
      </div>
    );
  } else {
    console.log("Avatar size needs to be set to sm, md, or lg");
  }

  return <Fragment>{avatar}</Fragment>;
};

// GroupAvatar is a component that generates an avatar for a given group.
// TODO: Add ability for users to choose their unique group icons
export interface GroupAvatarProps {
  size: string; // sm | md | lg
}

export const GroupAvatar: FunctionComponent<GroupAvatarProps> = (props) => {
  let avatar;

  if (props.size == "sm") {
    avatar = (
      <div className="flex h-8 w-8 items-center justify-center rounded border text-xs font-semibold bg-gray-100 text-gray-700 border-gray-900">
        <i className="fas fa-users" />
      </div>
    );
  } else if (props.size == "md") {
    avatar = (
      <div className="flex h-12 w-12 items-center justify-center rounded border text-sm font-semibold bg-gray-100 text-gray-700 border-gray-900">
        <i className="fas fa-users" />
      </div>
    );
  } else if (props.size == "lg") {
    avatar = (
      <div className="flex h-16 w-16 items-center justify-center rounded border text-lg font-semibold bg-gray-100 text-gray-700 border-gray-900">
        <i className="fas fa-users" />
      </div>
    );
  } else {
    console.log("Avatar size needs to be set to sm, md, or lg");
  }

  return <Fragment>{avatar}</Fragment>;
};

// CollaboratorListItem shows a list of users/groups in places like  CollaboratorModal
export interface CollaboratorListItemProps {
  title: string;
  meta: string;
  size: string; // sm | md
  type: string; // user | group
}

export const CollaboratorListItem: FunctionComponent<CollaboratorListItemProps> = (
  props
) => {
  let listItem;
  if (props.size == "sm") {
    listItem = (
      <div className="flex items-center flex-grow">
        <div className="flex-initial mr-4">
          {props.type == "user" && <UserAvatar size="sm" name={props.title} />}
          {props.type == "group" && <GroupAvatar size="sm" />}
        </div>
        <div className="inline-block">
          <div className="font-normal leading-snug inline-block">
            {props.title}
          </div>
          <div className="font-thin leading-snug text-gray-600 inline-block">
            &nbsp;({props.meta})
          </div>
        </div>
      </div>
    );
  } else if (props.size == "md") {
    listItem = (
      <div className="flex items-center flex-grow">
        <div className="flex-initial mr-4">
          {props.type == "user" && <UserAvatar size="md" name={props.title} />}
          {props.type == "group" && <GroupAvatar size="md" />}
        </div>
        <div className="flex-grow">
          <div className="font-normal leading-snug">{props.title}</div>
          <div className="font-thin leading-snug text-gray-600">
            {props.meta}
          </div>
        </div>
      </div>
    );
  } else {
    console.log("CollaboratorListItem doesn't have a valid size (sm or md)");
  }

  return <Fragment>{listItem}</Fragment>;
};
