import * as K from "../types/KotoOb";

import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Record, createRecord, loadRecord } from "../types/Record";
import { buildRecordPath, buildRecordsListPath } from "./AppRoutes";
import { loadBlockSpecsForForm, loadForm, submitForm } from "../types/Form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { AppKotoContext } from "./AppKotoContext";
import { Block } from "../types/Block";
import { EmptyViewNav } from "../components/TopNavigation";
import { FormFiller } from "../components/Form";
import { KotoCRDT } from "../types/KotoTypes";
import { RootState } from "../Store/store";
import { formFillerSlice } from "../Store/formFillerSlice";
import { loadProject } from "../types/Project";
import { urlParams } from "../App/AppRoutes";
import { viewSlice } from "../Store/viewSlice";

export const AppFormFiller: FunctionComponent<{}> = (_props) => {
  const dispatch = useDispatch();
  const { kContext } = useContext(AppKotoContext);

  const { projectId, formId, recordSetId, recordId } = useParams<urlParams>();
  const history = useHistory();

  const [record, setRecord] = useState(null as KotoCRDT<Record> | null);
  const [blocks, setBlocks] = useState([] as KotoCRDT<Block>[]);

  useEffect(() => {
    const load = async () => {
      const projectResult = await loadProject(kContext, projectId);

      if (projectResult.type !== "Success") {
        console.log("Failed to load project. ", projectResult.msg);
        return;
      }

      const project = projectResult.data;

      const result = await loadForm(kContext, projectId, formId);
      if (result.type !== "Success") {
        console.log("Failed to load form. ", result.msg);
        return;
      }
      const form = result.data;

      const specMap = Object.entries(
        await loadBlockSpecsForForm(kContext, form)
      ).reduce((prev, cur) => {
        return { ...prev, [cur[0]]: K.getKotoObFromKotoCRDT(cur[1]) };
      }, {});

      dispatch(formFillerSlice.actions.setForm(form));
      dispatch(formFillerSlice.actions.setBlockSpecs(specMap));

      if (recordId) {
        const result = await loadRecord(kContext, projectId, recordId);
        if (result.type === "Success") {
          setRecord(result.data);
        }
      } else {
        if (record === null) {
          const newRecord = createRecord(kContext, project, recordSetId);
          setRecord(newRecord);
        }
      }
    };

    load();
    return () => {
      dispatch(formFillerSlice.actions.setForm(null));
      dispatch(formFillerSlice.actions.setBlockSpecs({}));
    };
  }, [kContext, projectId, formId]);

  const form = useSelector((state: RootState) => {
    return state.formFiller.form;
  });

  const blockSpecMap = useSelector((state: RootState) => {
    return state.formFiller.blockSpecs;
  });

  const handleSubmit = async () => {
    if (record !== null && form !== null) {
      await submitForm(kContext, record, form.key, blocks);
      await K.storeCrdt(kContext, record);
      history.push(buildRecordPath(projectId, recordSetId, record.crdt.id));
    }
  };

  const handleCancel = async () => {
    history.push(buildRecordsListPath(projectId, recordSetId));
  };

  const handleBackButton = () => {
    history.goBack();
  };

  return (
    <Fragment>
      {/* TODO: Insert form filler actions here */}

      <EmptyViewNav
        setSideNavExpanded={() => {
          dispatch(viewSlice.actions.setNavVisible(true));
        }}
        handleBackButton={handleBackButton}
      ></EmptyViewNav>
      {form && record && (
        <div>
          <FormFiller
            kotoContext={kContext}
            form={form}
            blockSpecMap={blockSpecMap}
            blocks={blocks}
            setBlocks={setBlocks}
            recordSetId={recordSetId}
            recordId={record.crdt.id}
          />
          <div className="flex max-w-3xl mb-24 justify-between mx-2 sm:mx-auto">
            <button className="btn flex-grow mr-2" onClick={handleCancel}>
              Cancel
            </button>
            <button
              className="btn btn-gray flex-grow ml-2"
              onClick={handleSubmit}
            >
              {form.crdt.submissionSettings.buttonLabel.toString()}
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};
