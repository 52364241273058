import * as K from "../types/KotoOb";

import { BaseQuestionSpec, QuestionAnswer } from "../types/Questions";
import {
  Block,
  BlockSpec,
  createBlock,
  deleteQuestionFromSpec,
  duplicateQuestionInSpec,
  updateBlockSpecTitle,
  updateQuestionSpec,
} from "../types/Block";
import {
  FormId,
  QuestionSpecId,
  RecordId,
  RecordSetId,
} from "../types/IdTypes";
import { KotoCRDT, KotoOb } from "../types/KotoTypes";
import { Menu, MenuItem, MenuItemList } from "./Buttons";
import { QuestionDesigner, QuestionPartsFiller } from "./Questions";
import React, {
  ChangeEvent,
  Fragment,
  FunctionComponent,
  useState,
} from "react";

import { KotoContext } from "../types/KotoContext";
import { RestrictBlockModal } from "./Modals";
import { getQuestionTypeConfig } from "../types/QuestionTypeRegistry";

// import for JSX

interface BlockSpecDesignerProps {
  kContext: KotoContext;
  spec: KotoCRDT<BlockSpec>;
  setBlockSpec: (spec: KotoCRDT<BlockSpec>) => Promise<void>;
  active: boolean;
  blockDeleter: () => void;
}

export const BlockSpecDesigner: FunctionComponent<BlockSpecDesignerProps> = (
  props
) => {
  const blockSpecCrdt = props.spec;
  const [blockMenuButtonOpen, setBlockMenuButtonOpen] = useState(false);
  const [restrictBlockModalOpen, setRestrictBlockModalOpen] = useState(false);
  // TODO: Move isRestricted and isRepeatable into block-spec
  const [isRestricted, setIsRestricted] = useState(false);
  const [isRepeatable, setIsRepeatable] = useState(false);

  if (blockSpecCrdt.key === undefined) {
    return <div></div>;
  }

  const setSectionTitle = async (event: ChangeEvent<HTMLInputElement>) => {
    const newSpec = updateBlockSpecTitle(
      props.kContext,
      blockSpecCrdt,
      event.target.value
    );
    await props.setBlockSpec(newSpec);
  };

  const makeQuestionSetter = (idx: number) => {
    return async (fn: (question: BaseQuestionSpec) => void) => {
      const newSpec = updateQuestionSpec(
        props.kContext,
        blockSpecCrdt,
        idx,
        fn
      );
      await props.setBlockSpec(newSpec);
    };
  };

  const makeQuestionDeleter = (idx: number) => {
    return async () => {
      const newSpec = deleteQuestionFromSpec(
        props.kContext,
        blockSpecCrdt,
        idx
      );
      await props.setBlockSpec(newSpec);
    };
  };

  const makeQuestionDuplicator = (idx: number) => {
    return async () => {
      const newSpec = duplicateQuestionInSpec(
        props.kContext,
        blockSpecCrdt,
        idx
      );
      await props.setBlockSpec(newSpec);
    };
  };

  let blockClassName = "k_card mb-5";
  if (props.active) {
    blockClassName = blockClassName + " k_activeOutline shadow mb-5";
  }

  return (
    <Fragment>
      <div className={blockClassName}>
        {isRestricted && (
          <div className="flex-grow p-2 text-center bg-violet-100 text-violet-700 rounded border border-violet-700 text-sm mb-4">
            <i className="far fa-eye-slash mr-2"></i>
            This question block has been marked as sensitive.{" "}
            <a href="" className="">
              Manage access
            </a>
          </div>
        )}
        <div className="mb-6 flex items-center">
          <input
            onChange={setSectionTitle}
            className="flex-1 form-input k_textInput mt-0  font-semibold bg-white rounded-r-none border-r-0 py"
            placeholder="Section Title"
            value={props.spec.crdt.title.toString()}
          />
          <Menu
            menuOpen={blockMenuButtonOpen}
            setMenuOpen={setBlockMenuButtonOpen}
          >
            <button
              className="btn btn-inline rounded-l-none font-light"
              data-tooltip="Question block options"
              tooltip-position="bottom-right"
            >
              <i className="far fa-cog"></i>
              <span className="hidden sm:inline-block ml-2 ">Options</span>
            </button>
            <MenuItemList position="bottom-right" extraClassNames="sm:w-xs">
              <MenuItem
                extraClassNames="border-b border-gray-300"
                onClick={() => {
                  {
                    /* TODO: move "setIsRepeatable from here to inside the modal" */
                    setIsRepeatable(!isRepeatable);
                  }
                }}
              >
                <i className="far fa-fw fa-repeat mr-3" />
                <span className="font-medium">Make block repeatable</span>
                <span className="block ml-8">
                  Let those filling out the form repeat this question block.
                </span>
              </MenuItem>

              {/* TODO: move "setIsRestricted from here to inside the modal" */}
              <MenuItem
                extraClassNames="border-b border-gray-300"
                onClick={() => {
                  setBlockMenuButtonOpen(false);
                  setRestrictBlockModalOpen(true);
                  setIsRestricted(true);
                }}
              >
                <i className="far fa-fw fa-lock-alt mr-3" />
                <span className="font-medium">Restrict access to results</span>
                <span className="block ml-8">
                  Restrict who can see information collected from this question
                  block.
                </span>
              </MenuItem>
              <MenuItem>
                <i className="far fa-fw fa-align-justify mr-3" />
                Add a block description
              </MenuItem>
              <MenuItem danger={true} onClick={props.blockDeleter}>
                <i className="far fa-fw fa-trash-alt mr-3" />
                Delete this block
              </MenuItem>
            </MenuItemList>
          </Menu>
        </div>
        {props.spec.crdt.questions.map((question, idx) => {
          return (
            <QuestionDesigner
              key={question.id}
              questionSpec={question}
              setQuestion={makeQuestionSetter(idx)}
              deleteQuestion={makeQuestionDeleter(idx)}
              duplicateQuestion={makeQuestionDuplicator(idx)}
            />
          );
        })}
        {isRepeatable && (
          <div className="text-violet-500 p-2 border rounded bg-gray-100 border-gray-400 text-center text-sm">
            <i className="fas fa-border-inner mr-2"></i>
            This block is repeatable.
          </div>
        )}

        {/* TODO: Use toggleRestricted to restrict this block if hte user confirms in the modal*/}
        <RestrictBlockModal
          modalOpen={restrictBlockModalOpen}
          setModalOpen={setRestrictBlockModalOpen}
        />
      </div>
    </Fragment>
  );
};

interface BlockSpecFillerProps {
  kotoContext: KotoContext;
  formId: FormId | null;
  recordSetId: RecordSetId;
  recordId: RecordId;
  spec: KotoOb<BlockSpec>;
  block: KotoCRDT<Block> | null;
  setBlock: (updatedBlock: KotoCRDT<Block>) => void;
}

export const BlockSpecFiller: FunctionComponent<BlockSpecFillerProps> = (
  props
) => {
  const [blockCrdt, setBlockCrdt] = useState(
    props.block ||
      createBlock(
        props.kotoContext,
        props.spec.doc.projectId,
        props.formId,
        props.spec,
        props.recordSetId,
        props.recordId
      )
  );

  if (blockCrdt === undefined) {
    return <Fragment></Fragment>;
  }
  const makeAnswerSetter = (idx: QuestionSpecId) => {
    const questionSpec = blockCrdt.crdt.blockSpec.doc.questions.find((q) => {
      return q.id === idx;
    });

    if (questionSpec === undefined) {
      throw new Error(`Question spec not found with id ${idx}.`);
    }

    return (
      answerSetter: (spec: BaseQuestionSpec, parts: QuestionAnswer) => void
    ) => {
      const newCrdt = K.change(props.kotoContext, blockCrdt, (doc) => {
        answerSetter(questionSpec, doc.answers[idx]);
      });
      setBlockCrdt(newCrdt);
      props.setBlock(newCrdt);
    };
  };

  return (
    <div className="k_card my-8">
      <h2 className="k_cardSectionTitle mb-4">
        {blockCrdt.crdt.blockSpec.doc.title}
      </h2>
      {blockCrdt.crdt.blockSpec.doc.questions.map((question) => {
        return (
          <div key={question.id} className="mb-4 last:mb-0">
            <h3 className="k_cardQuestionTitle ">{question.label}</h3>
            <QuestionPartsFiller
              questionSpec={question}
              answer={blockCrdt.crdt.answers[question.id]}
              setAnswer={makeAnswerSetter(question.id)}
            />
          </div>
        );
      })}
    </div>
  );
};

interface BlocksViewProp {
  kotoContext: KotoContext;
  answers: KotoCRDT<Block>[];
}

export const BlocksView: FunctionComponent<BlocksViewProp> = (props) => {
  return (
    <div>
      {props.answers.map((block: KotoCRDT<Block>, idx) => {
        const blockSpec = block.crdt.blockSpec;

        const areAnyAnswered: boolean = Object.values(block.crdt.answers).some(
          (answer: QuestionAnswer) => {
            return answer.isAnswered;
          }
        );

        return (
          areAnyAnswered && (
            <div key={idx} className="k_card my-8">
              <h2 className="k_cardSectionTitle flex border-b border-gray-500 pb-4 mb-4">
                {blockSpec.doc.title}
              </h2>

              {blockSpec.doc.questions.map((questionSpec, idx: number) => {
                const answer = block.crdt.answers[questionSpec.id];

                return answer.isAnswered ? (
                  <div
                    className="mb-4 pb-4 border-b border-gray-300 leading-relaxed last:border-b-0 last:mb-0 last:pb-0 "
                    key={idx}
                  >
                    <p className="k_cardQuestionTitle ">{questionSpec.label}</p>
                    <div className="k_cardAnswer">
                      {answer === undefined
                        ? ""
                        : React.createElement(
                            getQuestionTypeConfig(questionSpec.type)
                              .questionViewer,
                            { spec: questionSpec, answer: answer }
                          )}
                    </div>
                  </div>
                ) : (
                  <Fragment key={idx}></Fragment>
                );
              })}
            </div>
          )
        );
      })}
    </div>
  );
};

export const BlockJSONView: FunctionComponent<BlocksViewProp> = (props) => {
  return <pre>{JSON.stringify(props.answers, null, 2)}</pre>;
};
