import React, { FunctionComponent, Fragment } from "react";
import { Card } from "../Card";

interface LanguageRegionProps {
  // TODO: Add activeSection to scroll to and expand a specific section of the settings
  //   activeSection: string;
}

// TODO: Replace div containing fake user icon with real user icons and
// placeholder images
export const LanguageRegion: FunctionComponent<LanguageRegionProps> = (
  _props
) => {
  return (
    <Fragment>
      <div className="my-8"></div>
      <Card isActive={false} isStacked={false}>
        <div className="k_Card">
          <div className="k_cardQuestionTitle">Language</div>
          <div className="k_cardQuestionDescription">
            Choose the language you’d like to use with Koto.
          </div>
          <select className="form-select k_select mb-4">
            <option value="English">English</option>
            <option value="Spanish">Spanish</option>
            <option value="Mandarin">Mandarin</option>
          </select>

          <div className="k_cardQuestionTitle">Time zone</div>
          <label className="k_choiceLabel">
            <input
              type="checkbox"
              className="form-checkbox k_choiceInput"
              disabled={false}
            ></input>
            <div className="k_choiceText">Set time zone automatically</div>
          </label>
          <select className="form-select k_select mb-4">
            <option value="(UTC-08:00) Pacific Time (US and Canada)">
              (UTC-08:00) Pacific Time (US and Canada)
            </option>
            <option value="(UTC-07:00) Arizona">(UTC-07:00) Arizona</option>
            <option value="(UTC-05:00) Indiana (East)">
              (UTC-05:00) Indiana (East)
            </option>
          </select>

          <div className="k_cardQuestionTitle">Temperature</div>
          <select className="form-select k_select mb-4">
            <option value="Fahrenheit">Fahrenheit (F°)</option>
            <option value="Celcius">Celcius (C°)</option>
          </select>

          <div className="k_cardQuestionTitle">Units</div>
          <select className="form-select k_select mb-4">
            <option value="Imperial">Imperial</option>
            <option value="Metric">Metric</option>
          </select>
        </div>
      </Card>
    </Fragment>
  );
};
