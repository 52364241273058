import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminSettingsViews } from "../components/Settings/AdministrativeSettings";

export interface AdminSettingsSlice {
  adminSettingsView: AdminSettingsViews;
}

export const adminSettingsSlice = createSlice({
  name: "administrative settings",
  initialState: {
    adminSettingsView: "settings",
  } as AdminSettingsSlice,
  reducers: {
    setAdminSettingView: (state, action: PayloadAction<AdminSettingsViews>) => {
      state.adminSettingsView = action.payload;
    },
  },
});
