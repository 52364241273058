import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HeaderBar } from "../components/TopNavigation";
import { RootState } from "../Store/store";
import { authSlice } from "../Store/authSlice";

export const AppHeaderBar: FunctionComponent<{}> = (_props) => {
  const dispatch = useDispatch();

  const firebaseUser = useSelector((state: RootState) => {
    return state.auth.firebaseUser;
  });

  const handleSignIn = () => {
    dispatch(authSlice.actions.setLoggingIn(true));
  };

  const userInfo =
    firebaseUser === null
      ? null
      : {
          userName: firebaseUser.displayName || "",
          userEmail: firebaseUser.email || "",
        };

  return <HeaderBar userInfo={userInfo} handleSignin={handleSignIn} />;
};
