import "firebase/firestore";

import * as K from "../../types/KotoOb";

import {
  BackingStore,
  QueryParams,
  QueryParamsWithKey,
} from "../../types/KotoStore";
import {
  KotoBaseDoc,
  KotoSerializedCRDT,
  KotoStorable,
  isCrdt,
} from "../../types/KotoTypes";

import { UserId } from "../../types/IdTypes";
import firebase from "firebase/app";
import { notEmpty } from "../../utils/utils";

export const encodeForFirestore = (ob: KotoStorable): any => {
  if (isCrdt(ob)) {
    return K.serialize(ob);
  } else if (ob.type === "Change") {
    const encoded = Object.assign({}, ob, {
      changes: ob.changes.map((changeArray: Uint8Array) => {
        return firebase.firestore.Blob.fromUint8Array(changeArray);
      }),
    });

    return encoded;
  } else {
    return ob;
  }
};

export const decodeSerializedCRDT = <T extends KotoBaseDoc>(
  data: any
): KotoSerializedCRDT<T> => {
  return { ...data, savedCRDT: data.savedCRDT.toUint8Array() as Uint8Array };
};

export const decodeFromFirestore = <T extends KotoStorable>(
  data: firebase.firestore.DocumentData
): T => {
  if (data.type === "Change") {
    const decoded = Object.assign({}, data as T, {
      changes: data.changes.map((blob: firebase.firestore.Blob) => {
        return blob.toUint8Array();
      }),
    });

    return decoded as T;
  } else if (data.savedCRDT !== undefined) {
    const decoded = Object.assign({}, data as T, {
      savedCRDT: data.savedCRDT.toUint8Array(),
    });
    return decoded;
  } else {
    return data as T;
  }
};

export class FirestoreStore extends BackingStore {
  name = "FireStore";

  private db: firebase.firestore.Firestore;

  changesToWrite: number;

  constructor(_userId: UserId, db: firebase.firestore.Firestore) {
    super();
    this.db = db;

    this.changesToWrite = 0;
  }

  async store<T extends KotoStorable>(ob: T): Promise<K.KotoResult<T>> {
    try {
      const ref = this.db.collection(ob.type).doc(ob.key);

      await ref.set(encodeForFirestore(ob));

      return { type: "Success", data: ob };
    } catch (error) {
      return { type: "Error", msg: error };
    }
  }

  async get<T extends KotoStorable>(
    queryParams: QueryParamsWithKey<T>
  ): Promise<K.KotoResult<T>> {
    const keyParam = queryParams.params.find((param) => {
      return param.fieldPath === "key";
    });

    if (keyParam === undefined) {
      return { type: "Error", msg: "No key param given." };
    }

    if (typeof keyParam.value !== "string") {
      return { type: "Error", msg: "Key parameter isn't a string." };
    }

    try {
      const refGet = await this.db
        .collection(queryParams.type)
        .doc(keyParam.value)
        .get();

      const data = refGet.data();
      if (!refGet.exists || data === undefined) {
        return { type: "NotFound", msg: "Not Found." };
      }

      const value = decodeFromFirestore<T>(data);

      return {
        type: "Success",
        data: value,
      };
    } catch (e) {
      console.log(e);
      return { type: "Error", msg: e.toString() };
    }
  }

  private buildQuery<T extends KotoStorable>(
    queryParams: QueryParams<T>
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> = this.db.collection(
      queryParams.type
    );
    queryParams.params.forEach((param) => {
      query = query.where(param.fieldPath, param.op, param.value) as any;
    });

    if (queryParams.orderBy !== undefined) {
      query = query.orderBy(
        queryParams.orderBy.field,
        queryParams.orderBy.direction
      );
    }

    query = query.limit(25);

    return query;
  }

  subscribe<T extends KotoStorable>(
    queryParams: QueryParamsWithKey<T>,
    cb: (result: K.KotoResult<T>) => void,
    errorCb?: (error: firebase.firestore.FirestoreError) => void
  ): () => void {
    const keyParam = queryParams.params.find((param) => {
      return param.fieldPath === "key";
    });

    if (keyParam === undefined) {
      throw new Error("No key param given.");
    }

    if (typeof keyParam.value !== "string") {
      throw new Error("Key parameter isn't a string.");
    }

    const unsubscribe = this.db
      .collection(queryParams.type)
      .doc(keyParam.value)
      .onSnapshot((refGet) => {
        const data = refGet.data();
        if (!refGet.exists || data === undefined) {
          cb({ type: "NotFound", msg: "Not Found." });
        } else {
          const value = decodeFromFirestore<T>(data);

          cb({
            type: "Success",
            data: value,
          });
        }
      }, errorCb);

    return unsubscribe;
  }

  subscribeList<T extends KotoStorable>(
    queryParams: QueryParams<T>,
    cb: (result: K.KotoResult<T[]>) => void,
    errorCb?: (error: firebase.firestore.FirestoreError) => void
  ): () => void {
    const query = this.buildQuery(queryParams);

    const unsubscribe = query.onSnapshot((querySnapshot) => {
      const retval = this.handleListResponse(queryParams, querySnapshot);
      cb(retval);
    }, errorCb);

    return unsubscribe;
  }

  private handleListResponse<T extends KotoStorable>(
    queryParams: QueryParams<T>,
    result: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  ): K.KotoResult<T[]> {
    if (result.empty) {
      return {
        type: "NotFound",
        msg: "No Results",
      };
    } else {
      return {
        type: "Success",
        data: result.docs
          .map((val) => {
            const decodedData = val.data();
            try {
              return decodeFromFirestore<T>(decodedData);
            } catch (e) {
              console.error(
                `Could not decode value ${
                  val.id
                } from list query ${JSON.stringify(queryParams)}: \n ${e}`
              );
              return undefined;
            }
          })
          .filter(notEmpty),
      };
    }
  }

  async getList<T extends KotoStorable>(
    queryParams: QueryParams<T>
  ): Promise<K.KotoResult<T[]>> {
    const query = this.buildQuery(queryParams);

    try {
      const result = await query.get();

      if (result.empty) {
        return {
          type: "NotFound",
          msg: "No Results",
        };
      } else {
        return {
          type: "Success",
          data: result.docs
            .map((val) => {
              const decodedData = val.data();
              try {
                return decodeFromFirestore<T>(decodedData);
              } catch (e) {
                console.error(
                  `Could not decode value ${
                    val.id
                  } from list query ${JSON.stringify(queryParams)}: \n ${e}`
                );
                return undefined;
              }
            })
            .filter(notEmpty),
        };
      }
    } catch (e) {
      return {
        type: "Error",
        msg: `Query failed. ${e}`,
      };
    }
  }

  async getIndex<T extends KotoStorable>(
    queryParams: QueryParams<T>
  ): Promise<K.KotoResult<T[]>> {
    let query = this.db.collection(queryParams.type);
    queryParams.params.forEach((param) => {
      query = query.where(param.fieldPath, param.op, param.value) as any;
    });

    try {
      query = query.limit(25) as any;
      const result = await query.get();

      if (result.empty) {
        return {
          type: "NotFound",
          msg: "No Results",
        };
      } else {
        return {
          type: "Success",
          data: result.docs.map((val) => val.data()) as T[],
        };
      }
    } catch (e) {
      return {
        type: "Error",
        msg: `Query failed. ${e}`,
      };
    }
  }

  async storeIndex<T extends KotoStorable>(
    items: { key: string; index: string; value: T }[]
  ): Promise<K.KotoResult<boolean>> {
    try {
      const batch = this.db.batch();

      items.forEach(({ key, index, value }) => {
        batch.set(this.db.collection(index).doc(key), value);
      });

      await batch.commit();

      return { type: "Success", data: true };
    } catch (error) {
      return { type: "Error", msg: error };
    }
  }

  async updateIndex(
    items: { key: string; index: string; change: any }[]
  ): Promise<K.KotoResult<boolean>> {
    try {
      const batch = this.db.batch();

      items.forEach(({ key, index, change }) => {
        batch.set(this.db.collection(index).doc(key), change, { merge: true });
      });

      await batch.commit();

      return { type: "Success", data: true };
    } catch (error) {
      return { type: "Error", msg: error };
    }
  }
}

export const makeFirestoreStore = (
  userId: UserId,
  db: firebase.firestore.Firestore
): BackingStore => {
  const storage = new FirestoreStore(userId, db);

  return storage;
};
