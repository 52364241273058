import "../components/Questions";

import * as K from "./KotoOb";

import { QuestionSpecId, genQuestionSpecId } from "./IdTypes";
import {
  getQuestionTypeConfig,
  getQuestionTypeNames,
} from "./QuestionTypeRegistry";

export type QuestionTypeName =
  | "multipleChoiceQuestion"
  | "shortTextQuestion"
  | "longTextQuestion"
  | "checkboxQuestion"
  | "dropdownQuestion"
  | "dateQuestion";

export type QuestionAnswerName =
  | "multipleChoiceAnswer"
  | "shortTextAnswer"
  | "longTextAnswer"
  | "checkboxAnswer"
  | "dropdownAnswer"
  | "dateAnswer";

export interface QuestionDesignProps<T extends BaseQuestionSpec> {
  questionSpec: K.KotoFrozen<T>;

  // Because questions live inside blocks so they need to be edited using a function that
  // modifies the question in place rather than returning a new question. Otherwise co-editing
  // of text fields won't work.
  setQuestion: (fn: (question: T) => void) => void;
}

export interface QuestionFillerProps<
  Q extends BaseQuestionSpec,
  A extends QuestionAnswer
> {
  spec: K.KotoFrozen<Q>;
  answer: K.KotoFrozen<A>;
  setAnswer: (fn: (questionSpec: Q, answers: A) => void) => void;
}

export interface QuestionViewerProps<
  Q extends BaseQuestionSpec,
  A extends QuestionAnswer
> {
  spec: K.KotoFrozen<Q>;
  answer: K.KotoFrozen<A>;
}

export interface TextPart {
  default: K.KotoText;
  ghostText: K.KotoText;
  regex: K.KotoText;
  minLength: number;
  maxLength: number;
}

export interface DatePart {
  date: Date | null;
}

export type Choices = K.KotoList<K.KotoText>;

export interface ChoicePart {
  default: string;
  choices: Choices;
  allowOther: boolean;
}

export interface MultiChoicePart {
  default: string[];
  choices: Choices;
  allowOther: boolean;
}

export interface QuestionParts {
  [name: string]: TextPart | ChoicePart | MultiChoicePart;
}

export interface QuestionAnswer {
  type: QuestionAnswerName;
  isAnswered: boolean;
  parts: any;
}

export interface BaseQuestionSpec {
  type: QuestionTypeName;
  id: QuestionSpecId;
  label: K.KotoText;
  required: boolean;
  parts: any;
}

export const createBaseQuestionSpec = (
  type: QuestionTypeName
): BaseQuestionSpec => {
  return {
    id: genQuestionSpecId(),
    type: type,
    label: new K.KotoText("New Question"),
    required: false,
    parts: undefined,
  };
};

export const createQuestionSpecOfType = (type: string): BaseQuestionSpec => {
  const config = getQuestionTypeConfig(type);

  return config.createQuestionSpec();
};

export const changeQuestionType = (q: BaseQuestionSpec, type: string) => {
  const tempQuestion = createQuestionSpecOfType(type);

  q.type = tempQuestion.type;
  q.parts = tempQuestion.parts;
};

export const makeQuestionLabelUpdater = (newLabel: string) => {
  return (question: BaseQuestionSpec) => {
    K.performUpdateText(question.label, newLabel);
  };
};

export const makeQuestionRequiredToggler = () => {
  return (question: BaseQuestionSpec) => {
    question.required = !question.required;
  };
};

export const makeQuestionTypeSetter = (
  requestedQuestionType: QuestionTypeName
) => {
  return (question: BaseQuestionSpec) => {
    let goodValue: QuestionTypeName;
    const testValue = requestedQuestionType;

    if (
      !getQuestionTypeNames()
        .map((x) => x.value)
        .includes(testValue as QuestionTypeName)
    ) {
      console.trace("Bad question type choosen. ", requestedQuestionType);
      return;
    } else {
      goodValue = testValue as QuestionTypeName;
    }

    changeQuestionType(question, goodValue);
  };
};
