import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppFirebaseContext } from "../App/AppFirebaseContext";
import { AppHeaderBar } from "./AppHeader";
import { Authentication } from "../components/Authentication";
import { RootState } from "../Store/store";
import { authSlice } from "../Store/authSlice";
import firebase from "firebase/app";
import firebaseui from "firebaseui";
import { useLocation } from "react-router";

interface ClaimsDocumentData extends firebase.firestore.DocumentSnapshot {
  _lastCommitted?: firebase.firestore.Timestamp;
}

export const initializeAuth = async (
  dispatch: any,
  auth: firebase.auth.Auth,
  firestoreDb: firebase.firestore.Firestore,
  firebaseAuthUi: firebaseui.auth.AuthUI
) => {
  let unsubscribeClaimsListener: (() => void) | null = null;

  const listenToClaims = (user: firebase.User) => {
    unsubscribeClaimsListener = firestoreDb
      .collection("user-claims")
      .doc(user.uid)
      .onSnapshot(onNewClaims(user), (error) => {
        console.log(`Error getting new claims for user ${user.uid}: ${error}`);
      });
  };

  let lastCommitted: firebase.firestore.Timestamp | null = null;

  const onNewClaims = (user: firebase.User) => {
    return async (
      snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    ) => {
      const data = snapshot.data() as ClaimsDocumentData;
      console.log("New claims doc\n", data);
      if (data._lastCommitted) {
        if (lastCommitted && !data._lastCommitted.isEqual(lastCommitted)) {
          // Force a refresh of the user's ID token
          console.log("Refreshing token");
          await user.getIdToken(true);
        }
        lastCommitted = data._lastCommitted;
      }
    };
  };

  auth.onAuthStateChanged(function (user) {
    console.log("HERE! Auth state changed", user);

    if (user) {
      listenToClaims(user);
      // User is signed in.
      dispatch(authSlice.actions.setFirebaseUser(user.toJSON()));
      dispatch(authSlice.actions.setLoggingIn(false));
    } else if (firebaseAuthUi.isPendingRedirect()) {
      console.log("Pending login.");
    } else {
      // User is signed out.
      // ...
      if (unsubscribeClaimsListener) {
        unsubscribeClaimsListener();
      }
      dispatch(authSlice.actions.setFirebaseUser(null));
      dispatch(authSlice.actions.setLoggingIn(false));
    }
    // All the auth stuff happens asyncronously. So we display:none the whole body
    // until this observer runs. This way we avoid the flicker.
    // document.body.style.display = "";
  });

  auth.onIdTokenChanged((user) => {
    if (user) {
      console.log(`new ID token for ${user.uid}`);
    }
  });
};

export const AppAuthentication: FunctionComponent<{}> = () => {
  const dispatch = useDispatch();

  const { firebaseAuthUi } = useContext(AppFirebaseContext);

  const location = useLocation();

  const isLoggingIn = useSelector((state: RootState) => {
    return state.auth.ui.isLoggingIn;
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log(
      "HERE logging in: ",
      isLoggingIn,
      firebaseAuthUi?.isPendingRedirect(),
      isLoggingIn || firebaseAuthUi?.isPendingRedirect()
    );

    if (isLoggingIn || firebaseAuthUi?.isPendingRedirect()) {
      dispatch(authSlice.actions.setLoggingIn(true));
      const uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: (
            authResult: firebase.auth.UserCredential,
            _redirectUrl: string
          ) => {
            console.log("Sign in Successful! ", authResult);
            if (authResult.user === null) {
              console.log("Not user in auth result.");
              return false;
            }

            dispatch(
              authSlice.actions.setFirebaseUser(authResult.user.toJSON())
            );
            console.log(`HERE redirect: ${_redirectUrl}`);
            return false;
          },
          signInFailure: async (error: any) => {
            console.log("Sign in Failed. ", error);
            return;
          },
          uiShown: () => {
            console.log("HERE! Auth UI is shown");
            setIsLoading(false);
          },
        },
        signInSuccessUrl: location.pathname,
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        // Terms of service url.
        tosUrl: "<your-tos-url>",
        // Privacy policy url.
        privacyPolicyUrl: "<your-privacy-policy-url>",
        // signInSuccessUrl: redirectUrl,
      };
      if (firebaseAuthUi) {
        console.log("HERE! attaching UI");
        firebaseAuthUi?.start("#firebaseui-auth-container", uiConfig);
      }
    } else {
      dispatch(authSlice.actions.setLoggingIn(false));
      setIsLoading(false);
    }

    return () => {
      if (firebaseAuthUi) {
        firebaseAuthUi.delete().catch((reason) => {
          console.error(`Firebase Auth Ui delete failed. ${reason}`);
        });
      }
    };
  }, []);

  return (
    <Fragment>
      <div
        className={
          !isLoggingIn || firebaseAuthUi?.isPendingRedirect() ? "hidden" : ""
        }
      >
        <AppHeaderBar />

        <Authentication loading={isLoading} />
      </div>
    </Fragment>
  );
};
