import React, { FunctionComponent, Fragment } from "react";
import { Card } from "../Card";
import { Icon } from "../Buttons";

interface UserGroupsProps {
  // TODO: Add activeSection to scroll to and expand a specific section of the settings
  //   activeSection: string;
}

export const UserGroups: FunctionComponent<UserGroupsProps> = (_props) => {
  return (
    <Fragment>
      {/* TODO: This button should open a modal allowing the user to create a group and add members to it */}
      <div className="flex max-w-3xl my-8 mx-2 sm:mx-auto z-0;">
        <button className="btn btn-gray w-full">Create a new group</button>
      </div>
      {/* TODO: Search field should search through all the groups and show and hide the relevant ones below */}
      <div className="flex max-w-3xl my-4 mx-2 sm:mx-auto z-0;">
        <input
          className="k_search flex-1"
          type="search"
          placeholder="Search for a group"
        ></input>
      </div>
      <div>
        <Card isActive={false} isStacked={true}>
          <div className="">
            <span className="k_cardQuestionTitle">Donut Lovers</span>{" "}
            <span className="k_cardQuestionDescription text-gray-600">
              @donut Lovers
            </span>
          </div>
          <div className="k_cardQuestionDescription mb-6">
            Created by Ron Gorodetzky on January 8th, 2020
          </div>
          <div className="w-full flex ">
            {/* TODO: This button should open a modal that shows all the members in the group */}
            <button
              className="btn flex-1 btn-stacked-h"
              data-tooltip="Show members"
              tooltip-position="bottom"
            >
              <Icon fontAwesomeClassNames="fas fa-user mr-2"></Icon> 2{" "}
              <span className="hidden sm:inline-block">members</span>
            </button>
            {/* TODO: This button should open a modal that allows the user to edit the group title and description */}
            <button
              className="btn flex-1 btn-stacked-h"
              data-tooltip="Edit group details"
              tooltip-position="bottom"
            >
              <Icon fontAwesomeClassNames="fas fa-pencil"></Icon>
              <span className="hidden sm:inline-block ml-2">Edit info</span>
            </button>
            {/* TODO: This button should disable the group */}
            <button
              className="btn flex-1  btn-stacked-h"
              data-tooltip="Disable group"
              tooltip-position="bottom"
            >
              <Icon fontAwesomeClassNames="fas fa-ban"></Icon>
              <span className="hidden sm:inline-block ml-2">Disable</span>
            </button>
          </div>
        </Card>
        <Card isActive={false} isStacked={true}>
          <div className="">
            <span className="k_cardQuestionTitle">Donut Lovers</span>{" "}
            <span className="k_cardQuestionDescription text-gray-600">
              @donut Lovers
            </span>
          </div>
          <div className="k_cardQuestionDescription mb-6">
            Created by Ron Gorodetzky on January 8th, 2020
          </div>
          <div className="w-full flex ">
            {/* TODO: This button should open a modal that shows all the members in the group */}
            <button
              className="btn flex-1 btn-stacked-h"
              data-tooltip="Show members"
              tooltip-position="bottom"
            >
              <Icon fontAwesomeClassNames="fas fa-user mr-2"></Icon> 2{" "}
              <span className="hidden sm:inline-block">members</span>
            </button>
            {/* TODO: This button should open a modal that allows the user to edit the group title and description */}
            <button
              className="btn flex-1 btn-stacked-h"
              data-tooltip="Edit group details"
              tooltip-position="bottom"
            >
              <Icon fontAwesomeClassNames="fas fa-pencil"></Icon>
              <span className="hidden sm:inline-block ml-2">Edit info</span>
            </button>
            {/* TODO: This button should disable the group */}
            <button
              className="btn flex-1  btn-stacked-h"
              data-tooltip="Disable group"
              tooltip-position="bottom"
            >
              <Icon fontAwesomeClassNames="fas fa-ban"></Icon>
              <span className="hidden sm:inline-block ml-2">Disable</span>
            </button>
          </div>
        </Card>
        <Card isActive={false} isStacked={true}>
          <div className="">
            <span className="k_cardQuestionTitle">Donut Lovers</span>{" "}
            <span className="k_cardQuestionDescription text-gray-600">
              @donut Lovers
            </span>
          </div>
          <div className="k_cardQuestionDescription mb-6">
            Created by Ron Gorodetzky on January 8th, 2020
          </div>
          <div className="w-full flex ">
            {/* TODO: This button should open a modal that shows all the members in the group */}
            <button
              className="btn flex-1 btn-stacked-h"
              data-tooltip="Show members"
              tooltip-position="bottom"
            >
              <Icon fontAwesomeClassNames="fas fa-user mr-2"></Icon> 2{" "}
              <span className="hidden sm:inline-block">members</span>
            </button>
            {/* TODO: This button should open a modal that allows the user to edit the group title and description */}
            <button
              className="btn flex-1 btn-stacked-h"
              data-tooltip="Edit group details"
              tooltip-position="bottom"
            >
              <Icon fontAwesomeClassNames="fas fa-pencil"></Icon>
              <span className="hidden sm:inline-block ml-2">Edit info</span>
            </button>
            {/* TODO: This button should disable the group */}
            <button
              className="btn flex-1  btn-stacked-h"
              data-tooltip="Disable group"
              tooltip-position="bottom"
            >
              <Icon fontAwesomeClassNames="fas fa-ban"></Icon>
              <span className="hidden sm:inline-block ml-2">Disable</span>
            </button>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};
