import * as functions from "firebase-functions";

export const FnErrorMsg = (context: functions.EventContext, msg: string) => {
  return `${context.eventId}, ${context.eventType}, ${msg}`;
};

export function sleep(duration: number) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

export const retry = async (
  func: () => Promise<Error | null | undefined>,
  numRetries: number = 5,
  maxBackoffMs = 1000
): Promise<Error | undefined> => {
  let retriesLeft = numRetries;
  let lastError;

  while (retriesLeft > 0) {
    const startTime = Date.now();
    const funcResult = await func();
    const endTime = Date.now();

    await sleep(Math.min(endTime - startTime, maxBackoffMs));

    if (funcResult === undefined || funcResult === null) {
      return; /* Success */
    } else {
      lastError = funcResult;
      retriesLeft = retriesLeft - 1;
      continue;
    }
  }

  return lastError;
};
