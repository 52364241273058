import React, { useRef, useEffect, FunctionComponent } from "react";

/**
 * An element that facilitates dispalying and hiding an overlay.
 *
 *
 * This component causes the given callback to be called when a click happens
 * outside the component. This can be used to toggle a "visibility" variable
 * which is then used to hide the component.
 *
 *
 * @param props toggle: A function which flips value used for the visibility of this element

 */

interface OverlayProps {
  toggle: () => void;
  position?: string; // normal | top
}

export const Overlay: FunctionComponent<OverlayProps> = (props) => {
  const node = useRef<HTMLDivElement>(null);

  const handleClick = (e: MouseEvent) => {
    if (!e.target) {
      console.trace("Received event without a target.");
      return;
    }

    if (node && node.current) {
      if (e.target instanceof Element) {
        if (!node.current.contains(e.target)) {
          // outside click
          props.toggle();
          return;
        }
      } else {
        console.trace(
          "Received event with a target that isn't an element: " +
            String(e.target)
        );
      }
    } else {
      console.trace("node is null, but expected to find a parent node");
      return;
    }
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("click", handleClick, false);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("click", handleClick, false);
    };
  }, []);

  let overlayClass =
    "w-screen h-screen bg-smoke-25 fixed pin flex overflow-y-hidden items-center justify-center";
  if (props.position == "top") {
    overlayClass =
      "w-screen h-screen bg-smoke-25 fixed pin flex overflow-y-hidden items-start justify-center pt-8 ";
  }

  return (
    // TODO: Add JS to prevent body from scrolling when overlay backdrop is shown

    // <div className="w-screen h-screen bg-smoke-25 fixed pin flex overflow-y-hidden items-center justify-center ">
    //   <div ref={node} className="flex-initial flex justify-center">
    //     {props.children}
    //   </div>
    // </div>

    <div className={overlayClass}>
      <div ref={node} className="flex-initial flex justify-center">
        {props.children}
      </div>
    </div>
  );
};
