import { Project, getProjectColor } from "../types/Project";
import React, { Fragment, FunctionComponent } from "react"; // import for JSX

import { KotoOb } from "../types/KotoTypes";
import { Link } from "react-router-dom";
import { ProjectId } from "../types/IdTypes";

interface ProjectIconProps {
  showActiveIndicator: boolean;
  projectTitle: string;
  color: string;
}

export const ProjectIcon: FunctionComponent<ProjectIconProps> = (props) => {
  const projectInitials = props.projectTitle.split(" ").map((n) => n[0]);
  const projectIconStyle =
    "flex h-10 w-10 rounded border text-center text-xs justify-center items-center font-medium border-" +
    props.color +
    "-800 bg-" +
    props.color +
    "-400 text-" +
    props.color +
    "-900";

  let wrapper =
    "flex flex-initial my-1 items-center justify-center p-px rounded-lg border-4 border-gray-800 hover:border-gray-600 hover:bg-transparent cursor-pointer";
  if (props.showActiveIndicator) {
    wrapper =
      "flex flex-initial my-1 items-center justify-center p-px rounded-lg border-4 border-white hover:border-white hover:bg-transparent cursor-pointer";
  }

  return (
    <Fragment>
      <div className="flex items-center justify-center">
        <div className={wrapper}>
          <div className={projectIconStyle}>{projectInitials}</div>
        </div>
      </div>
    </Fragment>
  );
};

interface ProjectSideChooserProps {
  activeProject: ProjectId;
  projects: KotoOb<Project>[];
  projectsCreateLink: string;
  projectLinkBuilder: (id: ProjectId) => string;
}

export const ProjectSideChooser: FunctionComponent<ProjectSideChooserProps> = React.memo(
  (props) => {
    return (
      <Fragment>
        {" "}
        {/* Project List Column */}
        <div className="bg-gray-800 flex-initial w-20 h-screen border-r border-gray-900 hidden sm:block pt-3 no-underline overscroll-y-none ">
          {props.projects.map((project, idx) => {
            return (
              <Link
                key={idx}
                to={props.projectLinkBuilder(project.doc.id)}
                className="no-underline font-semibold"
              >
                <ProjectIcon
                  showActiveIndicator={props.activeProject === project.key}
                  projectTitle={project.doc.name}
                  color={getProjectColor(project.key)}
                />
              </Link>
            );
          })}
          {/* TODO: Hook this button up to the create project flow via modal */}
          <Link className="no-underline" to={props.projectsCreateLink}>
            <div className="flex items-center justify-center m-2 p-1 rounded-lg border-4 border-gray-800 cursor-pointer ">
              <div
                className="btn border-none h-12 w-12 flex items-center justify-center text-lg text-gray-600 hover:bg-gray-700 hover:text-white  shadow-none"
                data-tooltip="Add new project"
                tooltip-position="right"
              >
                <i className="fas fa-plus"></i>
              </div>
            </div>
          </Link>
        </div>
      </Fragment>
    );
  },
  (prevProps, nextProps) => {
    const vals = [
      prevProps.activeProject === nextProps.activeProject,
      ...prevProps.projects.map((val, idx) => {
        return val.docVersion === nextProps.projects[idx].docVersion;
      }),
    ];
    return vals.every((val) => val);
  }
);
