import * as K from "../types/KotoOb";

import { Block, loadBlocks } from "../types/Block";
import { KotoCRDT, KotoStorable } from "../types/KotoTypes";
import { ProjectId, RecordId, RecordSetId } from "../types/IdTypes";
import { Record, loadRecord, makeRecordKey } from "../types/Record";

import { KotoContext } from "../types/KotoContext";
import { QueryParams } from "../types/KotoStore";

export interface IndexedRecord extends KotoStorable {
  type: "IndexedRecord";
  key: string;
  record: Record;
  blocks: { [blockId: string]: Block };
}

export const collectRecordInfo = async (
  kContext: KotoContext,
  projectId: ProjectId,
  recordId: RecordId
): Promise<{ record: KotoCRDT<Record>; blocks: KotoCRDT<Block>[] }> => {
  const recordResult = await loadRecord(kContext, projectId, recordId);

  if (recordResult.type !== "Success") {
    throw new Error(
      `Failed to load record ${recordId} in project ${projectId}. ${JSON.stringify(
        recordResult
      )}`
    );
  }

  const record = recordResult.data;

  const blocksRequest = await loadBlocks(kContext, projectId, recordId);

  if (blocksRequest.type !== "Success") {
    throw new Error(
      `Failed to load blocks for record ${recordId} in project ${projectId}. ${JSON.stringify(
        blocksRequest
      )}`
    );
  }

  const blocks = blocksRequest.data;
  blocks.sort((a, b) => {
    return a.createdTimeInMilliseconds - b.createdTimeInMilliseconds;
  });

  return { record, blocks };
};

export const createIndexedRecord = (
  record: KotoCRDT<Record>,
  blocks: KotoCRDT<Block>[]
): IndexedRecord => {
  return {
    type: "IndexedRecord",
    key: record.key,
    record: K.getKotoObFromKotoCRDT(record).doc,
    blocks: blocks.reduce((prev, cur) => {
      return { ...prev, [cur.crdt.id]: K.getKotoObFromKotoCRDT(cur).doc };
    }, {}),
  };
};

export const indexGivenRecord = async (
  kContext: KotoContext,
  record: KotoCRDT<Record>
) => {
  const indexedRecord = createIndexedRecord(record, []);

  const result = await kContext.storage.updateIndex([
    {
      key: indexedRecord.key,
      index: indexedRecord.type,
      change: { record: indexedRecord.record },
    },
  ]);

  return result;
};

export const indexRecord = async (
  kContext: KotoContext,
  projectId: ProjectId,
  recordId: RecordId
) => {
  const { record, blocks } = await collectRecordInfo(
    kContext,
    projectId,
    recordId
  );

  const indexedRecord = createIndexedRecord(record, blocks);

  const result = await kContext.storage.storeIndex([
    { key: indexedRecord.key, index: indexedRecord.type, value: indexedRecord },
  ]);

  return result;
};

const genRecordsListQuery = (
  projectId: ProjectId,
  recordSetId: RecordSetId,
  deleted: boolean = false,
  archived: boolean = false
): QueryParams<IndexedRecord> => {
  return {
    type: "IndexedRecord",
    params: [
      {
        fieldPath: "record.projectId",
        op: "==",
        value: projectId,
      },
      { fieldPath: "record.recordSetId", op: "==", value: recordSetId },
      { fieldPath: "record.deleted", op: "==", value: deleted ? 1 : 0 },
      { fieldPath: "record.archived", op: "==", value: archived ? 1 : 0 },
    ],
  };
};

export const loadRecordsList = async (
  kContext: KotoContext,
  projectId: ProjectId,
  recordSetId: RecordSetId,
  deleted: boolean = false,
  archived: boolean = false
) => {
  const result = await kContext.storage.getIndex<IndexedRecord>(
    genRecordsListQuery(projectId, recordSetId, deleted, archived)
  );

  if (result.type !== "Success") {
    return result;
  } else {
    return {
      type: result.type,
      data: result.data,
    };
  }
};

export const subscribeRecordsList = (
  kContext: KotoContext,
  projectId: ProjectId,
  recordSetId: RecordSetId,
  deleted: boolean = false,
  archived: boolean = false,
  cb: (result: K.KotoResult<IndexedRecord[]>) => void,
  errorCb: (err: any) => void
) => {
  const unSub = K.subscribeIndex(
    kContext,
    genRecordsListQuery(projectId, recordSetId, deleted, archived),
    cb,
    errorCb
  );

  return unSub;
};

export const addBlockToRecordIndex = async (
  kContext: KotoContext,
  block: Block
) => {
  const recordKey = makeRecordKey(block.projectId, block.recordId);

  const change = { blocks: { [block.id]: block } };

  const result = await kContext.storage.updateIndex([
    {
      key: recordKey,
      index: "IndexedRecord",
      change: change,
    },
  ]);

  return result;
};
