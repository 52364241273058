import { AppThunk, RootState } from "./store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Project, loadUserProjects } from "../types/Project";
import { ProjectId, RecordSetId } from "../types/IdTypes";

import { KotoCRDT } from "../types/KotoTypes";
import { KotoContext } from "../types/KotoContext";
import { notEmpty } from "../utils/utils";

export interface ProjectsState {
  activeProject: ProjectId;
  projects: { [projectId in ProjectId]: KotoCRDT<Project> };
}

export const projectsSlice = createSlice({
  name: "project",
  initialState: {
    projects: {},
  } as ProjectsState,
  reducers: {
    setProjects: (state, action: PayloadAction<KotoCRDT<Project>[]>) => {
      const projectList = action.payload;
      state.projects = projectList.reduce((prev, cur) => {
        prev[cur.key] = cur;
        return prev;
      }, {} as ProjectsState["projects"]);
    },
    updateProject: (state, action: PayloadAction<KotoCRDT<Project>>) => {
      state.projects[action.payload.key] = action.payload;
    },
  },
});

export const loadProjects = (kContext: KotoContext): AppThunk => {
  return async (dispatch) => {
    if (kContext.userId) {
      const projects = await loadUserProjects(kContext, kContext.userId);
      dispatch(projectsSlice.actions.setProjects(projects));
    } else {
      console.log("Can't load projects, user is not loaded yet.");
    }
  };
};

export const projectSelector = (projectId: ProjectId) => {
  return (state: RootState) => {
    return state.projects.projects[projectId];
  };
};

export const recordSetSelector = (
  projectId: ProjectId,
  recordSetId: RecordSetId
) => {
  return (state: RootState) => {
    const project = projectSelector(projectId)(state);
    return project.crdt.recordSets[recordSetId];
  };
};

export const friendlyColumnNameSelector = (
  projectId: ProjectId,
  recordSetId: RecordSetId
) => {
  return (state: RootState) => {
    const friendlyNameConfig = recordSetSelector(projectId, recordSetId)(state)
      .friendlyName;
    if (friendlyNameConfig === undefined) {
      return null;
    } else {
      const labels = friendlyNameConfig.friendlyParts
        .map((part) => {
          const blockSpec =
            state.blockSpecSlice.blockSpecMap[part.blockSpecKey];
          const questionSpec = blockSpec.doc.questions.find((q) => {
            return q.id === part.questionSpecId;
          });

          if (questionSpec === undefined) {
            console.log(`Could not find questionSpec ${part.questionSpecId}`);
            return null;
          }
          return questionSpec.label;
        })
        .filter(notEmpty);
      return labels.join(", ");
    }
  };
};
