export interface UiTheme {
  primary: string;
  secondary: string;
  tertiary: string;
  primaryLight: string;
  primaryDark: string;
  secondaryLight: string;
  secondaryDark: string;
  tertiaryLight: string;
  tertiaryDark: string;
  danger: string;
  dangerDark: string;
  warning: string;
  warningDark: string;
  success: string;
  successDark: string;
  link: string;
  linkDark: string;
  lightGrey: string;
  black: string;
  white: string;
  [key: string]: string;
}

export const defaultTheme: UiTheme = {
  primary: "#0D3F67",
  secondary: "#1EE3CF",
  tertiary: "#6B51FB",
  primaryLight: "#1876C2",
  primaryDark: "#08253C",
  secondaryLight: "#79EEE2",
  secondaryDark: "#18B8A8",
  tertiaryLight: "#C0B5FD",
  tertiaryDark: "#5943D0",
  danger: "#CD273F",
  dangerDark: "#A21F32",
  warning: "#FCB941",
  warningDark: "#D19A36",
  success: "#25D6A8",
  successDark: "#1EAB87",
  link: "#3734DB",
  linkDark: "#1EAB87",
  lightGrey: "#F2F4F6",
  black: "#393939",
  white: "#FFFFFF",
};

export interface Action {
  type: string;
  payload: any;
}

export interface ActionTarget {
  icon: string;
  text: string;
  link: string;
  description: string;
  isActive: boolean;
  action: Action;
}

export const defaultAction: ActionTarget = {
  icon: "menu",
  text: "Menu",
  link: "/defaultLink",
  description: "Default Menu",
  isActive: false,
  action: { type: "", payload: "" },
};

export const defaultEditAction: ActionTarget = {
  icon: "edit",
  text: "Edit",
  link: "/defaultLink",
  description: "Edit Record",
  isActive: false,
  action: { type: "", payload: "" },
};

export interface FooterUi {
  leftActions: ActionTarget[];
  middleActions: ActionTarget[];
  rightActions: ActionTarget[];
}

const defaultFooterUi: FooterUi = {
  leftActions: [defaultEditAction],
  middleActions: [],
  rightActions: [defaultAction],
};

export interface UiView {
  header: HeaderUi;
  navigation: NavUi;
  footer: FooterUi;
}

export interface HeaderUi {
  topLeftAction: ActionTarget;
  topTabs: ActionTarget[];
  activeTabIndex: number;
  actions: { toggleTabs: Action };
  label?: {
    primary: string;
    secondary?: string;
  };
}

export const defaultHeaderUi: HeaderUi = {
  topLeftAction: {
    icon: "hamburger",
    text: "Menu",
    description: "Sidebar",
    link: "#",
    isActive: false,
    action: { type: "", payload: "" },
  },
  topTabs: [
    {
      icon: "",
      text: "First",
      description: "First Item",
      link: "",
      isActive: false,
      action: { type: "", payload: "" },
    },
    {
      icon: "",
      text: "Second",
      description: "Second Item",
      link: "",
      isActive: false,
      action: { type: "", payload: "" },
    },
  ],
  activeTabIndex: 1,
  actions: { toggleTabs: { type: "link", payload: "#" } },
};

export interface NavSection {
  title: string;
  active: string;
  links: ActionTarget[];
}

export interface OrganizationInfo {
  icon: string;
  name: string;
}

const defaultOrganizationInfo: OrganizationInfo = {
  icon: "koto",
  name: "koto",
};

export interface NavUi {
  visible: boolean;
  organization: OrganizationInfo;
  sections: NavSection[];
}

const defaultNavUi: NavUi = {
  visible: false,
  organization: defaultOrganizationInfo,
  sections: [
    {
      title: "",
      active: "task",
      links: [
        {
          icon: "task",
          text: "Tasks",
          description: "Tasks explorer",
          link: "/tasks",
          isActive: false,
          action: { type: "", payload: "" },
        },
        {
          icon: "record",
          text: "Records",
          description: "Records",
          link: "/records",
          isActive: false,
          action: { type: "", payload: "" },
        },
        {
          icon: "dashboard",
          text: "Dashboard",
          description: "Dashboard",
          link: "/dashboard",
          isActive: false,
          action: { type: "", payload: "" },
        },
        {
          icon: "manage",
          text: "Manage Project",
          description: "Manage Project",
          link: "/manage",
          isActive: true,
          action: { type: "", payload: "" },
        },
      ],
    },
  ],
};

export const defaultUiView: UiView = {
  header: defaultHeaderUi,
  navigation: defaultNavUi,
  footer: defaultFooterUi,
};
