import React, { FunctionComponent, ReactElement, ReactNode } from "react";
import { useHistory } from "react-router";
import { useQuery } from "../utils/reactUtils";

export interface TabHeaderProps {
  children: ReactNode;
}
export const TabHeader: FunctionComponent<TabHeaderProps> = (_props) => {
  return <></>;
};

interface TabHeaderInternalProps {
  active: boolean;
  disabled?: boolean;
  onClick: () => void;
  type: string; // default | nav
}

const TabHeaderInternal: FunctionComponent<TabHeaderInternalProps> = (
  props
) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.onClick();
  };

  let activeStyles = "k_defaultTabBase k_defaultTabActive";
  let disabledStyles = "k_defaultTabBase k_defaultTabDisabled";
  let normalStyles = "k_defaultTabBase k_defaultTabNormal";

  if (props.type == "nav") {
    activeStyles = "k_navTabBase k_navTabActive";
    disabledStyles = "k_navTabBase k_navTabDisabled";
    normalStyles = "k_navTabBase k_navTabNormal";
  }

  if (props.active && props.disabled) {
    console.log(
      "WARNING: A tab component cannot be active and disabled at the same time!"
    );
    return <a></a>;
  } else if (props.active) {
    return (
      //       <a className="k_navTabBase k_navTabActive" href="#" onClick={handleClick}>
      <a className={activeStyles} href="#" onClick={handleClick}>
        {props.children}
      </a>
    );
  } else if (props.disabled) {
    return (
      //    <span className="k_navTabBase k_navTabDisabled">{props.children}</span>
      <span className={disabledStyles}>{props.children}</span>
    );
  } else {
    return (
      //   <a className="k_navTabBase k_navTabNormal" href="#" onClick={handleClick}>
      <a className={normalStyles} href="#" onClick={handleClick}>
        {props.children}
      </a>
    );
  }
};

export interface TabContentProps {}
export const TabContent: FunctionComponent<TabContentProps> = (props) => {
  return <>{props.children}</>;
};

export interface TabProps {
  label: string;
  children: [ReactElement<TabHeaderProps>, ReactElement<TabContentProps>];
}
export const Tab: FunctionComponent<TabProps> = (_props) => {
  return <></>;
};

export interface TabbedViewProps {
  activeView: string;
  selectTab: (label: string) => void;
  children: ReactElement<TabProps>[];
  type: string; // default | nav
}
export const TabbedView: FunctionComponent<TabbedViewProps> = (props) => {
  const headers: ReactElement<TabHeaderInternalProps>[] = [];
  let content;

  let borderStyles =
    "w-full overflow-x-auto sm:bg-gray-200 k_defaultTabBorder ";
  let spacingStyles = "flex k_defaultTabSpacing";
  let backgroundStyles = "k_defaultTabBackground";

  if (props.type == "nav") {
    borderStyles = "w-full overflow-x-auto bg-gray-200 k_navTabBorder ";
    spacingStyles = "flex k_navTabSpacing "; // sm:w-11/12 sm:mx-auto
    backgroundStyles = "k_navTabBackground";
  }

  const history = useHistory();
  const queryParams = useQuery();
  let activeView = props.activeView;
  const tabQueryArg = queryParams.get("tab");
  if (tabQueryArg !== null) {
    activeView = tabQueryArg;
  }

  props.children.map((child, idx) => {
    const headerTab = child.props.children[0];

    headers.push(
      <TabHeaderInternal
        type={props.type}
        key={idx}
        active={activeView === child.props.label}
        onClick={() => {
          props.selectTab(child.props.label);
          history.push({ search: `?tab=${child.props.label}` });
        }}
      >
        {headerTab.props.children}
      </TabHeaderInternal>
    );

    if (activeView === child.props.label) {
      content = child.props.children[1];
    }
  });

  return (
    <>
      <div className="">
        <div className={backgroundStyles}>
          {/* <nav className="w-full overflow-x-auto sm:bg-gray-200 k_navTabBorder z-30"> */}
          <nav className={borderStyles}>
            {/* flex sm:w-11/12 sm:mx-auto */}
            <div className={spacingStyles}>
              {headers}
              <div className="hidden sm:block sm:flex-grow "></div>
            </div>
          </nav>
        </div>
      </div>
      {content}
    </>
  );
};
