import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProjectId, RecordId } from "../types/IdTypes";

import { AppThunk } from "./store";
import { Block } from "../types/Block";
import { Form } from "../types/Form";
import { KotoCRDT } from "../types/KotoTypes";
import { KotoContext } from "../types/KotoContext";
import { Record } from "../types/Record";
import { RecordViews } from "../components/Record";
import { ReminderListItemType } from "../components/Reminders";
import { loadReminders } from "../types/Reminder";

export interface RecordSlice {
  record: KotoCRDT<Record> | null;
  blocks: KotoCRDT<Block>[];
  recordView: RecordViews;
  reminderList: ReminderListItemType[];
}

export const recordSlice = createSlice({
  name: "record",
  initialState: {
    record: null,
    blocks: [],
    recordView: "overview",
    reminderList: [],
  } as RecordSlice,
  reducers: {
    setRecord: (state, action: PayloadAction<KotoCRDT<Record>>) => {
      state.record = action.payload;
    },
    setBlocks: (state, action: PayloadAction<KotoCRDT<Block>[]>) => {
      state.blocks = action.payload;
    },
    setRecordView: (state, action: PayloadAction<RecordViews>) => {
      state.recordView = action.payload;
    },
    setReminderList: (state, action: PayloadAction<ReminderListItemType[]>) => {
      state.reminderList = action.payload;
    },
  },
});

export const loadRemindersForRecord = (
  kContext: KotoContext,
  projectId: ProjectId,
  recordId: RecordId,
  availableForms: KotoCRDT<Form>[]
): AppThunk => {
  return async (dispatch) => {
    const remindersResults = await loadReminders(kContext, projectId, recordId);

    if (remindersResults.type === "Error") {
      throw new Error(`Could not load reminders for ${recordId}.`);
    } else if (remindersResults.type === "NotFound") {
      dispatch(recordSlice.actions.setReminderList([]));
    } else {
      dispatch(
        recordSlice.actions.setReminderList(
          remindersResults.data.map((r) => {
            const formName =
              availableForms
                .find((form) => {
                  return form.key === r.crdt.reminderInfo.formKey;
                })
                ?.crdt.title.toString() || "Unknown Form";

            const displayDate = r.crdt.completed
              ? new Date(r.crdt.completedTimestamp).toLocaleString()
              : r.crdt.dueDate?.toDateString() || "no due date";

            return {
              formTitle: formName,
              formKey: r.crdt.reminderInfo.formKey,
              date: displayDate,
              status: r.crdt.completed ? "complete" : "new",
            };
          })
        )
      );
    }
  };
};
