import {
  InviteModal,
  Modal,
  ModalActionButton,
  ModalButton,
  ModalContent,
} from "./Modals";
import { Menu, MenuItem, MenuItemIcon, MenuItemList } from "./Buttons";
import React, {
  Fragment,
  FunctionComponent,
  KeyboardEvent,
  MouseEvent,
  useState,
} from "react";
import {
  buildAccountsSettingsPath,
  buildProjectSettingsPath,
} from "../App/AppRoutes";

import { KotoCRDT } from "../types/KotoTypes";
import { KotoContext } from "../types/KotoContext";
import { Link } from "react-router-dom";
import { Project } from "../types/Project";
import { ProjectIcon } from "./ProjectSideChooser";
import { ProjectId } from "../types/IdTypes";
import { ProjectList } from "./NavigationList";

// import for JSX

interface SideNavigationProps {
  kContext: KotoContext;
  projectName: string; // The name of the Organization
  userDisplayName: string; // The user's display name
  expanded: boolean;
  setNotExpanded: () => void;
  projects: KotoCRDT<Project>[];
  activeProject: ProjectId;
  projectsCreateLink: string;
  projectLinkBuilder: (id: ProjectId) => string;
  projectsReloader: () => void;
}

/**
 * The side navigation component.
 *
 * Provides a menu to the various top-level areas of Koto.
 *
 * @param props see SideNavigationProps
 */
export const SideNavigation: FunctionComponent<SideNavigationProps> = (
  props
) => {
  const aria_expanded = props.expanded;

  // TODO: Cleanup and merge these functions
  const handleCollapseKeyboard = (event: KeyboardEvent): void => {
    // Return if key pressed was not Space Bar or Enter
    if (
      event.type === "keydown" &&
      event.keyCode &&
      event.keyCode !== 13 &&
      event.keyCode !== 32
    ) {
      return;
    }

    event.preventDefault();
    props.setNotExpanded();
  };

  const handleCollapseMouse = (event: MouseEvent): void => {
    event.preventDefault();
    props.setNotExpanded();
  };

  const [projectMenuOpen, setProjectMenuOpen] = useState(false);

  return (
    <Fragment>
      <nav
        id="sidebar"
        className="sidebar overflow-visible"
        aria-label="Sidebar"
        aria-expanded={aria_expanded}
      >
        <div className="flex overflow-visible">
          {/* TODO: If ProjectSideChooser is enabled, show */}
          {/* <ProjectSideChooser
            activeProject={props.activeProject}
            projects={props.projects}
            projectsCreateLink={props.projectsCreateLink}
            projectLinkBuilder={props.projectLinkBuilder}
          /> */}

          {/* App Menu/Navigation Column */}
          <div className="flex-initial h-screen bg-gray-800 w-72 relative overflow-visible">
            <div className="sr-only bg-gray-800 flex relative text-white text-center border-b border-gray-700">
              <a
                href="#sidebar-toggle"
                id="sidebar-close"
                className="btn bg-gray-900 border-gray-700 flex-1 hover:bg-gray-700 menu-close block text-gray-400 rounded no-underline my-4 mx-4 text-sm uppercase font-normal hover:text-white"
                aria-label="Close sidebar"
                onKeyDown={handleCollapseKeyboard}
                onClick={handleCollapseMouse}
              >
                <i className="fas fa-times fa-fw mr-2 " aria-hidden="true"></i>
                Close sidebar
              </a>
            </div>

            <div className="bg-gray-800 flex text-white focus:outline-none">
              <MainMenu
                projectID={props.activeProject}
                organizationName={props.projectName}
                userDisplayName={props.userDisplayName}
              ></MainMenu>
            </div>
            <div className="bg-gray-800 text-white py-2 px-4 relative">
              <ul>{props.children}</ul>
            </div>

            <div className="  absolute bottom-0 left-0 w-full flex">
              <div className="flex-1 mx-4 mb-4 ">
                <Modal
                  title="Switch workspace"
                  modalOpen={projectMenuOpen}
                  setModalOpen={setProjectMenuOpen}
                >
                  <ModalButton extraClassNames="btn w-full border-gray-600 text-gray-400 font-light hover:bg-gray-900 hover:border-gray-700 hover:text-gray-400 relative">
                    <i className="fa-fw fas fa-repeat-alt mr-2" />
                    Switch workspace
                  </ModalButton>
                  <ModalContent>
                    <div className="sm:w-xl md:w-2xl overflow-visible">
                      <ProjectList
                        projects={props.projects}
                        kContext={props.kContext}
                        projectsReloader={props.projectsReloader}
                      ></ProjectList>
                    </div>
                  </ModalContent>
                  <Link to={props.projectsCreateLink}>
                    <ModalActionButton extraClassNames="btn-violet">
                      Create New Workspace
                    </ModalActionButton>
                  </Link>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <a
        href="#sidebar-toggle"
        className="backdrop"
        tabIndex={-1}
        hidden
        aria-hidden={true}
        aria-expanded={aria_expanded}
        onClick={handleCollapseMouse}
      ></a>
    </Fragment>
  );
};

interface MainMenuProps {
  organizationName: string; // The name of the Organization
  userDisplayName: string; // The user's display name
  projectID: ProjectId;
}

export const MainMenu: FunctionComponent<MainMenuProps> = React.memo(
  (props) => {
    const [appMenuButtonOpen, setAppMenuButtonOpen] = useState(false);
    const [inviteModalOpen, setInviteModalOpen] = useState(false);

    return (
      <Fragment>
        <Menu
          menuOpen={appMenuButtonOpen}
          setMenuOpen={setAppMenuButtonOpen}
          extraClassNames="w-full rounded-lg sm:rounded border-0 focus:outline-none"
        >
          <button className="w-full bg-gray-800 hover:bg-gray-900 py-6 px-8 text-left flex items-center border-0 rounded-none shadow-none hover:shadow-none focus:outline-none">
            <div className="flex-1 ">
              <span className="block font-bold text-white text-lg">
                {props.organizationName}
              </span>
              <span className="block font-light text-gray-400 text-sm">
                {props.userDisplayName}
              </span>
            </div>
            <div className="flex-initial">
              <i className="text-xs fas fa-chevron-down text-gray-400 ml-3 p-1"></i>
            </div>
          </button>
          <MenuItemList
            position="bottom-left"
            extraClassNames="sm:shadow-lg bg-white border-gray-400 sm:w-auto"
          >
            <div className="bg-gray-100 text-gray-700 py-2 border-b border-gray-300">
              <div className="py-2 px-5 flex items-center">
                <div className="mr-4 flex-initial">
                  <ProjectIcon
                    showActiveIndicator={false}
                    projectTitle="GoodCTZN"
                    color="orange"
                  ></ProjectIcon>
                </div>
                <div className="flex-grow">
                  <div className="text-lg font-medium">
                    {props.organizationName}
                  </div>
                  <div className="text-gray-600">
                    koto.team/{props.projectID}
                  </div>
                </div>
              </div>
            </div>
            <MenuItem
              extraClassNames="sm:py-4 flex items-center"
              linkTo={buildAccountsSettingsPath()}
              target="_blank"
              onClick={() => setAppMenuButtonOpen(false)}
            >
              <div className="flex-grow">
                <MenuItemIcon fontAwesomeClassNames="fa-fw fas fa-user" />
                Manage your account{" "}
              </div>
              <i className="far fa-external-link-square-alt ml-2 flex-initial text-gray-500"></i>
            </MenuItem>

            <MenuItem
              extraClassNames="sm:py-4"
              linkTo={buildProjectSettingsPath(props.projectID)}
            >
              <MenuItemIcon fontAwesomeClassNames="fa-fw far fa-cog" />
              Workspace Settings
            </MenuItem>

            <MenuItem extraClassNames="sm:py-4">
              <MenuItemIcon fontAwesomeClassNames="fa-fw far fa-question-circle" />
              Help
            </MenuItem>

            <MenuItem
              extraClassNames="sm:py-4"
              onClick={() => {
                setInviteModalOpen(true);
                setAppMenuButtonOpen(false);
              }}
            >
              <MenuItemIcon fontAwesomeClassNames="fa-fw far fa-user-plus" />
              Invite people
            </MenuItem>

            <MenuItem extraClassNames="sm:py-4" linkTo="/logout">
              <MenuItemIcon fontAwesomeClassNames="fa-fw far fa-sign-out-alt" />
              Sign Out
            </MenuItem>
            {/* TODO: When the user clicks this button, close the menu */}
            <div className="flex">
              <button
                className="btn mx-5 mb-4 flex-grow sm:hidden"
                onClick={() => setAppMenuButtonOpen(false)}
              >
                Close
              </button>
            </div>
          </MenuItemList>
        </Menu>
        <InviteModal
          modalOpen={inviteModalOpen}
          setModalOpen={setInviteModalOpen}
          projectName={props.organizationName}
        ></InviteModal>
      </Fragment>
    );
  }
);
