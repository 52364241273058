import * as K from "./KotoOb";

import {
  KotoBaseDoc,
  KotoCRDT,
  KotoKey,
  KotoSerializedCRDT,
} from "../types/KotoTypes";
import {
  ProjectId,
  QuestionSpecId,
  RecordSetId,
  UserId,
  genProjectId,
  genRecordSetId,
  parseId,
} from "./IdTypes";

import { KotoContext } from "./KotoContext";
import { QueryParamsWithKey } from "./KotoStore";
import { loadUser } from "./User";
import { validateFormKey } from "./Form";

const PROJECT_COLORS = ["red", "teal", "blue"];

export interface FriendlyNamePart {
  blockSpecKey: KotoKey;
  questionSpecId: QuestionSpecId;
}

export interface RecordSet {
  id: RecordSetId;
  name: K.KotoText;
  description?: K.KotoText;
  isPrivate: boolean;
  deleted: number;
  archived: number;
  validForms?: KotoKey[]; // Form keys
  friendlyName?: {
    hideNotice: boolean;
    friendlyParts: FriendlyNamePart[];
  };
}

interface RecordSetCollection {
  [name: string /* RecordSetId */]: RecordSet;
}

export interface Project extends KotoBaseDoc {
  id: ProjectId;
  type: "Project";
  name: K.KotoText;
  url: K.KotoText;
  owners: UserId[];
  recordSets: RecordSetCollection;
  archived: number;
  deleted: number;
}

export const createProject = (
  kContext: KotoContext,
  name: string
): KotoCRDT<Project> => {
  const projectId = genProjectId();
  const defaultRecordSetId = genRecordSetId();
  const kCrdt: KotoCRDT<Project> = K.create(kContext, {
    id: projectId,
    type: "Project",
    name: new K.KotoText(name),
    url: new K.KotoText(projectId),
    owners: [kContext.userId],
    recordSets: {
      [defaultRecordSetId]: {
        id: defaultRecordSetId,
        name: new K.KotoText(),
        isPrivate: false,
        deleted: 0,
        archived: 0,
      },
    },
    archived: 0,
    deleted: 0,
  });

  return kCrdt;
};

export const createAndStoreProject = async (
  kContext: KotoContext,
  name: string
): Promise<KotoCRDT<Project>> => {
  const kCrdt: KotoCRDT<Project> = createProject(kContext, name);
  const result = await K.storeCrdt(kContext, kCrdt);

  if (result.type !== "Success") {
    throw new Error(
      `Failed to store Project ${kCrdt.key}. ${result.type}, ${result.msg}`
    );
  }

  return kCrdt;
};

export const loadProject = async (
  kContext: KotoContext,
  projectId: ProjectId
): Promise<K.KotoResult<KotoCRDT<Project>>> => {
  const query: QueryParamsWithKey<KotoSerializedCRDT<Project>> = {
    type: "Project",
    params: [
      {
        fieldPath: "key",
        op: "==",
        value: projectId,
      },
      {
        fieldPath: "doc.owners",
        op: "array-contains",
        value: kContext.userId,
      },
    ],
  };

  return K.getCrdt(kContext, query);
};

export const loadUserProjects = async (
  kContext: KotoContext,
  userId: UserId
): Promise<KotoCRDT<Project>[]> => {
  const result = await loadUser(kContext, userId);
  if (result.type === "Success") {
    const projectIds = result.data.crdt.projects;
    const projectsLookups = await Promise.all(
      projectIds.map((pid) => {
        return loadProject(kContext, pid);
      })
    );

    const projects = projectsLookups
      .map((successes) => {
        if (successes.type === "Success") {
          let proj = successes.data;

          return proj;
        }
        return null;
      })
      .filter((val): val is KotoCRDT<Project> => {
        return (
          val !== null && val.crdt.deleted === 0 && val.crdt.archived === 0
        );
      });

    return projects;
  } else {
    return [];
  }
};

export const getProjectColor = (projectId: ProjectId) => {
  const idInt = parseId(projectId).randomBits;
  return PROJECT_COLORS[idInt % PROJECT_COLORS.length];
};

export const addRecordSet = (
  kContext: KotoContext,
  project: KotoCRDT<Project>,
  name: string,
  description: string,
  isPrivate: boolean
): [KotoCRDT<Project>, RecordSetId] => {
  const recordSetId = genRecordSetId();
  const newProject = K.change(kContext, project, (doc) => {
    doc.recordSets[recordSetId] = {
      id: recordSetId,
      name: new K.KotoText(name),
      description: new K.KotoText(description),
      isPrivate: isPrivate,
      archived: 0,
      deleted: 0,
    };
  });

  return [newProject, recordSetId];
};

export const addAndStoreRecordSet = (
  kContext: KotoContext,
  project: KotoCRDT<Project>,
  name: string,
  description: string,
  isPrivate: boolean
): [KotoCRDT<Project>, RecordSetId] => {
  const [newProject, recordSetId] = addRecordSet(
    kContext,
    project,
    name,
    description,
    isPrivate
  );

  K.storeCrdt(kContext, newProject);

  return [newProject, recordSetId];
};

export const getRecordSetName = (recordSet: RecordSet) => {
  return recordSet.name.toString() || "Records";
};

export const deleteProject = (
  kContext: KotoContext,
  project: KotoCRDT<Project>
): KotoCRDT<Project> => {
  return K.change(kContext, project, (doc) => {
    doc.deleted = 1;
  });
};

export const archiveProject = (
  kContext: KotoContext,
  project: KotoCRDT<Project>
): KotoCRDT<Project> => {
  return K.change(kContext, project, (doc) => {
    doc.archived = 1;
  });
};

export const deleteRecordSetFromProject = (
  kContext: KotoContext,
  project: KotoCRDT<Project>,
  recordSetId: RecordSetId
): KotoCRDT<Project> => {
  return K.change(kContext, project, (doc) => {
    doc.recordSets[recordSetId].deleted = 1;
  });
};

export const archiveRecordSetFromProject = (
  kContext: KotoContext,
  project: KotoCRDT<Project>,
  recordSetId: RecordSetId
): KotoCRDT<Project> => {
  return K.change(kContext, project, (doc) => {
    doc.recordSets[recordSetId].archived = 1;
  });
};

export const updateRecordSetSettings = (
  kConext: KotoContext,
  project: KotoCRDT<Project>,
  recordSetId: RecordSetId,
  settings: RecordSet
): KotoCRDT<Project> => {
  if (recordSetId !== settings.id) {
    throw new Error("The new settings ID must match the record set id.");
  }

  if (!(recordSetId in project.crdt.recordSets)) {
    throw new Error(
      `Record set ${recordSetId} does not exist on project ${project.key}.`
    );
  }
  return K.change(kConext, project, (doc) => {
    doc.recordSets[recordSetId] = settings;
  });
};

export const updateRecordSetName = (
  kContext: KotoContext,
  project: KotoCRDT<Project>,
  recordSetId: RecordSetId,
  newName: string
): KotoCRDT<Project> => {
  if (!(recordSetId in project.crdt.recordSets)) {
    throw new Error(
      `Record set ${recordSetId} does not exist on project ${project.key}.`
    );
  }
  return K.change(kContext, project, (doc) => {
    K.performUpdateText(doc.recordSets[recordSetId].name, newName);
  });
};

export const updateRecordSetDescription = (
  kContext: KotoContext,
  project: KotoCRDT<Project>,
  recordSetId: RecordSetId,
  newDescription: string
): KotoCRDT<Project> => {
  if (!(recordSetId in project.crdt.recordSets)) {
    throw new Error(
      `Record set ${recordSetId} does not exist on project ${project.key}.`
    );
  }
  return K.change(kContext, project, (doc) => {
    if (doc.recordSets[recordSetId].description !== undefined) {
      K.performUpdateText(
        doc.recordSets[recordSetId].description!,
        newDescription
      );
    } else {
      doc.recordSets[recordSetId].description = new K.KotoText(newDescription);
    }
  });
};

export const updateRecordSetPrivacy = (
  kContext: KotoContext,
  project: KotoCRDT<Project>,
  recordSetId: RecordSetId,
  isPrivate: boolean
): KotoCRDT<Project> => {
  if (!(recordSetId in project.crdt.recordSets)) {
    throw new Error(
      `Record set ${recordSetId} does not exist on project ${project.key}.`
    );
  }
  return K.change(kContext, project, (doc) => {
    doc.recordSets[recordSetId].isPrivate = isPrivate;
  });
};

export const udpateRecordSetValidForms = (
  kContext: KotoContext,
  project: KotoCRDT<Project>,
  recordSetId: RecordSetId,
  validForms: KotoKey[]
) => {
  if (!(recordSetId in project.crdt.recordSets)) {
    throw new Error(
      `Record set ${recordSetId} does not exist on project ${project.key}.`
    );
  }

  validForms.forEach((key) => {
    if (!validateFormKey(key)) {
      throw new Error(
        `Cannot add bad form key ${key} to record set ${recordSetId} in project ${project.key}.`
      );
    }
  });

  const sorted = validForms.sort();
  return K.change(kContext, project, (doc) => {
    // TODO: do a proper editing of the form instead of just overwriting it
    doc.recordSets[recordSetId].validForms = sorted;
  });
};

export const hideFriendlyNameNotice = (
  kContext: KotoContext,
  project: KotoCRDT<Project>,
  recordSetId: RecordSetId
) => {
  return K.change(kContext, project, (doc) => {
    const recordSet = doc.recordSets[recordSetId];

    if (recordSet === undefined) {
      throw new Error(
        `Project ${project.key} has no recordSetId ${recordSetId}`
      );
    }

    if (recordSet.friendlyName === undefined) {
      recordSet.friendlyName = {
        hideNotice: true,
        friendlyParts: [],
      };
    } else {
      recordSet.friendlyName.hideNotice = true;
    }
  });
};

export const setFriendlyName = (
  kContext: KotoContext,
  project: KotoCRDT<Project>,
  recordSetId: RecordSetId,
  friendlyParts: FriendlyNamePart[]
) => {
  return K.change(kContext, project, (doc) => {
    const recordSet = doc.recordSets[recordSetId];

    if (recordSet === undefined) {
      throw new Error(
        `Project ${project.key} has no recordSetId ${recordSetId}`
      );
    }

    recordSet.friendlyName = {
      hideNotice: true,
      friendlyParts: friendlyParts,
    };
  });
};
