import * as K from "../types/KotoOb";

import { Block, loadBlocks } from "../types/Block";
import { FormFiller, makeFormFillerPropsFromBlocks } from "../components/Form";
import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { buildRecordPath, urlParams } from "./AppRoutes";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { AppKotoContext } from "./AppKotoContext";
import { BasicButton } from "../components/Buttons";
import { KotoCRDT } from "../types/KotoTypes";
import { RootState } from "../Store/store";
import { loadForms } from "../Store/formListSlice";
import { loadRecord } from "../types/Record";
import { recordSlice } from "../Store/recordSlice";

export const AppRecordEdit: FunctionComponent<{}> = (_props) => {
  const history = useHistory();
  const { kContext } = useContext(AppKotoContext);
  const { projectId, recordId, recordSetId } = useParams<urlParams>();

  const dispatch = useDispatch();

  const record = useSelector((state: RootState) => {
    return state.record.record;
  });

  const [blockCrdts, setBlockCrdts] = useState([] as KotoCRDT<Block>[]);

  useEffect(() => {
    const load = async () => {
      const recordResult = await loadRecord(kContext, projectId, recordId);
      if (recordResult.type !== "Success") {
        console.log(
          `Could't load record ${recordId}. Got error: ${recordResult.type}: ${recordResult.msg}`
        );
        return;
      } else {
        dispatch(recordSlice.actions.setRecord(recordResult.data));
      }

      const blockResults = await loadBlocks(kContext, projectId, recordId);
      if (blockResults.type !== "Success") {
        console.log(
          `Couldn't load blocks for recordId ${recordId}. Got Error: ${blockResults.type}: ${blockResults.msg}`
        );
      } else {
        setBlockCrdts(blockResults.data);
        dispatch(recordSlice.actions.setBlocks(blockResults.data));
      }
    };
    load();
  }, [kContext, recordId, projectId]);

  useEffect(() => {
    dispatch(loadForms(kContext, projectId));
  }, [projectId, kContext]);

  const [confirmationModalInfo, setConfirmationModalInfo] = useState({
    open: false,
    setOpen: (val: boolean) =>
      setConfirmationModalInfo({ ...confirmationModalInfo, open: val }),
    modalType: "",
    entityString: "",
    confirmationFunc: () => {},
  });

  if (blockCrdts.length === 0) {
    return <Fragment></Fragment>;
  }

  const configs = makeFormFillerPropsFromBlocks(kContext, blockCrdts);

  const handleCancelButton = () => {
    // TODO: Check to see that this works if you enter another recordSetId
    history.push(buildRecordPath(projectId, recordSetId, recordId));
  };

  const handleUpdateButton = async () => {
    await Promise.all(
      blockCrdts.map((block) => {
        return K.storeCrdt(kContext, block);
      })
    );
    history.push(buildRecordPath(projectId, recordSetId, recordId));
  };

  return (
    <Fragment>
      <div className="top-0 m-0 p-1 text-center bg-violet-500 text-white">
        <div className="flex flex-initial text-base text-thin truncate items-center">
          Editing {configs.recordId}
        </div>
        <div className="flex-1 font-medium text-gray-700 leading-none flex items-center truncate">
          <BasicButton onClick={handleCancelButton}>Cancel</BasicButton>
          <BasicButton onClick={handleUpdateButton}>Update</BasicButton>
        </div>
      </div>
      {record && (
        <FormFiller
          kotoContext={kContext}
          blockSpecMap={configs.blockSpecMap}
          blocks={configs.blocks}
          form={configs.formWithBlockSpecs}
          recordSetId={configs.recordSetId}
          recordId={configs.recordId}
          setBlocks={setBlockCrdts}
        ></FormFiller>
      )}
    </Fragment>
  );
};
