import React, {
  FunctionComponent,
  Fragment,
  useRef,
  useState,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import { registerQuestionType } from "../../types/QuestionTypeRegistry";
import {
  QuestionDesignProps,
  QuestionFillerProps,
  QuestionViewerProps,
  BaseQuestionSpec,
  Choices,
  ChoicePart,
  QuestionAnswer,
  createBaseQuestionSpec,
} from "../../types/Questions";
import * as K from "../../types/KotoOb";

export interface DropDownChoiceQuestionSpec extends BaseQuestionSpec {
  parts: { choice: ChoicePart };
}

export interface DropDownChoiceAnswer extends QuestionAnswer {
  parts: { choice: string };
}

const createDropDownChoiceQuestionSpec = (
  choices: Choices = [],
  defaultChoice: string = "",
  allowOther: boolean = false
): DropDownChoiceQuestionSpec => {
  return {
    ...createBaseQuestionSpec("dropdownQuestion"),
    parts: {
      choice: {
        default: defaultChoice,
        choices: choices,
        allowOther: allowOther,
      },
    },
  };
};

const createDropDownChoiceAnswer = (
  spec: DropDownChoiceQuestionSpec
): DropDownChoiceAnswer => {
  return {
    type: "dropdownAnswer",
    isAnswered: false,
    parts: { choice: spec.parts.choice.default },
  };
};

export const makeChoiceAdder = (label?: string) => {
  return (question: DropDownChoiceQuestionSpec) => {
    if (question.type !== "dropdownQuestion") {
      throw new Error(
        `Question must be of type 'dropdownQuestion' but got '${question.type}' instead.`
      );
    }

    const choiceCount = question.parts.choice.choices.length + 1;
    question.parts.choice.choices.push(
      new K.KotoText(label || `Option ${choiceCount}`)
    );
  };
};

export const makeChoiceRemover = (choiceNum: number) => {
  return (question: DropDownChoiceQuestionSpec) => {
    question.parts.choice.choices.deleteAt!(choiceNum);
  };
};

export const makeChoiceTextUpdater = (choiceNum: number, text: string) => {
  return (question: DropDownChoiceQuestionSpec) => {
    K.performUpdateText(question.parts.choice.choices[choiceNum], text);
  };
};

const DropDownChoiceQuestionPartsDesigner: FunctionComponent<QuestionDesignProps<
  DropDownChoiceQuestionSpec
>> = (props) => {
  const [activeInput, setActiveInput] = useState(-1);

  const currentInput = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (
      currentInput.current &&
      currentInput.current !== document.activeElement
    ) {
      currentInput.current.focus();
      currentInput.current.select();
    }
  });

  const updateChoiceText = (
    idx: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    props.setQuestion(makeChoiceTextUpdater(idx, event.target.value));
  };

  const removeChoice = (idx: number) => {
    props.setQuestion(makeChoiceRemover(idx));
  };

  const addChoice = () => {
    setActiveInput(props.questionSpec.parts.choice.choices.length);
    props.setQuestion(makeChoiceAdder());
  };

  const handleKeyPress = (
    idx: number,
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    const choiceCount = props.questionSpec.parts.choice.choices.length;
    if (event.key == "Enter") {
      if (idx === choiceCount - 1) {
        addChoice();
      } else {
        setActiveInput(idx + 1);
      }
    } else if (event.key == "Backspace") {
      if (event.currentTarget.value == "") {
        removeChoice(idx);
        setActiveInput(idx - 1);
      }
    }
  };

  return (
    <Fragment>
      {props.questionSpec.parts.choice.choices.map((choice, idx) => {
        return (
          <div className="mx-5 my-1 first:mt-5 flex" key={idx}>
            <input
              onChange={(e) => updateChoiceText(idx, e)}
              className="form-input k_textInput flex-1 bg-white py-2 px-3"
              value={choice.toString()}
              ref={activeInput === idx ? currentInput : undefined}
              onBlur={() => setActiveInput(-1)}
              onKeyDown={(e) => handleKeyPress(idx, e)}
            ></input>
            <div className="flex-initial w-10 text-center">
              {" "}
              <button
                className="text-gray-500 hover:text-gray-800 mt-2"
                onClick={() => removeChoice(idx)}
                data-tooltip="Remove option"
                tooltip-position="bottom"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        );
      })}

      <div className="mx-5 my-1 first:mt-5 flex">
        <input
          onClick={() => {
            addChoice();
          }}
          className="form-input k_textInput flex-1 bg-white py-2 px-3"
          placeholder="Add an option"
          value={""}
          readOnly={true}
        ></input>
        <div className="flex-initial w-10 text-center"></div>
      </div>
    </Fragment>
  );
};

const DropDownChoiceQuestionPartsFiller: FunctionComponent<QuestionFillerProps<
  DropDownChoiceQuestionSpec,
  DropDownChoiceAnswer
>> = (props) => {
  const updateChoice = (value: string) => {
    props.setAnswer((_, answer) => {
      answer.isAnswered = true;
      answer.parts.choice = value;
    });
  };

  return (
    <Fragment>
      <div className="">
        <select
          id={props.spec.id}
          onChange={(event) => updateChoice(event.target.value)}
          className="form-select k_select flex-1 border-r-0 rounded-r-none"
          defaultValue={props.answer.parts.choice}
        >
          {props.spec.parts.choice.choices.map((choice, idx) => {
            const itemId = `${props.spec.id}.${idx}`;
            const label = choice.toString();
            const value = choice.toString();

            return (
              <option
                className="form-radio k_choiceInput"
                id={itemId}
                label={label}
                value={value}
                key={itemId}
              />
            );
          })}
        </select>
      </div>
    </Fragment>
  );
};

const DropDownChoiceAnswerViewer: FunctionComponent<QuestionViewerProps<
  DropDownChoiceQuestionSpec,
  DropDownChoiceAnswer
>> = (props) => {
  return <div>{props.answer.parts.choice}</div>;
};

registerQuestionType(
  "dropdownQuestion",
  "Drop Down Menu",
  createDropDownChoiceQuestionSpec,
  createDropDownChoiceAnswer,
  DropDownChoiceQuestionPartsDesigner,
  DropDownChoiceQuestionPartsFiller,
  DropDownChoiceAnswerViewer
);
