/**
 * All Koto data will be stored in CRDT objects
 *
 * All write operations will happen in Automerge objects. This will provide
 * history and merging features.
 *
 * The changes will be stored on the server as Automerge changes.
 *
 * The UI will render plain objects.
 *
 * Write operations happen by first requesting the Automerge version of the object.
 *
 * References to other objects must refer to the objects Id as well as its Version.
 */
import * as Automerge from "automerge";

import stringify from "fast-json-stable-stringify";

const innerObjectToAutomerge = <T>(o: T): T => {
  const draftObj: any = {};

  for (const part of Object.entries(o)) {
    const typePart = typeof part[1];
    if (typePart === "string") {
      draftObj[part[0]] = new Automerge.Text(part[1]);
    } else if (typePart === "number") {
      draftObj[part[0]] = new Automerge.Counter(part[1]);
    } else if (Array.isArray(part[1])) {
      const working = [];
      for (const x of part[1]) {
        working.push(innerObjectToAutomerge(x));
      }
      draftObj[part[0]] = working;
    } else if (typePart === "object") {
      draftObj[part[0]] = innerObjectToAutomerge(part[1]);
    } else {
      draftObj[part[0]] = part[1];
    }
  }
  return draftObj;
};

export const objectToAutomerge = <T>(o: T): Automerge.Doc<T> => {
  const draftObj: T = innerObjectToAutomerge(o);
  return Automerge.from(draftObj);
};

export const getVersion = (doc: Automerge.Doc<any>): string => {
  const backend: any = Automerge.Frontend.getBackendState(doc);

  return stringify(
    backend.state
      .getIn(["opSet", "states"])
      .map((seqs: any) => seqs.size)
      .toJSON()
  );
};

export const automergeTypeToPlainType = <T>(
  o: Automerge.Doc<T>
): { raw: Serialized<T>; version: any } => {
  return { raw: JSON.parse(JSON.stringify(o)), version: getVersion(o) };
};

export const getCRDTAtVersion = <T>(
  doc: Automerge.Doc<T>,
  versionString: string
): Automerge.Doc<T> | null => {
  const version = JSON.parse(versionString);

  const changesToApply = Automerge.getChanges(Automerge.init(), doc)
    .map((rawChange) => {
      const change = Automerge.decodeChange(rawChange);
      if (
        version[change.actor] != null &&
        version[change.actor] >= change.seq
      ) {
        return rawChange;
      } else {
        return null;
      }
    })
    .filter((item) => {
      return item !== null;
    }) as Uint8Array[];

  const newDoc = Automerge.applyChanges(Automerge.init<T>(), changesToApply);
  const newDocVersion = getVersion(newDoc);

  if (newDocVersion !== versionString) {
    throw Error(`Got Version: ${newDocVersion} but expected ${versionString}`);
  }

  return newDoc;
};

export const deepCopyAutomergeObjects = (ob: any) => {
  function copyOb(o: any) {
    if (o instanceof Automerge.Text) {
      return new Automerge.Text(o.toString());
    }

    if (o instanceof Array) {
      return o.map((item) => {
        return doCopy(item);
      });
    }

    if (o instanceof Automerge.Counter) {
      return new Automerge.Counter(o.value);
    }

    if (
      o instanceof Object &&
      Object.getPrototypeOf(o) === Object.getPrototypeOf({})
    ) {
      return Object.assign(
        {},
        ...Object.entries(o).map((entry) => {
          const [key, val] = entry;
          return { [key]: doCopy(val) };
        })
      );
    }

    throw new Error(
      "Unknown type: " + typeof o + "\nWith JSON output: " + JSON.stringify(o)
    );
  }

  function doCopy(x: any): any {
    switch (typeof x) {
      case "number":
        return x;
      case "string":
        return x;
      case "boolean":
        return x;
      case "object":
        return copyOb(x);
      default:
        throw new Error("Unknown type, cannot make a copy");
    }
  }

  return doCopy(ob);
};

type Serialize<T> = T extends Automerge.Text
  ? string
  : T extends Automerge.Counter
  ? number
  : T;

export type Serialized<T> = { readonly [P in keyof T]: Serialize<T[P]> };

export const getRawDoc = <T>(
  doc: Automerge.Doc<T> | Automerge.FreezeObject<T>
): Serialized<T> => {
  return JSON.parse(JSON.stringify(doc));
};
