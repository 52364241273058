import { KotoResult, SubscriptionCanceler } from "./KotoOb";
import { KotoStorable, KotoTypes } from "./KotoTypes";

import { IdType } from "./IdTypes";

export interface QueryParams<T extends KotoStorable> {
  type: T["type"];
  params: {
    fieldPath: string;
    op:
      | "<"
      | "<="
      | "=="
      | "!="
      | ">="
      | ">"
      | "array-contains"
      | "in"
      | "array-contains-any";
    value: string | number | boolean | string[];
  }[];
  orderBy?: { field: string; direction: "asc" | "desc" };
}

export interface QueryParamsWithKey<T extends KotoStorable> {
  type: T["type"];
  params:
    | [{ fieldPath: "key"; op: "=="; value: string }]
    | {
        fieldPath: string;
        op:
          | "<"
          | "<="
          | "=="
          | "!="
          | ">="
          | ">"
          | "array-contains"
          | "in"
          | "array-contains-any";
        value: string | number | boolean;
      }[];
}

export abstract class BackingStore {
  abstract name: string;

  async store<T extends KotoStorable>(_ob: T): Promise<KotoResult<T>> {
    return { type: "Error", msg: "Not Implemented." };
  }

  async get<T extends KotoStorable>(
    _queryParams: QueryParamsWithKey<T>
  ): Promise<KotoResult<T>> {
    return { type: "Error", msg: "Not Implemented." };
  }

  subscribe<T extends KotoStorable>(
    _queryParams: QueryParamsWithKey<T>,
    _cb: (result: KotoResult<T>) => void,
    _errorCb?: (err: any) => void
  ): SubscriptionCanceler {
    throw new Error("Not Implemented.");
  }

  subscribeList<T extends KotoStorable>(
    _queryParams: QueryParams<T>,
    _cb: (result: KotoResult<T[]>) => void,
    _errorCb?: (err: any) => void
  ): SubscriptionCanceler {
    throw new Error("Not Implemented");
  }

  async getList<T extends KotoStorable>(
    _queryParams: QueryParams<T>
  ): Promise<KotoResult<T[]>> {
    return { type: "Error", msg: "Not Implemented." };
  }

  async getIndex<T extends KotoStorable>(
    _queryParams: QueryParams<T>
  ): Promise<KotoResult<T[]>> {
    return { type: "Error", msg: "Not Implemented." };
  }

  async storeIndex<T extends KotoStorable>(
    _items: { key: string; index: string; value: T }[]
  ): Promise<KotoResult<boolean>> {
    return { type: "Error", msg: "Not Implemented." };
  }

  async updateIndex(
    _items: { key: string; index: string; change: any }[]
  ): Promise<KotoResult<boolean>> {
    return { type: "Error", msg: "Not Implemented." };
  }

  async subscribeChanges(
    _forType: KotoTypes,
    _forKey: IdType,
    _callback: () => void
  ): Promise<() => void> {
    return () => {
      return;
    };
  }
}
