import React, {
  FunctionComponent,
  KeyboardEvent,
  MouseEvent,
  Fragment,
} from "react";
import { Hideable } from "./Hideable";
import * as immer from "immer";
import {
  CheckboxItemType,
  CheckboxListItem,
  toggleItemInCheckboxList,
} from "./Checkbox";
import { Link } from "react-router-dom";

interface SidebarMenuButtonProps {
  extraClassNames?: string;
  setSideNavExpanded: () => void;
}

// This button activates the side nav and it looks different depending on where it's used. on mobile and sm screens it's used in the action bar fixed to the bottom of the page and has an icon above and label text below. On medium and large screens it's shown in the top-left of the page and is icon-only. On extra-large screens the sidebar is always visible so the button is hidden.
export const SidebarMenuButton: FunctionComponent<SidebarMenuButtonProps> = (
  props
) => {
  // TODO: Cleanup and merge these functions
  const handleCollapseKeyboard = (event: KeyboardEvent): void => {
    // Return if key pressed was not Space Bar or Enter
    if (
      event.type === "keydown" &&
      event.keyCode &&
      event.keyCode !== 13 &&
      event.keyCode !== 32
    ) {
      return;
    }

    event.preventDefault();

    props.setSideNavExpanded();
  };

  const handleCollapseMouse = (event: MouseEvent): void => {
    event.preventDefault();
    props.setSideNavExpanded();
  };

  return (
    <a
      href="#sidebar"
      id="sidebar-toggle"
      className={"no-underline text-center btn btn-ab " + props.extraClassNames}
      aria-label="Open sidebar"
      onKeyDown={handleCollapseKeyboard}
      onClick={handleCollapseMouse}
    >
      <span className="sr-only">Open sidebar</span>
      {/* TODO: Replace with sidebar icon */}
      <div className="">
        <i className="fa-fw fas fa-bars" />
        <div className="block md:hidden">Menu</div>
      </div>
      {/* <span className="hidden sm:inline-block lg:hidden  ml-2">Menu</span> */}
    </a>
  );
};

interface ButtonProps {
  onClick?: (event: MouseEvent) => void;
  extraClassNames?: string;
}

export const BasicButton: FunctionComponent<ButtonProps> = (props) => {
  let className = "btn";

  if (props.extraClassNames !== undefined) {
    className = className + " " + props.extraClassNames;
  }

  return (
    <button className={className} onClick={props.onClick}>
      {props.children}
    </button>
  );
};

export interface Icon {
  fontAwesomeClassNames: string;
}

export const Icon: FunctionComponent<Icon> = (props) => {
  const className = props.fontAwesomeClassNames;

  return <i className={className}></i>;
};

export const MenuItemIcon: FunctionComponent<Icon> = (props) => {
  const className = "mr-3 fa-fw " + props.fontAwesomeClassNames;

  return <i className={className}></i>;
};

interface MenuButtonProps {
  extraClassNames?: string;
  tooltip?: string;
  tooltipPosition?: string;
}

export const MenuButton: FunctionComponent<MenuButtonProps> = (props) => {
  let className = "btn " + props.extraClassNames;
  return (
    <button
      className={className}
      data-tooltip={props.tooltip}
      tooltip-position={props.tooltipPosition}
    >
      {props.children}
    </button>
  );
};

interface MenuItemListProps {
  extraClassNames?: string;
  position: string; // bottom-right | bottom-left
}

export const MenuItemList: FunctionComponent<MenuItemListProps> = (props) => {
  let className = "sm:absolute sm:top-auto cursor-pointer ";
  let menuClassName =
    "mobile-modal sm:rounded sm:shadow-md  sm:relative sm:mx-0 z-50 sm:w-64 text-left ";

  if (props.position == "bottom-right") {
    className = "sm:absolute cursor-pointer pos-bottom-right sm:mt-1";
  } else if (props.position == "bottom-left") {
    className = "sm:absolute cursor-pointer pos-bottom-left sm:mt-1";
  } else if (props.position == "top-left") {
    className = "sm:absolute  cursor-pointer pos-top-left  mb-1";
  } else if (props.position == "top-right") {
    className = "sm:absolute  cursor-pointer pos-top-right  mb-1";
  }

  if (props.extraClassNames) {
    menuClassName = menuClassName + " " + props.extraClassNames;
  }

  return (
    <div className={className}>
      <div className={menuClassName}>
        <ul>{props.children}</ul>
      </div>
    </div>
  );
};

interface FilterMenuItemListProps {
  extraClassNames?: string;
  position: string; // bottom-right | bottom-left
}

export const FilterMenuItemList: FunctionComponent<FilterMenuItemListProps> = (
  props
) => {
  let className = "sm:absolute sm:top-auto cursor-pointer ";
  let menuClassName =
    "mobile-modal sm:rounded sm:shadow-md  sm:relative sm:mx-0 z-50 sm:w-64 ";

  if (props.position == "bottom-right") {
    className = "sm:absolute cursor-pointer pos-bottom-right sm:mt-1";
  } else if (props.position == "bottom-left") {
    className = "sm:absolute cursor-pointer pos-bottom-left sm:mt-1";
  } else if (props.position == "top-left") {
    className = "sm:absolute  cursor-pointer pos-top-left  sm:mb-1";
  } else if (props.position == "top-right") {
    className = "sm:absolute  cursor-pointer pos-top-right  sm:mb-1";
  }

  if (props.extraClassNames) {
    menuClassName = menuClassName + " " + props.extraClassNames;
  }

  return (
    <div className={className}>
      <div className={menuClassName}>
        <div className="flex p-4 border-b border-gray-300 items-center bg-gray-200">
          <input
            className="form-input k_textInput flex-1 rounded border border-gray-400 text-gray-800 m-0 "
            placeholder="Filter"
          ></input>
        </div>

        <ul>{props.children}</ul>
      </div>
    </div>
  );
};

interface MenuItemProps {
  danger?: boolean;
  extraClassNames?: string;
  linkTo?: string;
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void;
  target?: string;
}

export const MenuItem: FunctionComponent<MenuItemProps> = (props) => {
  let className =
    "block px-5 sm:px-4 py-4 sm:py-2 text-gray-800 hover:bg-gray-200 hover:text-gray-900 font-light no-underline  ";

  if (props.danger == true) {
    className = className + " text-red-800 hover:text-red-900";
  }

  if (props.extraClassNames) {
    className = className + " " + props.extraClassNames;
  }

  let children;
  if (props.linkTo) {
    children = (
      <Link to={props.linkTo} className={className} target={props.target}>
        {props.children}
      </Link>
    );
  } else if (props.onClick) {
    children = (
      <div className={className} onClick={props.onClick}>
        {props.children}
      </div>
    );
  } else if (props.onClick && props.linkTo) {
    console.log(
      "A MenuItem can only be a link OR have an onClick event, not both"
    );
    children = <div className={className}>{props.children}</div>;
  } else {
    children = <div className={className}>{props.children}</div>;
  }

  return <li>{children}</li>;
};

export interface MenuProps {
  menuOpen: boolean;
  setMenuOpen: (val: boolean) => void;
  extraClassNames?: string;
}

export const Menu: FunctionComponent<MenuProps> = (props) => {
  const toggleMenu = (): void => {
    props.setMenuOpen(!props.menuOpen);
  };
  let className = "relative";
  if (props.extraClassNames) {
    className = "relative " + props.extraClassNames;
  }
  const children = React.Children.toArray(props.children);
  // TODO: See if you can get rid of the outer div and just clone children[0]
  return (
    <div className={className}>
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleMenu();
        }}
        className="focus:outline-none"
      >
        {children[0]}
      </span>
      {props.menuOpen && (
        <Hideable toggleFunc={toggleMenu}>{children[1]}</Hideable>
      )}
    </div>
  );
};

const handleSelectAll = <T extends CheckboxItemType>(
  checklist: T[],
  setCheckboxesList: (newList: T[]) => void
): void => {
  const newCheckboxList = immer.produce(checklist, (draft) => {
    for (let i = 0; i < draft.length; i++) {
      draft[i].selected = true;
    }
  });
  setCheckboxesList(newCheckboxList);
};

const handleSelectNone = <T extends CheckboxItemType>(
  checklist: T[],
  setCheckboxesList: (newList: T[]) => void
): void => {
  const newCheckboxList = immer.produce(checklist, (draft) => {
    for (let i = 0; i < draft.length; i++) {
      draft[i].selected = false;
    }
  });
  setCheckboxesList(newCheckboxList);
};

export interface FilterItemType extends CheckboxItemType {
  option: any;
}

interface FilterListProps {
  items: FilterItemType[];
  setItems: (items: FilterItemType[]) => void;
  extraClassNames?: string;
}

export const FilterList: FunctionComponent<FilterListProps> = (props) => {
  let extras;
  if (props.extraClassNames != undefined) {
    extras = props.extraClassNames;
  }

  return (
    <div className="relative">
      <div
        className={" bg-white rounded border border-gray-400 mt-2 " + extras}
      >
        <div className="flex bg-gray-100 rounded-t justify-center px-4 pt-4">
          <button
            className="flex-1 py-2 mr-3 btn btn-sm bg-white"
            onClick={() => {
              handleSelectAll(props.items, props.setItems);
            }}
            data-tooltip="Select all"
            tooltip-position="bottom"
          >
            Select all
          </button>
          <button
            className="flex-1 py-2 btn btn-sm bg-white"
            onClick={() => {
              handleSelectNone(props.items, props.setItems);
            }}
            data-tooltip="Select none"
            tooltip-position="bottom"
          >
            Select none
          </button>
        </div>
        <div className="flex bg-gray-100 pt-2 pb-4 px-4 justify-center border-b border-gray-300">
          <input
            className="flex-1 rounded border border-gray-400 text-gray-800 form-input"
            placeholder="Filter"
          ></input>
        </div>

        <div className="h-32 bg-white overflow-y-scroll">
          <ul>
            {props.items.map((item, index) => {
              return (
                <MenuItem key={index}>
                  <CheckboxListItem
                    item={item}
                    toggleChecked={() =>
                      toggleItemInCheckboxList(
                        props.items,
                        props.setItems,
                        index
                      )
                    }
                  />
                </MenuItem>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

interface FilterMenuProps {
  items: FilterItemType[];
  setItems: (items: FilterItemType[]) => void;
  extraClassNames?: string;
}

export const FilterMenu: FunctionComponent<FilterMenuProps> = (props) => {
  let extras;
  if (props.extraClassNames != undefined) {
    extras = props.extraClassNames;
  }

  return (
    <div className="relative">
      <div
        className={
          "mobile-modal sm:rounded sm:relative sm:m-0 sm:mt-0 sm:shadow-md sm:min-w-64 sm:border-none z-50 " +
          extras
        }
      >
        <div className="flex bg-gray-100 rounded-t justify-center px-4 pt-4">
          <button
            className="flex-1 py-2 mr-3 btn btn-sm bg-white"
            onClick={() => {
              handleSelectAll(props.items, props.setItems);
            }}
            data-tooltip="Select all"
            tooltip-position="bottom"
          >
            Select all
          </button>
          <button
            className="flex-1 py-2 btn btn-sm bg-white"
            onClick={() => {
              handleSelectNone(props.items, props.setItems);
            }}
            data-tooltip="Select none"
            tooltip-position="bottom"
          >
            Select none
          </button>
        </div>
        <div className="flex bg-gray-100 pt-2 pb-4 px-4 justify-center border-b border-gray-300">
          <input
            className="flex-1 rounded border border-gray-400 text-gray-800 form-input"
            placeholder="Filter"
          ></input>
        </div>

        <div className="max-h-64 overflow-auto">
          <ul>
            {props.items.map((item, index) => {
              return (
                <MenuItem key={index}>
                  <CheckboxListItem
                    item={item}
                    toggleChecked={() =>
                      toggleItemInCheckboxList(
                        props.items,
                        props.setItems,
                        index
                      )
                    }
                  />
                </MenuItem>
              );
            })}
          </ul>
        </div>

        <div className="block bg-white py-4 px-4 border-t border-gray-200 sticky inset-x-0 bottom-0 sm:hidden ">
          <button
            className="w-full py-2 px-5 mr-2 mb-2 btn btn-gray"
            data-tooltip="Apply filter"
            tooltip-position="bottom"
          >
            Filter
          </button>
          <button
            className="w-full py-2 px-5 mr-2 mb-2 btn btn-gray-outline"
            data-tooltip="Cancel"
            tooltip-position="bottom"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

interface MobileActionBarProps {
  extraClassNames?: string;
}

export const MobileActionBar: FunctionComponent<MobileActionBarProps> = (
  props
) => {
  return (
    <div
      className={
        "block bottom-0 inset-x-0 mx-auto fixed xl:ml-sidebar  " +
        props.extraClassNames
      }
    >
      <div className="bg-gray-100 shadow-ab p-3 pb-5 flex text-sm z-30 space-x-4 sm:text-base sm:flex  ">
        {props.children}
      </div>
    </div>
  );
};

interface ToggleSwitchProps {
  checked: boolean;
  setChecked: (val: boolean) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ToggleSwitch: FunctionComponent<ToggleSwitchProps> = (props) => {
  const toggleChecked = (): void => {
    props.setChecked(!props.checked);
  };

  return (
    <Fragment>
      <label className="k_toggleSwitch">
        <input
          type="checkbox"
          checked={props.checked}
          onChange={() => {
            props.onChange;
            toggleChecked();
          }}
        />
        <span className="k_slider"></span>
      </label>
    </Fragment>
  );
};
