import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../Store/store";
import { AnalyticsPageView } from "../components/Analytics";

export const AppAnalyticsPageView: FunctionComponent<{}> = (_props) => {
  const pathName = useSelector((state: RootState) => {
    return state.router.location.pathname;
  });

  return <AnalyticsPageView appName="koto-dev" pathName={pathName} />;
};
