import * as K from "./KotoOb";

import {
  KotoBaseDoc,
  KotoCRDT,
  KotoKey,
  KotoSerializedCRDT,
} from "./KotoTypes";
import { ProjectId, RecordId, ReminderId, genReminderId } from "./IdTypes";

import { KotoContext } from "./KotoContext";
import { QueryParams } from "./KotoStore";
import { Record } from "./Record";

interface FormReminder {
  reminderType: "form";
  formKey: KotoKey;
}

export type ReminderInfo = FormReminder;

export interface Reminder extends KotoBaseDoc {
  id: ReminderId;
  type: "Reminder";
  projectId: ProjectId;
  recordId: RecordId;
  dueDate?: Date;
  reminderInfo: ReminderInfo;
  completed: number;
  completedTimestamp: number;
  deleted: number;
  assignee?: string | null;
}

export const makeReminderKey = (
  projectId: ProjectId,
  recordId: RecordId,
  reminderId: ReminderId
): string => {
  return `${projectId}-${recordId}-${reminderId}`;
};

export const createReminder = (
  kContext: KotoContext,
  record: KotoCRDT<Record>,
  info: ReminderInfo,
  dueDate?: Date,
  assignee?: string
): KotoCRDT<Reminder> => {
  if (info.formKey === "") {
    throw new Error("Form key can not be empty when creating a reminder.");
  }

  const reminderId = genReminderId();
  const raw: Reminder = {
    id: reminderId,
    type: "Reminder",
    projectId: record.crdt.projectId,
    recordSetId: record.crdt.recordSetId,
    recordId: record.crdt.id,
    reminderInfo: info,
    completed: 0,
    completedTimestamp: 0,
    deleted: 0,
    assignee: assignee || null,
  };

  if (dueDate) {
    raw.dueDate = dueDate;
  }

  return K.create(
    kContext,
    raw,
    makeReminderKey(record.crdt.projectId, record.crdt.id, reminderId)
  );
};

const genRecordRemindersQuery = (
  projectId: ProjectId,
  recordId: RecordId
): QueryParams<KotoSerializedCRDT<Reminder>> => {
  return {
    type: "Reminder",
    params: [
      {
        fieldPath: "doc.projectId",
        op: "==",
        value: projectId,
      },
      { fieldPath: "doc.recordId", op: "==", value: recordId },
    ],
  };
};

export const loadReminders = async (
  kContext: KotoContext,
  projectId: ProjectId,
  recordId: RecordId
): Promise<K.KotoResult<KotoCRDT<Reminder>[]>> => {
  const result = await K.getList<Reminder>(
    kContext,
    genRecordRemindersQuery(projectId, recordId)
  );

  if (result.type !== "Success") {
    return result;
  } else {
    return {
      type: result.type,
      data: result.data,
    };
  }
};

export const subscribeRemindersForRecord = (
  kContext: KotoContext,
  projectId: ProjectId,
  recordId: RecordId,
  cb: (result: K.KotoResult<KotoCRDT<Reminder>[]>) => void,
  errorCb: (err: any) => void
): K.SubscriptionCanceler => {
  return K.subscribeList(
    kContext,
    genRecordRemindersQuery(projectId, recordId),
    cb,
    errorCb
  );
};

export const subscribeRemindersForProject = (
  kContext: KotoContext,
  projectId: ProjectId,
  cb: (result: K.KotoResult<KotoCRDT<Reminder>[]>) => void,
  errorCb: (err: any) => void
): K.SubscriptionCanceler => {
  return K.subscribeList(
    kContext,
    {
      type: "Reminder",
      params: [
        {
          fieldPath: "doc.projectId",
          op: "==",
          value: projectId,
        },
      ],
    },
    cb,
    errorCb
  );
};

export const findRemindersForForm = async (
  kContext: KotoContext,
  record: KotoCRDT<Record>,
  formKey: KotoKey
): Promise<KotoCRDT<Reminder>[]> => {
  const result = await K.getList<Reminder>(kContext, {
    type: "Reminder",
    params: [
      {
        fieldPath: "doc.projectId",
        op: "==",
        value: record.crdt.projectId,
      },
      { fieldPath: "doc.recordId", op: "==", value: record.crdt.id },
      { fieldPath: "doc.reminderInfo.formKey", op: "==", value: formKey },
      { fieldPath: "doc.completed", op: "==", value: 0 },
    ],
  });

  if (result.type === "NotFound") {
    return [];
  } else if (result.type === "Success") {
    return result.data;
  } else {
    throw new Error(
      `Failed to query for reminders for form ${formKey} on record ${record.key}`
    );
  }
};

export const completeReminder = (
  kContext: KotoContext,
  reminder: KotoCRDT<Reminder>
): KotoCRDT<Reminder> => {
  return K.change(kContext, reminder, (doc) => {
    doc.completed = 1;
    doc.completedTimestamp = Date.now();
  });
};

export const deleteReminder = (
  kContext: KotoContext,
  reminder: KotoCRDT<Reminder>
): KotoCRDT<Reminder> => {
  return K.change(kContext, reminder, (doc) => {
    doc.deleted = 1;
  });
};
