import React, { FunctionComponent, Fragment, useState } from "react";
import { Expander, ExpanderBody, ExpanderCollapsedDescription } from "../Card";
import { ObjectViewNav } from "../TopNavigation";
import { useDispatch } from "react-redux";
import { viewSlice } from "../../Store/viewSlice";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { buildProjectSettingsPath, urlParams } from "../../App/AppRoutes";
import { adminSettingsSlice } from "../../Store/adminSettingsSlice";
import {
  TabbedView,
  Tab,
  TabContent,
  TabHeader,
  TabbedViewProps,
} from "../Tabs";
import { Permissions } from "../Settings/Permissions";
import { Authentication } from "../Settings/Authentication";
import { AccessLogs } from "../Settings/AccessLogs";

interface AdministrativeSettingsProps {
  // TODO: Add activeSection to scroll to and expand a specific section of the settings
  //   activeSection: string;
  adminSettingsView: AdminSettingsViews;
}

export type AdminSettingsViews =
  | "settings"
  | "permissions"
  | "authentication"
  | "access-logs";

export const AdministrativeSettings: FunctionComponent<AdministrativeSettingsProps> = (
  props
) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { projectId } = useParams<urlParams>();
  const handleBackButton = () => {
    history.push(buildProjectSettingsPath(projectId));
  };
  const handleSelectTab = (view: AdminSettingsViews) => {
    dispatch(adminSettingsSlice.actions.setAdminSettingView(view));
  };

  const [joinProjectExpanded, setJoinProjectExpanded] = useState(false);
  const [languageExpanded, setLanguageExpanded] = useState(false);
  const [nameExpanded, setNameExpanded] = useState(false);
  const [emailExpanded, setEmailExpanded] = useState(false);
  const [retentionExpanded, setRetentionExpanded] = useState(false);

  // const [
  //   adminSettingsActiveTabLabel,
  //   setAdminSettingsActiveTabLabel,
  // ] = useState("settings");

  const adminSettingsActiveTabLabel = props.adminSettingsView;

  return (
    <Fragment>
      <ObjectViewNav
        title="Administrative settings"
        setSideNavExpanded={() => {
          dispatch(viewSlice.actions.setNavVisible(true));
        }}
        handleBackButton={handleBackButton}
      ></ObjectViewNav>
      <TabbedView
        type="nav"
        activeView={adminSettingsActiveTabLabel}
        selectTab={handleSelectTab as TabbedViewProps["selectTab"]}
        // selectTab={(label) => {
        //   setAdminSettingsActiveTabLabel(label);
        // }}
      >
        <Tab label="settings">
          <TabHeader>Settings</TabHeader>
          <TabContent>
            <div className="my-8">
              <Expander
                isExpanded={joinProjectExpanded}
                title="Workspace name and URL"
                hideControl={false}
                toggleExpanded={() =>
                  setJoinProjectExpanded(!joinProjectExpanded)
                }
              >
                <ExpanderCollapsedDescription></ExpanderCollapsedDescription>
                <ExpanderBody>
                  <div className="k_cardQuestionDescription mb-4">
                    Review the current workspace title and workspace URL but be
                    careful – changing these can confuse other users.
                  </div>

                  <div>
                    {/* TODO: Add current project title here */}
                    <div className="k_cardQuestionTitle">Workspace Name</div>
                    <input className="form-input k_textInput mb-4"></input>
                    {/* TODO: Add current project URL here */}
                    <div className="k_cardQuestionTitle">Workspace URL</div>
                    <input className="form-input k_textInput mb-4"></input>
                    <button className="btn btn-gray w-full">Save</button>
                  </div>
                </ExpanderBody>
              </Expander>
              <Expander
                isExpanded={joinProjectExpanded}
                title="Joining this workspace"
                hideControl={false}
                toggleExpanded={() =>
                  setJoinProjectExpanded(!joinProjectExpanded)
                }
              >
                <ExpanderCollapsedDescription></ExpanderCollapsedDescription>
                <ExpanderBody>
                  <div className="k_cardQuestionDescription mb-4">
                    Choose how people join your workspace: by accepting an email
                    invitation or signing up with an email address from an
                    approved domain. If you enable the setting to let people
                    with an email address on an approved domain join
                    automatically, Koto will generate a link that anyone with an
                    approved email address can use to confirm their email and
                    sign up.
                  </div>

                  <div>
                    {/* TODO: Implmenet Allow Invitatiosn vs whitelist invitations setting */}
                    <label className="k_choiceLabel">
                      <input
                        type="radio"
                        className="form-radio k_choiceInput"
                        name="Option 1"
                        disabled={false}
                        id="Invitations_Allow"
                        value="Allow Invitations"
                      />
                      <div className="k_choiceText">Allow Invitations</div>
                    </label>
                    <label className="k_choiceLabel">
                      <input
                        type="radio"
                        className="form-radio k_choiceInput"
                        name="Option 2"
                        disabled={false}
                        id="Invitations_Whitelist"
                        value="Whitelist Invitations"
                      />
                      <div className="k_choiceText">
                        Allow invitations, and approve invitations for any email
                        address from these domains:
                      </div>
                    </label>
                    <textarea className="k_textArea "></textarea>
                    <div className="k_cardAnnotation mb-4">
                      If you want to add more than one domain, separate each one
                      with a comma.
                    </div>
                    <button className="btn btn-gray w-full">Save</button>
                  </div>
                </ExpanderBody>
              </Expander>
              <Expander
                isExpanded={languageExpanded}
                title="Workspace language"
                hideControl={false}
                toggleExpanded={() => setLanguageExpanded(!languageExpanded)}
              >
                <ExpanderCollapsedDescription></ExpanderCollapsedDescription>
                <ExpanderBody>
                  {/* TODO: Implement global language setting*/}
                  <div className="k_cardQuestionDescription mb-4">
                    Set the language for your workspace. This affects system
                    notifications and sign-up emails. Your workspace language is
                    currently set to: <strong>English (US)</strong>
                  </div>
                  <label className="">
                    <select className="form-select k_select">
                      <option value="English">English</option>
                      <option value="Spanish">Spanish</option>
                      <option value="French">French</option>
                      <option value="Mandarin">Mandarin</option>
                    </select>
                  </label>
                </ExpanderBody>
              </Expander>{" "}
              <Expander
                isExpanded={nameExpanded}
                title="Name display"
                hideControl={false}
                toggleExpanded={() => setNameExpanded(!nameExpanded)}
              >
                <ExpanderCollapsedDescription></ExpanderCollapsedDescription>
                <ExpanderBody>
                  {/* TODO: Implement global name display setting*/}
                  <div className="k_cardQuestionDescription mb-4">
                    If you’d like, Koto can show your members’ full names
                    instead of their shorter display names.
                  </div>
                  <label className="k_choiceLabel mb-4">
                    <input
                      type="checkbox"
                      className="form-checkbox k_choiceInput"
                      disabled={false}
                      value="setAutomatically"
                    />
                    <div className="k_choiceText">
                      Show full names instead of display names
                    </div>
                  </label>
                  <button className="btn btn-gray w-full">Save</button>
                </ExpanderBody>
              </Expander>
              <Expander
                isExpanded={emailExpanded}
                title="Email display"
                hideControl={false}
                toggleExpanded={() => setEmailExpanded(!emailExpanded)}
              >
                <ExpanderCollapsedDescription></ExpanderCollapsedDescription>
                <ExpanderBody>
                  {/* TODO: Implement global email display setting*/}
                  <div className="k_cardQuestionDescription mb-4">
                    Choose whether to display members’ email addresses in their
                    Koto profiles.
                  </div>
                  <label className="k_choiceLabel mb-4">
                    <input
                      type="checkbox"
                      className="form-checkbox k_choiceInput"
                      disabled={false}
                      value="setAutomatically"
                    />
                    <div className="k_choiceText">
                      Display members’ email addresses
                    </div>
                  </label>
                  <button className="btn btn-gray w-full">Save</button>
                </ExpanderBody>
              </Expander>
              <Expander
                isExpanded={retentionExpanded}
                title="Record Retention &amp; Deletion"
                hideControl={false}
                toggleExpanded={() => setRetentionExpanded(!retentionExpanded)}
              >
                <ExpanderCollapsedDescription></ExpanderCollapsedDescription>
                <ExpanderBody>
                  {/* TODO: Implement global record retntion setting*/}
                  <div className="k_cardQuestionDescription mb-4">
                    By default, Koto keeps all your records for the lifetime of
                    your workspace. If you’d like, you can have them deleted
                    after a set amount of time. You can also configure this for
                    specific record types.
                  </div>
                  <label className="mb-4">
                    <select className="form-select k_select">
                      <option value="Keep all records (default)">
                        Keep all records (default)
                      </option>
                      <option value="Delete records after ...">
                        Delete records after ...
                      </option>
                    </select>
                  </label>
                  {/* TODO: Only show the following div if "Delete records after ..." is selected*/}
                  <div className="mb-4 flex items-center">
                    <input
                      className="flex-initial k_textInput mr-2 mt-0 w-64"
                      placeholder="Enter a number"
                      disabled={false}
                    ></input>
                    <select className="flex-grow form-select my-0 k_select ">
                      <option value="Days">Days</option>
                      <option value="Years">Years</option>
                    </select>
                  </div>
                  {/* TODO: Clean up k_choiceLabel so that text doesn't wrap under selector*/}
                  <label className="k_choiceLabel mb-4 ">
                    <input
                      type="checkbox"
                      className="form-checkbox k_choiceInput "
                      disabled={false}
                      value="setAutomatically"
                    />
                    <div className="k_choiceText">
                      Allow administrators to set different retention periods —
                      making it shorter or longer — for different record types.
                    </div>
                  </label>
                  <button className="btn btn-gray w-full">Save</button>
                </ExpanderBody>
              </Expander>
            </div>
          </TabContent>
        </Tab>
        <Tab label="permissions">
          <TabHeader>Permissions</TabHeader>
          <TabContent>
            <Permissions />
          </TabContent>
        </Tab>
        <Tab label="authentication">
          <TabHeader>Authentication</TabHeader>
          <TabContent>
            <Authentication />
          </TabContent>
        </Tab>
        <Tab label="access-logs">
          <TabHeader>Access Logs</TabHeader>
          <TabContent>
            <AccessLogs />
          </TabContent>
        </Tab>
      </TabbedView>
    </Fragment>
  );
};
