import "firebase/firestore";

import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAeioTBXxHaeaAXP_WK1TVqd8MD68WQCeE",
  authDomain: "koto-dev-69eaa.firebaseapp.com",
  databaseURL: "https://koto-dev-69eaa.firebaseio.com",
  projectId: "koto-dev-69eaa",
  storageBucket: "koto-dev-69eaa.appspot.com",
  messagingSenderId: "856015482852",
  appId: "1:856015482852:web:68ee99eaad65e57f",
  measurementId: "G-317J42YWGP",
};

export const firebaseSetup = (): [
  firebase.app.App,
  firebase.firestore.Firestore
] => {
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const firestoreDb = firebaseApp.firestore();

  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "koto.demo.goodctzn.com"
  ) {
    firestoreDb.settings({
      host: `${window.location.hostname}:9768`,
      ssl: false,
    });
  }

  firestoreDb
    .enablePersistence({ synchronizeTabs: true })
    .catch(function (err) {
      if (err.code == "failed-precondition") {
        console.log(`Could not enable persistence: ${err.code}`);
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code == "unimplemented") {
        console.log(`The current browser does not support persistence.`);
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });

  return [firebaseApp, firestoreDb];
};
