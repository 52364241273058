import * as Worker from "../Backend/Worker/koto.backend.worker";

import { KotoConfig, createKotoConfig } from "./KotoConfig";
import { UserId, genPlainUid, genSessionId } from "./IdTypes";

import { BackingStore } from "./KotoStore";
import { KOTO_WORKER_KEY } from "./KotoTypes";
import { makeLocalCacheStore } from "./KotoOb";

export interface KotoContext {
  contextId: string;
  userId: string;
  sessionId: string;
  storage: BackingStore;
  config: KotoConfig;
  workers: {
    [KOTO_WORKER_KEY]: typeof Worker | null;
    [name: string]: any;
  };
  cache: { [name: string]: any };
}

export function createContext(userId: UserId): KotoContext {
  const config = createKotoConfig();

  const workers: KotoContext["workers"] = {
    [KOTO_WORKER_KEY]: null,
  };

  if (config.useWebWorker) {
    // const worker = new Worker(
    //   new URL("../Backend/Worker/koto.web.worker.ts", import.meta.url)
    // );
    workers[KOTO_WORKER_KEY] = Worker;
  }

  return {
    contextId: genPlainUid(),
    userId: userId,
    sessionId: genSessionId(),
    storage: makeLocalCacheStore(),
    config: config,
    workers: workers,
    cache: {},
  };
}

export const createProdContext = (
  uid: UserId,
  backend: BackingStore
): KotoContext => {
  const config = createKotoConfig();

  const workers: KotoContext["workers"] = {
    [KOTO_WORKER_KEY]: null,
  };

  if (config.useWebWorker) {
    // const worker = new Worker(
    //   new URL("../Backend/Worker/koto.web.worker.ts", import.meta.url)
    // );
    workers[KOTO_WORKER_KEY] = Worker;
  }

  return {
    contextId: genPlainUid(),
    userId: uid,
    sessionId: genSessionId(),
    storage: backend,
    config: config,
    workers: workers,
    cache: {},
  };
};
