import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { KotoCRDT } from "../types/KotoTypes";
import { Reminder } from "../types/Reminder";

export interface ReminderListSlice {
  reminderList: KotoCRDT<Reminder>[];
}

export const reminderListSlice = createSlice({
  name: "reminderList",
  initialState: {
    reminderList: [],
  } as ReminderListSlice,
  reducers: {
    loadList: (state, action: PayloadAction<KotoCRDT<Reminder>[]>) => {
      state.reminderList = action.payload;
    },
  },
});
