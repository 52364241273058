import React, { FunctionComponent, useState, Fragment } from "react";
import { Expander, ExpanderBody, ExpanderCollapsedDescription } from "../Card";

interface PermissionsProps {
  // TODO: Add activeSection to scroll to and expand a specific section of the
  //   settings activeSection: string;
}

// TODO: Add backend configuration to enable these permissions. TODO: If a user
// selects either of the following options, display a search ahead select menu
// allowing them to choose differnet user groups:
// - "Everybody but these specific  user groups ..."
// - "Only these specific user groups ..."

export const Permissions: FunctionComponent<PermissionsProps> = (_props) => {
  const [invitationsExpanded, setInvitationsExpanded] = useState(false);
  const [formManagementExpanded, setFormManagementExpanded] = useState(false);
  const [recordManagementExpanded, setRecordManagementExpanded] = useState(
    false
  );
  const [
    dashboardManagementExpanded,
    setDashboardManagementExpanded,
  ] = useState(false);
  const [reportManagementExpanded, setReportManagementExpanded] = useState(
    false
  );
  const [userGroupsExpanded, setUserGroupsExpanded] = useState(false);
  const [bulkDownloadExpanded, setBulkDownloadExpanded] = useState(false);

  return (
    <Fragment>
      <div className="my-8">
        <Expander
          isExpanded={invitationsExpanded}
          title="Invitations"
          hideControl={false}
          toggleExpanded={() => setInvitationsExpanded(!invitationsExpanded)}
        >
          <ExpanderCollapsedDescription>
            <div className="k_cardQuestionDescription mt-2">
              By default, any member can invite new people to your project. If
              you’d like, you can change this so invitations require admin
              approval.
            </div>
          </ExpanderCollapsedDescription>
          <ExpanderBody>
            <label className="k_choiceLabel mb-4">
              <input
                type="checkbox"
                className="form-checkbox k_choiceInput"
                disabled={false}
                value="setAutomatically"
              />
              <div className="k_choiceText">Require admin approval</div>
            </label>
            <button className="btn btn-gray w-full">Save</button>
          </ExpanderBody>
        </Expander>
        <Expander
          isExpanded={formManagementExpanded}
          title="Form management"
          hideControl={false}
          toggleExpanded={() =>
            setFormManagementExpanded(!formManagementExpanded)
          }
        >
          <ExpanderCollapsedDescription>
            <div className="k_cardQuestionDescription mt-2">
              Choose who can create, archive, remove, and manage permissions in
              forms.
            </div>
          </ExpanderCollapsedDescription>
          <ExpanderBody>
            <label className="k_cardQuestionDescription">
              People who can create forms
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <label className="k_cardQuestionDescription">
              People who can archive forms
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <label className="k_cardQuestionDescription">
              People who can remove forms
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <label className="k_cardQuestionDescription">
              People who can manage permissions in forms
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <button className="btn btn-gray w-full">Save</button>
          </ExpanderBody>
        </Expander>
        <Expander
          isExpanded={recordManagementExpanded}
          title="Record management"
          hideControl={false}
          toggleExpanded={() =>
            setRecordManagementExpanded(!recordManagementExpanded)
          }
        >
          <ExpanderCollapsedDescription>
            <div className="k_cardQuestionDescription mt-2">
              Choose who can create, archive, edit, remove records.
            </div>
          </ExpanderCollapsedDescription>
          <ExpanderBody>
            <label className="k_cardQuestionDescription">
              People who can create records
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <label className="k_cardQuestionDescription">
              People who can archive records
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <label className="k_cardQuestionDescription">
              People who can remove records
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <button className="btn btn-gray w-full">Save</button>
          </ExpanderBody>
        </Expander>
        <Expander
          isExpanded={dashboardManagementExpanded}
          title="Dashboard management"
          hideControl={false}
          toggleExpanded={() =>
            setDashboardManagementExpanded(!dashboardManagementExpanded)
          }
        >
          <ExpanderCollapsedDescription>
            <div className="k_cardQuestionDescription mt-2">
              Choose who can create, archive, remove, and manage permissions in
              dashboards.
            </div>
          </ExpanderCollapsedDescription>
          <ExpanderBody>
            <label className="k_cardQuestionDescription">
              People who can create dashboards
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <label className="k_cardQuestionDescription">
              People who can archive dashboards
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <label className="k_cardQuestionDescription">
              People who can remove dashboards
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <label className="k_cardQuestionDescription">
              People who can manage permissions in dashboards
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <button className="btn btn-gray w-full">Save</button>
          </ExpanderBody>
        </Expander>
        <Expander
          isExpanded={reportManagementExpanded}
          title="Report management"
          hideControl={false}
          toggleExpanded={() =>
            setReportManagementExpanded(!reportManagementExpanded)
          }
        >
          <ExpanderCollapsedDescription>
            <div className="k_cardQuestionDescription mt-2">
              Choose who can create, archive, remove, and manage permissions in
              reports.
            </div>
          </ExpanderCollapsedDescription>
          <ExpanderBody>
            <label className="k_cardQuestionDescription">
              People who can create reports
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <label className="k_cardQuestionDescription">
              People who can archive reports
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <label className="k_cardQuestionDescription">
              People who can remove reports
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <label className="k_cardQuestionDescription">
              People who can manage permissions in reports
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <button className="btn btn-gray w-full">Save</button>
          </ExpanderBody>
        </Expander>
        <Expander
          isExpanded={userGroupsExpanded}
          title="User Groups"
          hideControl={false}
          toggleExpanded={() => setUserGroupsExpanded(!userGroupsExpanded)}
        >
          <ExpanderCollapsedDescription>
            <div className="k_cardQuestionDescription mt-2">
              Set who can create and manage user groups.
            </div>
          </ExpanderCollapsedDescription>
          <ExpanderBody>
            <label className="k_cardQuestionDescription">
              People who can create and disable user groups:
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <label className="k_cardQuestionDescription">
              People who can modify user groups:
              <select className="form-select k_select mb-4">
                <option value="Everybody (default)">Everybody (default)</option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Admins only">Admins only</option>
              </select>
            </label>
            <button className="btn btn-gray w-full">Save</button>
          </ExpanderBody>
        </Expander>
        <Expander
          isExpanded={bulkDownloadExpanded}
          title="Bulk Downloading Data"
          hideControl={false}
          toggleExpanded={() => setBulkDownloadExpanded(!bulkDownloadExpanded)}
        >
          <ExpanderCollapsedDescription>
            <div className="k_cardQuestionDescription mt-2">
              Set who can export and download project data in bulk.
            </div>
          </ExpanderCollapsedDescription>
          <ExpanderBody>
            <label className="k_cardQuestionDescription">
              People who can bulk export project data:
              <select className="form-select k_select mb-4">
                <option value="Admins only">Admins only (default)</option>
                <option value="Only these specific user groups ...">
                  Only these specific user groups ...
                </option>
                <option value="Everybody but these specific user groups">
                  Everybody but these specific user groups ...
                </option>
                <option value="Everybody (default)">Everybody</option>
              </select>
            </label>
            <button className="btn btn-gray w-full">Save</button>
          </ExpanderBody>
        </Expander>
      </div>
    </Fragment>
  );
};
