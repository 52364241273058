import * as K from "./KotoOb";

import {
  Block,
  BlockSpec,
  addQuestionToSpec,
  createAndStoreBlockSpec,
  createBlock,
  createBlockSpec,
  loadBlockSpecFromKey,
} from "./Block";
import { FormId, ProjectId, RecordId, RecordSetId, genFormId } from "./IdTypes";
import {
  KotoBaseDoc,
  KotoCRDT,
  KotoKey,
  KotoOb,
  KotoRef,
  KotoSerializedCRDT,
} from "../types/KotoTypes";
import { QueryParams, QueryParamsWithKey } from "./KotoStore";
import {
  completeReminder,
  createReminder,
  findRemindersForForm,
} from "./Reminder";

import { KotoContext } from "./KotoContext";
import { QuestionTypeName } from "./Questions";
import { Record } from "./Record";

export interface Form extends KotoBaseDoc {
  id: FormId;
  type: "Form";
  projectId: ProjectId;
  title: K.KotoText;
  description: K.KotoText;
  blockSpecRefs: KotoRef<BlockSpec>[];
  submissionSettings: {
    buttonLabel: K.KotoText;
    confirmationMessage: K.KotoText;
  };
  deleted: number;
  archived: number;
}

const makeFormKey = (projectId: ProjectId, formId: FormId): KotoKey => {
  return `${projectId}-${formId}`;
};

export const getFormInfoFromKey = (formKey: KotoKey) => {
  const [projectId, formId] = formKey.split("-");
  return { projectId, formId };
};

export const validateFormKey = (key: string) => {
  const formKeyRegex = /pj[0-9a-zA-Z]+-fm[0-9a-zA-Z]+/;
  return key.match(formKeyRegex) !== null;
};

export const createForm = (
  context: KotoContext,
  projectId: ProjectId,
  title: string = "New Form"
): KotoCRDT<Form> => {
  const formId = genFormId();
  return K.create(
    context,
    {
      id: formId,
      type: "Form",
      projectId: projectId,
      title: new K.KotoText(title),
      description: new K.KotoText(),
      blockSpecRefs: [],
      submissionSettings: {
        buttonLabel: new K.KotoText("Submit"),
        confirmationMessage: new K.KotoText("Submitted."),
      },
      deleted: 0,
      archived: 0,
    },
    makeFormKey(projectId, formId)
  );
};

export const createAndStoreForm = async (
  kContext: KotoContext,
  projectId: ProjectId,
  title: string = "New Form"
): Promise<KotoCRDT<Form>> => {
  const defaultBlockSpec = await createAndStoreBlockSpec(kContext, projectId);

  const tempForm = createForm(kContext, projectId, title);
  const newForm = addBlockSpecToForm(kContext, defaultBlockSpec, tempForm);

  const result = await K.storeCrdt(kContext, newForm);

  if (result.type !== "Success") {
    throw new Error(`Failed to store Form ${newForm.key}`);
  }

  return newForm;
};

export const copyForm = (
  kContext: KotoContext,
  form: KotoCRDT<Form>,
  nextNum: number
): KotoCRDT<Form> => {
  const newFormId = genFormId();

  let newTitle = form.crdt.title.toString();
  const newTitleEdited = newTitle.replace(/ \d+$/, ` ${nextNum.toString()}`);
  if (newTitle === newTitleEdited) {
    newTitle = `${newTitle} ${nextNum}`;
  } else {
    newTitle = newTitleEdited;
  }

  const newRawForm: Form = {
    id: newFormId,
    type: form.crdt.type,
    projectId: form.crdt.projectId,
    title: new K.KotoText(newTitle),
    description: new K.KotoText(form.crdt.description.toString()),
    blockSpecRefs: [
      ...form.crdt.blockSpecRefs.map(
        (value: KotoRef<BlockSpec>): KotoRef<BlockSpec> => {
          return {
            key: value.key,
            type: value.type,
          };
        }
      ),
    ],
    submissionSettings: {
      buttonLabel: new K.KotoText(
        form.crdt.submissionSettings.buttonLabel.toString()
      ),
      confirmationMessage: new K.KotoText(
        form.crdt.submissionSettings.confirmationMessage.toString()
      ),
    },
    deleted: form.crdt.deleted,
    archived: form.crdt.archived,
  };

  const newForm = K.create(
    kContext,
    newRawForm,
    makeFormKey(form.crdt.projectId, newFormId)
  );

  return newForm;
};

export const loadForm = async (
  kContext: KotoContext,
  projectId: ProjectId,
  formId: FormId
): Promise<K.KotoResult<KotoCRDT<Form>>> => {
  const queryParams: QueryParamsWithKey<KotoSerializedCRDT<Form>> = {
    type: "Form",
    params: [
      {
        fieldPath: "key",
        op: "==",
        value: makeFormKey(projectId, formId),
      },
    ],
  };

  return K.getCrdt<Form>(kContext, queryParams);
};

const genFormsForProjectQuery = (
  projectId: ProjectId,
  deleted: boolean = false,
  archived: boolean = false
): QueryParams<KotoSerializedCRDT<Form>> => {
  return {
    type: "Form",
    params: [
      {
        fieldPath: "doc.projectId",
        op: "==",
        value: projectId,
      },
      {
        fieldPath: "doc.deleted",
        op: "==",
        value: deleted ? 1 : 0,
      },
      {
        fieldPath: "doc.archived",
        op: "==",
        value: archived ? 1 : 0,
      },
    ],
  };
};

export const loadFormsForProject = async (
  kContext: KotoContext,
  projectId: ProjectId,
  deleted: boolean = false,
  archived: boolean = false
): Promise<K.KotoResult<KotoCRDT<Form>[]>> => {
  const result = await K.getList<Form>(
    kContext,
    genFormsForProjectQuery(projectId, deleted, archived)
  );

  if (result.type !== "Success") {
    return result;
  } else {
    return {
      type: result.type,
      data: result.data,
    };
  }
};

export const subscribeToFormsForProject = (
  kContext: KotoContext,
  projectId: ProjectId,
  deleted: boolean = false,
  archived: boolean = false,
  cb: (result: K.KotoResult<KotoCRDT<Form>[]>) => void,
  errorCb: (err: any) => void
): K.SubscriptionCanceler => {
  return K.subscribeList(
    kContext,
    genFormsForProjectQuery(projectId, deleted, archived),
    cb,
    errorCb
  );
};

export const loadFormsForProjectWithTitlePrefix = async (
  kContext: KotoContext,
  projectId: ProjectId,
  titlePrefix: string,
  deleted: boolean = false,
  archived: boolean = false
): Promise<K.KotoResult<KotoCRDT<Form>[]>> => {
  const result = await K.getList<Form>(kContext, {
    type: "Form",
    params: [
      {
        fieldPath: "doc.title",
        op: ">=",
        value: titlePrefix,
      },
      {
        fieldPath: "doc.title",
        op: "<=",
        value: titlePrefix + "\uf8ff",
      },
      {
        fieldPath: "doc.projectId",
        op: "==",
        value: projectId,
      },
      {
        fieldPath: "doc.deleted",
        op: "==",
        value: deleted ? 1 : 0,
      },
      {
        fieldPath: "doc.archived",
        op: "==",
        value: archived ? 1 : 0,
      },
    ],
  });

  if (result.type !== "Success") {
    return result;
  } else {
    return {
      type: result.type,
      data: result.data,
    };
  }
};

export const loadBlockSpecsForForm = async (
  kContext: KotoContext,
  form: KotoCRDT<Form>
): Promise<{ [key: string]: KotoCRDT<BlockSpec> }> => {
  const specResultMap = await Promise.all(
    form.crdt.blockSpecRefs.map((ref) => {
      const spec = loadBlockSpecFromKey(kContext, ref.key);
      return spec;
    })
  );

  const specMap = specResultMap
    .map((result) => {
      if (result.type === "Success") {
        return result.data;
      } else {
        return null;
      }
    })
    .reduce((prev, cur) => {
      if (cur !== null) {
        return { ...prev, [cur.key]: cur };
      } else {
        return prev;
      }
    }, {});

  return specMap;
};

export const updateFormTitle = (
  kContext: KotoContext,
  kForm: KotoCRDT<Form>,
  newTitle: string
) => {
  const newForm = K.change(kContext, kForm, (doc) => {
    K.performUpdateText(doc.title, newTitle);
  });
  return newForm;
};

export const updateFormDescription = (
  kContext: KotoContext,
  kForm: KotoCRDT<Form>,
  newDescription: string
) => {
  const newForm = K.change(kContext, kForm, (doc) => {
    K.performUpdateText(doc.description, newDescription);
  });
  return newForm;
};

export const addBlockSpecToForm = (
  kContext: KotoContext,
  blockSpec: KotoCRDT<BlockSpec>,
  form: KotoCRDT<Form>,
  idx?: number
) => {
  const realIndex = idx === undefined ? form.crdt.blockSpecRefs.length : idx;

  return K.change(kContext, form, (doc) => {
    doc.blockSpecRefs.splice(realIndex, 0, K.getRef(blockSpec));
  });
};

export const addSectionToForm = (
  kContext: KotoContext,
  kForm: KotoCRDT<Form>
) => {
  const spec = createBlockSpec(kContext, kForm.crdt.projectId);
  const form = addBlockSpecToForm(kContext, spec, kForm);
  return { form, spec };
};

export const addNewQuestionToForm = async (
  kContext: KotoContext,
  kForm: KotoCRDT<Form>,
  activeBlockSpecId: KotoKey,
  questionType: QuestionTypeName = "shortTextQuestion"
): Promise<{ form: KotoCRDT<Form>; spec: KotoCRDT<BlockSpec> }> => {
  let spec: KotoCRDT<BlockSpec>;
  let form: KotoCRDT<Form> = kForm;

  if (kForm.crdt.blockSpecRefs.length === 0) {
    spec = createBlockSpec(kContext, kForm.crdt.projectId);
    form = addBlockSpecToForm(kContext, spec, form);
  } else {
    const workingBlock = activeBlockSpecId || kForm.crdt.blockSpecRefs[0].key;

    spec = await addNewQuestionToBlockSpecInForm(
      kContext,
      kForm,
      workingBlock,
      questionType
    );
  }

  return { form, spec };
};

export const addNewQuestionToBlockSpecInForm = async (
  kContext: KotoContext,
  kForm: KotoCRDT<Form>,
  activeBlockSpecId: KotoKey,
  questionType: QuestionTypeName
): Promise<KotoCRDT<BlockSpec>> => {
  const matchingBlocks = kForm.crdt.blockSpecRefs.filter((blockOb) => {
    return blockOb.key === activeBlockSpecId;
  });

  if (matchingBlocks.length !== 1) {
    throw Error(`Could not find a single block with ID ${activeBlockSpecId}`);
  }

  const activeBlock = matchingBlocks[0];
  const blockSpecCrdt = await K.getKotoCrdtFromKotoOb(
    kContext,
    activeBlock as KotoOb<BlockSpec>
  );
  const newBlockSpec = addQuestionToSpec(kContext, blockSpecCrdt, questionType);

  return newBlockSpec;
};

export const deleteBlockSpecFromForm = (
  kContext: KotoContext,
  oldForm: KotoCRDT<Form>,
  blockSpecKey: KotoKey
): KotoCRDT<Form> => {
  const blockSpecIdx = oldForm.crdt.blockSpecRefs.findIndex((blockSpec) => {
    return blockSpec.key === blockSpecKey;
  });

  if (blockSpecIdx === -1) {
    throw new Error(
      `Trying to delete a block spec ${blockSpecKey} that doesn't exist in form ${oldForm.crdt.id}.`
    );
  }

  return K.change(kContext, oldForm, (doc) => {
    doc.blockSpecRefs.splice(blockSpecIdx, 1);
  });
};

export const deleteForm = (kContext: KotoContext, form: KotoCRDT<Form>) => {
  return K.change(kContext, form, (doc) => {
    doc.deleted = 1;
  });
};

export const archiveForm = (kContext: KotoContext, form: KotoCRDT<Form>) => {
  return K.change(kContext, form, (doc) => {
    doc.archived = 1;
  });
};

/**
 * This function takes a form and blocks and returns valid blocks for this form.
 *
 * The blocks that are passed in could be blocks that came from the Record
 * and/or blocks that have been edited by the user filling out the form.
 *
 * @param kContext a koto context
 * @param form the form that is being filled
 * @param blockSpecMap a map of keys to blockspecs. Must cover all blockSpec
 * refs in the form
 * @param recordId The record the form is being filled for
 * @param blocks The blocks available for autofill or intermediate form filling stages
 *
 * @return List of blocks the form produces
 */
export const fillForm = (
  kContext: KotoContext,
  form: KotoCRDT<Form>,
  blockSpecMap: { [key: string]: KotoOb<BlockSpec> },
  recordSetId: RecordSetId,
  recordId: RecordId,
  blocks: KotoCRDT<Block>[]
): {
  block: KotoCRDT<Block>;
  index: number;
  blockSpec: KotoOb<BlockSpec>;
}[] => {
  const availableBlocks = [...blocks];

  const matchingBlocks: {
    block: KotoCRDT<Block>;
    index: number;
    blockSpec: KotoOb<BlockSpec>;
  }[] = [];

  form.crdt.blockSpecRefs.map((ref, blockRefIndex) => {
    const blockSpec = blockSpecMap[ref.key];

    if (!blockSpec) {
      throw new Error(`Couldn't find blockSpec: ${ref}`);
    }

    const matchingBlockIndex = availableBlocks.findIndex((value) => {
      return value.crdt.blockSpec.key === ref.key;
    });

    const matchingBlock = availableBlocks.splice(matchingBlockIndex, 1);

    if (matchingBlock.length === 0) {
      matchingBlocks.push({
        block: createBlock(
          kContext,
          form.crdt.projectId,
          form.crdt.id,
          blockSpec,
          recordSetId,
          recordId
        ),
        index: blockRefIndex,
        blockSpec: blockSpec,
      });
    } else {
      matchingBlocks.push({
        block: matchingBlock[0],
        index: blockRefIndex,
        blockSpec: blockSpec,
      });
    }
  });

  return matchingBlocks;
};

export const submitForm = async (
  kContext: KotoContext,
  record: KotoCRDT<Record>,
  formKey: KotoKey,
  blocks: KotoCRDT<Block>[]
) => {
  if (blocks) {
    const saveBlockPromises = blocks
      .filter((x) => x)
      .map((block) => {
        return K.storeCrdt(kContext, block);
      });

    await Promise.all(saveBlockPromises);

    try {
      const reminders = await findRemindersForForm(kContext, record, formKey);
      if (reminders.length > 0) {
        const completedReminder = completeReminder(kContext, reminders[0]);
        await K.storeCrdt(kContext, completedReminder);
      } else {
        const newReminder = createReminder(kContext, record, {
          reminderType: "form",
          formKey: formKey,
        });
        const completedReminder = completeReminder(kContext, newReminder);

        await K.storeCrdt(kContext, completedReminder);
      }
    } catch (e) {
      console.log(`Failed to create reminder. ${JSON.stringify(e)}`);
    }
  }
};
