import { createSlice } from "@reduxjs/toolkit";
import * as utils from "../utils/utils";

export const blockListSlice = createSlice({
  name: "blockList",
  initialState: {
    view: "read",
    blocks: [],
  },
  reducers: {
    toggleView: (state) => {
      state.view = utils.nextInCycle(["view", "fill", "design"], state.view);
    },
  },
});
