import React, { FunctionComponent, ChangeEvent } from "react";

import { registerQuestionType } from "../../types/QuestionTypeRegistry";

import {
  QuestionDesignProps,
  QuestionFillerProps,
  TextPart,
  QuestionViewerProps,
  BaseQuestionSpec,
  QuestionAnswer,
  createBaseQuestionSpec,
} from "../../types/Questions";
import * as K from "../../types/KotoOb";

export interface ShortTextQuestionSpec extends BaseQuestionSpec {
  parts: { text: TextPart };
}

export interface ShortTextAnswer extends QuestionAnswer {
  parts: { text: K.KotoText };
}

const createShortTextQuestionSpec = (
  defaultValue: string = "",
  regex: string = ".*",
  ghostText: string = "",
  minLength: number = 0,
  maxLength: number = 255
): ShortTextQuestionSpec => {
  return {
    ...createBaseQuestionSpec("shortTextQuestion"),
    parts: {
      text: {
        default: new K.KotoText(defaultValue),
        regex: new K.KotoText(regex),
        ghostText: new K.KotoText(ghostText),
        minLength: minLength,
        maxLength: maxLength,
      },
    },
  };
};

const createShortTextAnswer = (
  spec: ShortTextQuestionSpec
): ShortTextAnswer => {
  return {
    type: "shortTextAnswer",
    isAnswered: false,
    parts: { text: new K.KotoText(spec.parts.text.default.toString()) },
  };
};

const ShortQuestionPartsDesigner: FunctionComponent<QuestionDesignProps<
  ShortTextQuestionSpec
>> = (_props) => {
  return (
    <div className="mx-5 my-3 first:mt-5">
      <input
        className="bg-gray-100 rounded border border-gray-400 py-2 px-3 w-full text-gray-800"
        placeholder="Response Text"
        disabled={true}
      ></input>
    </div>
  );
};

export const makeAnswerTextUpdater = (value: string) => {
  return (_spec: ShortTextQuestionSpec, answer: ShortTextAnswer) => {
    if (value === "") {
      answer.isAnswered = false;
    } else {
      answer.isAnswered = true;
    }
    K.performUpdateText(answer.parts.text, value);
  };
};

export const ShortQuestionPartsFiller: FunctionComponent<QuestionFillerProps<
  ShortTextQuestionSpec,
  ShortTextAnswer
>> = (props) => {
  const updateAnswerText = (event: ChangeEvent<HTMLInputElement>) => {
    props.setAnswer(makeAnswerTextUpdater(event.target.value));
  };

  return (
    <div className="">
      <input
        onChange={updateAnswerText}
        className="k_textInput"
        placeholder="Response Text"
        disabled={false}
        value={props.answer.parts.text.toString()}
      ></input>
    </div>
  );
};

const ShortTextAnswerViewer: FunctionComponent<QuestionViewerProps<
  ShortTextQuestionSpec,
  ShortTextAnswer
>> = (props) => {
  return <div>{props.answer.parts.text.toString()}</div>;
};

export const ShortQuestionType = {
  type: "shortTextQuestion",
  longName: "Short Question",
  createQuestionParts: createShortTextQuestionSpec,
  createAnswerParts: createShortTextAnswer,
  partsDesigner: ShortQuestionPartsDesigner,
  partsFiller: ShortQuestionPartsFiller,
  partsViewer: ShortTextAnswerViewer,
};

registerQuestionType(
  "shortTextQuestion",
  "Short Question",
  createShortTextQuestionSpec,
  createShortTextAnswer,
  ShortQuestionPartsDesigner,
  ShortQuestionPartsFiller,
  ShortTextAnswerViewer
);
