export interface KotoConfig {
  defaultPojectId: string;
  checkProjectExists: boolean;
  inlineRecordEdit: boolean;
  useWebWorker: boolean;
  useFirstQuestionAsFriendlyName: boolean;
}

export const createKotoConfig = (): KotoConfig => {
  return {
    defaultPojectId: "defaultProjectId",
    checkProjectExists: false,
    inlineRecordEdit: true,
    useWebWorker: false,
    useFirstQuestionAsFriendlyName: true,
  };
};
