import React, { FunctionComponent, ChangeEvent } from "react";
import { registerQuestionType } from "../../types/QuestionTypeRegistry";

import {
  QuestionDesignProps,
  QuestionFillerProps,
  TextPart,
  QuestionViewerProps,
  BaseQuestionSpec,
  QuestionAnswer,
  createBaseQuestionSpec,
} from "../../types/Questions";
import * as K from "../../types/KotoOb";

export interface LongTextQuestionSpec extends BaseQuestionSpec {
  parts: { text: TextPart };
}

export interface LongTextAnswer extends QuestionAnswer {
  parts: { text: K.KotoText };
}

const createLongTextQuestionSpec = (
  defaultValue: string = "",
  regex: string = ".*",
  ghostText: string = "",
  minLength: number = 0,
  maxLength: number = 4096
): LongTextQuestionSpec => {
  return {
    ...createBaseQuestionSpec("longTextQuestion"),
    parts: {
      text: {
        default: new K.KotoText(defaultValue),
        regex: new K.KotoText(regex),
        ghostText: new K.KotoText(ghostText),
        minLength: minLength,
        maxLength: maxLength,
      },
    },
  };
};

const createLongTextAnswer = (spec: LongTextQuestionSpec): LongTextAnswer => {
  return {
    type: "longTextAnswer",
    isAnswered: false,
    parts: { text: new K.KotoText(spec.parts.text.default.toString()) },
  };
};

const LongTextQuestionPartsDesigner: FunctionComponent<QuestionDesignProps<
  LongTextQuestionSpec
>> = (_props) => {
  return (
    <div className="mx-5 my-3 first:mt-5">
      <textarea
        className="bg-gray-100 rounded border border-gray-400 py-2 px-3 w-full text-gray-800"
        rows={3}
        placeholder="Response Text"
        disabled={true}
      />
    </div>
  );
};

export const makeAnswerTextUpdater = (value: string) => {
  return (_: LongTextQuestionSpec, answer: LongTextAnswer) => {
    if (value === "") {
      answer.isAnswered = false;
    } else {
      answer.isAnswered = true;
    }
    K.performUpdateText(answer.parts.text, value);
  };
};

export const LongTextQuestionPartsFiller: FunctionComponent<QuestionFillerProps<
  LongTextQuestionSpec,
  LongTextAnswer
>> = (props) => {
  const updateAnswerText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    props.setAnswer(makeAnswerTextUpdater(event.target.value));
  };

  return (
    <div className="">
      <textarea
        onChange={updateAnswerText}
        className="k_textArea"
        rows={3}
        placeholder="Response Text"
        disabled={false}
        value={props.answer.parts.text.toString()}
      />
    </div>
  );
};

const LongTextAnswerViewer: FunctionComponent<QuestionViewerProps<
  LongTextQuestionSpec,
  LongTextAnswer
>> = (props) => {
  return <div>{props.answer.parts.text.toString()}</div>;
};

registerQuestionType(
  "longTextQuestion",
  "Long Question",
  createLongTextQuestionSpec,
  createLongTextAnswer,
  LongTextQuestionPartsDesigner,
  LongTextQuestionPartsFiller,
  LongTextAnswerViewer
);
