/**
 *
 * The Koto datamodel consists of three main objects:
 *
 * KotoCRDT - This is the main object that contains a full live CRDT of a piece
 * of Koto data
 *
 * KotoOb - This is a plain non-crdt version of a KotoCRDT at a particular
 * version. This object is plain JSON, intended to be read-only, and has enough
 * information to lookup the coresponding KotoCRDT. This is the object that gets
 * passed through the frontend.
 *
 * KotoSerializedCRDT - This is a serialized version of a KotoCRDT. This is
 * intended for use in backing stores and to optionally save snapshots of
 * KotoCRDTs at particular versions.
 */

import * as Automerge from "automerge";

import { BackingStore, QueryParams, QueryParamsWithKey } from "./KotoStore";
import {
  IdType,
  MetadataId,
  UserId,
  genChangesId,
  genPlainUid,
} from "./IdTypes";
import {
  KOTO_WORKER_KEY,
  KotoBaseDoc,
  KotoBaseMetadata,
  KotoCRDT,
  KotoFullOb,
  KotoKey,
  KotoOb,
  KotoRef,
  KotoSerializedCRDT,
  KotoSerializedChange,
  KotoStorable,
  KotoTypes,
  Version,
  isSerializedCrdt,
} from "./KotoTypes";
import {
  automergeTypeToPlainType,
  deepCopyAutomergeObjects,
  getCRDTAtVersion,
  getRawDoc,
  getVersion,
} from "../utils/automerge";

import { KotoContext } from "./KotoContext";
import { diff_match_patch } from "diff-match-patch";
import { retry } from "../../../functions/src/utils";

export const createObMetadata = (kContext: KotoContext): KotoBaseMetadata => {
  return {
    creationTimestamp: Date.now(),
    lastUpdatedClientTimestamp: Date.now(),
    lastUpdatedUserId: kContext.userId,
  };
};

export type KotoList<T> = Automerge.List<T>;
export class KotoText extends Automerge.Text {}
export class KotoCounter extends Automerge.Counter {}
export type KotoFrozen<T> = Automerge.FreezeObject<T>;
export type KotoState<T> = Automerge.State<T>;

export interface FrontendMessage {
  type: string;
  data: any;
}
export interface KotoChangeRequest {
  key: KotoKey;
  type: KotoTypes;
  request: Automerge.Change;
}

export interface KotoPatchRequest {
  key: KotoKey;
  type: KotoTypes;
  patch: Automerge.Patch;
}

export type KotoResult<T> =
  | KotoResultSuccess<T>
  | KotoResultError<T>
  | KotoResultNotFound<T>;

export interface KotoResultSuccess<T> {
  type: "Success";
  data: T;
}

export interface KotoResultError<_T> {
  type: "Error";
  msg: string;
}

export interface KotoResultNotFound<_T> {
  type: "NotFound";
  msg: string;
}

export const getRef = <T extends KotoBaseDoc>(
  ob: KotoOb<T> | KotoCRDT<T>
): KotoRef<T> => {
  const ref: KotoRef<T> = { key: ob.key, type: ob.type };
  return ref;
};

export interface KMetadata<T extends KotoBaseDoc> {
  id: MetadataId;
  obId: T["id"];
  obType: T["type"];

  type: "KMetadata";

  sourceId?: IdType; // ID of the object that created the target object (e.g. The Form that created the Block)

  serverTimestamp?: number; // The first time the server saw the target object

  clientTimestamp?: number; // The time the client created the target object
  clientUser?: string; // The user that created the target object

  [name: string]: string | number | (string | number)[] | undefined;
}

class LocalCacheStore extends BackingStore {
  name = "InMemory";

  private localStore: Map<string, KotoStorable>;

  constructor() {
    super();
    this.localStore = new Map<string, KotoStorable>();
  }

  async store<T extends KotoStorable>(ob: T): Promise<KotoResult<T>> {
    try {
      this.localStore.set(ob.key, ob);
      return { type: "Success", data: ob };
    } catch (e) {
      return { type: "Error", msg: JSON.stringify(e) };
    }
  }

  async get<T extends KotoStorable>(
    queryParams: QueryParamsWithKey<T>
  ): Promise<KotoResult<T>> {
    const keyParam = queryParams.params.find((param) => {
      return param.fieldPath === "key";
    });

    if (keyParam === undefined) {
      return { type: "Error", msg: "No key param given." };
    }

    if (typeof keyParam.value !== "string") {
      return { type: "Error", msg: "Key parameter isn't a string." };
    }

    const value = this.localStore.get(keyParam.value);

    if (value) {
      return { type: "Success", data: value as T };
    } else {
      return {
        type: "NotFound",
        msg: `Could not find key: ${keyParam.value}.`,
      };
    }
  }
}

export const makeLocalCacheStore = () => {
  return new LocalCacheStore();
};

export const sendChanges = async <T extends KotoBaseDoc>(
  kContext: KotoContext,
  kCrdt: KotoCRDT<T>
): Promise<KotoResult<KotoCRDT<T>>> => {
  const oldVal = kContext.cache[kCrdt.key];

  let changes: KotoSerializedChange<T>;
  if (oldVal) {
    changes = serializeChanges<T>(kContext.userId, kCrdt, oldVal);
  } else {
    changes = serializeChanges<T>(kContext.userId, kCrdt);
  }

  const results = await kContext.storage.store(changes);

  if (results.type === "Success") {
    kContext.cache[kCrdt.key] = kCrdt;
    return {
      type: "Success",
      data: kCrdt,
    };
  } else {
    return {
      type: "Error",
      msg: JSON.stringify(results),
    };
  }
};

export const storeCrdt = async <T extends KotoBaseDoc>(
  kContext: KotoContext,
  kCrdt: KotoCRDT<T>
): Promise<KotoResult<KotoCRDT<T>>> => {
  try {
    const result = await kContext.storage.store(kCrdt);

    if (result.type === "Success") {
      return { type: "Success", data: kCrdt };
    } else {
      return result;
    }
  } catch (e) {
    return { type: "Error", msg: JSON.stringify(e) };
  }
};

export const getCrdt = async <T extends KotoBaseDoc>(
  kContext: KotoContext,
  queryParams: QueryParamsWithKey<KotoSerializedCRDT<T>>
): Promise<KotoResult<KotoCRDT<T>>> => {
  const result = await kContext.storage.get(queryParams);

  if (result.type === "Success") {
    return {
      type: result.type,
      data: isSerializedCrdt(result.data)
        ? deserialize(result.data)
        : result.data,
    };
  } else {
    return result;
  }
};

export const waitForCrdt = async <T extends KotoBaseDoc>(
  kContext: KotoContext,
  type: KotoTypes,
  key: KotoKey,
  retryCount: number = 5,
  minBackoffMs: number = 1000
): Promise<KotoResult<KotoCRDT<T>>> => {
  const result: KotoResult<KotoCRDT<T>> = await retryResult(
    () => {
      return getCrdt<T>(kContext, {
        type: type,
        params: [{ fieldPath: "key", op: "==", value: key }],
      });
    },
    retryCount,
    minBackoffMs
  );

  return result;
};

export const subscribeCrdt = <T extends KotoBaseDoc>(
  kContext: KotoContext,
  queryParams: QueryParamsWithKey<KotoSerializedCRDT<T>>,
  cb: (result: KotoResult<KotoCRDT<T>>) => void,
  errorCb?: (err: any) => void
): SubscriptionCanceler => {
  const wrappedCb = (result: KotoResult<KotoSerializedCRDT<T>>) => {
    if (result.type === "Success") {
      const crdtData = isSerializedCrdt(result.data)
        ? deserialize(result.data)
        : result.data;

      cb({
        type: result.type,
        data: crdtData,
      });
    } else {
      cb(result);
    }
  };

  return kContext.storage.subscribe(queryParams, wrappedCb, errorCb);
};

export const getList = async <T extends KotoBaseDoc>(
  kContext: KotoContext,
  queryParams: QueryParams<KotoSerializedCRDT<T>>
): Promise<KotoResult<KotoCRDT<T>[]>> => {
  const result = await kContext.storage.getList(queryParams);

  if (result.type === "Success") {
    return {
      type: result.type,
      data: result.data.map((item) => deserialize(item)),
    };
  } else {
    return result;
  }
};

export type SubscriptionCanceler = () => void;

export const subscribeList = <T extends KotoBaseDoc>(
  kContext: KotoContext,
  queryParams: QueryParams<KotoSerializedCRDT<T>>,
  cb: (result: KotoResult<KotoCRDT<T>[]>) => void,
  errorCb?: (err: any) => void
): SubscriptionCanceler => {
  const wrappedCb = (result: KotoResult<KotoSerializedCRDT<T>[]>) => {
    if (result.type === "Success") {
      cb({
        type: result.type,
        data: result.data.map((item) => deserialize(item)),
      });
    } else {
      cb(result);
    }
  };

  return kContext.storage.subscribeList(queryParams, wrappedCb, errorCb);
};

export const subscribeIndex = <T extends KotoStorable>(
  kContext: KotoContext,
  queryParams: QueryParams<T>,
  cb: (result: KotoResult<T[]>) => void,
  errorCb?: (err: any) => void
): SubscriptionCanceler => {
  return kContext.storage.subscribeList(queryParams, cb, errorCb);
};

export const retryResult = async <T>(
  func: () => Promise<KotoResult<T>>,
  numRetries: number = 5,
  minBackoffMs: number = 1000
): Promise<KotoResult<any>> => {
  let result: KotoResult<T> = { type: "Error", msg: "Not Assigned." };

  await retry(
    async () => {
      const lookupResult = await func();
      result = lookupResult;
      if (lookupResult.type === "Success") {
        return;
      } else {
        return new Error(lookupResult.msg);
      }
    },
    numRetries,
    minBackoffMs
  );

  return result;
};

export const getKotoObFromKotoCRDT = <T extends KotoBaseDoc>(
  kcrdt: KotoCRDT<T>
): KotoOb<T> => {
  const backendState = Automerge.Frontend.getBackendState(kcrdt.crdt);

  let plainTypes;

  if (!backendState) {
    console.log("TODO: THIS SHOULDN'T RUN");
    // TODO: #IMPORTANT, The version here is made up and may brake things, it will take a
    // larger refactor to fix this.
    plainTypes = {
      raw: JSON.parse(JSON.stringify(kcrdt.crdt)),
      version: Date.now().toString(),
    };
  } else {
    plainTypes = automergeTypeToPlainType(kcrdt.crdt);
  }

  return {
    key: kcrdt.key,
    type: kcrdt.type,
    kotoObVer: kcrdt.kotoObVer,
    doc: plainTypes.raw,
    docVersion: plainTypes.version,
    createdTimeInMilliseconds: kcrdt.createdTimeInMilliseconds,
  };
};

export const getKotoCrdtFromKotoOb = async <T extends KotoBaseDoc>(
  kContext: KotoContext,
  kOb: KotoOb<T>
): Promise<KotoCRDT<T>> => {
  const query: QueryParamsWithKey<KotoSerializedCRDT<T>> = {
    type: kOb.type,
    params: [
      {
        fieldPath: "key",
        op: "==",
        value: kOb.key,
      },
    ],
  };

  const kResult = await kContext.storage.get<KotoSerializedCRDT<T>>(query);

  if (kResult.type === "Error" || kResult.type === "NotFound") {
    throw Error(`Can't find CRDT for: ${kOb.key}`);
  } else {
    if (isSerializedCrdt(kResult.data)) {
      return deserialize<T>(kResult.data);
    } else {
      return kResult.data;
    }
  }
};

export const create = <T extends KotoBaseDoc>(
  kContext: KotoContext,
  rawDoc: T,
  key?: string
): KotoCRDT<T> => {
  const kotoKey = key || rawDoc.id;

  const obDoc: KotoFullOb<T> = {
    ...rawDoc,
    metadata: createObMetadata(kContext),
  };

  const worker = kContext.workers[KOTO_WORKER_KEY];
  let doc;

  if (worker !== null) {
    const [innerDoc, request] = Automerge.Frontend.from(obDoc);
    doc = innerDoc;

    (async () => {
      await worker.initialize({ type: "initialize", data: { key: kotoKey } });
      await worker.applyLocalRequest({
        type: "applyRequest",
        data: { key: kotoKey, type: obDoc.type, request: request },
      });
    })().catch((error) => {
      throw error;
    });
  } else {
    doc = Automerge.from(obDoc);
  }

  const kCrdt: KotoCRDT<T> = {
    key: kotoKey,
    type: rawDoc.type,
    kotoObVer: 1,
    crdt: doc,
    createdTimeInMilliseconds: Date.now(),
  };

  if (worker !== null) {
    localCacheStore[kotoKey] = kCrdt;
  }

  return kCrdt;
};

export const change = <T extends KotoBaseDoc>(
  kContext: KotoContext,
  kCrdt: KotoCRDT<T>,
  cb: (doc: Automerge.Proxy<T>) => void
): KotoCRDT<T> => {
  const message = { uid: kContext.userId, clientTimestamp: Date.now() };

  const worker = kContext.workers[KOTO_WORKER_KEY];
  let newDoc;

  if (worker) {
    const [innerDoc, request] = Automerge.Frontend.change(
      kCrdt.crdt,
      JSON.stringify(message),
      (doc: Automerge.Proxy<KotoFullOb<T>>) => {
        cb(doc);
        doc.metadata.lastUpdatedClientTimestamp = Date.now();
        doc.metadata.lastUpdatedUserId = kContext.userId;
      }
    );

    newDoc = innerDoc;

    (async () => {
      await worker.applyLocalRequest({
        type: "applyRequest",
        data: { key: kCrdt.key, type: kCrdt.type, request: request },
      });
    })().catch((error) => {
      throw error;
    });
  } else {
    newDoc = Automerge.change(
      kCrdt.crdt,
      JSON.stringify(message),
      (doc: Automerge.Proxy<KotoFullOb<T>>) => {
        cb(doc);
        doc.metadata.lastUpdatedClientTimestamp = Date.now();
        doc.metadata.lastUpdatedUserId = kContext.userId;
      }
    );
  }

  const newKotoCrdt: KotoCRDT<T> = {
    key: kCrdt.key,
    type: kCrdt.type,
    kotoObVer: kCrdt.kotoObVer,
    crdt: newDoc,
    createdTimeInMilliseconds: kCrdt.createdTimeInMilliseconds,
  };

  if (kCrdt.type !== newKotoCrdt.type || kCrdt.key !== newKotoCrdt.key) {
    throw Error(
      `The given changes aren't meant to apply to this CRDT. \n
      Expected: ${kCrdt.type}, ${kCrdt.key}\n
      Got: ${newKotoCrdt.type}, ${newKotoCrdt.key}`
    );
  }

  if (worker) {
    localCacheStore[kCrdt.key] = newKotoCrdt;
  }

  return newKotoCrdt;
};

export const getKotoCrdtVersion = <T extends KotoBaseDoc>(
  kCrdt: KotoCRDT<T>
): string => {
  return getVersion(kCrdt.crdt);
};

export const getHistoryofCrdt = <T extends KotoBaseDoc>(kCrdt: KotoCRDT<T>) => {
  return Automerge.getHistory(kCrdt.crdt);
};

export const getHistoryofOb = async <T extends KotoBaseDoc>(
  kContext: KotoContext,
  kOb: KotoOb<T>
) => {
  if (kOb.docVersion === "") {
    throw new Error(`Can't get history of historical KotoOb.`);
  }
  try {
    const crdt = await getKotoCrdtFromKotoOb(kContext, kOb);

    return Automerge.getHistory(crdt.crdt);
  } catch {
    throw Error("Can't find CRDT.");
  }
};

export const getObAtVersion = <T extends KotoBaseDoc>(
  k: KotoCRDT<T>,
  version: Version
): KotoOb<T> | null => {
  const doc = k.crdt;

  const newDocAtVersion = getCRDTAtVersion(doc, version);

  if (newDocAtVersion === null) {
    throw Error("Cannot get Ob at Version.");
  }

  return {
    key: k.key,
    type: k.type,
    kotoObVer: 1,
    doc: getRawDoc(newDocAtVersion),
    docVersion: version,
    createdTimeInMilliseconds: k.createdTimeInMilliseconds,
  };
};

export const validateSerializedCRDT = (
  _ob: KotoSerializedCRDT<any>
): KotoResult<boolean> => {
  return { type: "Error", msg: "Did not validate." };
};

export const serializeChanges = <T extends KotoBaseDoc>(
  userId: UserId,
  kEnd: KotoCRDT<T>,
  kStart?: KotoCRDT<T>
): KotoSerializedChange<T> => {
  const initDoc: Automerge.Doc<T> =
    kStart === undefined ? Automerge.init<T>() : kStart.crdt;

  const changes = Automerge.getChanges<T>(initDoc, kEnd.crdt);

  if (changes.length === 0) {
    const msg = `No changes to serialize for object ${kEnd.key}.`;
    console.trace(msg);
    throw new Error(msg);
  }
  return {
    key: genPlainUid(),
    id: genChangesId(),
    type: "Change",
    userId: userId,
    forKey: kEnd.key,
    kotoObVer: 1,
    forId: kEnd.crdt.id,
    forType: kEnd.type,
    changes: changes,
  };
};

export const applyChanges = <T extends KotoBaseDoc>(
  kChanges: KotoSerializedChange<T>[],
  kCrdt?: KotoCRDT<T> | undefined
): KotoCRDT<T> => {
  const workingKey = kChanges[0].forKey;

  let workingCrdt;
  if (kCrdt === undefined) {
    workingCrdt = Automerge.init<KotoFullOb<T>>();
  } else {
    workingCrdt = kCrdt.crdt;
  }

  const newDoc = Automerge.applyChanges<KotoFullOb<T>>(
    workingCrdt,
    kChanges
      .map((c) => {
        return c.changes;
      })
      .reduce((prev, cur) => {
        return [...prev, ...cur];
      }, [])
  );

  const newCrdt: KotoCRDT<T> = {
    key: workingKey,
    type: newDoc.type,
    kotoObVer: 1,
    crdt: newDoc,
    createdTimeInMilliseconds:
      kCrdt === undefined ? Date.now() : kCrdt.createdTimeInMilliseconds,
  };

  if (kCrdt !== undefined) {
    if (newCrdt.key !== kCrdt.key) {
      throw Error(
        `Changes are not allowed to change the ID of the object. Was: ${kCrdt.key} Changed to: ${newCrdt.key}`
      );
    } else if (newCrdt.type !== kCrdt.type) {
      throw Error(
        `Changes are not allowed to change the type of an object. Was: ${kCrdt.type} Changed to: ${newCrdt.type}`
      );
    }
  }

  return newCrdt;
};

export const serialize = <T extends KotoBaseDoc>(
  kCrdt: KotoCRDT<T>
): KotoSerializedCRDT<T> => {
  return {
    key: kCrdt.key,
    type: kCrdt.type,
    kotoObVer: 1,
    doc: getRawDoc(kCrdt.crdt),
    docVersion: getKotoCrdtVersion(kCrdt),
    savedCRDT: Automerge.save(kCrdt.crdt),
    createdTimeInMilliseconds: kCrdt.createdTimeInMilliseconds,
  };
};

export const deserialize = <T extends KotoBaseDoc>(
  data: KotoSerializedCRDT<T>
): KotoCRDT<T> => {
  const crdt = Automerge.load<KotoFullOb<T>>(data.savedCRDT, {
    // actorId: context.sessionId
  });

  if (data.doc.id !== crdt.id.toString() || data.type !== crdt.type) {
    throw Error(`Deserialized data doesn't match Koto data: 
    \nKoto Data: ${data.type},  ${data.doc.id}
    \nDeserialized: ${crdt.type}, ${crdt.id}`);
  }

  const newKotoOb: KotoCRDT<T> = {
    key: data.key,
    type: data.type,
    kotoObVer: 1,
    crdt: crdt,
    createdTimeInMilliseconds: data.createdTimeInMilliseconds,
  };

  return newKotoOb;
};

//
// Koto CRDT Editing helpers
//

export const performUpdateText = (
  textField: KotoText,
  changedText: string
): void => {
  if (textField === undefined || !textField.insertAt) {
    console.trace("Not a real text field: ", textField);
    return;
  }

  const srcText = textField.toString();

  const dmp = new diff_match_patch();
  const diff = dmp.diff_main(srcText, changedText);
  dmp.diff_cleanupSemantic(diff);

  const patches = dmp.patch_make(srcText, diff);

  patches.forEach((patch) => {
    let idx = patch.start1;
    patch.diffs.forEach(([operation, changeText]) => {
      if (idx === null) {
        console.trace("Patch index is null unexpectedly.");
        return;
      }

      if (textField === undefined || !textField.insertAt) {
        console.trace("What happened to the text field!?", textField);
        return;
      }

      switch (operation) {
        case 1: // Insertion
          textField.insertAt(idx, ...changeText.split(""));
          idx += changeText.length;
          break;
        case 0: // No Change
          idx += changeText.length;
          break;
        case -1: // Deletion
          for (let i = 0; i < changeText.length; i++) {
            textField.deleteAt!(idx);
          }
          break;
      }
    });
  });
};

export const doDeepCopyInDoc = (ob: KotoFrozen<any>) => {
  return deepCopyAutomergeObjects(ob);
};

const localCacheStore: { [key in KotoKey]: Automerge.FreezeObject<any> } = {};
