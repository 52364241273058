import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { BlockSpecId } from "../types/IdTypes";
import { DesignerViews } from "../components/Form";
import { Form } from "../types/Form";
import { KotoCRDT } from "../types/KotoTypes";

export interface FormDesignerSlice {
  form: KotoCRDT<Form> | null;
  activeBlockSpecId: BlockSpecId;
  designerView: DesignerViews;
}

export const formDesignerSlice = createSlice({
  name: "formDesigner",
  initialState: {
    form: null,
    activeBlockSpecId: "",
    designerView: "design",
  } as FormDesignerSlice,
  reducers: {
    setForm: (state, action: PayloadAction<KotoCRDT<Form> | null>) => {
      state.form = action.payload;
    },
    setActiveBlockSpec: (state, action: PayloadAction<BlockSpecId>) => {
      state.activeBlockSpecId = action.payload;
    },
    setDesignerView: (state, action: PayloadAction<DesignerViews>) => {
      state.designerView = action.payload;
    },
  },
});
