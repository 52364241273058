import * as K from "./KotoOb";

import { KotoBaseDoc, KotoCRDT, KotoSerializedCRDT } from "./KotoTypes";
import { ProjectId, RecordId, RecordSetId, genRecordId } from "./IdTypes";

import { KotoContext } from "./KotoContext";
import { Project } from "./Project";
import { QueryParamsWithKey } from "./KotoStore";

export interface Record extends KotoBaseDoc {
  id: RecordId;
  type: "Record";
  projectId: ProjectId;
  recordSetId: RecordSetId;
  name?: string;
  deleted: number;
  archived: number;
}

export const makeRecordKey = (
  projectId: ProjectId,
  recordId: RecordId
): string => {
  return `${projectId}-${recordId}`;
};

export const createRecord = (
  kContext: KotoContext,
  project: KotoCRDT<Project>,
  recordSetId: RecordSetId
): KotoCRDT<Record> => {
  if (!(recordSetId in project.crdt.recordSets)) {
    throw new Error(
      `Record set ${recordSetId} does not exist in project ${project.key}.`
    );
  }

  const recordId = genRecordId();
  const kCrdt = K.create(
    kContext,
    {
      id: recordId,
      type: "Record",
      projectId: project.key,
      recordSetId: recordSetId,
      deleted: 0,
      archived: 0,
    },
    makeRecordKey(project.key, recordId)
  );

  return kCrdt;
};

export const loadRecordsForRecordSet = async (
  kContext: KotoContext,
  projectId: ProjectId,
  recordSetId: RecordSetId,
  deleted: boolean = false,
  archived: boolean = false
): Promise<K.KotoResult<KotoCRDT<Record>[]>> => {
  const result = await K.getList<Record>(kContext, {
    type: "Record",
    params: [
      {
        fieldPath: "doc.projectId",
        op: "==",
        value: projectId,
      },
      { fieldPath: "doc.recordSetId", op: "==", value: recordSetId },
      { fieldPath: "doc.deleted", op: "==", value: deleted ? 1 : 0 },
      { fieldPath: "doc.archived", op: "==", value: archived ? 1 : 0 },
    ],
  });

  if (result.type !== "Success") {
    return result;
  } else {
    return {
      type: result.type,
      data: result.data,
    };
  }
};

const makeRecordQuery = (
  projectId: ProjectId,
  recordId: RecordId
): QueryParamsWithKey<KotoSerializedCRDT<Record>> => {
  return {
    type: "Record",
    params: [
      {
        fieldPath: "key",
        op: "==",
        value: makeRecordKey(projectId, recordId),
      },
    ],
  };
};

export const loadRecord = async (
  kContext: KotoContext,
  projectId: ProjectId,
  recordId: RecordId
): Promise<K.KotoResult<KotoCRDT<Record>>> => {
  const queryParams = makeRecordQuery(projectId, recordId);

  return K.getCrdt<Record>(kContext, queryParams);
};

export const subscribeRecord = (
  kContext: KotoContext,
  projectId: ProjectId,
  recordId: RecordId,
  cb: (result: K.KotoResult<KotoCRDT<Record>>) => void,
  errorCb: (err: any) => void
): K.SubscriptionCanceler => {
  const queryParams = makeRecordQuery(projectId, recordId);

  return K.subscribeCrdt(kContext, queryParams, cb, errorCb);
};

export const deleteRecord = (
  kContext: KotoContext,
  record: KotoCRDT<Record>
) => {
  return K.change(kContext, record, (doc) => {
    doc.deleted = 1;
  });
};

export const archiveRecord = (
  kContext: KotoContext,
  record: KotoCRDT<Record>
) => {
  return K.change(kContext, record, (doc) => {
    doc.archived = 1;
  });
};
