import * as K from "../types/KotoOb";

import {
  MobileActionBar,
  Menu,
  MenuItem,
  MenuItemIcon,
  MenuItemList,
  SidebarMenuButton,
} from "../components/Buttons";
import {
  CollaboratorModal,
  ConfirmationModal,
  ShareFormModal,
} from "../components/Modals";
import { DesignerViews, FormDesigner } from "../components/Form";
import {
  Form,
  addNewQuestionToForm,
  addSectionToForm,
  archiveForm,
  copyForm,
  createAndStoreForm,
  deleteForm,
  loadBlockSpecsForForm,
  loadForm,
  loadFormsForProjectWithTitlePrefix,
} from "../types/Form";
import { KotoCRDT, KotoKey } from "../types/KotoTypes";
import {
  ReArrangeGroup,
  ReArrangeModal,
  ReArrangeRow,
} from "../components/ReArrange";
import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Redirect, useParams } from "react-router";
import {
  buildFormDesignPath,
  buildFormPreviewPath,
  buildFormsListPath,
} from "./AppRoutes";
import { useDispatch, useSelector } from "react-redux";

import { AppKotoContext } from "./AppKotoContext";
import { BlockSpec } from "../types/Block";
import { ObjectViewNav } from "../components/TopNavigation";
import { RootState } from "../Store/store";
import { formDesignerSlice } from "../Store/formDesignerSlice";
import { urlParams } from "../App/AppRoutes";
import { useHistory } from "react-router-dom";
import { viewSlice } from "../Store/viewSlice";

export const AppFormCreate: FunctionComponent<{}> = (_props) => {
  const { kContext } = useContext(AppKotoContext);

  const { projectId } = useParams<urlParams>();

  const [newId, setNewId] = useState("");

  useEffect(() => {
    const create = async () => {
      const newForm = await createAndStoreForm(kContext, projectId, "New Form");
      setNewId(newForm.crdt.id);
    };
    create();
  }, [projectId]);

  return (
    <Fragment>
      {newId !== "" && <Redirect to={buildFormDesignPath(projectId, newId)} />}
    </Fragment>
  );
};

export const AppFormDesigner: FunctionComponent<{}> = (_props) => {
  const dispatch = useDispatch();
  const { kContext } = useContext(AppKotoContext);
  const { projectId, formId } = useParams<urlParams>();
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);

  const [blockSpecMap, setBlockSpecMap] = useState({});

  const history = useHistory();

  const designerView = useSelector(
    (state: RootState) => state.formDesigner.designerView
  );

  useEffect(() => {
    const load = async () => {
      const result = await loadForm(kContext, projectId, formId);
      if (result.type !== "Success") {
        console.log("Failed to load form. ", result.msg);
        return;
      }
      const form = result.data;

      dispatch(formDesignerSlice.actions.setForm(form));

      const specMap = await loadBlockSpecsForForm(kContext, form);

      setBlockSpecMap(specMap);
    };

    load();
    return () => {
      dispatch(formDesignerSlice.actions.setForm(null));
    };
  }, [kContext, projectId, formId]);

  const formCrdt = useSelector((state: RootState) => {
    return state.formDesigner.form;
  });

  const activeBlockId = useSelector((state: RootState) => {
    if (formCrdt === null) {
      return "";
    } else if (formCrdt.crdt.blockSpecRefs.length === 0) {
      return "";
    } else if (state.formDesigner.activeBlockSpecId === "") {
      return formCrdt.crdt.blockSpecRefs[0].key;
    } else {
      return state.formDesigner.activeBlockSpecId;
    }
  });

  const recordSets = useSelector((state: RootState) => {
    return state.projects.projects[projectId].crdt.recordSets;
  });

  const [moreMenuButtonOpen, setMoreMenuButtonOpen] = useState(false);
  const [shareModalButtonOpen, setShareModalButtonOpen] = useState(false);
  const [collaboratorModalOpen, setCollaboratorModalOpen] = useState(false);

  const [confirmationModalInfo, setModalInfo] = useState({
    open: false,
    setOpen: (val: boolean) =>
      setModalInfo({ ...confirmationModalInfo, open: val }),
    modalType: "",
    entityString: "",
    confirmationFunc: () => {},
  });

  const createDeleteFormHandler = (form: KotoCRDT<Form>) => {
    return async () => {
      setModalInfo({
        open: true,
        setOpen: confirmationModalInfo.setOpen,
        modalType: "delete",
        entityString: form.crdt.title.toString(),
        confirmationFunc: async () => {
          const deletedForm = deleteForm(kContext, form);
          await K.storeCrdt(kContext, deletedForm);
          confirmationModalInfo.setOpen(false);
          history.push(buildFormsListPath(form.crdt.projectId));
        },
      });
    };
  };

  const createArchiveFormHandler = (form: KotoCRDT<Form>) => {
    return async () => {
      setModalInfo({
        open: true,
        setOpen: confirmationModalInfo.setOpen,
        modalType: "archive",
        entityString: form.crdt.title.toString(),
        confirmationFunc: async () => {
          const archivedForm = archiveForm(kContext, form);
          await K.storeCrdt(kContext, archivedForm);
          confirmationModalInfo.setOpen(false);
          history.push(buildFormsListPath(form.crdt.projectId));
        },
      });
    };
  };

  const [reArrangeModalOpen, setReArrangeModalOpen] = useState(false);
  const [buttonListOpen, setButtonListOpen] = useState(false);
  const toggleButtonList = (): void => {
    setButtonListOpen(!buttonListOpen);
  };
  const [shareModalActiveTabLabel, setShareModalActiveTabLabel] = useState(
    "url"
  );

  const handleSetForm = async (form: KotoCRDT<Form>) => {
    dispatch(formDesignerSlice.actions.setForm(form));
    await K.storeCrdt(kContext, form);
  };

  const handleSetActiveBlockSpec = (key: KotoKey) => {
    dispatch(formDesignerSlice.actions.setActiveBlockSpec(key));
  };

  const handleSetBlockSpec = async (blockSpec: KotoCRDT<BlockSpec>) => {
    if (formCrdt === null) {
      console.log("Can't set blockspec, form not ready yet.");
      return;
    }

    setBlockSpecMap({ ...blockSpecMap, [blockSpec.key]: blockSpec });
    await K.storeCrdt(kContext, blockSpec);
  };

  const handleBackButton = () => {
    history.push(buildFormsListPath(projectId));
  };

  const handleAddSection = async () => {
    if (formCrdt === null) {
      console.log("No active Form.");
      return;
    }

    const { form, spec } = addSectionToForm(kContext, formCrdt);

    await handleSetBlockSpec(spec);
    handleSetActiveBlockSpec(spec.key);
    await handleSetForm(form);
  };

  const handleAddQuestion = async () => {
    if (formCrdt === null) {
      console.log("No active Form.");
      return;
    }

    if (formCrdt === null) {
      console.log("No Form CRDT.");
      return;
    }

    const { form: newForm, spec: newBlockSpec } = await addNewQuestionToForm(
      kContext,
      formCrdt,
      activeBlockId,
      "shortTextQuestion"
    );

    await handleSetBlockSpec(newBlockSpec);
    handleSetActiveBlockSpec(newBlockSpec.key);
    await handleSetForm(newForm);
  };

  const handleSelectTab = (view: DesignerViews) => {
    dispatch(formDesignerSlice.actions.setDesignerView(view));
  };

  const handleMakeACopy = async () => {
    if (formCrdt !== null) {
      const titlePrefix = formCrdt.crdt.title.toString().replace(/ \d+$/, "");

      const formList = await loadFormsForProjectWithTitlePrefix(
        kContext,
        formCrdt.crdt.projectId,
        titlePrefix
      );

      if (formList.type !== "Success") {
        throw new Error("Could not query for forms.");
      }

      const formCount = formList.data.length;

      const copiedForm = copyForm(kContext, formCrdt, formCount + 1);
      history.push(
        buildFormDesignPath(copiedForm.crdt.projectId, copiedForm.crdt.id)
      );
      await K.storeCrdt(kContext, copiedForm);
      setMoreMenuButtonOpen(false);
    } else {
      console.log("Can't copy null form.");
    }
  };

  return (
    formCrdt && (
      <Fragment>
        <ObjectViewNav
          title={formCrdt.crdt.title.toString()}
          titleEditable={false}
          setSideNavExpanded={() => {
            dispatch(viewSlice.actions.setNavVisible(true));
          }}
          handleBackButton={handleBackButton}
        >
          <div>
            <button
              className="btn btn-teal mr-2"
              onClick={() => {
                setShareModalButtonOpen(true);
              }}
            >
              <i className="fa-fw far fa-share "></i>
              <span className="hidden lg:inline-block ml-2">Share</span>
            </button>
            <button
              className="btn btn-gray-200 btn-no-shadow mr-2"
              onClick={() =>
                window.open(buildFormPreviewPath(projectId, formId), "_blank")
              }
            >
              <i className="fa-fw far fa-eye "></i>
              <span className="hidden xl:inline-block ml-2">Preview</span>
            </button>

            <ShareFormModal
              title="Share form"
              modalOpen={shareModalButtonOpen}
              setModalOpen={setShareModalButtonOpen}
              activeView={shareModalActiveTabLabel}
              selectTab={(label) => {
                setShareModalActiveTabLabel(label);
              }}
              projectId={projectId}
              formId={formCrdt.crdt.id}
              recordSets={recordSets}
            ></ShareFormModal>

            {/* TODO: Hook up these settings */}
            <Menu
              menuOpen={moreMenuButtonOpen}
              setMenuOpen={setMoreMenuButtonOpen}
              extraClassNames="inline-block"
            >
              <button
                className="btn btn-gray-200 btn-no-shadow"
                data-tooltip="More options"
                tooltip-position="bottom-right"
              >
                <i className="fa-fw far fa-ellipsis-h"></i>
              </button>
              <MenuItemList position="bottom-right">
                <MenuItem>
                  <MenuItemIcon fontAwesomeClassNames="far fa-print" />
                  Print
                </MenuItem>
                <MenuItem onClick={handleMakeACopy}>
                  <MenuItemIcon fontAwesomeClassNames="far fa-copy" />
                  Make a copy
                </MenuItem>
                <MenuItem
                  extraClassNames="border-b border-gray-300"
                  onClick={() => {
                    setMoreMenuButtonOpen(false);
                    setCollaboratorModalOpen(true);
                  }}
                >
                  <MenuItemIcon fontAwesomeClassNames="far fa-user-plus" />
                  Add collaborators
                </MenuItem>
                <MenuItem onClick={createArchiveFormHandler(formCrdt)}>
                  <MenuItemIcon fontAwesomeClassNames="far fa-archive" />
                  Archive
                </MenuItem>
                <MenuItem
                  danger={true}
                  onClick={createDeleteFormHandler(formCrdt)}
                >
                  <MenuItemIcon fontAwesomeClassNames="far fa-trash-alt" />
                  Delete
                </MenuItem>
              </MenuItemList>
            </Menu>
            <CollaboratorModal
              modalOpen={collaboratorModalOpen}
              setModalOpen={setCollaboratorModalOpen}
            ></CollaboratorModal>
          </div>
        </ObjectViewNav>
        <FormDesigner
          kContext={kContext}
          form={formCrdt}
          setForm={handleSetForm}
          blockSpecMap={blockSpecMap}
          setBlockSpec={handleSetBlockSpec}
          activeBlockSpec={activeBlockId}
          setActiveBlockSpec={handleSetActiveBlockSpec}
          activeView={designerView}
          selectTab={handleSelectTab}
        />
        {designerView === "design" && (
          <Fragment>
            {/* This bar shows common actions taken on forms like Adding a quesiton, question group, or re-arranging */}

            <div className="hidden md:block bottom-0 inset-x-0 fixed xl:ml-sidebar pb-6 px-4">
              <div className="bg-white border rounded-lg shadow-md border-gray-500 p-3 mx-auto flex space-x-4 z-30 space-x-4 max-w-4xl ">
                <button
                  className="flex-1 btn btn-no-border"
                  onClick={() => {
                    setButtonListOpen(false);
                    handleAddQuestion();
                  }}
                >
                  <i className="far fa-plus-square mr-2"></i>
                  Add Question
                </button>
                <button
                  className="flex-1 btn btn-no-border"
                  onClick={() => {
                    setButtonListOpen(false);
                    handleAddSection();
                  }}
                >
                  <i className="far fa-plus-circle mr-2"></i>
                  Add Question Group
                </button>
                <button
                  className="flex-1 btn btn-no-border"
                  onClick={() => setReArrangeModalOpen(true)}
                >
                  <i className="far fa-random mr-2"></i>Rearrange
                </button>
              </div>
            </div>
            <div className="md:hidden">
              <MobileActionBar>
                {buttonListOpen && (
                  <div className="k_btnList absolute w-full flex justify-center -mx-4">
                    <div className="text-center flex flex-col">
                      <button
                        className="btn btn-gray-700 shadow mb-4 mx-0"
                        onClick={() => {
                          setButtonListOpen(false);
                          handleAddQuestion();
                        }}
                      >
                        Question
                      </button>
                      <button
                        className="btn btn-gray-700 shadow mb-4 mx-0"
                        onClick={() => {
                          setButtonListOpen(false);
                          handleAddSection();
                        }}
                      >
                        Question Group
                      </button>
                    </div>
                  </div>
                )}
                <SidebarMenuButton
                  extraClassNames="flex-1"
                  setSideNavExpanded={() => {
                    dispatch(viewSlice.actions.setNavVisible(true));
                  }}
                >
                  <i className="fa-fw fas fa-bars" />
                  <div className="block">Menu</div>
                </SidebarMenuButton>

                <button
                  className="btn btn-teal flex-1"
                  onClick={toggleButtonList}
                >
                  {buttonListOpen ? (
                    <Fragment>
                      <i className="fa-fw far fa-times"></i>
                      <div className="block">Close</div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <i className="fa-fw fas fa-plus" />
                      <div className="block">Add</div>
                    </Fragment>
                  )}
                </button>

                <button className="btn btn-ab flex-1">
                  <Menu
                    menuOpen={moreMenuOpen}
                    setMenuOpen={setMoreMenuOpen}
                    extraClassNames="flex-1"
                  >
                    <div className="flex-auto">
                      <i className="fa-fw far fa-ellipsis-h" />
                      <div className="block">More</div>
                    </div>
                    {/* TODO: Hook up these settings */}
                    <MenuItemList position="top-right">
                      <MenuItem
                        onClick={() => {
                          setMoreMenuButtonOpen(false);
                          setShareModalButtonOpen(true);
                        }}
                      >
                        <MenuItemIcon fontAwesomeClassNames="far fa-share" />
                        Share
                      </MenuItem>
                      <MenuItem
                        extraClassNames="border-b border-gray-300 md:hidden"
                        onClick={() => setReArrangeModalOpen(true)}
                      >
                        <MenuItemIcon fontAwesomeClassNames="far fa-random" />
                        Re-arrange
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          window.open(
                            buildFormPreviewPath(projectId, formId),
                            "_blank"
                          )
                        }
                      >
                        <MenuItemIcon fontAwesomeClassNames="fas fa-eye" />
                        Preview
                      </MenuItem>
                      <MenuItem>
                        <MenuItemIcon fontAwesomeClassNames="far fa-print" />
                        Print
                      </MenuItem>
                      <MenuItem onClick={handleMakeACopy}>
                        <MenuItemIcon fontAwesomeClassNames="far fa-copy" />
                        Make a copy
                      </MenuItem>
                      <MenuItem
                        extraClassNames="border-b border-gray-300"
                        onClick={() => {
                          setMoreMenuButtonOpen(false);
                          setCollaboratorModalOpen(true);
                        }}
                      >
                        <MenuItemIcon fontAwesomeClassNames="far fa-user-plus" />
                        Add collaborators
                      </MenuItem>
                      <MenuItem onClick={createArchiveFormHandler(formCrdt)}>
                        <MenuItemIcon fontAwesomeClassNames="far fa-archive" />
                        Archive
                      </MenuItem>
                      <MenuItem
                        danger={true}
                        onClick={createDeleteFormHandler(formCrdt)}
                      >
                        <MenuItemIcon fontAwesomeClassNames="far fa-trash-alt" />
                        Delete
                      </MenuItem>
                    </MenuItemList>
                  </Menu>
                </button>
              </MobileActionBar>
            </div>
          </Fragment>
        )}
        <ConfirmationModal
          modalOpen={confirmationModalInfo.open}
          setModalOpen={confirmationModalInfo.setOpen}
          confirmationType={confirmationModalInfo.modalType}
          entityString={confirmationModalInfo.entityString}
          confirmationFunc={confirmationModalInfo.confirmationFunc}
        ></ConfirmationModal>
        {/* TODO: Hook up to actual block and question titles and actually re-arrange as a result */}
        <ReArrangeModal
          modalOpen={reArrangeModalOpen}
          setModalOpen={setReArrangeModalOpen}
        >
          <ReArrangeGroup>
            <ReArrangeRow>Basic Information</ReArrangeRow>
            <ReArrangeRow>First Name</ReArrangeRow>
            <ReArrangeRow>Last Name</ReArrangeRow>
            <ReArrangeRow>Gender</ReArrangeRow>
            <ReArrangeRow>Address</ReArrangeRow>
          </ReArrangeGroup>
          <ReArrangeGroup>
            <ReArrangeRow>Destination</ReArrangeRow>
            <ReArrangeRow>Address</ReArrangeRow>
          </ReArrangeGroup>
          <ReArrangeGroup>
            <ReArrangeRow>Family Information</ReArrangeRow>
            <ReArrangeRow>First Name</ReArrangeRow>
            <ReArrangeRow>Last Name</ReArrangeRow>
            <ReArrangeRow>Gender</ReArrangeRow>
            <ReArrangeRow>Relationship</ReArrangeRow>
          </ReArrangeGroup>
          <ReArrangeGroup>
            <ReArrangeRow>Medical Information</ReArrangeRow>
            <ReArrangeRow>Results</ReArrangeRow>
            <ReArrangeRow>Reviewing doctor</ReArrangeRow>
            <ReArrangeRow>Date of checkup</ReArrangeRow>
          </ReArrangeGroup>
        </ReArrangeModal>
      </Fragment>
    )
  );
};
