import React, { FunctionComponent, Fragment, useState } from "react";
import { createPortal } from "react-dom";
import { Overlay } from "./Overlay";
import { ToggleSwitch } from "./Buttons";

export interface CreateRecordSetModalProps {
  title: string;
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
  recordSetAdder: (
    name: string,
    description: string,
    isPrivate: boolean
  ) => void;
}

// Modal for creating a new record set.
// Used in the "Create record set" menu of sidebar
export const CreateRecordSetModal: FunctionComponent<CreateRecordSetModalProps> = (
  props
) => {
  const toggleModal = (): void => {
    props.setModalOpen(!props.modalOpen);
  };

  const [recordSetName, setRecordSetName] = useState("");
  const [recordSetDescription, setRecordSetDescription] = useState("");
  const [recordSetIsPrivate, setRecordSetIsPrivate] = useState(false);

  const children = React.Children.toArray(props.children);

  const handleCancel = () => {
    props.setModalOpen(false);
  };

  const handleSubmit = async () => {
    props.setModalOpen(false);
    await props.recordSetAdder(
      recordSetName,
      recordSetDescription,
      recordSetIsPrivate
    );
  };

  const component = (
    <Fragment>
      <span onClick={toggleModal}>{children[0]}</span>
      {props.modalOpen && (
        <Overlay toggle={toggleModal}>
          <div className="mobile-modal sm:max-h-90 sm:w-2xl  sm:relative sm:rounded p-4 sm:p-8 bg-white">
            <div className="flex-1 text-3xl font-semibold leading-snug mb-4">
              {props.title}
            </div>
            <div className="">
              <div className="k_cardQuestionDescription mb-4">
                Create a collection of records that your team can use to save
                and look up information. They’re best when they are organized
                around a specific entity – Artwork or Clients, for example.
              </div>

              <div className="k_cardGroup">
                <div className="k_cardQuestionTitle">Title</div>
                <div className="k_cardQuestionDescription">
                  Choose a title that describes the types of records in this
                  set.
                </div>

                <div className="relative">
                  <input
                    className="k_textInput relative"
                    placeholder="Enter a record set title"
                    disabled={false}
                    onChange={(e) => {
                      setRecordSetName(e.target.value);
                    }}
                    value={recordSetName}
                  ></input>
                  {/* TODO: Set a character limit on this text input (80 chars) */}
                  {/* TODO: Show current characters left in the div below */}
                  <span className="absolute right-0 top-0 mt-3 mr-4 text-gray-600">
                    80
                  </span>
                </div>
              </div>

              <div className="k_cardGroup">
                <div className="k_cardQuestionTitle">
                  Description{" "}
                  <span className="text-gray-600 font-thin">(optional)</span>
                </div>
                <div className="k_cardQuestionDescription">
                  What kind records will be in this set?
                </div>
                <input
                  className="k_textInput"
                  placeholder="Add a description"
                  disabled={false}
                  onChange={(e) => {
                    setRecordSetDescription(e.target.value);
                  }}
                  value={recordSetDescription}
                ></input>
              </div>

              <div className="k_cardGroup">
                <div className="k_cardQuestionTitle">Make private</div>
                <div className="k_cardQuestionDescription flex">
                  <div className="flex-1 ">
                    When a record set is private, it can only be viewed or
                    joined by invitation to the set.
                  </div>
                  {/* TODO: Hook up Toggle Switch to make the project private/not private */}

                  <div className="flex-initial ml-8 md:ml-24">
                    <ToggleSwitch
                      checked={recordSetIsPrivate}
                      setChecked={setRecordSetIsPrivate}
                      onChange={(e) => {
                        setRecordSetIsPrivate(e.target.checked);
                      }}
                    ></ToggleSwitch>
                  </div>
                </div>
              </div>

              <div className="flex">
                <button className="btn flex-initial" onClick={handleCancel}>
                  Cancel
                </button>
                <div className="flex-grow"></div>
                <button
                  className="btn btn-gray flex-initial"
                  onClick={handleSubmit}
                >
                  Create record set
                </button>
                {/* TODO: Show manage collaborators modal after use clicks "Create record set" if set is marked as private */}
              </div>
            </div>
          </div>
        </Overlay>
      )}
    </Fragment>
  );

  const modalElement = document.getElementById("modal-root");

  if (modalElement === null) {
    return <div>NO MODAL ROOT ELEMENT!!!!</div>;
  }

  return createPortal(component, modalElement);
};

interface SidebarRecordSetCreatorProps {
  recordSetAdder: (
    name: string,
    description: string,
    isPrivate: boolean
  ) => void;
}

export const SidebarRecordSetCreator: FunctionComponent<SidebarRecordSetCreatorProps> = React.memo(
  (props) => {
    const [createRecordSetModalOpen, setCreateRecordSetModalOpen] = useState(
      false
    );

    return (
      <Fragment>
        <button
          className="btn btn-no-border btn-sm text-gray-400 ml-2 hover:text-white hover:bg-white hover:bg-opacity-10"
          onClick={() => {
            setCreateRecordSetModalOpen(!createRecordSetModalOpen);
          }}
        >
          <i className="far fa-plus fa-fw flex-initial"></i>
        </button>
        <CreateRecordSetModal
          title="Create new collection"
          modalOpen={createRecordSetModalOpen}
          setModalOpen={setCreateRecordSetModalOpen}
          recordSetAdder={props.recordSetAdder}
        ></CreateRecordSetModal>
      </Fragment>
    );
  }
);
