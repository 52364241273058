import React, { Fragment, FunctionComponent } from "react";

import { AccountSettings } from "../components/Settings/AccountSettings";
import { AppHeaderBar } from "./AppHeader";

export const AppAccountSettingsList: FunctionComponent<{}> = (_props) => {
  return (
    <Fragment>
      <AppHeaderBar />
      <AccountSettings></AccountSettings>
    </Fragment>
  );
};

export const AppAccountSettingsSettings: FunctionComponent<{}> = (_props) => {
  return (
    <Fragment>
      <AppHeaderBar />
      <AccountSettings></AccountSettings>
    </Fragment>
  );
};
