import React, { FunctionComponent } from "react";

import { AppHeaderBar } from "./AppHeader";
import { Homepage } from "../components/Homepage";

interface AppLoggedOutViewProps {}

export const AppLoggedOutView: FunctionComponent<AppLoggedOutViewProps> = (
  _props
) => {
  return (
    <div>
      <AppHeaderBar />
      <Homepage />
    </div>
  );
};
