import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItemList,
  SidebarMenuButton,
} from "./Buttons";
import { MultiplayerIcon, MultiplayerMoreButton } from "./Multiplayer";
import React, { Fragment, FunctionComponent, useState } from "react";

import { ConnectivityStatus } from "./ConnectivityStatus";
import { Link } from "react-router-dom";
import { SearchModal } from "./Modals";
import { buildAccountsSettingsPath } from "../App/AppRoutes";

interface ListViewNavProps {
  title: string;
  results: string;
  setSideNavExpanded: () => void;
}

export const ListViewNav: FunctionComponent<ListViewNavProps> = (props) => {
  const [searchModalOpen, setSearchModalOpen] = useState(false);

  return (
    <Fragment>
      {/* Mobile Top Nav Bar */}
      <div className="sm:hidden sticky top-0 right-0">
        <nav className="bg-gray-100 relative text-white flex items-center">
          <div className="flex-1 ml-6 font-medium text-gray-700 leading-none flex items-center truncate">
            {props.title}
          </div>
          <div className="flex-initial flex items-center">
            <ConnectivityStatus
              status="online"
              extraClassNames="p-6 btn-no-border text-gray-600 hover:border-transparent hover:bg-transparent"
            />
            <button
              data-tooltip="Search"
              tooltip-position="bottom-right"
              className="btn bg-transparent p-6 btn-no-border text-gray-600 hover:border-transparent hover:bg-transparent"
              onClick={() => {
                setSearchModalOpen(true);
              }}
            >
              {/* TODO:  Should trigger search modal */}
              <i className="far fa-search text-gray-700 "></i>{" "}
            </button>
          </div>
        </nav>
      </div>

      {/* Desktop Top Nav Bar */}
      <div className="hidden sm:block sticky w-full top-0 right-0">
        <div className="bg-gray-100 sm:w-11/12 mx-auto">
          {/* Search Bar & Connectivity Indicator */}
          <div className="flex items-center py-5">
            <div className="hidden md:flex">
              <SidebarMenuButton
                setSideNavExpanded={props.setSideNavExpanded}
              ></SidebarMenuButton>
            </div>
            {/* TODO: Hook up Connectivity Status to actual status */}
            <div className="flex-1 text-xl font-medium truncate pr-4 text-gray-800">
              {props.title}
            </div>
            <div className="flex-initial text-right align-middle hidden md:inline-block">
              <div className="inline-flex">{props.children}</div>
            </div>
            <ConnectivityStatus
              status="online"
              extraClassNames="btn bg-transparent p-4 btn-no-border text-gray-600 hover:border-transparent hover:bg-transparent text-sm ml-2"
            />
            <button
              className="btn bg-transparent p-4 btn-no-border text-gray-600 hover:border-transparent hover:bg-transparent text-sm"
              onClick={() => {
                setSearchModalOpen(true);
              }}
            >
              <i className="fas fa-search mr-2"></i>
            </button>
          </div>
        </div>
      </div>

      <SearchModal
        modalOpen={searchModalOpen}
        setModalOpen={setSearchModalOpen}
      ></SearchModal>
    </Fragment>
  );
};

interface ObjectViewNavProps {
  title: string;
  subtitle?: string;
  titleEditable?: boolean;
  setSideNavExpanded: () => void;
  handleBackButton: () => void;
}

export const ObjectViewNav: FunctionComponent<ObjectViewNavProps> = (props) => {
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  return (
    <Fragment>
      {/* Mobile Top Nav */}
      <div className="sm:hidden sticky top-0 right-0 z-20">
        <nav className="bg-gray-200 relative text-white flex items-center ">
          <div className="flex flex-initial text-base text-thin truncate items-center">
            <button
              className="btn p-6 btn-no-border text-gray-600 hover:border-transparent hover:bg-transparent"
              onClick={props.handleBackButton}
              data-tooltip="Go back"
              tooltip-position="bottom"
            >
              <i className="fa-fw fas fa-long-arrow-left"></i>
              {/* <span className="ml-2 hidden xl:inline-block">Back</span> */}
            </button>
          </div>
          <div className="flex-grow"></div>
          <div className="flex-initial flex items-center">
            <ConnectivityStatus
              status="online"
              extraClassNames="p-6 btn-no-border text-gray-600 hover:border-transparent hover:bg-transparent"
            />
            <button
              data-tooltip="Search"
              tooltip-position="bottom-right"
              className="btn bg-transparent p-6 btn-no-border text-gray-600 hover:border-transparent hover:bg-transparent"
              onClick={() => {
                setSearchModalOpen(true);
              }}
            >
              {/* TODO:  Should trigger search modal */}
              <i className="far fa-search text-gray-700 "></i>{" "}
            </button>
          </div>
        </nav>
      </div>
      {/* Title and Multiplayer */}
      <div className="z-0 sm:hidden">
        <div className="flex bg-gray-200 pb-2 px-4">
          <div className="flex-1 font-medium text-xl text-gray-800">
            {props.title}
          </div>
        </div>
        <div className="flex bg-gray-200 py-2 px-4">
          <div className="flex-initial flex mr-2 mb-4">
            <MultiplayerIcon
              name="Kavi Harshawat"
              color="purple"
              tooltip="Kavi Harshawat"
              tooltipPosition="bottom"
            />
            <MultiplayerIcon
              name="Ron Gorodetsky"
              color="red"
              tooltip="Ron Gorodetsky"
              tooltipPosition="bottom"
            />
            <MultiplayerMoreButton number="8" />
          </div>
        </div>
      </div>

      {/* Desktop Top Nav */}
      <div className="hidden sm:block sticky w-full top-0 right-0 bg-gray-200 z-20 py-2">
        <div className=" sm:w-11/12 mx-auto">
          <div className="flex items-center">
            <div className="sm:hidden md:flex md:flex-initial xl:hidden mr-2">
              <SidebarMenuButton
                extraClassNames="flex-initial"
                setSideNavExpanded={props.setSideNavExpanded}
              ></SidebarMenuButton>
            </div>
            <button
              className="flex-initial btn btn-gray-200 btn-no-shadow mr-4"
              onClick={props.handleBackButton}
              data-tooltip="Go back"
              tooltip-position="bottom"
            >
              <i className="fa-fw fas fa-long-arrow-left"></i>
              <span className="hidden ml-2 lg:inline-block">Back</span>
            </button>
            {/* TODO: Hook up Connectivity Status to actual status */}
            <div className="flex-1 text-lg font-medium truncate pr-4 text-gray-800">
              {props.title}
            </div>
            {/* Multiplayer Users (Optional) */}
            {/* TODO: Only show Multiplayer Icons when needed */}
            <div className="flex-initial flex mr-2 k_mpWrapper">
              <MultiplayerIcon
                name="Kavi Harshawat"
                color="purple"
                tooltip="Kavi Harshawat"
                tooltipPosition="bottom"
              />
              <MultiplayerIcon
                name="Ron Gorodetsky"
                color="red"
                tooltip="Ron Gorodetsky"
                tooltipPosition="bottom"
              />
              <MultiplayerMoreButton number="8" />
            </div>
            <div className="flex-initial text-right align-middle hidden md:inline-block">
              <div className="inline-flex">{props.children}</div>
            </div>
            <ConnectivityStatus
              status="online"
              extraClassNames="btn bg-transparent p-4 btn-no-border text-gray-600 hover:border-transparent hover:bg-transparent text-sm ml-2"
            />
            <button
              className="btn bg-transparent p-4 btn-no-border text-gray-600 hover:border-transparent hover:bg-transparent text-sm"
              onClick={() => {
                setSearchModalOpen(true);
              }}
            >
              <i className="fas fa-search mr-2"></i>
            </button>
          </div>
        </div>
      </div>

      <SearchModal
        modalOpen={searchModalOpen}
        setModalOpen={setSearchModalOpen}
      ></SearchModal>
    </Fragment>
  );
};

export interface HeaderBarProps {
  handleSignin: () => void;
  userInfo: null | {
    userName: string;
    userEmail: string;
  };
}
export const HeaderBar: FunctionComponent<HeaderBarProps> = (props) => {
  const [menuButtonOpen, setMenuButtonOpen] = useState(false);

  return (
    <Fragment>
      <nav className="w-full bg-white border-b border-gray-400 h-16 flex items-center sticky top-0 z-30">
        <Link to="/">
          <img className="ml-4 w-12" src="assets/KotoLogo.svg"></img>
        </Link>
        {/* <div className="flex-inital h-10 w-10 ml-4 rounded-lg bg-teal-500 border-2 border-teal-700"></div> */}
        <div className="flex-1 text-right">
          <ul className="hidden sm:block">
            <li className="inline-block  mr-4">
              <a
                href="#"
                className="no-underline text-gray-700 rounded border border-white bg-white p-2 hover:bg-gray-300 hover:border-gray-500 hover:text-gray-900"
              >
                Pricing
              </a>
            </li>
            <li className="inline-block  mr-4">
              <a
                href="#"
                className="no-underline text-gray-700 rounded border border-white bg-white p-2 hover:bg-gray-300 hover:border-gray-500 hover:text-gray-900"
              >
                Support
              </a>
            </li>
          </ul>
        </div>
        {/* TODO: Hook this up to the actual user name and email address */}
        <div className="flex-initial mr-4">
          {(props.userInfo !== null && (
            <Menu menuOpen={menuButtonOpen} setMenuOpen={setMenuButtonOpen}>
              <MenuButton>
                <span className="hidden sm:inline-block">
                  {props.userInfo.userName}
                </span>
                <span className="inline-block sm:hidden">Menu</span>
                <i className="fas fa-chevron-down ml-2" />
              </MenuButton>
              <MenuItemList
                position="bottom-right"
                extraClassNames="right-0 mt-1"
              >
                <div className="bg-gray-100 border-b border-gray-300 p-4 text-sm text-center">
                  <span className="text-gray-800 block font-medium">
                    {props.userInfo.userName}
                  </span>
                  <span className="text-gray-600 block">
                    ({props.userInfo.userEmail})
                  </span>
                </div>
                <MenuItem linkTo={buildAccountsSettingsPath()}>
                  {/* TODO: Link to actual projects page */}
                  <i className="mr-2 fa-fw fas fa-user" />
                  Manage your account
                </MenuItem>
                <MenuItem linkTo={"/"}>
                  {/* TODO: Link to actual projects page */}
                  <i className="mr-2 fa-fw fas fa-list" />
                  Your projects
                </MenuItem>
                <div className="sm:hidden">
                  <MenuItem>
                    <i className="mr-2 fa-fw fas fa-calculator" />
                    Pricing
                  </MenuItem>
                  <MenuItem>
                    <i className="mr-2 fa-fw fas fa-question-circle" />
                    Support
                  </MenuItem>
                </div>
                <MenuItem linkTo="/logout">
                  {/* TODO: Link to actual sign out flow */}
                  <i className="mr-2 fa-fw fas fa-sign-out" />
                  Sign out
                </MenuItem>
              </MenuItemList>
            </Menu>
          )) || (
            <button className="btn" onClick={props.handleSignin}>
              Sign in
            </button>
          )}
        </div>
      </nav>
    </Fragment>
  );
};

interface EmptyViewNavProps {
  title?: string;
  setSideNavExpanded: () => void;
  handleBackButton: () => void;
}

export const EmptyViewNav: FunctionComponent<EmptyViewNavProps> = (props) => {
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  return (
    <Fragment>
      {/* Mobile Top Nav Bar */}
      <div className="sm:hidden sticky top-0 right-0">
        <nav className="bg-gray-100 relative text-white flex items-center">
          <div className="hidden md:flex">
            <SidebarMenuButton
              extraClassNames="btn-gray-200 btn-no-shadow mr-2"
              setSideNavExpanded={props.setSideNavExpanded}
            ></SidebarMenuButton>
          </div>
          <button
            className="btn bg-transparent p-6 btn-no-border text-gray-600 hover:border-transparent hover:bg-transparent"
            onClick={props.handleBackButton}
          >
            <i className="fa-fw fas fa-long-arrow-left"></i>
          </button>
          <div className="flex-grow"></div>
          <div className="flex-initial flex mr-2 k_mpWrapper">
            {/* TODO: Multiplayer icons to go here if available */}
          </div>
          <div className="flex-initial flex items-center">
            <ConnectivityStatus
              status="online"
              extraClassNames="p-6 btn-no-border text-gray-600 hover:border-transparent hover:bg-transparent"
            />
            <button
              data-tooltip="Search"
              tooltip-position="bottom-right"
              className="btn bg-transparent p-6 btn-no-border text-gray-600 hover:border-transparent hover:bg-transparent"
              onClick={() => {
                setSearchModalOpen(true);
              }}
            >
              {/* TODO:  Should trigger search modal */}
              <i className="far fa-search text-gray-700 "></i>{" "}
            </button>
          </div>
        </nav>
      </div>
      <div className="z-0 sm:hidden">
        <div className="flex pb-2 px-4">
          <div className="flex-1 font-medium text-xl text-gray-800">
            {props.title}
          </div>
        </div>
      </div>

      {/* Desktop Top Nav Bar */}
      <div className="hidden sm:block sticky w-full top-0 right-0 bg-gray-100 z-20">
        <div className=" sm:w-11/12 mx-auto">
          <div className="flex items-center py-5">
            <SidebarMenuButton
              extraClassNames="btn-gray-200 btn-no-shadow mr-2"
              setSideNavExpanded={props.setSideNavExpanded}
            ></SidebarMenuButton>
            <button
              className="btn btn-gray-200 btn-no-shadow mr-4"
              onClick={props.handleBackButton}
              data-tooltip="Go back"
              tooltip-position="bottom"
            >
              <i className="fa-fw fas fa-long-arrow-left"></i>
              <span className="hidden ml-2 lg:inline-block">Back</span>
            </button>
            <div className="flex-grow text-lg font-medium truncate pr-4 text-gray-800">
              {props.title && <div>{props.title}</div>}
            </div>
            <div className="flex-initial flex mr-2 k_mpWrapper">
              {/* TODO: Multiplayer icons to go here if available */}
            </div>
            <ConnectivityStatus
              status="online"
              extraClassNames="btn bg-transparent p-4 btn-no-border text-gray-600 hover:border-transparent hover:bg-transparent text-sm ml-2"
            />
            <button
              className="btn bg-transparent p-4 btn-no-border text-gray-600 hover:border-transparent hover:bg-transparent text-sm"
              onClick={() => {
                setSearchModalOpen(true);
              }}
            >
              <i className="fas fa-search mr-2"></i>
            </button>
          </div>
        </div>
      </div>
      <SearchModal
        modalOpen={searchModalOpen}
        setModalOpen={setSearchModalOpen}
      ></SearchModal>
    </Fragment>
  );
};
