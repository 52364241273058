import { Block, BlockSpec } from "../types/Block";
import { KotoCRDT, KotoOb } from "../types/KotoTypes";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { BlockId } from "../types/IdTypes";
import { Form } from "../types/Form";

type BlockSpecObMap = { [key: string]: KotoOb<BlockSpec> };
export interface FormFillerSliceProps {
  form: KotoCRDT<Form> | null;
  blockSpecs: BlockSpecObMap;
  answersMap: { [blockId in BlockId]: KotoCRDT<Block> };
}

export const formFillerSlice = createSlice({
  name: "formFiller",
  initialState: {
    form: null,
    blockSpecs: {},
    answersMap: {} as { [blockId in BlockId]: KotoCRDT<Block> },
  } as FormFillerSliceProps,
  reducers: {
    setForm: (state, action: PayloadAction<KotoCRDT<Form> | null>) => {
      state.form = action.payload;
    },
    setBlockSpecs: (state, action: PayloadAction<BlockSpecObMap>) => {
      state.blockSpecs = action.payload;
    },
    setBlock: (state, action: PayloadAction<KotoCRDT<Block>>) => {
      state.answersMap[action.payload.key] = action.payload;
    },
    setBlocksMap: (
      state,
      action: PayloadAction<{ [blockId in BlockId]: KotoCRDT<Block> }>
    ) => {
      state.answersMap = action.payload;
    },
  },
});
