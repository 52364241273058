import {
  Action,
  combineReducers,
  configureStore,
  createImmutableStateInvariantMiddleware,
} from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk, { ThunkAction } from "redux-thunk";

import { adminSettingsSlice } from "./adminSettingsSlice";
import { authSlice } from "./authSlice";
import { blockListSlice } from "./blockListSlice";
import { blockSpecSlice } from "./blockSpecSlice";
import { formDesignerSlice } from "./formDesignerSlice";
import { formFillerSlice } from "./formFillerSlice";
import { formListSlice } from "./formListSlice";
import { projectsSlice } from "./projectsSlice";
import { recordListSlice } from "./recordListSlice";
import { recordSlice } from "./recordSlice";
import { reminderListSlice } from "./remindersListSlice";
import { styleSlice } from "./styleSlice";
import { viewSlice } from "./viewSlice";

export const getNewStore = (history: any) => {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    auth: authSlice.reducer,
    blockList: blockListSlice.reducer,
    style: styleSlice.reducer,
    view: viewSlice.reducer,
    formList: formListSlice.reducer,
    formDesigner: formDesignerSlice.reducer,
    recordList: recordListSlice.reducer,
    projects: projectsSlice.reducer,
    formFiller: formFillerSlice.reducer,
    record: recordSlice.reducer,
    adminSettings: adminSettingsSlice.reducer,
    blockSpecSlice: blockSpecSlice.reducer,
    remindersList: reminderListSlice.reducer,
  });

  const store = configureStore({
    reducer: rootReducer,
    // We don't use the default middleware bucause automerge objects like Text
    // aren't strictly serializable. So time travel will be a little weird, but
    // that's a reasonable tradeoff because there's a bunch of scaffolding for
    // the CRDTs that would be weird during time-travel anyway.
    middleware: [
      routerMiddleware(history),
      thunk,
      createImmutableStateInvariantMiddleware(),
    ],
  });

  return { store, rootReducer };
};

export type RootReducer = ReturnType<typeof getNewStore>["rootReducer"];
export type RootState = ReturnType<RootReducer>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
