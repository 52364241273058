import * as K from "../types/KotoOb";

import { FormFiller, makeFormFillerPropsFromBlocks } from "../components/Form";
import { KotoCRDT, KotoKey } from "../types/KotoTypes";
import React, { Fragment, FunctionComponent } from "react"; // import for JSX
import { ReminderList, ReminderListItemType } from "../components/Reminders";
import {
  Tab,
  TabContent,
  TabHeader,
  TabbedView,
  TabbedViewProps,
} from "../components/Tabs";

import { Block } from "../types/Block";
import { BlocksView } from "../components/Block";
import { Form } from "../types/Form";
import { KotoContext } from "../types/KotoContext";
import { KotoRawHistory } from "./KotoObHistory";
import { Record } from "../types/Record";

export type RecordViews = "overview" | "record" | "history";

interface RecordViewProps {
  kContext: KotoContext;
  record: KotoCRDT<Record>;
  blocks: KotoCRDT<Block>[];
  activeView: RecordViews;
  selectTab: (label: RecordViews) => void;
  reminderList: ReminderListItemType[];
  addReminder: (formKey: KotoKey, dueDate: Date, assignee: string) => void;
  availableForms: KotoCRDT<Form>[];
  blockCrdts: KotoCRDT<Block>[];
  setBlockCrdts: (blocks: KotoCRDT<Block>[]) => void;
  editMode: boolean;
}

export const RecordView: FunctionComponent<RecordViewProps> = (props) => {
  if (props.blockCrdts.length === 0) {
    return <Fragment></Fragment>;
  }

  const configs = makeFormFillerPropsFromBlocks(
    props.kContext,
    props.blockCrdts
  );

  return (
    <TabbedView
      type="nav"
      activeView={props.activeView}
      selectTab={props.selectTab as TabbedViewProps["selectTab"]}
    >
      <Tab label="overview">
        <TabHeader>Overview</TabHeader>
        <TabContent>
          {/* <div className="k_card mt-8 text-center">Overview goes here</div> */}
          <ReminderList
            reminderList={props.reminderList}
            addReminder={props.addReminder}
            availableForms={props.availableForms}
            projectId={props.record.crdt.projectId}
            recordSetId={props.record.crdt.recordSetId}
            recordId={props.record.crdt.id}
          ></ReminderList>
        </TabContent>
      </Tab>
      <Tab label="record">
        <TabHeader>Record</TabHeader>
        <TabContent>
          {props.kContext.config.inlineRecordEdit && props.editMode ? (
            <FormFiller
              kotoContext={props.kContext}
              blockSpecMap={configs.blockSpecMap}
              blocks={configs.blocks}
              form={configs.formWithBlockSpecs}
              recordSetId={configs.recordSetId}
              recordId={configs.recordId}
              setBlocks={props.setBlockCrdts}
            ></FormFiller>
          ) : (
            <div>
              <BlocksView kotoContext={props.kContext} answers={props.blocks} />
            </div>
          )}
        </TabContent>
      </Tab>
      <Tab label="activity">
        <TabHeader>Activity</TabHeader>
        <TabContent>
          <div className="k_card mt-8 text-center">
            <KotoRawHistory
              kContext={props.kContext}
              ob={K.getKotoObFromKotoCRDT(props.record)}
            ></KotoRawHistory>
          </div>
        </TabContent>
      </Tab>
    </TabbedView>
  );
};
