import { FunctionComponent } from "react";
import {
  BaseQuestionSpec,
  QuestionAnswer,
  QuestionTypeName,
  QuestionDesignProps,
  QuestionFillerProps,
  QuestionViewerProps,
} from "./Questions";

export interface QuestionType<
  Q extends BaseQuestionSpec,
  A extends QuestionAnswer
> {
  type: QuestionTypeName;
  longName: string;
  createQuestionSpec: () => Q;
  createQuestionAnswer: (spec: Q) => A;
  questionDesigner: FunctionComponent<QuestionDesignProps<Q>>;
  questionFiller: FunctionComponent<QuestionFillerProps<Q, A>>;
  questionViewer: FunctionComponent<QuestionViewerProps<Q, A>>;
}

// TODO: Fix the typing here
const QuestionTypes: {
  [type: string]: QuestionType<BaseQuestionSpec, QuestionAnswer>;
} = {};

export const registerQuestionType = (
  type: QuestionTypeName,
  longName: string,
  createQuestionSpec: () => BaseQuestionSpec,
  createQuestionAnswer: (spec: BaseQuestionSpec) => QuestionAnswer,
  partsDesigner: FunctionComponent<QuestionDesignProps<BaseQuestionSpec>>,
  partsFiller: FunctionComponent<
    QuestionFillerProps<BaseQuestionSpec, QuestionAnswer>
  >,
  partsViewer: FunctionComponent<
    QuestionViewerProps<BaseQuestionSpec, QuestionAnswer>
  >
) => {
  QuestionTypes[type] = {
    type: type,
    longName: longName,
    createQuestionSpec: createQuestionSpec,
    createQuestionAnswer: createQuestionAnswer,
    questionDesigner: partsDesigner,
    questionFiller: partsFiller,
    questionViewer: partsViewer,
  };
};

export const getQuestionTypeNames = () => {
  return Object.keys(QuestionTypes)
    .map((key) => {
      return {
        value: key as QuestionTypeName,
        label: QuestionTypes[key].longName,
      };
    })
    .sort();
};

export const getQuestionTypeConfig = (type: string) => {
  if (!(type in QuestionTypes)) {
    console.trace(`Could not find question config of type: ${type}`);
  }
  return QuestionTypes[type];
};
