import React, { FunctionComponent, useState, Fragment } from "react";
import { Card } from "../Card";
import { Menu, MenuItem, MenuButton, MenuItemList, Icon } from "../Buttons";

interface ManageMemberSettingsProps {
  // TODO: Add activeSection to scroll to and expand a specific section of the settings
  //   activeSection: string;
}

// TODO: Add menus to each card item below.
// TODO: Change this to work with arbitrary data coming from backend
export const ManageMemberSettings: FunctionComponent<ManageMemberSettingsProps> = (
  _props
) => {
  // TODO: Remove single usestate for this component
  const [menuButtonOpen, setMenuButtonOpen] = useState(false);

  return (
    <Fragment>
      <div className="flex max-w-3xl mx-auto my-8  z-0;">
        <input
          className="k_search flex-1"
          type="search"
          placeholder="Search for users"
        ></input>
      </div>
      <div>
        <Card isActive={false} isStacked={true}>
          <div className="flex items-center">
            <div className="flex-1">
              <div className="k_cardQuestionTitle">Kavi Harshawat</div>
              <div className="k_cardQuestionDescription text-gray-500">
                kavi@goodctzn.com
              </div>
            </div>

            <div className="flex-initial">
              <Menu menuOpen={menuButtonOpen} setMenuOpen={setMenuButtonOpen}>
                <MenuButton>
                  <Icon fontAwesomeClassNames="far fa-ellipsis-h"></Icon>
                </MenuButton>
                <MenuItemList position="bottom-left">
                  <MenuItem>Edit info</MenuItem>
                  <MenuItem>Change account type</MenuItem>
                  <MenuItem danger={true}>Deactivate account</MenuItem>
                </MenuItemList>
              </Menu>
            </div>
          </div>
          <div className="bg-gray-100 border border-gray-300 k_cardQuestionDescription p-4 mt-2">
            <div>
              <span className="text-gray-500">Account Type:</span> Administrator
            </div>
            <div>
              <span className="text-gray-500">Status:</span> Active
            </div>
            <div>
              <span className="text-gray-500">Authentication:</span> 2fA
            </div>
          </div>
        </Card>
        <Card isActive={false} isStacked={true}>
          <div className="flex">
            <div className="flex-1">
              <div className="k_cardQuestionTitle">Ron Gorodetzky</div>
              <div className="k_cardQuestionDescription text-gray-500">
                ron@goodctzn.com
              </div>
            </div>

            <div className="flex-initial">
              <Menu menuOpen={menuButtonOpen} setMenuOpen={setMenuButtonOpen}>
                <MenuButton>
                  <Icon fontAwesomeClassNames="far fa-ellipsis-h"></Icon>
                </MenuButton>
                <MenuItemList position="bottom-left">
                  <MenuItem>Edit info</MenuItem>
                  <MenuItem>Change account type</MenuItem>
                  <MenuItem danger={true}>Deactivate account</MenuItem>
                </MenuItemList>
              </Menu>
            </div>
          </div>

          <div className="bg-gray-100 border border-gray-300 k_cardQuestionDescription p-4 mt-2">
            <div>
              <span className="text-gray-500">Account Type:</span> Administrator
            </div>
            <div>
              <span className="text-gray-500">Status:</span> Active
            </div>
            <div>
              <span className="text-gray-500">Authentication:</span> 2fA
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};
