export const nextInCycle = (list: any[], current: any) => {
  const currentIndex = list.findIndex((x) => x === current);
  let nextIndex = -1;

  if (currentIndex === -1) {
    return -1;
  }

  if (currentIndex === list.length - 1) {
    nextIndex = 0;
  } else {
    nextIndex = currentIndex + 1;
  }

  return list[nextIndex];
};

export const arraysEqual = (first: any[], second: any[]): boolean => {
  return first
    .map((val, idx) => {
      return second[idx] === val;
    })
    .every((result) => {
      return result === true;
    });
};

export const objectIsEmpty = (obj: Object): boolean => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => {
  return value !== null && value !== undefined;
};

export const queryObj = (obj: any, path: string) =>
  path.split(".").reduce((a, b) => a && a[b], obj);
