import React, { FunctionComponent } from "react";

interface LoadingAnimationProps {}

export const LoadingAnimation: FunctionComponent<LoadingAnimationProps> = (
  _props
) => {
  return (
    <div className="sk-flow sk-center">
      <div className="sk-flow-dot"></div>
      <div className="sk-flow-dot"></div>
      <div className="sk-flow-dot"></div>
    </div>
  );
};
