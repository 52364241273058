import React, { FunctionComponent, Fragment, useState } from "react";
import { Expander, ExpanderBody, ExpanderCollapsedDescription } from "../Card";

interface AccountSettingsProps {
  // TODO: Add activeSection to scroll to and expand a specific section of the settings
  //   activeSection: string;
}

export const AccountSettings: FunctionComponent<AccountSettingsProps> = (
  _props
) => {
  const [passwordExpanded, setPasswordExpanded] = useState(false);
  const [twofactorExpanded, setTwofactorExpanded] = useState(false);
  const [emailExpanded, setEmailExpanded] = useState(false);

  const togglePasswordExpanded = () => {
    setPasswordExpanded(!passwordExpanded);
  };
  const toggleTwofactorExpanded = () => {
    setTwofactorExpanded(!twofactorExpanded);
  };
  const toggleEmailExpanded = () => {
    setEmailExpanded(!emailExpanded);
  };

  return (
    <Fragment>
      <div className="mt-8">
        <h1 className="text-2xl font-medium max-w-3xl mx-2 md:mx-auto my-4">
          Your Account Settings
        </h1>
        {/* TODO: Hide this section if user uses Sign In Services */}
        <Expander
          isExpanded={passwordExpanded}
          title="Password"
          hideControl={false}
          toggleExpanded={togglePasswordExpanded}
        >
          <ExpanderCollapsedDescription></ExpanderCollapsedDescription>
          <ExpanderBody>
            <div className="k_cardGroup">
              <label className="k_cardQuestionDescription">
                Current Password
                <input
                  className="k_textInput mb-4"
                  placeholder="Enter your current password"
                  disabled={false}
                ></input>
              </label>
              <label className="k_cardQuestionDescription">
                New Password
                <input
                  className="k_textInput mb-4"
                  placeholder="Enter a new password"
                  disabled={false}
                ></input>
              </label>
              <button className="btn btn-gray w-full mb-4">
                Save Password
              </button>
              <div className="k_cardQuestionDescription">
                Can't remember your password? Click the button below.
              </div>
              <button className="btn w-full">
                Reset your password by email
              </button>
            </div>
          </ExpanderBody>
        </Expander>
        <Expander
          isExpanded={twofactorExpanded}
          title="Two-Factor Authentication"
          hideControl={false}
          toggleExpanded={toggleTwofactorExpanded}
        >
          <ExpanderCollapsedDescription></ExpanderCollapsedDescription>
          <ExpanderBody>
            <div className="k_cardGroup">
              {/* TODO: Add real phone number for backup */}
              <div className="k_cardQuestionDescription mb-4">
                Two-Factor authentication is active for your account. Your
                backup phone number is <strong>+1 5555555</strong> (
                <a href="">edit</a>).
              </div>
              <div className="inline-block w-full inline-block pb-4 mb-4 border-b border-gray-300">
                <a className="cursor-pointer">
                  <i className="far fa-key mr-2"></i>You have 10 unused backup
                  codes
                </a>
              </div>
              {/* TODO: Link to deactivation of 2fa and sms flows */}
              <button className="btn w-full mb-4">
                Deactivate two-factor authentication
              </button>
              <button className="btn w-full ">
                Use authentication via SMS instead
              </button>
            </div>
          </ExpanderBody>
        </Expander>

        {/* TODO: Hide this section if user uses Sign In Services */}
        <Expander
          isExpanded={emailExpanded}
          title="Email address"
          hideControl={false}
          toggleExpanded={toggleEmailExpanded}
        >
          <ExpanderCollapsedDescription>
            {/* TODO: Add real address when available */}
            <div>
              Your email address is <strong>example@example.com</strong>
            </div>
          </ExpanderCollapsedDescription>
          <ExpanderBody>
            <div className="k_cardGroup">
              <label className="k_cardQuestionDescription">
                Current Password
                <input
                  className="k_textInput mb-4"
                  placeholder="Enter your current password"
                  disabled={false}
                ></input>
              </label>
              <label className="k_cardQuestionDescription">
                New email address
                <input
                  className="k_textInput mb-4"
                  placeholder="Enter a new password"
                  disabled={false}
                ></input>
              </label>
              <button className="btn btn-gray w-full mb-4">Update email</button>
            </div>
          </ExpanderBody>
        </Expander>

        <Expander
          isExpanded={true}
          title="Sign out all other sessions"
          hideControl={true}
        >
          <ExpanderCollapsedDescription></ExpanderCollapsedDescription>
          <ExpanderBody>
            <div className="k_cardQuestionDescription mb-4">
              You can sign out of all other sessions if you lost your phone,
              forgot to sign out of a public computer, or just want to sign out
              of everywhere except your current browser.
            </div>
            {/* TODO: Add ability to sign out of all sessions */}
            <button className="btn btn-orange w-full">
              Sign out of all other sessions
            </button>
          </ExpanderBody>
        </Expander>
        <Expander
          isExpanded={true}
          title="Deactivate account"
          hideControl={true}
        >
          <ExpanderCollapsedDescription></ExpanderCollapsedDescription>
          <ExpanderBody>
            <div className="k_cardQuestionDescription mb-4">
              If you no longer need your account on the GoodCTZN project, you
              can deactivate your account. Any other Slack projects you belong
              to will not be affected.
            </div>
            {/* TODO: Add ability to deactivate account */}
            <button className="btn w-full">Deactivate your account</button>
          </ExpanderBody>
        </Expander>
      </div>
    </Fragment>
  );
};
