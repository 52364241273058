import * as K from "../types/KotoOb";

import {
  PROJECTS_CREATE_ROUTE,
  buildFormsListPath,
  buildProjectCreateDetailPath,
  buildProjectPath,
  urlParams,
} from "./AppRoutes";
import { Project, createAndStoreProject, loadProject } from "../types/Project";
import {
  ProjectDetails,
  ProjectInvite,
  ProjectsPage,
} from "../components/ProjectsPage";
import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Redirect, useParams } from "react-router";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { AppHeaderBar } from "./AppHeader";
import { AppKotoContext } from "./AppKotoContext";
import { KotoCRDT } from "../types/KotoTypes";
import { RootState } from "../Store/store";
import { addProjectToUserId } from "../types/User";
import { authSlice } from "../Store/authSlice";
import { loadProjects } from "../Store/projectsSlice";
import { useHistory } from "react-router-dom";

export const AppProjectCreate: FunctionComponent<{}> = (_props) => {
  const { kContext } = useContext(AppKotoContext);
  const dispatch = useDispatch();

  const [newId, setNewId] = useState("");

  useEffect(() => {
    const create = async () => {
      const newProject = await createAndStoreProject(kContext, "New Project");

      addProjectToUserId(kContext, newProject.key, kContext.userId).then(
        (result) => {
          if (result.type === "Success") {
            const newUser = result.data;
            dispatch(
              authSlice.actions.setUserData(K.getKotoObFromKotoCRDT(newUser))
            );
          } else {
            console.log("Error: ", result);
          }
        }
      );
      setNewId(newProject.crdt.id);
    };
    create();
  }, []);

  return (
    <Fragment>
      {newId !== "" && <Redirect to={buildProjectCreateDetailPath(newId)} />}
    </Fragment>
  );
};

export const AppProjectDetails: FunctionComponent<{}> = (_props: any) => {
  const { kContext } = useContext(AppKotoContext);
  const { projectId } = useParams<urlParams>();
  const [projectCRDT, setProjectCRDT] = useState<KotoCRDT<Project>>();
  const [notFound] = useState(false);
  const history = useHistory();

  const handleSetProject = (newProject: KotoCRDT<Project>) => {
    setProjectCRDT(newProject);
  };

  const loadData = async () => {
    if (projectId) {
      const result = await loadProject(kContext, projectId);
      if (result.type === "Success") {
        handleSetProject(result.data);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, [projectId]);

  if (notFound) {
    return <div> NOT FOUND</div>;
  } else if (projectCRDT) {
    return (
      <Fragment>
        <AppHeaderBar />
        <ProjectDetails
          kotoContext={kContext}
          project={projectCRDT}
          setProject={handleSetProject}
          handleNext={() => {
            history.push(buildFormsListPath(projectId));
          }}
          handleCancel={() => {
            history.push("/");
          }}
        />
      </Fragment>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export const AppProjectInvite: FunctionComponent<{}> = (_props: any) => {
  const { kContext } = useContext(AppKotoContext);

  const { projectId } = useParams<urlParams>();
  const history = useHistory();

  const projectUrl = buildProjectPath(projectId);

  return (
    <Fragment>
      <AppHeaderBar />

      <ProjectInvite
        kotoContext={kContext}
        inviteLink={projectUrl}
        projectId={projectId}
        handleBack={() => {
          history.goBack();
        }}
        handleSkip={() => {
          history.push(projectUrl);
        }}
        handleInvite={() => {
          console.log("HERE: invite clicked");
          let unblock = history.block((tx) => {
            if (window.confirm(`Fake Invite!`)) {
              // Unblock the navigation.
              unblock();

              // Retry the transition.
              history.push(tx.pathname);
            }
          });
          history.push(projectUrl);
        }}
      />
    </Fragment>
  );
};

export const AppProjectsPage: FunctionComponent<{}> = (_props: any) => {
  const { kContext } = useContext(AppKotoContext);
  const dispatch = useDispatch();

  const projectIds = useSelector((state: RootState) => {
    return state.auth.userData?.doc.projects;
  }, shallowEqual);

  const projects: KotoCRDT<Project>[] = useSelector((state: RootState) => {
    const projects = Object.values(state.projects.projects);
    projects.sort(
      (a, b) => a.createdTimeInMilliseconds - b.createdTimeInMilliseconds
    );
    return projects;
  }, shallowEqual);

  useEffect(() => {
    dispatch(loadProjects(kContext));
  }, [projectIds]);

  return (
    <Fragment>
      <AppHeaderBar />
      <ProjectsPage
        kContext={kContext}
        projects={projects}
        projectCreateLink={PROJECTS_CREATE_ROUTE}
        projectsReloader={() => dispatch(loadProjects(kContext))}
      />
    </Fragment>
  );
};
