import React, { FunctionComponent, Fragment } from "react";
import { Card } from "../Card";

interface ThemesProps {
  // TODO: Add activeSection to scroll to and expand a specific section of the settings
  //   activeSection: string;
}

// TODO: Replace div containing fake user icon with real user icons and
// placeholder images
export const Themes: FunctionComponent<ThemesProps> = (_props) => {
  return (
    <Fragment>
      <div className="my-8"></div>
      <Card isActive={false} isStacked={false}>
        <div className="k_Card">
          <div className="k_cardQuestionTitle">Themes</div>
          <div className="k_cardQuestionDescription">
            Choose the appearance of Koto.
          </div>
          <div>
            <label className="k_choiceLabel">
              <input
                type="radio"
                className="form-radio k_choiceInput"
                disabled={false}
                value="light"
              ></input>
              <div className="k_choiceText">Light</div>
            </label>
            <label className="k_choiceLabel">
              <input
                type="radio"
                className="form-radio k_choiceInput"
                disabled={false}
                value="dark"
              ></input>
              <div className="k_choiceText">Dark</div>
            </label>
          </div>
        </div>
      </Card>
    </Fragment>
  );
};
