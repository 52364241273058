import React, { FunctionComponent, Fragment } from "react";

// TODO: Make this work with a list of Multiplayer Icons. When there are more than X Multiplayer Icons use MultiplayerMoreButton to show "+Y more"

interface ConnectivityStatusProps {
  status: string; // online | offline | syncing | saving
  extraClassNames?: string;
}

export const ConnectivityStatus: FunctionComponent<ConnectivityStatusProps> = (
  props
) => {
  if (props.status == "online") {
    return (
      <Fragment>
        <button
          className={"btn flex-initial " + props.extraClassNames}
          data-tooltip="Status: Online"
          tooltip-position="bottom"
        >
          <i className="fas fa-cloud"></i>
          {/* TODO: Show word temporarily after status is changed */}
          <span className="hidden ml-2">Online</span>
        </button>
      </Fragment>
    );
  } else if (props.status == "syncing") {
    return (
      <Fragment>
        <button
          className={"btn flex-initial italic " + props.extraClassNames}
          data-tooltip="Status: Syncing"
          tooltip-position="bottom"
        >
          <i className="fas fa-sync-alt"></i>
          {/* TODO: Show word temporarily after status is changed */}
          <span className="hidden ml-2">Syncing</span>
        </button>
      </Fragment>
    );
  } else if (props.status == "offline") {
    return (
      <Fragment>
        <button
          className={"btn flex-initial " + props.extraClassNames}
          data-tooltip="Status: Offline"
          tooltip-position="bottom"
        >
          <i className="fas fa-signal-slash"></i>
          {/* TODO: Show word temporarily after status is changed */}
          <span className="hidden ml-2">Offline</span>
        </button>
      </Fragment>
    );
  } else if (props.status == "saving") {
    return (
      <Fragment>
        <button
          className={"btn flex-initial " + props.extraClassNames}
          data-tooltip="Status: Saving draft"
          tooltip-position="bottom"
        >
          <i className="fas fa-save"></i>
          {/* TODO: Show word temporarily after status is changed */}
          <span className="hidden ml-2">Saving draft</span>
        </button>
      </Fragment>
    );
  } else {
    console.log("No Connectivity Status Available");
    return <div></div>;
  }
};
