import {
  BasicButton,
  FilterItemType,
  FilterList,
  ToggleSwitch,
} from "./Buttons";
import {
  FormId,
  ProjectId,
  QuestionSpecId,
  RecordSetId,
} from "../types/IdTypes";
import {
  FriendlyNamePart,
  Project,
  getRecordSetName,
  udpateRecordSetValidForms,
  updateRecordSetDescription,
  updateRecordSetName,
  updateRecordSetPrivacy,
} from "../types/Project";
import { KotoCRDT, KotoKey } from "../types/KotoTypes";
import React, { Fragment, FunctionComponent, useRef, useState } from "react";
import { Tab, TabContent, TabHeader, TabbedView } from "./Tabs";

import { CollaboratorListItem } from "./UserListItem";
import { Form } from "../types/Form";
import { KotoContext } from "../types/KotoContext";
import { Overlay } from "./Overlay";
import { PillChooser } from "./PillChooser";
import { buildFormFillerPath } from "../App/AppRoutes";
import { createPortal } from "react-dom";
import { notEmpty } from "../utils/utils";

interface ModalProps {
  title: string;
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
}

interface ModalContentProps {}

interface ModalButtonProps {
  extraClassNames?: string;
}

interface ModalActionButtonProps {
  extraClassNames?: string;
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
}

export const ModalButton: FunctionComponent<ModalButtonProps> = (props) => {
  return (
    <BasicButton extraClassNames={props.extraClassNames}>
      {props.children}
    </BasicButton>
  );
};

export const ModalContent: FunctionComponent<ModalContentProps> = (props) => {
  return <Fragment>{props.children}</Fragment>;
};

export const ModalActionButton: FunctionComponent<ModalActionButtonProps> = (
  props
) => {
  let className =
    "sm:w-auto sm:flex-initial w-full py-2 px-5 mr-2 mb-2 sm:m-0 btn btn-gray";
  if (props.extraClassNames) {
    className = className + " " + props.extraClassNames;
  }

  return (
    <BasicButton onClick={props.onClick} extraClassNames={className}>
      {props.children}
    </BasicButton>
  );
};

export const Modal: FunctionComponent<ModalProps> = (props) => {
  const toggleModal = () => {
    props.setModalOpen(!props.modalOpen);
  };

  const children = React.Children.toArray(props.children);

  const component = (
    <Fragment>
      {props.modalOpen && (
        <Overlay toggle={toggleModal}>
          <div className="mobile-modal sm:max-w-3xl sm:relative sm:rounded overflow-visible">
            <div className="sticky inset-x-0 top-0">
              <div className="bg-white border-t border-white flex rounded-t-lg px-4 pb-4 sm:px-8 pt-8 text-3xl font-semibold leading-snug">
                {props.title}
              </div>
            </div>
            <div className="mx-4 sm:mx-8  ">{children[1]}</div>
            <div className="block sm:flex sm:flex-row-reverse sm:items-center bg-white py-4 px-4 sm:px-8 sticky inset-x-0 bottom-0 mt-1 overflow-auto sm:mb-2">
              {children[2]}
              <div className="sm:flex-grow"></div>
              <button
                className="sm:w-auto sm:flex-initial w-full py-2 px-5 mr-2 mb-2 sm:m-0 btn btn-gray-outline"
                onClick={toggleModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Overlay>
      )}
    </Fragment>
  );

  const modalElement = document.getElementById("modal-root");

  if (modalElement === null) {
    return <div>NO MODAL ROOT ELEMENT!!!!</div>;
  }

  return (
    <Fragment>
      {createPortal(component, modalElement)}
      <span
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          toggleModal();
        }}
      >
        {children[0]}
      </span>
    </Fragment>
  );
};

// Modal to change the settings of a Record Set
// Shown when user clicks the "Settings" button at the top of any record set
export interface RecordSetSettingsModalProps {
  kContext: KotoContext;
  projectCrdt: KotoCRDT<Project>;
  recordSetId: RecordSetId;
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
  setProject: (updateProject: KotoCRDT<Project>) => void;
  availableForms: KotoCRDT<Form>[];
  availableQuestions: {
    blockSpecKey: KotoKey;
    questionSpecId: QuestionSpecId;
    label: string;
  }[];
  setFriendlyName: (selections: FriendlyNamePart[]) => Promise<void>;
}

export const RecordSetSettingsModal: FunctionComponent<RecordSetSettingsModalProps> = (
  props
) => {
  const recordSet = props.projectCrdt.crdt.recordSets[props.recordSetId];
  const modalTitle = `Settings for ${recordSet.name.toString() || "Record"}`;

  const questionSpecIdtoId = (a: typeof props.availableQuestions) => {
    return a.map((val) => {
      return { ...val, id: val.questionSpecId };
    });
  };

  const stripId = (
    a: ReturnType<typeof questionSpecIdtoId>
  ): typeof props.availableQuestions => {
    return a.map((val) => {
      const { ["id"]: remove, ...rest } = val;
      return rest;
    });
  };

  const friendlyNameParts =
    props.projectCrdt.crdt.recordSets[
      props.recordSetId
    ].friendlyName?.friendlyParts
      .map((part) => {
        return props.availableQuestions.find((question) => {
          return (
            question.blockSpecKey === part.blockSpecKey &&
            question.questionSpecId === part.questionSpecId
          );
        });
      })
      .filter(notEmpty) || [];

  const handleGetFriendlyNameParts = (text: string) => {
    return props.availableQuestions.filter((q) => {
      return (
        friendlyNameParts.find((part) => {
          return (
            part.questionSpecId === q.questionSpecId &&
            part.blockSpecKey === q.blockSpecKey
          );
        }) === undefined && q.label.startsWith(text)
      );
    });
  };

  const toggleModal = (): void => {
    props.setModalOpen(!props.modalOpen);
  };

  const children = React.Children.toArray(props.children);

  const handleLableUpdate = (value: string) => {
    props.setProject(
      updateRecordSetName(
        props.kContext,
        props.projectCrdt,
        props.recordSetId,
        value
      )
    );
  };

  const handleDescriptionUpdate = (value: string) => {
    props.setProject(
      updateRecordSetDescription(
        props.kContext,
        props.projectCrdt,
        props.recordSetId,
        value
      )
    );
  };

  const handleSetPrivate = (value: boolean) => {
    props.setProject(
      updateRecordSetPrivacy(
        props.kContext,
        props.projectCrdt,
        props.recordSetId,
        value
      )
    );
  };

  const filterItems = props.availableForms.map((f) => {
    const isSelected =
      props.projectCrdt.crdt.recordSets[props.recordSetId].validForms !==
      undefined
        ? props.projectCrdt.crdt.recordSets[
            props.recordSetId
          ].validForms?.findIndex((validForm) => {
            return validForm === f.key;
          }) !== -1
        : true;
    return {
      label: f.crdt.title.toString(),
      selected: isSelected,
      option: f.key,
    };
  });

  const handleSetFormsSettings = (filteredForms: FilterItemType[]) => {
    props.setProject(
      udpateRecordSetValidForms(
        props.kContext,
        props.projectCrdt,
        props.recordSetId,
        filteredForms
          .map((entry) => {
            return entry.selected ? entry.option : null;
          })
          .filter(notEmpty)
      )
    );
  };

  const component = (
    <Fragment>
      {props.modalOpen && (
        <Overlay toggle={toggleModal} position="top">
          <div className=" mobile-modal sm:max-h-90 sm:max-w-2xl  sm:relative sm:rounded p-4 sm:p-8 text-left">
            <div className="bg-white flex rounded-t-lg  items-center mb-4">
              <div className="flex-1 text-3xl font-semibold leading-snug">
                {modalTitle}
              </div>
              <button
                className="btn btn-sm ml-8 flex-initial"
                onClick={toggleModal}
                data-tooltip="Close"
                tooltip-position="bottom"
              >
                <i className="fas fa-times"></i>
                <span className="hidden sm:inline-block ml-2">Close</span>
              </button>
            </div>

            <div className="k_cardGroup">
              <div className="k_cardQuestionTitle">Record set label</div>
              <div className="k_cardQuestionDescription">
                You can rename a record type at any time. But, use it sparingly:
                it might confuse or disorient your colleagues!
              </div>
              <input
                className="k_textInput"
                placeholder="Enter a label"
                disabled={false}
                onChange={(e) => {
                  handleLableUpdate(e.target.value);
                }}
                value={recordSet.name.toString()}
              ></input>
            </div>

            <div className="k_cardGroup">
              <div className="k_cardQuestionTitle">
                Description{" "}
                <span className="text-gray-600 font-thin">(optional)</span>
              </div>
              <div className="k_cardQuestionDescription">
                What kind records will be in this set?
              </div>
              <input
                className="k_textInput"
                placeholder="Add a description"
                disabled={false}
                onChange={(e) => {
                  handleDescriptionUpdate(e.target.value);
                }}
                value={
                  props.projectCrdt.crdt.recordSets[props.recordSetId]
                    .description || ""
                }
              ></input>
            </div>

            {/* TODO: Make this search box search across available form fields */}
            {/* TODO: Users selections should be added as chicklets */}
            <div className="k_cardGroup">
              <div className="k_cardQuestionTitle">Friendly name</div>
              <div className="k_cardQuestionDescription">
                Use the search box below to use one or more form fields as the
                friendly name (like a product name or a first and last name).
              </div>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <i className="fas fa-search text-sm text-gray-600 mt-1"></i>
                </div>
                <PillChooser<FriendlyNamePart & { id: string; label: string }>
                  selections={questionSpecIdtoId(friendlyNameParts)}
                  getFilteredSelections={(text) => {
                    return questionSpecIdtoId(handleGetFriendlyNameParts(text));
                  }}
                  placeholder="Search for one or more form fields"
                  setSelections={async (selections) => {
                    props.setFriendlyName(stripId(selections));
                  }}
                />
              </div>
            </div>

            <div className="k_cardGroup">
              <div className="k_cardQuestionTitle">Make private</div>
              <div className="k_cardQuestionDescription flex">
                <div className="flex-1 ">
                  When a record set is private, it can only be viewed or joined
                  by invitation to the set.
                </div>
                {/* TODO: Hook up Toggle Switch to make the project private/not private */}
                {/* TODO: If private, show manage collaborators button */}
                <div className="flex-initial ml-8 md:ml-24">
                  <ToggleSwitch
                    checked={recordSet.isPrivate}
                    setChecked={handleSetPrivate}
                  ></ToggleSwitch>
                </div>
              </div>
              {recordSet.isPrivate && (
                <button className="btn mt-4">
                  <i className="far fa-users mr-2"></i>Add collaborators
                </button>
              )}
            </div>

            <div className="k_cardGroup mb-0">
              <div className="k_cardQuestionTitle">Add a record button</div>
              <div className="k_cardQuestionDescription flex items-center">
                <p>
                  Select the forms that should be shown when the user clicks the{" "}
                  <span className="text-xs rounded bg-teal-400 text-teal-900 border border-teal-900 font-medium py-px px-1 inline-block whitespace-normal">
                    <i className="fas fa-plus mr-1"></i> Add a record
                  </span>{" "}
                  button.
                </p>
              </div>

              <FilterList
                items={filterItems}
                setItems={handleSetFormsSettings}
                extraClassNames=""
              ></FilterList>
            </div>
          </div>
        </Overlay>
      )}
    </Fragment>
  );

  const modalElement = document.getElementById("modal-root");

  if (modalElement === null) {
    return <div>NO MODAL ROOT ELEMENT!!!!</div>;
  }

  return (
    <Fragment>
      {createPortal(component, modalElement)}
      <span onClick={toggleModal}>{children[0]}</span>
    </Fragment>
  );
};

// Modal to share a form via email, URL, or embed
// Used in Form Designer
export interface ShareFormModalProps {
  title: string;
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
  activeView: string; // "email" | "url"
  selectTab: (label: string) => void;
  projectId: ProjectId;
  formId: FormId;
  recordSets: Project["recordSets"];
}

export const ShareFormModal: FunctionComponent<ShareFormModalProps> = (
  props
) => {
  const toggleModal = (): void => {
    props.setModalOpen(!props.modalOpen);
  };

  const children = React.Children.toArray(props.children);

  const [activeRecordSet, setActiveRecordSet] = useState("");

  const recordSetSelector = (
    <select
      className="form-select k_select"
      value={activeRecordSet}
      onChange={(e) => {
        setActiveRecordSet(e.target.value);
      }}
    >
      <option value="">Select a record set</option>
      {Object.entries(props.recordSets).map((recordSetInfo) => {
        const [recordSetId, recordSet] = recordSetInfo;

        return (
          <option key={recordSetId} value={recordSetId}>
            {getRecordSetName(recordSet)}
          </option>
        );
      })}
    </select>
  );

  const urlRef = useRef<HTMLInputElement>(null);

  const handleCopyToClipboard = () => {
    if (urlRef.current !== null) {
      const urlField = urlRef.current;
      urlField.select();
      document.execCommand("copy");
      urlField.setSelectionRange(0, 0);
    }
  };

  const component = (
    <Fragment>
      {props.modalOpen && (
        <Overlay toggle={toggleModal}>
          <div className="mobile-modal sm:max-h-90 sm:w-xl md:w-2xl  sm:relative sm:rounded ">
            <div className="bg-white sm:border-b border-gray-400 flex rounded-t-lg p-4 sm:p-8  items-center ">
              <div className="flex-1 text-3xl font-semibold leading-snug">
                {props.title}
              </div>
              <button
                className="btn ml-8 flex-initial"
                onClick={toggleModal}
                data-tooltip="Close"
                tooltip-position="bottom"
              >
                <i className="fas fa-times"></i>
                <span className="hidden sm:inline-block ml-2">Close</span>
              </button>
            </div>

            <div className="">
              <TabbedView
                type="default"
                activeView={props.activeView}
                selectTab={props.selectTab}
              >
                <Tab label="email">
                  <TabHeader>
                    <i className="fal fa-envelope mr-2"></i> Email
                  </TabHeader>
                  <TabContent>
                    <div className="m-8">
                      {/* TODO: Add actual list of record sets in the select menu below */}
                      {/* TODO: If there's only one record set available, select that record set and disable the select menu  */}
                      <p className="k_cardQuestionDescription">
                        Select the record set you want this form to add records
                        to.
                      </p>
                      {recordSetSelector}
                      <div className="border-b border-gray-400 my-4"></div>
                      <input
                        className="k_textInput mb-2"
                        placeholder="Recipient"
                      ></input>
                      <input
                        className="k_textInput mb-2"
                        placeholder="Subject"
                      ></input>
                      <textarea
                        className="k_textArea mb-8 "
                        placeholder="Add a message"
                      ></textarea>
                      <div className="flex">
                        <div className="flex-grow"></div>
                        <button className="btn btn-gray flex-initial w-32">
                          Send
                        </button>
                      </div>
                    </div>
                  </TabContent>
                </Tab>
                <Tab label="url">
                  <TabHeader>
                    <i className="fal fa-link mr-2"></i> URL
                  </TabHeader>
                  <TabContent>
                    <div className="m-8">
                      {/* TODO: Add actual list of record sets in the select menu below */}
                      {/* TODO: If there's only one record set available, select that record set and disable the select menu  */}
                      <p className="k_cardQuestionDescription">
                        Select the record set you want this form to add records
                        to.
                      </p>
                      {recordSetSelector}
                      {activeRecordSet && (
                        <div className="relative">
                          {/* TODO: Only show this URL box once a record set has been selected */}
                          {/* TODO: Put form URL for sharing in this text box to be copied */}
                          <input
                            ref={urlRef}
                            className="k_textInput mb-8 pr-24 truncate"
                            placeholder="URL"
                            readOnly={true}
                            value={buildFormFillerPath(
                              props.projectId,
                              props.formId,
                              activeRecordSet,
                              true
                            )}
                          ></input>
                          <button
                            className="btn btn-sm btn-link absolute top-0 right-0 mt-3 mr-2 no-underline"
                            onClick={handleCopyToClipboard}
                          >
                            Copy link
                          </button>
                        </div>
                      )}
                    </div>
                  </TabContent>
                </Tab>
              </TabbedView>
            </div>
          </div>
        </Overlay>
      )}
    </Fragment>
  );

  const modalElement = document.getElementById("modal-root");

  if (modalElement === null) {
    return <div>NO MODAL ROOT ELEMENT!!!!</div>;
  }

  return (
    <Fragment>
      {createPortal(component, modalElement)}
      <span onClick={toggleModal}>{children[0]}</span>
    </Fragment>
  );
};

export interface ShareRecordModalProps {
  title: string;
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
  activeView: string; // "email" | "url"
  selectTab: (label: string) => void;
  url: string;
}

export const ShareRecordModal: FunctionComponent<ShareRecordModalProps> = (
  props
) => {
  const toggleModal = (): void => {
    props.setModalOpen(!props.modalOpen);
  };

  const urlRef = useRef<HTMLInputElement>(null);
  const copyUrl = () => {
    if (urlRef.current) {
      urlRef.current.select();
      document.execCommand("copy");
      // This is just personal preference.
      // I prefer to not show the whole text area selected.
      urlRef.current.focus();
    }
  };

  const children = React.Children.toArray(props.children);

  const component = (
    <Fragment>
      {props.modalOpen && (
        <Overlay toggle={toggleModal}>
          <div className="mobile-modal sm:max-h-90 sm:w-xl md:w-2xl  sm:relative sm:rounded ">
            <div className="bg-white sm:border-b border-gray-400 flex rounded-t-lg p-4 sm:p-8  items-center ">
              <div className="flex-1 text-3xl font-semibold leading-snug">
                {props.title}
              </div>
              <button
                className="btn ml-8 flex-initial"
                onClick={toggleModal}
                data-tooltip="Close"
                tooltip-position="bottom"
              >
                <i className="fas fa-times"></i>
                <span className="hidden sm:inline-block ml-2">Close</span>
              </button>
            </div>

            <div className="">
              <TabbedView
                type="default"
                activeView={props.activeView}
                selectTab={props.selectTab}
              >
                <Tab label="email">
                  <TabHeader>
                    <i className="fal fa-envelope mr-2"></i> Email
                  </TabHeader>
                  <TabContent>
                    <div className="m-8">
                      <input
                        className="k_textInput mb-2"
                        placeholder="Recipient"
                      ></input>
                      <input
                        className="k_textInput mb-2"
                        placeholder="Subject"
                      ></input>
                      <textarea
                        className="k_textArea mb-8 "
                        placeholder="Add a message"
                      ></textarea>
                      <div className="flex">
                        <div className="flex-grow"></div>
                        <button className="btn btn-gray flex-initial w-32">
                          Send
                        </button>
                      </div>
                    </div>
                  </TabContent>
                </Tab>
                <Tab label="url">
                  <TabHeader>
                    <i className="fal fa-link mr-2"></i> URL
                  </TabHeader>
                  <TabContent>
                    <div className="m-8">
                      <div className="relative">
                        {/* TODO: Put form URL for sharing in this text box to be copied */}
                        <input
                          ref={urlRef}
                          className="k_textInput mb-8 pr-24 truncate"
                          value={props.url}
                          readOnly={true}
                        ></input>
                        <button
                          className="btn btn-sm btn-link absolute top-0 right-0 mt-3 mr-2 no-underline"
                          onClick={copyUrl}
                        >
                          Copy link
                        </button>
                      </div>
                    </div>
                  </TabContent>
                </Tab>
              </TabbedView>
            </div>
          </div>
        </Overlay>
      )}
    </Fragment>
  );

  const modalElement = document.getElementById("modal-root");

  if (modalElement === null) {
    return <div>NO MODAL ROOT ELEMENT!!!!</div>;
  }

  return (
    <Fragment>
      {createPortal(component, modalElement)}
      <span onClick={toggleModal}>{children[0]}</span>
    </Fragment>
  );
};

// Modal for showing, adding, and removing collaboroators in private forms and record sets
export interface CollaboratorModalProps {
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
}

export const CollaboratorModal: FunctionComponent<CollaboratorModalProps> = (
  props
) => {
  const toggleModal = (): void => {
    props.setModalOpen(!props.modalOpen);
  };

  const children = React.Children.toArray(props.children);

  const component = (
    <Fragment>
      {props.modalOpen && (
        <Overlay toggle={toggleModal}>
          <div className="mobile-modal sm:max-h-90 sm:w-xl md:w-2xl sm:relative sm:rounded p-4 sm:p-8 bg-white ">
            <div className="flex items-center mb-4">
              <div className="k_cardSectionTitle flex-1 ">
                Manage collaborators
              </div>
              <button
                className="btn btn-sm flex-initial"
                onClick={toggleModal}
                data-tooltip="Close"
                tooltip-position="bottom"
              >
                <i className="fas fa-times"></i>
                <span className="hidden sm:inline-block ml-2">Close</span>
              </button>
            </div>

            {/* TODO: Enable search for people and groups using this text box */}
            {/* TODO: Add typeahead text box dropdown here for results */}

            <div className="relative mb-4">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <i className="text-gray-500 fas fa-search mt-1 text-sm"></i>
              </div>
              <input
                className="k_textInput pl-8"
                placeholder="Add people or groups"
              ></input>
            </div>

            {/* TODO: Map a list of CollaboratorListItems here. Set type to "user" or "group" as appropriate */}
            {/* TODO: If current user show "Leave" button, otherwise show remove button */}
            {/* TODO: Hook up remove buttons to remove users from access  */}

            {/* Example of a user */}
            <div className="flex items-center mb-4">
              <CollaboratorListItem
                title="Kavi Harshawat"
                meta="kavi@goodctzn.com"
                size="md"
                type="user"
              />
              <button className="flex-initial text-red-700 btn btn-sm">
                Leave
              </button>
            </div>

            {/* Example of a group */}
            <div className="flex items-center mb-4">
              <CollaboratorListItem
                title="Volunteers"
                meta="6 members"
                size="md"
                type="group"
              />

              <button className="flex-initial text-gray-700 btn btn-sm">
                Remove
              </button>
            </div>
          </div>
        </Overlay>
      )}
    </Fragment>
  );

  const modalElement = document.getElementById("modal-root");

  if (modalElement === null) {
    return <div>NO MODAL ROOT ELEMENT!!!!</div>;
  }

  return (
    <Fragment>
      {createPortal(component, modalElement)}
      <span onClick={toggleModal}>{children[0]}</span>
    </Fragment>
  );
};

// Modal for creating a new record set.
// Used in the "Create record set" menu of sidebar
export interface CreateFormModalProps {
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
}

export const CreateFormModal: FunctionComponent<CreateFormModalProps> = (
  props
) => {
  const toggleModal = (): void => {
    props.setModalOpen(!props.modalOpen);
  };

  const children = React.Children.toArray(props.children);
  const [isFormPrivate, setIsFormPrivate] = useState(false);

  const component = (
    <Fragment>
      <span onClick={toggleModal}>{children[0]}</span>
      {props.modalOpen && (
        <Overlay toggle={toggleModal}>
          <div className="mobile-modal sm:max-h-90 sm:w-xl md:w-2xl  sm:relative sm:rounded p-4 sm:p-8 bg-white">
            <div className="flex-1 text-3xl font-semibold leading-snug mb-4">
              Create a new form
            </div>
            <div className="">
              <div className="k_cardQuestionDescription mb-4">
                Forms allow you to collect information and add them to records.
              </div>

              <div className="k_cardGroup">
                <div className="k_cardQuestionTitle">Title</div>
                <div className="k_cardQuestionDescription">
                  Give your form a short but descriptive title that can help
                  others recognize it.
                </div>

                <div className="relative">
                  <input
                    className="k_textInput relative"
                    placeholder="Enter a record set title"
                    disabled={false}
                  ></input>
                  {/* TODO: Set a character limit on this text input (80 chars) */}
                  {/* TODO: Show current characters left in the div below */}
                  <span className="absolute right-0 top-0 mt-3 mr-4 text-gray-600">
                    80
                  </span>
                </div>
              </div>

              <div className="k_cardGroup">
                <div className="k_cardQuestionTitle">Make private</div>
                <div className="k_cardQuestionDescription flex">
                  <div className="flex-1 ">
                    When a form is private, it can only be viewed and edited by
                    invitation.
                  </div>
                  {/* TODO: Hook up Toggle Switch to make the project private/not private */}

                  <div className="flex-initial ml-8 md:ml-24">
                    <ToggleSwitch
                      checked={isFormPrivate}
                      setChecked={setIsFormPrivate}
                    ></ToggleSwitch>
                  </div>
                </div>
              </div>

              <div className="flex">
                <button className="btn flex-initial" onClick={toggleModal}>
                  Cancel
                </button>
                <div className="flex-grow"></div>
                <button className="btn btn-gray flex-initial">
                  Create form
                </button>
                {/* TODO: Show manage collaborators modal after use clicks "Create record set" if set is marked as private */}
              </div>
            </div>
          </div>
        </Overlay>
      )}
    </Fragment>
  );

  const modalElement = document.getElementById("modal-root");

  if (modalElement === null) {
    return <div>NO MODAL ROOT ELEMENT!!!!</div>;
  }

  return createPortal(component, modalElement);
};

// Modal for creating a new record set.
// Used in the "Create record set" menu of sidebar
export interface RestrictBlockModalProps {
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
}

export const RestrictBlockModal: FunctionComponent<RestrictBlockModalProps> = (
  props
) => {
  const toggleModal = (): void => {
    props.setModalOpen(!props.modalOpen);
  };

  const children = React.Children.toArray(props.children);

  const component = (
    <Fragment>
      <span onClick={toggleModal}>{children[0]}</span>
      {props.modalOpen && (
        <Overlay toggle={toggleModal}>
          <div className="mobile-modal sm:max-h-90 sm:w-xl md:w-2xl  sm:relative sm:rounded p-4 sm:p-8 bg-white">
            <div className="flex items-center mb-4">
              <div className="flex-1 text-2xl font-semibold leading-snug text-gray-800">
                Restrict access to results
              </div>
            </div>

            <div className="k_cardQuestionDescription mb-4">
              Limit who can see information collected from this block in
              records. This can be especially useful when collecting sensitive
              information.
            </div>

            <div className="k_cardGroup">
              {/* TODO: Enable search for people and groups using this text box */}
              {/* TODO: Add typeahead text box dropdown here for results */}

              <div className="relative mb-4">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <i className="text-gray-500 fas fa-search mt-1 text-sm"></i>
                </div>
                <input
                  className="k_textInput pl-8"
                  placeholder="Add people or groups"
                ></input>
              </div>

              {/* TODO: Map a list of CollaboratorListItems here. Set type to "user" or "group" as appropriate */}
              {/* TODO: If current user show "Leave" button, otherwise show remove button */}
              {/* TODO: Hook up remove buttons to remove users from access  */}

              {/* Example of a user */}
              <div className="flex items-center mb-4">
                <CollaboratorListItem
                  title="Kavi Harshawat"
                  meta="kavi@goodctzn.com"
                  size="sm"
                  type="user"
                />
                <button className="flex-initial text-gray-700 btn btn-sm">
                  Remove
                </button>
              </div>

              {/* Example of a group */}
              <div className="flex items-center mb-4">
                <CollaboratorListItem
                  title="Volunteers"
                  meta="6 members"
                  size="sm"
                  type="group"
                />

                <button className="flex-initial text-gray-700 btn btn-sm">
                  Remove
                </button>
              </div>
            </div>

            <div className="flex items-center border rounded bg-orange-100 border-orange-700 text-orange-700 p-4 k_cardGroup">
              <i className="fas fa-exclamation-triangle text-xl mr-4"></i>
              <div>
                Once a question block is restricted, you won’t be able to
                unrestrict it or make the form public. You will still be able to
                change who has access.
              </div>
            </div>

            {/* TODO: Clicking restrict access triggers ConfirmationRestritBlockModal component */}
            {/* TODO: Clicking cancel, drops all changes */}
            <div className="flex">
              <button className="btn flex-initial" onClick={toggleModal}>
                Cancel
              </button>
              <div className="flex-grow"></div>
              <button className="btn btn-gray flex-initial">
                Restrict access
              </button>
            </div>
          </div>
        </Overlay>
      )}
    </Fragment>
  );

  const modalElement = document.getElementById("modal-root");

  if (modalElement === null) {
    return <div>NO MODAL ROOT ELEMENT!!!!</div>;
  }

  return createPortal(component, modalElement);
};

export interface ConfirmationRestrictBlockModalProps {
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
}

export const ConfirmationRestrictBlockModal: FunctionComponent<ConfirmationRestrictBlockModalProps> = (
  props
) => {
  const toggleModal = (): void => {
    props.setModalOpen(!props.modalOpen);
  };

  const children = React.Children.toArray(props.children);

  const component = (
    <Fragment>
      <span onClick={toggleModal}>{children[0]}</span>
      {props.modalOpen && (
        <Overlay toggle={toggleModal}>
          <div className="mobile-modal sm:max-h-90 sm:w-xl md:w-2xl  sm:relative sm:rounded p-4 sm:p-8 bg-white">
            <div className="flex items-center mb-4 text-orange-700">
              <i className="fas fa-exclamation-triangle mr-2"></i>
              <div className="flex-1 text-2xl font-semibold leading-snug text-gray-800">
                Are you sure?
              </div>
            </div>

            <div className="k_cardQuestionDescription k_cardGroup">
              <span className="text-medium">This change can’t be undone.</span>{" "}
              Once a question block is restricted, you won’t be able to
              unrestrict it or make the form public.
            </div>

            {/* TODO: Clicking Yes I'm sure adopts changes from RestrictBlockModal */}
            {/* TODO: Clicking go back, goes back to RestrictBlockModal with previous state shown */}
            <div className="flex">
              <button className="btn flex-initial" onClick={toggleModal}>
                Go back
              </button>
              <div className="flex-grow"></div>
              <button className="btn btn-orange flex-initial">
                Yes I'm sure
              </button>
            </div>
          </div>
        </Overlay>
      )}
    </Fragment>
  );

  const modalElement = document.getElementById("modal-root");

  if (modalElement === null) {
    return <div>NO MODAL ROOT ELEMENT!!!!</div>;
  }

  return createPortal(component, modalElement);
};

export interface ConfirmationModalProps {
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
  confirmationType: string; // delete | archive
  entityString: string; // entity to be archived or deleted
  confirmationFunc: () => void;
}

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = (
  props
) => {
  const toggleModal = (): void => {
    props.setModalOpen(!props.modalOpen);
  };

  const component = (
    <Fragment>
      <span onClick={toggleModal}>{}</span>
      {props.modalOpen && (
        <Overlay toggle={toggleModal}>
          <div className="mobile-modal sm:max-h-90 sm:w-xl md:w-2xl  sm:relative sm:rounded p-4 sm:p-8 bg-white">
            <div className="flex items-center mb-4">
              <div className="flex-1 text-2xl font-semibold leading-snug text-gray-800">
                {props.confirmationType == "delete" && (
                  <Fragment>Are you sure you want to delete this?</Fragment>
                )}
                {props.confirmationType == "archive" && (
                  <Fragment>Are you sure you want to archive this?</Fragment>
                )}
              </div>
            </div>

            <div className="k_cardQuestionDescription k_cardGroup">
              {props.confirmationType == "delete" && (
                <Fragment>
                  Deleting{" "}
                  <span className="font-medium">{props.entityString}</span> will
                  remove it from your Koto project permenantly. This action
                  can't be undone.
                </Fragment>
              )}
              {props.confirmationType == "archive" && (
                <Fragment>
                  Archiving{" "}
                  <span className="font-medium">{props.entityString}</span> will
                  hide it from your Koto project but you can always unarchive it
                  later if you wish.
                </Fragment>
              )}
            </div>
            <div className="flex mt-8">
              <button className="btn flex-initial" onClick={toggleModal}>
                Go back
              </button>
              <div className="flex-grow"></div>
              {props.confirmationType == "delete" && (
                <Fragment>
                  <button
                    className="btn btn-red flex-initial"
                    onClick={props.confirmationFunc}
                  >
                    Delete {props.entityString}
                  </button>
                </Fragment>
              )}
              {props.confirmationType == "archive" && (
                <Fragment>
                  <button
                    className="btn btn-orange flex-initial"
                    onClick={props.confirmationFunc}
                  >
                    Archive {props.entityString}
                  </button>
                </Fragment>
              )}
            </div>
          </div>
        </Overlay>
      )}
    </Fragment>
  );

  const modalElement = document.getElementById("modal-root");

  if (modalElement === null) {
    return <div>NO MODAL ROOT ELEMENT!!!!</div>;
  }

  return createPortal(component, modalElement);
};

export interface SearchModalProps {
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
}

export const SearchModal: FunctionComponent<SearchModalProps> = (props) => {
  const toggleModal = (): void => {
    props.setModalOpen(!props.modalOpen);
  };

  const component = (
    <Fragment>
      {props.modalOpen && (
        <Overlay toggle={toggleModal} position="top">
          <div className="m-2 sm:mx-0 sm:mt-8 sm:max-h-90 sm:w-xl md:w-2xl rounded-lg p-4 bg-white xl:ml-sidebar border border-gray-500 shadow-2xl">
            {/* TODO: Make this search box work */}
            {/* TODO: When open, automatically add focus to input box */}
            <div className="">
              <div className="flex items-center ">
                <div className="flex-1 relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-lg">
                    <i className="fas fa-search text-gray-600 "></i>
                  </div>
                  <input
                    className="form-input pl-10 h-12 sm:text-xl m-0 border-none bg-gray-200 w-full rounded-lg"
                    placeholder="Search"
                  ></input>
                </div>
                <button
                  className="btn btn-no-border flex-initial sm:text-lg ml-2 text-gray-600 rounded-lg my-0"
                  onClick={toggleModal}
                >
                  <span className="hidden md:block">Cancel</span>
                  <span className="block md:hidden">
                    <i className="fas fa-times"></i>
                  </span>
                </button>
              </div>
            </div>

            <div className="pt-4">
              <div className="flex items-center justify-center my-4">
                <img src="assets/Search.svg" className=" mx-auto" />
              </div>

              <div className="flex items-center justify-center text-xl text-gray-500 leading-relaxed my-4 p-4 text-center">
                Search records, forms, reports, and more.
              </div>
            </div>
          </div>
        </Overlay>
      )}
    </Fragment>
  );

  const modalElement = document.getElementById("modal-root");

  if (modalElement === null) {
    return <div>NO MODAL ROOT ELEMENT!!!!</div>;
  }

  return createPortal(component, modalElement);
};

export interface InviteModalProps {
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
  projectName: string;
}

export const InviteModal: FunctionComponent<InviteModalProps> = (props) => {
  // TODO: Needs to take in list of available project groups

  const [isEmailInvite, setIsEmailInvite] = useState(false);
  const [isURLInvite, setIsURLInvite] = useState(false);
  const [hasMessage, setHasMessage] = useState(false);
  const [groupsListOpen, setGroupsListOpen] = useState(false);
  const [hasExpiration, setHasExpiration] = useState(false);

  const toggleGroupsList = (): void => {
    setGroupsListOpen(!groupsListOpen);
  };

  // const toggleModal = (): void => {
  //   props.setModalOpen(!props.modalOpen);
  // };

  // TODO: Replace with actual list of project groups
  const [groupList, setGroupList] = useState([
    { label: "Admins", selected: false, option: "First" },
    { label: "Staff", selected: false, option: "Second" },
    { label: "Volunteers", selected: false, option: "Third" },
    { label: "Project team", selected: false, option: "Fourth" },
  ] as FilterItemType[]);

  const component = (
    <Fragment>
      {props.modalOpen && (
        // TODO: Whenever a button is clicked in this modal, this overlay thinks it should close
        // <Overlay toggle={() => console.log("close overlay")} position="top">
        <div className="mobile-modal sm:max-h-90 sm:w-xl md:w-2xl sm:mx-auto sm:relative sm:rounded p-4 sm:p-8 overflow-visible">
          {isEmailInvite === false && isURLInvite === false && (
            <Fragment>
              <div className="flex items-center">
                <div className="k_cardSectionTitle flex-grow leading-none m-0">
                  Invite others to your project
                </div>
                <button
                  className="btn btn-sm btn-no-border"
                  onClick={() => {
                    props.setModalOpen(false);
                    setIsEmailInvite(false);
                    setIsURLInvite(false);
                  }}
                >
                  <i className="far fa-times mr-2"></i>Close
                </button>
              </div>
              <div className="k_cardQuestionDescription mt-4">
                Choose how you would like to invite people to this project.
              </div>
              <button
                className="btn btn-gray-200 w-full mt-4"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setIsEmailInvite(true);
                }}
              >
                Invite by email
              </button>
              <button
                className="btn btn-gray-200 w-full mt-2"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setIsURLInvite(true);
                }}
              >
                <i className="far fa-link mr-2"></i>Get an Invite Link
              </button>
            </Fragment>
          )}

          {isEmailInvite && (
            <Fragment>
              <div className="flex items-center">
                <div className="k_cardSectionTitle flex-grow leading-none m-0">
                  Invite others to your project
                </div>
                <button
                  className="btn btn-sm btn-no-border"
                  onClick={() => {
                    props.setModalOpen(false);
                    setIsEmailInvite(false);
                    setIsURLInvite(false);
                  }}
                >
                  <i className="far fa-times mr-2"></i>Close
                </button>
              </div>
              <div className="k_cardGroup">
                <div className="k_cardQuestionTitle mt-4">Email addresses</div>
                <div className="k_cardQuestionDescription">
                  We’ll send invitations to join{" "}
                  <span className="font-medium">{props.projectName}</span>
                </div>
                {/* TODO: Automatically add another box if the user clicks into the last one */}
                <input
                  className="form-input k_textInput"
                  placeholder="name@example.com"
                ></input>
                <input
                  className="form-input k_textInput"
                  placeholder="name@example.com"
                ></input>
              </div>

              {!groupsListOpen && (
                <button
                  className="btn btn-gray-200 w-full"
                  onClick={toggleGroupsList}
                >
                  Automatically to groups
                </button>
              )}
              {groupsListOpen && (
                <Fragment>
                  <div className="flex mt-4">
                    <div className="flex-grow k_cardQuestionTitle">Groups</div>
                    <div className="flex-initial italic text-gray-700">
                      Optional
                    </div>
                  </div>
                  <FilterList
                    items={groupList}
                    setItems={setGroupList}
                    extraClassNames=""
                  ></FilterList>
                  {/* TODO: Link to page to manage groups */}
                  <div className="flex items-end mt-1 mb-4">
                    <a className="flex-initial text-sm no-underline">
                      Click here to manage groups
                    </a>
                    <div className="flex-grow"></div>
                    <button
                      className="text-blue-700 hover:text-blue-800 text-sm flex-initial"
                      onClick={() => {
                        setGroupsListOpen(false);
                      }}
                    >
                      <i className="far fa-times mr-2" />
                      Hide
                    </button>
                  </div>
                </Fragment>
              )}

              {hasMessage === false && (
                <button
                  className="btn btn-gray-200 w-full mt-2"
                  onClick={() => setHasMessage(true)}
                >
                  Include a message
                </button>
              )}
              {hasMessage && (
                <Fragment>
                  <div className="flex mt-4">
                    <div className="flex-grow k_cardQuestionTitle">Message</div>
                    <div className="flex-initial italic text-gray-700">
                      Optional
                    </div>
                  </div>
                  <div className="k_cardQuestionDescription">
                    Include a message to make your invites more personal.
                  </div>
                  <textarea
                    className="form-textarea k_textArea"
                    placeholder="Write a message"
                  ></textarea>
                  <div className="flex">
                    <div className="flex-grow"></div>
                    <button
                      className="text-blue-700 hover:text-blue-800 text-sm mt-1"
                      onClick={() => {
                        setHasMessage(false);
                      }}
                    >
                      <i className="far fa-times mr-2" />
                      Hide
                    </button>
                  </div>
                </Fragment>
              )}

              <div className="flex mt-8">
                <button
                  className="flex-initial btn"
                  onClick={() => setIsEmailInvite(false)}
                >
                  Back
                </button>
                <button className="flex-grow"></button>
                <button
                  className="flex-initial btn btn-gray"
                  onClick={() => {
                    props.setModalOpen(false);
                  }}
                >
                  Invite
                </button>
              </div>
            </Fragment>
          )}

          {isURLInvite === true && (
            <Fragment>
              <div className="flex items-center">
                <div className="k_cardSectionTitle flex-grow leading-none m-0">
                  Share an invite link
                </div>
                <button
                  className="btn btn-sm btn-no-border"
                  onClick={() => {
                    props.setModalOpen(false);
                    setIsEmailInvite(false);
                    setIsURLInvite(false);
                  }}
                >
                  <i className="far fa-times mr-2"></i>Close
                </button>
              </div>

              <div className="k_cardQuestionDescription mt-4">
                Anyone can use this link to join{" "}
                <span className="font-medium">{props.projectName}</span> on
                Koto.
              </div>

              {hasExpiration === false && (
                <Fragment>
                  <div className="mt-4 k_cardQuestionTitle">Expiration</div>
                  <div className="flex items-center">
                    <select className="form-select k_select flex-1 border-r-0 rounded-r-none">
                      <option value="1">Expires in 1 day</option>
                      <option value="7">Expires in 7 days</option>
                      <option value="30">Expires in 30 days</option>
                      <option value="never">Never expires</option>
                    </select>
                    {/* TODO: Configure URL with expiration time when clicking this button */}
                    <button
                      className="btn btn-inline flex-initial  rounded-l-none"
                      onClick={() => {
                        setHasExpiration(true);
                      }}
                    >
                      Create invite link
                    </button>
                  </div>
                </Fragment>
              )}

              {hasExpiration && (
                <Fragment>
                  <div className="k_cardQuestionTitle mt-4">
                    Invite link for {props.projectName}
                  </div>
                  <div className="relative">
                    {/* TODO: Prefill with URL */}
                    <input className="form-input k_textInput bg-gray-200 text-gray-600  pr-24 truncate"></input>
                    <button className="btn btn-sm btn-no-border text-blue-500 mr-1 absolute right-0 mt-2">
                      Copy link
                    </button>
                  </div>

                  <div className="mt-4">
                    {/* TOOD: Use expiration date as string and save here */}
                    Expires in <span className="font-medium">[X days]</span>.
                    Need to change this? Deactivate your link and choose a new
                    duration.{" "}
                    <button
                      className="text-blue-700 hover:text-blue-800"
                      onClick={() => {
                        setHasExpiration(false);
                      }}
                    >
                      Deactivate link
                    </button>
                  </div>
                </Fragment>
              )}
              <div className="flex mt-8">
                <button
                  className="flex-initial btn"
                  onClick={() => setIsURLInvite(false)}
                >
                  Back
                </button>
                <button className="flex-grow"></button>
                <button
                  className="flex-initial btn btn-gray"
                  onClick={() => {
                    props.setModalOpen(false);
                  }}
                >
                  Done
                </button>
              </div>
            </Fragment>
          )}
        </div>
        // </Overlay>
      )}
    </Fragment>
  );

  const modalElement = document.getElementById("modal-root");

  if (modalElement === null) {
    return <div>NO MODAL ROOT ELEMENT!!!!</div>;
  }

  return createPortal(component, modalElement);
};
