import JSBI from "jsbi";

export type UserId = string;

export type ProjectId = string; // ws<flake>
export type RecordSetId = string; // rt<flake>
export type RecordId = string; // rd<flake>
export type FormId = string; // fm<flake>
export type BlockId = string; // bk<flake>
export type QuestionSpecId = string; // qs<flake>
export type ReminderId = string; // rm<flake>

export type PermissionsId = string; // pm<flake>
export type MetadataId = string; // md<flake>

export type BlockSpecId = string; // bs<flake>
export type ChangesId = string; // cc<flake>

export type CRDTId = string; // cr<flake>
export type GroupId = string; // gp<flake>

export type IdType =
  | ProjectId
  | RecordId
  | FormId
  | BlockId
  | QuestionSpecId
  | PermissionsId
  | MetadataId
  | BlockSpecId;

// The path of an object is used to provide a namespace and produce a full key
// for a koto object. It doesn't necessarily describe what bucket/table/backend
// an object is stored in.
// The path is also used for identifying the permissions on an Object.
export type ProjectPath = [];
export type RecordPath = [ProjectId];
export type BlockSpecPath = [ProjectId];
export type QuestionSpecPath = [ProjectId, BlockSpecId];
export type FormPath = [ProjectId];
export type BlockPath = [ProjectId, RecordId];
export type QuestionAnswerPath = [ProjectId, RecordId];
export type KMetadataPath = [ProjectId, RecordId, BlockId];
export type ChangeCollectionPath = [UserId];
export type PermissionsPath = [];
export type GroupPath = [];

export type ObPath =
  | ProjectPath
  | RecordPath
  | BlockSpecPath
  | FormPath
  | BlockPath
  | QuestionSpecPath
  | GroupPath
  | PermissionsPath;

export const pathToFullKey = (path: ObPath, id: IdType): string => {
  return (path as string[]).concat([id]).join("-");
};

// Based on SimpleFlakes. 41 bits of timestamp and 23 bits of random bits.

const FLAKE_EPOCH = Date.UTC(2000, 0, 1);
const RANDOMBITS_MASK = Math.pow(2, 23) - 1;
const FLAKE_TIME_BITSHIFT = JSBI.BigInt(23);

const BASE62_BASE_ARRAY = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
  ""
);
// const BASE36_BASE_ARRAY = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

const PLAIN_UID_LENGTH = 11;

export const isValidUid = (uid: string) => {
  return /^[0-9A-Za-z]{11}$/.test(uid);
};

export const isValidPrefixedId = (uid: string) => {
  const prefix = uid.substring(0, 2);
  const baseUid = uid.substring(2);

  return Object.values(PREFIXES).includes(prefix) && isValidUid(baseUid);
};

export const genSimpleFlakeBigInt = (
  timestamp = Date.now(),
  randomBits = -1
): JSBI => {
  if (timestamp < FLAKE_EPOCH) {
    throw new Error("Timestamp has to be after 2000-01-01");
  }

  const flakeTimestamp = JSBI.BigInt(timestamp - FLAKE_EPOCH);

  let flakeRandomBits;
  if (randomBits === -1) {
    const rand = Math.random() * Number.MAX_SAFE_INTEGER;
    flakeRandomBits = JSBI.BigInt(rand & RANDOMBITS_MASK);
  } else {
    flakeRandomBits = JSBI.BigInt(randomBits & RANDOMBITS_MASK);
  }

  const flakeTimePart = JSBI.leftShift(flakeTimestamp, FLAKE_TIME_BITSHIFT);
  const flake = JSBI.bitwiseOr(flakeTimePart, flakeRandomBits);
  return flake;
};

type ParsedFlake = { timestamp: number; randomBits: number };

export const parseFlakeBigInt = (flake: JSBI): ParsedFlake => {
  const timestamp = JSBI.signedRightShift(flake, FLAKE_TIME_BITSHIFT);
  const randomBits = JSBI.bitwiseAnd(flake, JSBI.BigInt(RANDOMBITS_MASK));

  return {
    timestamp: JSBI.toNumber(timestamp) + FLAKE_EPOCH,
    randomBits: JSBI.toNumber(randomBits),
  };
};

// Convert a positive number to base represented by baseArray
export const jsbiToBase = (n: JSBI, baseArray: Array<string>): string => {
  const base = JSBI.BigInt(baseArray.length);
  let digits = "";
  let workingNum = n;
  if (JSBI.equal(n, JSBI.BigInt(0))) {
    digits = "0";
  }
  while (JSBI.greaterThan(workingNum, JSBI.BigInt(0))) {
    digits =
      baseArray[JSBI.toNumber(JSBI.remainder(workingNum, base))] + digits;
    workingNum = JSBI.divide(workingNum, base);
  }
  return digits;
};

// Convert a positive digits represented by baseArray to a JSBI
export const digitsInBaseToJSBI = (
  digits: string,
  baseArray: Array<string>
): JSBI => {
  const base = JSBI.BigInt(baseArray.length);
  let workingNum = JSBI.BigInt(0);

  for (let i = 0; i < digits.length; i++) {
    workingNum = JSBI.add(
      JSBI.multiply(base, workingNum),
      JSBI.BigInt(baseArray.indexOf(digits[i]))
    );
  }

  return workingNum;
};

const bigIntToBase62 = (n: JSBI): string => {
  return jsbiToBase(n, BASE62_BASE_ARRAY);
};

const base62toBigInt = (digits: string): JSBI => {
  return digitsInBaseToJSBI(digits, BASE62_BASE_ARRAY);
};

export const genPlainUid = (parts?: ParsedFlake): string => {
  if (parts === undefined) {
    return bigIntToBase62(genSimpleFlakeBigInt()).padStart(
      PLAIN_UID_LENGTH,
      "0"
    );
  } else {
    return bigIntToBase62(
      genSimpleFlakeBigInt(parts.timestamp, parts.randomBits)
    ).padStart(PLAIN_UID_LENGTH, "0");
  }
};

export const genSessionId = (): string => {
  const rand = Math.round(Math.random() * Number.MAX_SAFE_INTEGER);
  return rand.toString(16);
};

export const parsePlainUID = (id: string): ParsedFlake => {
  const flake = base62toBigInt(id);
  const parsed = parseFlakeBigInt(flake);

  return parsed;
};

export const parseId = (id: string): ParsedFlake => {
  return parsePlainUID(id.slice(2));
};

const PREFIXES = {
  projectId: "pj",
  recordSetId: "rt",
  recordId: "rd",
  formId: "fm",
  blockId: "bk",
  blockSpecId: "bs",
  questionSpecId: "qs",
  metadataId: "md",
  changesId: "ch",
  reminderId: "rm",
};

export const genProjectId = (): ProjectId => {
  return PREFIXES.projectId + genPlainUid();
};

export const genRecordSetId = (): RecordSetId => {
  return PREFIXES.recordSetId + genPlainUid();
};

export const genRecordId = (): RecordId => {
  return PREFIXES.recordId + genPlainUid();
};

export const genFormId = (): FormId => {
  return PREFIXES.formId + genPlainUid();
};

export const genBlockId = (): BlockId => {
  return PREFIXES.blockId + genPlainUid();
};

export const genBlockSpecId = (): BlockSpecId => {
  return PREFIXES.blockSpecId + genPlainUid();
};

export const genQuestionSpecId = (): QuestionSpecId => {
  return PREFIXES.questionSpecId + genPlainUid();
};

export const genMetadataId = (): MetadataId => {
  return PREFIXES.metadataId + genPlainUid();
};

export const genPermissionsId = (): PermissionsId => {
  return "pm" + genPlainUid();
};

export const genChangesId = (): ChangesId => {
  return PREFIXES.changesId + genPlainUid();
};

export const genGroupId = (): GroupId => {
  return "gp" + genPlainUid();
};

export const genReminderId = (): ReminderId => {
  return PREFIXES.reminderId + genPlainUid();
};
