import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { AppThunk } from "./store";
import { KotoOb } from "../types/KotoTypes";
import { User } from "../types/User";
import firebase from "firebase/app";
import { useHistory } from "react-router";

interface AuthUIState {
  isLoadingAuth: boolean;
  isLoggingIn: boolean;
  isLoggedIn: boolean;
}

export interface AuthState {
  ui: AuthUIState;
  firebaseUser: firebase.User | null;
  userData: KotoOb<User> | null;
}

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    ui: {
      isLoadingAuth: true,
      isLoggingIn: true,
      isLoggedIn: false,
    },
    firebaseUser: null,
    userData: null,
  } as AuthState,
  reducers: {
    setFirebaseUser: (state: AuthState, action: PayloadAction<any | null>) => {
      if (action.payload === null) {
        state.ui.isLoggedIn = false;
        state.userData = null;
      } else {
        state.ui.isLoggedIn = true;
      }
      state.firebaseUser = action.payload;
      state.ui.isLoadingAuth = false;
      state.ui.isLoggingIn = false;
    },
    setUserData: (state: AuthState, action: PayloadAction<KotoOb<User>>) => {
      state.userData = action.payload;
    },
    setIsLoading: (state: AuthState, action: PayloadAction<boolean>) => {
      state.ui.isLoadingAuth = action.payload;
    },
    setLoggingIn: (state: AuthState, action: PayloadAction<boolean>) => {
      state.ui.isLoggingIn = action.payload;
    },
  },
});

export const logout = (history: ReturnType<typeof useHistory>): AppThunk => {
  return async (dispatch) => {
    dispatch(authSlice.actions.setFirebaseUser(null));
    await firebase.auth().signOut();
    history.push("/");
  };
};
