import "core-js/stable";
import "regenerator-runtime/runtime";

import * as K from "../types/KotoOb";

import ErrorBoundary, { NoMatch } from "./AppError";
import { KotoContext, createProdContext } from "../types/KotoContext";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppAuthentication } from "./AppAuthentication";
import { AppKotoContext } from "../App/AppKotoContext";
import { AppLoggedInView } from "../App/AppLoggedInView";
// import { AppAuthentication } from "../App/AppAuthentication";
import { AppLoggedOutView } from "./AppLoggedOutView";
import { LoadingAnimation } from "../components/LoadingAnimation";
import { RootState } from "../Store/store";
import { authSlice } from "../Store/authSlice";
import { createSmartStore } from "../Backend/BackingStore/SmartStore";
import firebase from "firebase/app";
// import { createIndexedDbStore } from "../Backend/BackingStore/indexedDbStore";
import { loadOrCreateUser } from "../types/User";
import { makeFirestoreStore } from "../Backend/BackingStore/firestoreStore";
import { useWindowSize } from "@reach/window-size";
import { viewSlice } from "../Store/viewSlice";

interface AppProps {
  db: firebase.firestore.Firestore;
  kContext?: KotoContext;
}

export const App: FunctionComponent<AppProps> = (props) => {
  let mounted = true;
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const [kContext, setKContext] = useState<KotoContext | null>(null);

  const userInfo = useSelector((state: RootState) => {
    return state.auth.firebaseUser;
  });

  useEffect(() => {
    const setupUser = async () => {
      if (userInfo) {
        const fireStoreStore = makeFirestoreStore(userInfo.uid, props.db);
        const store = createSmartStore(
          userInfo.uid,
          [
            K.makeLocalCacheStore(),
            // await createIndexedDbStore(),
          ],
          [fireStoreStore]
        );

        const prodContext =
          props.kContext || createProdContext(userInfo.uid, store);

        const userData = await loadOrCreateUser(prodContext, userInfo);
        dispatch(
          authSlice.actions.setUserData(K.getKotoObFromKotoCRDT(userData))
        );
        if (mounted) {
          setKContext(prodContext);
        }
        dispatch(authSlice.actions.setIsLoading(false));
      }
    };
    setupUser();

    return () => {
      mounted = false;
    };
  }, [userInfo]);

  useEffect(() => {
    dispatch(viewSlice.actions.setIsMobile(width < 1024));
  }, [width]);

  const isLoggedIn = useSelector((state: RootState) => {
    return state.auth.ui.isLoggedIn;
  });

  const isLoggingIn = useSelector((state: RootState) => {
    return state.auth.ui.isLoggingIn;
  });

  const isAuthLoading = useSelector((state: RootState) => {
    return state.auth.ui.isLoadingAuth;
  });

  const notFound = useSelector((state: RootState) => {
    return state.view.notFound;
  });

  return (
    <Fragment>
      <ErrorBoundary>
        {notFound ? (
          <NoMatch />
        ) : (
          <Fragment>
            <div className={isAuthLoading ? "" : "hidden"}>
              <div className="flex items-center justify-center h-screen">
                <LoadingAnimation />
              </div>
            </div>
            <div className={isAuthLoading ? "hidden" : ""}>
              <AppAuthentication />
              <div className={isLoggingIn ? "hidden" : ""}>
                <Fragment>{!isLoggedIn && <AppLoggedOutView />}</Fragment>
                <Fragment>
                  {isLoggedIn && kContext && (
                    <AppKotoContext.Provider value={{ kContext }}>
                      <AppLoggedInView />
                    </AppKotoContext.Provider>
                  )}
                </Fragment>
              </div>
            </div>
          </Fragment>
        )}{" "}
      </ErrorBoundary>
    </Fragment>
  );
};
