import * as K from "../types/KotoOb";

import {
  MobileActionBar,
  FilterMenuItemList,
  Menu,
  MenuItem,
  MenuItemIcon,
  MenuItemList,
  SidebarMenuButton,
} from "../components/Buttons";
import {
  BaseQuestionSpec,
  QuestionAnswer,
  QuestionViewerProps,
} from "../types/Questions";
import {
  ConfirmationModal,
  RecordSetSettingsModal,
  ShareRecordModal,
} from "../components/Modals";
import { FlexibleList, ListUI } from "../components/FlexibleLists";
import {
  FriendlyNamePart,
  Project,
  RecordSet,
  archiveRecordSetFromProject,
  deleteRecordSetFromProject,
  setFriendlyName,
} from "../types/Project";
import {
  IndexedRecord,
  subscribeRecordsList,
} from "../indexing/recordIndexing";
import React, {
  Fragment,
  FunctionComponent,
  FunctionComponentElement,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Record,
  archiveRecord,
  deleteRecord,
  loadRecord,
} from "../types/Record";
import { RecordSetId, parseId } from "../types/IdTypes";
import {
  availableQuestionsSelector,
  loadBlockSpecs,
} from "../Store/blockSpecSlice";
import {
  buildFormFillerPath,
  buildProjectPath,
  buildRecordPath,
  urlParams,
} from "./AppRoutes";
import {
  friendlyColumnNameSelector,
  projectsSlice,
  recordSetSelector,
} from "../Store/projectsSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { AppKotoContext } from "./AppKotoContext";
import { Form } from "../types/Form";
import { KotoCRDT } from "../types/KotoTypes";
import { ListViewNav } from "../components/TopNavigation";
import { NoMatch } from "./AppError";
import { RootState } from "../Store/store";
import { getQuestionTypeConfig } from "../types/QuestionTypeRegistry";
import { loadForms } from "../Store/formListSlice";
import { recordListSlice } from "../Store/recordListSlice";
import { recordSlice } from "../Store/recordSlice";
import { viewSlice } from "../Store/viewSlice";

export const AppRecordsList: FunctionComponent<{}> = (_props) => {
  const { kContext } = useContext(AppKotoContext);
  const { projectId, recordSetId } = useParams<urlParams>();

  const dispatch = useDispatch();
  const history = useHistory();

  const records = useSelector(
    (state: RootState) => state.recordList.recordList,
    shallowEqual
  );

  const recordSetConfig = useSelector(
    recordSetSelector(projectId, recordSetId)
  );

  const friendlyNameSelector = (
    friendlyNameConfig: RecordSet["friendlyName"],
    record: IndexedRecord
  ) => {
    const partResults: FunctionComponentElement<
      QuestionViewerProps<BaseQuestionSpec, QuestionAnswer>
    >[] = [];

    if (friendlyNameConfig) {
      friendlyNameConfig.friendlyParts.forEach((part) => {
        const foundBlocks = Object.values(record.blocks).filter((block) => {
          return block.blockSpec.key === part.blockSpecKey;
        });

        foundBlocks.sort((a, b) => {
          return (
            (b.metadata
              ? b.metadata.lastUpdatedClientTimestamp
              : parseId(b.id).timestamp) -
            (a.metadata
              ? a.metadata.lastUpdatedClientTimestamp
              : parseId(a.id).timestamp)
          );
        });

        const foundBlock = foundBlocks[0];

        if (foundBlock) {
          const questionSpec = foundBlock.blockSpec.doc.questions.find((q) => {
            return q.id === part.questionSpecId;
          });
          const answer = foundBlock.answers[part.questionSpecId];
          if (answer && questionSpec !== undefined) {
            const questionConfig = getQuestionTypeConfig(questionSpec.type);
            if (questionSpec) {
              partResults.push(
                React.createElement(questionConfig.questionViewer, {
                  spec: questionSpec,
                  answer: answer,
                  key: questionSpec.id,
                })
              );
            }
          }
        }
      });
    }

    return partResults;
  };

  useEffect(() => {
    const unSub = subscribeRecordsList(
      kContext,
      projectId,
      recordSetId,
      false,
      false,
      (results) => {
        if (results.type !== "Success") {
          console.log("Failed to load records. ", results);
          return;
        }

        dispatch(recordListSlice.actions.loadList(results.data));
      },
      (err) => {
        console.error(err);
      }
    );

    dispatch(loadBlockSpecs(kContext, projectId));

    return () => {
      dispatch(recordListSlice.actions.loadList([]));
      unSub();
    };
  }, [projectId, recordSetId]);

  // let ui = useSelector((state: RootState) => state.recordList.ui);

  const blockSpecsMap = useSelector((state: RootState) => {
    return state.blockSpecSlice.blockSpecMap;
  });

  // Temporarily repurposing the friendly column name functionality to hard code
  // using the first created question.

  let friendlyColumnName: string | undefined;
  let friendlyNameConfig: RecordSet["friendlyName"];

  if (kContext.config.useFirstQuestionAsFriendlyName) {
    const blockSpecs = Object.values(blockSpecsMap);

    const firstBlockSpec = blockSpecs.sort((a, b) => {
      return a.createdTimeInMilliseconds - b.createdTimeInMilliseconds;
    })[0];

    if (firstBlockSpec) {
      const firstQuestion = firstBlockSpec.doc.questions[0];
      if (firstQuestion) {
        friendlyColumnName = firstQuestion.label.toString();
        friendlyNameConfig = {
          hideNotice: true,
          friendlyParts: [
            {
              blockSpecKey: firstBlockSpec.key,
              questionSpecId: firstQuestion.id,
            },
          ],
        };
      }
    }
  } else {
    friendlyColumnName =
      useSelector(friendlyColumnNameSelector(projectId, recordSetId)) ?? "Name";

    friendlyNameConfig = recordSetConfig.friendlyName;
  }

  const ui: ListUI = {
    headers: ["id", "lastUpdated"],
    friendlyHeaderNames: {
      lastUpdated: "Last Updated",
      id: "Record ID",
    },
    primaryHeaders: { primary: "id", secondary: "lastUpdated" },
    selections: {},
  };

  if (friendlyColumnName) {
    ui.headers.splice(0, 0, "friendlyName");
    ui.friendlyHeaderNames.friendlyName = friendlyColumnName;
    ui.primaryHeaders.primary = "friendlyName";
  }

  const currentProject = useSelector((state: RootState) => {
    return state.projects.projects[projectId];
  });

  if (!(recordSetId in currentProject.crdt.recordSets)) {
    return <NoMatch />;
  }

  const availableForms = useSelector(
    (state: RootState) => state.formList.formsList
  );

  const validForms: KotoCRDT<Form>[] = currentProject.crdt.recordSets[
    recordSetId
  ].validForms
    ? availableForms.filter((form) => {
        return (
          currentProject.crdt.recordSets[recordSetId].validForms?.findIndex(
            (val) => {
              return form.key === val;
            }
          ) !== -1
        );
      })
    : availableForms;

  useEffect(() => {
    dispatch(loadForms(kContext, projectId));
    //   dispatch(loadBlockSpecs(kContext, projectId));
  }, [projectId, kContext]);

  const [recordSetSettingsModalOpen, setRecordSetSettingsModalOpen] = useState(
    false
  );

  const resultsString = "Showing 0 results";

  const recordSetNotFound = currentProject
    ? currentProject.crdt.recordSets[recordSetId] == undefined
    : false;

  let disableButton: boolean;
  if (records.length === 0) {
    disableButton = true;
  } else {
    disableButton = false;
  }

  const [recordMenuOpen, setRecordMenuOpen] = useState(false);
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);

  const [modalInfo, setModalInfo] = useState({
    open: false,
    setOpen: (val: boolean) => setModalInfo({ ...modalInfo, open: val }),
    modalType: "",
    entityString: "",
    confirmationFunc: () => {},
  });

  const availabelQuestions = useSelector(availableQuestionsSelector);
  // useEffect(() => {
  //   dispatch(loadBlockSpecs(kContext, projectId));
  // }, [kContext, projectId]);

  const handleSetFriendlyName = async (parts: FriendlyNamePart[]) => {
    if (currentProject === null) {
      console.log("Project not loaded yet, can't set friendly name.");
      return;
    }

    const newProject = setFriendlyName(
      kContext,
      currentProject,
      recordSetId,
      parts.map((part) => {
        return {
          blockSpecKey: part.blockSpecKey,
          questionSpecId: part.questionSpecId,
        };
      })
    );
    dispatch(projectsSlice.actions.updateProject(newProject));
    await K.storeCrdt(kContext, newProject);
    return;
  };

  const createDeleteRecordHandler = (record: Record) => {
    return async () => {
      setModalInfo({
        open: true,
        setOpen: modalInfo.setOpen,
        modalType: "delete",
        entityString: record.id,
        confirmationFunc: async () => {
          const result = await loadRecord(
            kContext,
            record.projectId,
            record.id
          );
          if (result.type !== "Success") {
            console.log(
              `Could not load record ${
                record.id
              } for archiving. ${JSON.stringify(result)}`
            );
            return;
          }
          const recordCRDT = result.data;
          const deletedForm = deleteRecord(kContext, recordCRDT);
          await K.storeCrdt(kContext, deletedForm);
          modalInfo.setOpen(false);
        },
      });
    };
  };

  const createArchiveRecordHandler = (record: Record) => {
    return async () => {
      setModalInfo({
        open: true,
        setOpen: modalInfo.setOpen,
        modalType: "archive",
        entityString: record.id,
        confirmationFunc: async () => {
          const result = await loadRecord(
            kContext,
            record.projectId,
            record.id
          );
          if (result.type !== "Success") {
            console.log(
              `Could not load record ${
                record.id
              } for archiving. ${JSON.stringify(result)}`
            );
            return;
          }
          const recordCRDT = result.data;
          const archivedForm = archiveRecord(kContext, recordCRDT);
          await K.storeCrdt(kContext, archivedForm);
          modalInfo.setOpen(false);
        },
      });
    };
  };

  const createRecordHistoryHandler = (record: Record) => {
    return async () => {
      dispatch(recordSlice.actions.setRecordView("history"));
      history.push(
        buildRecordPath(record.projectId, record.recordSetId, record.id)
      );
    };
  };

  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareModalActiveTabLabel, setShareModalActiveTabLabel] = useState(
    "url"
  );
  const [shareModalRecordUrl, setShareModalRecordUrl] = useState("");

  const createRecordShareHandler = (record: Record) => {
    return async () => {
      setShareModalOpen(true);
      setShareModalRecordUrl(
        buildRecordPath(record.projectId, record.recordSetId, record.id, true)
      );
    };
  };

  const createDeleteRecordSetHandler = (recordSetId: RecordSetId) => {
    return async () => {
      const projCrdt = currentProject;

      setModalInfo({
        open: true,
        setOpen: modalInfo.setOpen,
        modalType: "delete",
        entityString:
          currentProject.crdt.recordSets[recordSetId].name.toString() ||
          "Default Record Set",
        confirmationFunc: async () => {
          const editedProject = deleteRecordSetFromProject(
            kContext,
            projCrdt,
            recordSetId
          );
          K.storeCrdt(kContext, editedProject);
          dispatch(projectsSlice.actions.updateProject(editedProject));
          history.push(buildProjectPath(projectId));
        },
      });
    };
  };

  const createArchiveRecordSetHandler = (recordSetId: RecordSetId) => {
    return async () => {
      const projCrdt = currentProject;

      setModalInfo({
        open: true,
        setOpen: modalInfo.setOpen,
        modalType: "archive",
        entityString:
          currentProject.crdt.recordSets[recordSetId].name.toString() ||
          "Default Record Set",
        confirmationFunc: async () => {
          const editedProject = archiveRecordSetFromProject(
            kContext,
            projCrdt,
            recordSetId
          );
          K.storeCrdt(kContext, editedProject);
          dispatch(projectsSlice.actions.updateProject(editedProject));
          history.push(buildProjectPath(projectId));
        },
      });
    };
  };

  const handleSetProject = async (updatedProject: KotoCRDT<Project>) => {
    await K.storeCrdt(kContext, updatedProject);
    dispatch(projectsSlice.actions.updateProject(updatedProject));
  };

  return (
    <Fragment>
      {recordSetNotFound ? (
        <div>
          <div>NO RECORDSET FOUND</div>
        </div>
      ) : (
        currentProject && (
          <Fragment>
            <ListViewNav
              title={
                currentProject.crdt.recordSets[recordSetId].name.toString() ||
                "Default Record Set"
              }
              results={resultsString}
              setSideNavExpanded={() => {
                dispatch(viewSlice.actions.setNavVisible(true));
              }}
            >
              <Menu menuOpen={recordMenuOpen} setMenuOpen={setRecordMenuOpen}>
                <button
                  className="btn btn-teal mr-2"
                  disabled={false}
                  data-tooltip="Add a record"
                  tooltip-position="bottom"
                >
                  <i className="fa-fw fas fa-plus"></i>
                  <span className="hidden lg:inline-block ml-2">
                    Add a Record
                  </span>
                </button>

                <FilterMenuItemList
                  position="bottom-right"
                  extraClassNames="sm:min-w-xs text-left"
                >
                  {/* TODO: If num of forms == 1, change the add record button to just open that form */}
                  {validForms.map((form) => {
                    return (
                      <MenuItem
                        key={form.key}
                        linkTo={buildFormFillerPath(
                          projectId,
                          form.crdt.id,
                          recordSetId
                        )}
                      >
                        {form.crdt.title.toString()}
                      </MenuItem>
                    );
                  })}

                  <MenuItem
                    extraClassNames="border-t border-gray-300 text-sm text-blue-500 font-medium text-center"
                    onClick={() => {
                      setRecordSetSettingsModalOpen(true);
                    }}
                  >
                    Edit list
                  </MenuItem>
                </FilterMenuItemList>
              </Menu>
              <div>
                <button
                  className="btn btn-gray-100 btn-no-shadow btn-stacked-h"
                  disabled={disableButton}
                  data-tooltip="Filter list"
                  tooltip-position="bottom"
                >
                  <i className="fa-fw fal fa-sliders-v "></i>
                  <span className="hidden lg:inline-block ml-2">Filter</span>
                </button>
                <button
                  className="btn btn-gray-100 btn-no-shadow btn-stacked-h mr-2"
                  disabled={disableButton}
                  data-tooltip="Sort list"
                  tooltip-position="bottom"
                >
                  <i className="fa-fw far fa-sort-amount-down-alt "></i>
                  <span className="hidden lg:inline-block ml-2">Sort</span>
                </button>
              </div>
              <Menu
                menuOpen={moreMenuOpen}
                setMenuOpen={setMoreMenuOpen}
                extraClassNames="inline-block text-left"
              >
                <button className="btn btn-gray-100 btn-no-shadow">
                  <i className="fa-fw far fa-ellipsis-h"></i>
                </button>
                <MenuItemList position="bottom-right">
                  <MenuItem
                    onClick={() => {
                      setRecordSetSettingsModalOpen(true);
                      setMoreMenuOpen(false);
                    }}
                  >
                    <MenuItemIcon fontAwesomeClassNames="far fa-cog" />
                    Record set settings
                  </MenuItem>

                  <MenuItem
                    extraClassNames="border-t border-gray-300"
                    onClick={createArchiveRecordSetHandler(recordSetId)}
                  >
                    <MenuItemIcon fontAwesomeClassNames="far fa-archive" />
                    Archive
                  </MenuItem>

                  <MenuItem
                    danger={true}
                    onClick={createDeleteRecordSetHandler(recordSetId)}
                  >
                    <MenuItemIcon fontAwesomeClassNames="far fa-trash-alt" />
                    Delete
                  </MenuItem>
                </MenuItemList>
              </Menu>
              {/* TODO: Need to pass available forms into this modal so that they can be shown in the "Add a record button" settings */}
              <RecordSetSettingsModal
                kContext={kContext}
                modalOpen={recordSetSettingsModalOpen}
                projectCrdt={currentProject}
                setProject={handleSetProject}
                recordSetId={recordSetId}
                setModalOpen={setRecordSetSettingsModalOpen}
                availableForms={availableForms}
                availableQuestions={availabelQuestions}
                setFriendlyName={handleSetFriendlyName}
              ></RecordSetSettingsModal>
            </ListViewNav>
            <MobileActionBar extraClassNames="md:hidden">
              <SidebarMenuButton
                extraClassNames="flex-1"
                setSideNavExpanded={() => {
                  dispatch(viewSlice.actions.setNavVisible(true));
                }}
              >
                <i className="fa-fw fas fa-bars" />
                <div className="block">Menu</div>
              </SidebarMenuButton>

              <button className="btn btn-teal flex-1">
                <Menu
                  menuOpen={recordMenuOpen}
                  setMenuOpen={setRecordMenuOpen}
                  extraClassNames="flex-auto"
                >
                  <div className="flex-auto">
                    <i className="fa-fw fas fa-plus" />
                    <div className="block">Add</div>
                  </div>
                  <FilterMenuItemList
                    position="top-left"
                    extraClassNames="sm:min-w-xs text-left text-base"
                  >
                    {/* TODO: If num of forms == 1, change the add record button to just open that form */}
                    {availableForms.map((form) => {
                      return (
                        <MenuItem
                          key={form.key}
                          linkTo={`/p/${projectId}/forms/fill/${form.crdt.id}/${recordSetId}`}
                        >
                          {form.crdt.title.toString()}
                        </MenuItem>
                      );
                    })}

                    <MenuItem
                      extraClassNames="border-t border-gray-300 text-sm text-blue-500 font-medium text-center"
                      onClick={() => {
                        setRecordSetSettingsModalOpen(true);
                      }}
                    >
                      Edit list
                    </MenuItem>
                  </FilterMenuItemList>
                </Menu>
              </button>

              <button
                className="btn btn-ab flex-1 ml-2"
                disabled={disableButton}
              >
                <Menu
                  menuOpen={moreMenuOpen}
                  setMenuOpen={setMoreMenuOpen}
                  extraClassNames="inline-block text-left"
                >
                  <div className="flex-auto text-center">
                    <i className="fa-fw far fa-ellipsis-h" />
                    <div className="block">More</div>
                  </div>

                  <MenuItemList
                    position="top-right"
                    extraClassNames="text-base"
                  >
                    <MenuItem
                      onClick={() => {
                        setRecordSetSettingsModalOpen(true);
                        setMoreMenuOpen(false);
                      }}
                    >
                      <MenuItemIcon fontAwesomeClassNames="far fa-cog" />
                      Record set settings
                    </MenuItem>

                    <MenuItem
                      extraClassNames="border-t border-gray-300"
                      onClick={createArchiveRecordSetHandler(recordSetId)}
                    >
                      <MenuItemIcon fontAwesomeClassNames="far fa-archive" />
                      Archive
                    </MenuItem>

                    <MenuItem
                      danger={true}
                      onClick={createDeleteRecordSetHandler(recordSetId)}
                    >
                      <MenuItemIcon fontAwesomeClassNames="far fa-trash-alt" />
                      Delete
                    </MenuItem>
                  </MenuItemList>
                </Menu>
              </button>
            </MobileActionBar>
            {records.length === 0 && (
              <Fragment>
                <div className="w-11/12 mx-auto text-center my-4 px-8 py-20 border border-gray-300 rounded bg-white">
                  <div className="">
                    <img
                      src="assets/EmptyRecords.svg"
                      className="w-32 mx-auto mb-4"
                    />
                  </div>

                  <h1 className="font-bold text-3xl text-violet-500 leading-loose mx-auto">
                    No Records Yet
                  </h1>

                  <h2 className="font-normal text-xl text-gray-600 max-w-full sm:max-w-xl mx-auto">
                    This is where you'll see records created by forms.
                  </h2>
                </div>
              </Fragment>
            )}
            {records.length > 0 && (
              // TODO: Need to be able to set danger prop on menu items
              // TODO: Need to be able to open modal when clicking on menu items
              <FlexibleList
                listItems={records.map((indexedRecord) => {
                  const record = indexedRecord.record;
                  return {
                    ob: {
                      friendlyName: friendlyNameSelector(
                        friendlyNameConfig,
                        indexedRecord
                      ),
                      id: record.id,
                      lastUpdated: record.metadata
                        ? new Date(
                            record.metadata.lastUpdatedClientTimestamp
                          ).toString()
                        : "",
                    },
                    link: buildRecordPath(projectId, recordSetId, record.id),
                  };
                })}
                ui={ui}
                setUi={(ui) => dispatch(recordListSlice.actions.setUi(ui))}
                menuItemMap={records
                  .map((record) => {
                    return {
                      [record.record.id]: [
                        {
                          iconClass: "fa-eye",
                          text: "View",
                          action: buildRecordPath(
                            projectId,
                            recordSetId,
                            record.record.id
                          ),
                        },
                        {
                          iconClass: "fa-pencil",
                          text: "Edit",
                          action: `#`,
                        },
                        {
                          iconClass: "fa-share",
                          text: "Share",
                          action: createRecordShareHandler(record.record),
                        },
                        {
                          iconClass: "fa-history",
                          text: "History",
                          action: createRecordHistoryHandler(record.record),
                        },
                        {
                          iconClass: "fa-archive",
                          text: "Archive",
                          action: createArchiveRecordHandler(record.record),
                        },
                        {
                          iconClass: "fa-trash-alt",
                          text: "Delete",
                          action: createDeleteRecordHandler(record.record),
                        },
                      ],
                    };
                  })
                  .reduce((prev, next) => {
                    return Object.assign(prev, next);
                  }, {})}
              />
            )}
          </Fragment>
        )
      )}

      <ConfirmationModal
        modalOpen={modalInfo.open}
        setModalOpen={modalInfo.setOpen}
        confirmationType={modalInfo.modalType}
        entityString={modalInfo.entityString}
        confirmationFunc={modalInfo.confirmationFunc}
      ></ConfirmationModal>

      <ShareRecordModal
        title="Share record"
        modalOpen={shareModalOpen}
        setModalOpen={setShareModalOpen}
        activeView={shareModalActiveTabLabel}
        selectTab={(label: string) => {
          setShareModalActiveTabLabel(label);
        }}
        url={shareModalRecordUrl}
      ></ShareRecordModal>
    </Fragment>
  );
};
