import React, {
  FunctionComponent,
  Fragment,
  ReactNode,
  ReactElement,
} from "react";

interface CardProps {
  isActive: boolean;
  isStacked: boolean;
}

export const Card: FunctionComponent<CardProps> = (props) => {
  let className = "k_card mb-4";
  if (props.isStacked) {
    className = "k_card k_cardStacked";
  }

  return <div className={className}>{props.children}</div>;
};

interface ExpanderCollapsedDesciptionProps {
  children?: ReactNode;
}
export const ExpanderCollapsedDescription: FunctionComponent<ExpanderCollapsedDesciptionProps> = (
  props
) => {
  let className = "";
  if (props.children) {
    className = "mt-4";
  }
  return (
    <Fragment>
      <div className={className}>{props.children}</div>
    </Fragment>
  );
};

interface ExpanderBodyProps {
  children: ReactNode;
}
export const ExpanderBody: FunctionComponent<ExpanderBodyProps> = (props) => {
  return (
    <Fragment>
      <div className="mt-4">{props.children}</div>
    </Fragment>
  );
};

interface ExpanderProps {
  hideControl: boolean;
  isExpanded: boolean;
  toggleExpanded?: () => void;
  title: string;
  children: [
    ReactElement<ExpanderCollapsedDesciptionProps>,
    ReactElement<ExpanderBodyProps>
  ];
}

export const Expander: FunctionComponent<ExpanderProps> = (props) => {
  let controlClassName =
    "flex-initial btn py-1 px-2 rounded border bg-white  font-medium text-sm";
  let buttonLabel = "Expand";

  if (props.isExpanded == true) {
    buttonLabel = "Close";
  }

  if (props.hideControl) {
    controlClassName = "hidden";
  }

  const expanderCollapsedView = props.children[0];
  const expanderBody = props.children[1];

  return (
    <Fragment>
      <div className="k_card k_cardStacked">
        <div className="flex items-end">
          <div className="k_cardQuestionTitle flex-grow ">{props.title}</div>
          <button className={controlClassName} onClick={props.toggleExpanded}>
            {buttonLabel}
          </button>
        </div>
        {expanderCollapsedView}
        {props.isExpanded && expanderBody}
      </div>
    </Fragment>
  );
};

interface ExpanderContentProps {}

export const ExpanderContent: FunctionComponent<ExpanderContentProps> = (
  props
) => {
  return <div>{props.children}</div>;
};
