import React, {
  FunctionComponent,
  Fragment,
  ChangeEvent,
  KeyboardEvent,
  useState,
  useEffect,
  useRef,
} from "react";
import { registerQuestionType } from "../../types/QuestionTypeRegistry";

import {
  QuestionDesignProps,
  QuestionFillerProps,
  QuestionViewerProps,
  BaseQuestionSpec,
  Choices,
  ChoicePart,
  QuestionAnswer,
  createBaseQuestionSpec,
} from "../../types/Questions";
import * as K from "../../types/KotoOb";

export interface MultipleChoiceQuestionSpec extends BaseQuestionSpec {
  parts: { choice: ChoicePart };
}

interface MultipleChoiceAnswer extends QuestionAnswer {
  parts: { choice: string };
}

const createMultipleChoiceQuestionSpec = (
  choices: Choices = [],
  defaultChoice: string = "",
  allowOther: boolean = false
): MultipleChoiceQuestionSpec => {
  return {
    ...createBaseQuestionSpec("multipleChoiceQuestion"),
    parts: {
      choice: {
        default: defaultChoice,
        choices: choices,
        allowOther: allowOther,
      },
    },
  };
};

const createMultipleChoiceAnswer = (
  spec: MultipleChoiceQuestionSpec
): MultipleChoiceAnswer => {
  return {
    type: "multipleChoiceAnswer",
    isAnswered: false,
    parts: { choice: spec.parts.choice.default },
  };
};

export const makeChoiceAdder = (label?: string) => {
  return (question: MultipleChoiceQuestionSpec) => {
    if (question.type !== "multipleChoiceQuestion") {
      throw new Error(
        `Question must be of type 'multipleChoiceQuestion' but got '${question.type}' instead.`
      );
    }

    const choiceCount = question.parts.choice.choices.length + 1;
    question.parts.choice.choices.push(
      new K.KotoText(label || `Option ${choiceCount}`)
    );
  };
};

export const makeChoiceRemover = (choiceNum: number) => {
  return (question: MultipleChoiceQuestionSpec) => {
    question.parts.choice.choices.deleteAt!(choiceNum);
  };
};

export const makeChoiceTextUpdater = (choiceNum: number, text: string) => {
  return (question: MultipleChoiceQuestionSpec) => {
    K.performUpdateText(question.parts.choice.choices[choiceNum], text);
  };
};

const MultipleChoiceQuestionPartsDesigner: FunctionComponent<
  QuestionDesignProps<MultipleChoiceQuestionSpec>
> = (props) => {
  const [activeInput, setActiveInput] = useState(-1);

  const currentInput = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (
      currentInput.current &&
      currentInput.current !== document.activeElement
    ) {
      currentInput.current.focus();
      currentInput.current.select();
    }
  });

  const updateChoiceText = (
    idx: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    props.setQuestion(makeChoiceTextUpdater(idx, event.target.value));
  };

  const removeChoice = (idx: number) => {
    props.setQuestion(makeChoiceRemover(idx));
  };

  const addChoice = () => {
    setActiveInput(props.questionSpec.parts.choice.choices.length);
    props.setQuestion(makeChoiceAdder());
  };

  const handleKeyPress = (
    idx: number,
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    const choiceCount = props.questionSpec.parts.choice.choices.length;
    if (event.key == "Enter") {
      if (idx === choiceCount - 1) {
        addChoice();
      } else {
        setActiveInput(idx + 1);
      }
    } else if (event.key == "Backspace") {
      if (event.currentTarget.value == "") {
        removeChoice(idx);
        setActiveInput(idx - 1);
      }
    }
  };

  return (
    <Fragment>
      {props.questionSpec.parts.choice.choices.map((choice, idx) => {
        return (
          <div className="mx-5 my-1 first:mt-5 flex" key={idx}>
            <div className="text-gray-800 mt-2 pr-3">
              <input
                type="radio"
                className="form-radio border border-gray-400 bg-gray-100"
                disabled={true}
              ></input>
            </div>
            <input
              onChange={(e) => updateChoiceText(idx, e)}
              className="form-input k_textInput flex-1 bg-white py-2 px-3"
              value={choice.toString()}
              ref={activeInput === idx ? currentInput : undefined}
              onBlur={() => setActiveInput(-1)}
              onKeyDown={(e) => handleKeyPress(idx, e)}
            ></input>
            <div className="flex-initial w-10 text-center">
              {" "}
              <button
                className="text-gray-500 hover:text-gray-800 mt-2"
                onClick={() => removeChoice(idx)}
                data-tooltip="Remove option"
                tooltip-position="bottom"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        );
      })}

      <div className="mx-5 my-1 first:mt-5 flex">
        <div className="text-gray-800 mt-2 pr-3">
          <input
            type="radio"
            className="form-radio border border-gray-400 bg-gray-100"
            disabled={true}
          ></input>
        </div>
        <input
          onClick={() => {
            addChoice();
          }}
          className="form-input k_textInput flex-1 bg-white py-2 px-3"
          placeholder="Add an option"
          value={""}
          readOnly={true}
        ></input>
        <div className="flex-initial w-10 text-center"></div>
      </div>
    </Fragment>
  );
};

const MultipleChoiceQuestionPartsFiller: FunctionComponent<
  QuestionFillerProps<MultipleChoiceQuestionSpec, MultipleChoiceAnswer>
> = (props) => {
  const updateChoice = (value: string) => {
    props.setAnswer((_, answer) => {
      answer.isAnswered = true;
      answer.parts.choice = value;
    });
  };

  return (
    <Fragment>
      <div className="">
        {props.spec.parts.choice.choices.map((choice, idx) => {
          const itemId = `${props.spec.id}.${idx}`;
          const label = choice;
          const value = choice;
          const name = `${props.spec.id}.question`;
          return (
            <Fragment key={idx}>
              <label className="k_choiceLabel" htmlFor={itemId} key={itemId}>
                <input
                  type="radio"
                  className="form-radio k_choiceInput"
                  name={name}
                  disabled={false}
                  id={itemId}
                  value={value}
                  onChange={() => updateChoice(value.toString())}
                />
                <div className="k_choiceText">{label}</div>
              </label>
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
};

const MultipleChoiceAnswerViewer: FunctionComponent<
  QuestionViewerProps<MultipleChoiceQuestionSpec, MultipleChoiceAnswer>
> = (props) => {
  return <div>{props.answer.parts.choice}</div>;
};

registerQuestionType(
  "multipleChoiceQuestion",
  "Multiple Choice Question",
  createMultipleChoiceQuestionSpec,
  createMultipleChoiceAnswer,
  MultipleChoiceQuestionPartsDesigner,
  MultipleChoiceQuestionPartsFiller,
  MultipleChoiceAnswerViewer
);
