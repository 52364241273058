import React, { FunctionComponent } from "react";
import * as immer from "immer";

export interface CheckboxItemType {
  label: string;
  selected: boolean;
  [x: string]: any;
}

interface CheckboxProps {
  item: CheckboxItemType;
  toggleChecked: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxListItem: FunctionComponent<CheckboxProps> = (props) => {
  return (
    <label className="block font-light">
      <input
        type="checkbox"
        className="form-checkbox rounded border border-gray-400  mr-4 mb-1 mt-1 float-left"
        checked={props.item.selected}
        onChange={props.toggleChecked}
      ></input>
      <span className="block ml-8">{props.item.label}</span>
    </label>
  );
};

interface CheckboxListProps {
  checklist: CheckboxItemType[];
  setChecklist: (newList: CheckboxItemType[]) => void;
}

export const toggleItemInCheckboxList = <T extends CheckboxItemType>(
  checklist: T[],
  setCheckboxesList: (newList: T[]) => void,
  index: number
): void => {
  const newCheckboxList = immer.produce(checklist, (draft) => {
    draft[index].selected = !draft[index].selected;
  });
  setCheckboxesList(newCheckboxList);
};

export const CheckboxList: FunctionComponent<CheckboxListProps> = (props) => {
  return (
    <>
      {props.checklist.map((item, index) => {
        return (
          <CheckboxListItem
            key={index}
            item={item}
            toggleChecked={() =>
              toggleItemInCheckboxList(
                props.checklist,
                props.setChecklist,
                index
              )
            }
          />
        );
      })}
    </>
  );
};
