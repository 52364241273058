import React, { FunctionComponent, Fragment } from "react";
import { Card } from "../Card";

interface BillingProps {
  // TODO: Add activeSection to scroll to and expand a specific section of the settings
  //   activeSection: string;
}

// TODO: Replace div containing fake user icon with real user icons and
// placeholder images
export const Billing: FunctionComponent<BillingProps> = (_props) => {
  return (
    <Fragment>
      <div className="my-8"></div>
      <Card isActive={false} isStacked={false}>
        <div className="k_Card">
          <div className="k_cardQuestionTitle">Billing</div>
          <div className="k_cardQuestionDescription">
            Torquent lacinia diam phasellus cubilia proin augue scelerisque
            litora consequat faucibus luctus magna himenaeos bibendum fames
            mattis lacus tristique a blandit efficitur pellentesque non
            imperdiet elit nibh sollicitudin habitasse sed taciti auctor etiam
            nulla est commodo inceptos vel hendrerit primis interdum turpis
            feugiat sociosqu montes arcu fringilla finibus aptent integer
          </div>
        </div>
      </Card>
    </Fragment>
  );
};
