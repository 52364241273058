import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListUI } from "../components/FlexibleLists";
import { IndexedRecord } from "../indexing/recordIndexing";

export interface RecordListSlice {
  recordList: IndexedRecord[];
  ui: ListUI;
}

export const recordListSlice = createSlice({
  name: "recordList",
  initialState: {
    recordList: [],
    ui: {
      headers: ["name", "lastUpdated", "id"],
      friendlyHeaderNames: {
        name: "Name",
        lastUpdated: "Last Updated",
        id: "Record ID",
      },
      primaryHeaders: { primary: "id", secondary: "lastUpdated" },
      selections: {},
    },
  } as RecordListSlice,
  reducers: {
    loadList: (state, action: PayloadAction<IndexedRecord[]>) => {
      state.recordList = action.payload;
    },
    setUi: (state, action: PayloadAction<ListUI>) => {
      state.ui = action.payload;
    },
  },
});
