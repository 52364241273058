import React, {
  Component,
  ErrorInfo,
  Fragment,
  FunctionComponent,
  ReactNode,
} from "react";

import { AppHeaderBar } from "./AppHeader";
import { useLocation } from "react-router";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Fragment>
          <AppHeaderBar />
          <div className="w-11/12 mx-auto text-center my-4 px-8 py-20 border border-gray-300 rounded bg-white">
            <div className="">
              <img src="assets/404.svg" className="w-32 mx-auto mb-4" />
            </div>

            <h2 className="font-normal text-xl text-gray-600 max-w-full mx-auto">
              Oops! There was an error.
            </h2>
            <p className="font-normal text-lg text-gray-500 font-mono max-w-full mx-auto my-8">
              <code>{location.href}</code>
            </p>
            {/* TODO: Add actual URL for homepage here */}
            <a href="" className="btn btn-violet no-underline ">
              Go to Homepage
            </a>
          </div>
        </Fragment>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

export const NoMatch: FunctionComponent<{}> = (_props: any) => {
  const location = useLocation();

  return (
    <Fragment>
      <AppHeaderBar />
      <div className="w-11/12 mx-auto text-center my-4 px-8 py-20 border border-gray-300 rounded bg-white">
        <div className="">
          <img src="assets/404.svg" className="w-32 mx-auto mb-4" />
        </div>

        <h1 className="font-bold text-3xl text-violet-500 leading-loose mx-auto">
          404
        </h1>

        <h2 className="font-normal text-xl text-gray-600 max-w-full mx-auto">
          Oops! The page you are looking for does not exist. The link to this
          page may be out-of-date or does not exist.
        </h2>
        <p className="font-normal text-lg text-gray-500 font-mono max-w-full mx-auto my-8">
          <code>www.koto.team{location.pathname}</code>
        </p>
        {/* TODO: Add actual URL for homepage here */}
        <a href="" className="btn btn-violet no-underline ">
          Go to Homepage
        </a>
      </div>
    </Fragment>
  );
};
