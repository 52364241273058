import {
  MobileActionBar,
  Menu,
  SidebarMenuButton,
} from "../components/Buttons";
import { FlexibleList, ListUI } from "../components/FlexibleLists";
import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { buildFormFillerForRecordPath, urlParams } from "./AppRoutes";
import { useDispatch, useSelector } from "react-redux";

import { AppKotoContext } from "./AppKotoContext";
import { ListViewNav } from "../components/TopNavigation";
import { RootState } from "../Store/store";
import { getFormInfoFromKey } from "../types/Form";
import { loadForms } from "../Store/formListSlice";
import { recordSlice } from "../Store/recordSlice";
import { reminderListSlice } from "../Store/remindersListSlice";
import { subscribeRemindersForProject } from "../types/Reminder";
import { useParams } from "react-router";
import { viewSlice } from "../Store/viewSlice";

export const AppRemindersList: FunctionComponent<{}> = (_props) => {
  const { kContext } = useContext(AppKotoContext);
  const { projectId } = useParams<urlParams>();

  const dispatch = useDispatch();

  useEffect(() => {
    const unSub = subscribeRemindersForProject(
      kContext,
      projectId,
      (remindersResults) => {
        if (remindersResults.type === "Error") {
          throw new Error(`Could not load reminders for ${projectId}.`);
        } else if (remindersResults.type === "NotFound") {
          dispatch(reminderListSlice.actions.loadList([]));
        } else {
          dispatch(reminderListSlice.actions.loadList(remindersResults.data));
        }
      },
      (err) => {
        console.error("Could not subscribe to reminders. ", err.message);
      }
    );

    return () => {
      dispatch(recordSlice.actions.setReminderList([]));
      unSub();
    };
  }, [projectId]);

  const reminders = useSelector(
    (state: RootState) => state.remindersList.reminderList
  );

  const availableForms = useSelector(
    (state: RootState) => state.formList.formsByKey
  );

  useEffect(() => {
    dispatch(loadForms(kContext, projectId));
    //   dispatch(loadBlockSpecs(kContext, projectId));
  }, [projectId, kContext]);

  const currentProject = useSelector((state: RootState) => {
    return state.projects.projects[projectId];
  });

  const resultsString = "Showing 0 results";

  const [listUi, setListUi] = useState<ListUI>({
    headers: ["status", "recordId", "formTitle", "assignee", "date"],
    friendlyHeaderNames: {
      status: "Status",
      recordId: "Record Id",
      formTitle: "Form",
      assignee: "Assignee",
      date: "Due Date",
    },
    primaryHeaders: { primary: "status", secondary: "recordId" },
    selections: {},
  });

  return (
    <Fragment>
      {currentProject && (
        <Fragment>
          <ListViewNav
            title={`Tasks`}
            results={resultsString}
            setSideNavExpanded={() => {
              dispatch(viewSlice.actions.setNavVisible(true));
            }}
          >
            <div>
              <button
                className="btn btn-gray-100 btn-no-shadow btn-stacked-h"
                disabled={true}
                data-tooltip="Filter list"
                tooltip-position="bottom"
              >
                <i className="fa-fw fal fa-sliders-v "></i>
                <span className="hidden lg:inline-block ml-2">Filter</span>
              </button>
              <button
                className="btn btn-gray-100 btn-no-shadow btn-stacked-h mr-2"
                disabled={true}
                data-tooltip="Sort list"
                tooltip-position="bottom"
              >
                <i className="fa-fw far fa-sort-amount-down-alt "></i>
                <span className="hidden lg:inline-block ml-2">Sort</span>
              </button>
            </div>
            <Menu
              menuOpen={false}
              setMenuOpen={() => {}}
              extraClassNames="inline-block text-left"
            >
              <button className="btn btn-gray-100 btn-no-shadow">
                <i className="fa-fw far fa-ellipsis-h"></i>
              </button>
            </Menu>
          </ListViewNav>
          <MobileActionBar extraClassNames="md:hidden">
            <SidebarMenuButton
              extraClassNames="flex-1"
              setSideNavExpanded={() => {
                dispatch(viewSlice.actions.setNavVisible(true));
              }}
            >
              <i className="fa-fw fas fa-bars" />
              <div className="block">Menu</div>
            </SidebarMenuButton>

            <div className="flex-1"></div>

            <button className="btn btn-ab flex-1 ml-2">
              <Menu
                menuOpen={false}
                setMenuOpen={() => {}}
                extraClassNames="inline-block text-left"
              >
                <div className="flex-1 text-center">
                  <i className="fa-fw far fa-ellipsis-h" />
                  <div className="block">More</div>
                </div>
              </Menu>
            </button>
          </MobileActionBar>
          {reminders.length === 0 && (
            <Fragment>
              <div className="w-11/12 mx-auto text-center my-4 px-8 py-20 border border-gray-300 rounded bg-white">
                <div className="">
                  <img
                    src="assets/EmptyRecords.svg"
                    className="w-32 mx-auto mb-4"
                  />
                </div>

                <h1 className="font-bold text-3xl text-violet-500 leading-loose mx-auto">
                  No Tasks Yet
                </h1>
              </div>
            </Fragment>
          )}
          {reminders.length > 0 && (
            // TODO: Need to be able to set danger prop on menu items
            // TODO: Need to be able to open modal when clicking on menu items
            <FlexibleList
              listItems={reminders.map((reminder) => {
                const form = availableForms[reminder.crdt.reminderInfo.formKey];

                const formTitle = form
                  ? form.crdt.title.toString()
                  : reminder.crdt.reminderInfo.formKey;

                return {
                  ob: {
                    id: reminder.crdt.id,
                    status: reminder.crdt.completed ? "completed" : "new",
                    recordId: reminder.crdt.recordId,
                    formTitle: formTitle,
                    date: reminder.crdt.completed
                      ? new Date(
                          reminder.crdt.completedTimestamp
                        ).toLocaleString()
                      : reminder.crdt.dueDate?.toDateString() || "no due date",
                    assignee: reminder.crdt.assignee || "",
                  },
                  link: reminder.crdt.completed
                    ? undefined
                    : buildFormFillerForRecordPath(
                        projectId,
                        getFormInfoFromKey(reminder.crdt.reminderInfo.formKey)
                          .formId,
                        reminder.crdt.recordSetId,
                        reminder.crdt.recordId
                      ),
                };
              })}
              ui={listUi}
              setUi={setListUi}
              menuItemMap={{}}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
