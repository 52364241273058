import * as K from "../types/KotoOb";

import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { loadBlockSpecsForForm, loadForm } from "../types/Form";
import { useDispatch, useSelector } from "react-redux";

import { AppKotoContext } from "./AppKotoContext";
import { Block } from "../types/Block";
import { FormFiller } from "../components/Form";
import { KotoCRDT } from "../types/KotoTypes";
import { RootState } from "../Store/store";
import { formFillerSlice } from "../Store/formFillerSlice";
import { loadProject } from "../types/Project";
import { urlParams } from "../App/AppRoutes";
import { useParams } from "react-router";

export const AppFormPreview: FunctionComponent<{}> = (_props) => {
  const dispatch = useDispatch();
  const { kContext } = useContext(AppKotoContext);

  const { projectId, formId } = useParams<urlParams>();

  const [blocks, setBlocks] = useState([] as KotoCRDT<Block>[]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const load = async () => {
      const projectResult = await loadProject(kContext, projectId);

      if (projectResult.type !== "Success") {
        console.log("Failed to load project. ", projectResult.msg);
        return;
      }

      const result = await loadForm(kContext, projectId, formId);
      if (result.type !== "Success") {
        console.log("Failed to load form. ", result.msg);
        return;
      }
      const form = result.data;

      const specMap = Object.entries(
        await loadBlockSpecsForForm(kContext, form)
      ).reduce((prev, cur) => {
        return { ...prev, [cur[0]]: K.getKotoObFromKotoCRDT(cur[1]) };
      }, {});

      dispatch(formFillerSlice.actions.setForm(form));
      dispatch(formFillerSlice.actions.setBlockSpecs(specMap));
      setLoaded(true);
    };

    load();
    return () => {
      dispatch(formFillerSlice.actions.setForm(null));
      dispatch(formFillerSlice.actions.setBlockSpecs({}));
      setLoaded(false);
    };
  }, [kContext, projectId, formId]);

  const form = useSelector((state: RootState) => {
    return state.formFiller.form;
  });

  const blockSpecMap = useSelector((state: RootState) => {
    return state.formFiller.blockSpecs;
  });

  return (
    <Fragment>
      {form && loaded && (
        <div>
          <FormFiller
            kotoContext={kContext}
            form={form}
            blockSpecMap={blockSpecMap}
            blocks={blocks}
            setBlocks={setBlocks}
            recordSetId={""}
            recordId={""}
          />
        </div>
      )}
    </Fragment>
  );
};
