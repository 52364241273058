import React, { FunctionComponent, Fragment } from "react";
import { Card } from "../Card";

interface AccessLogsProps {
  // TODO: Add activeSection to scroll to and expand a specific section of the settings
  //   activeSection: string;
}

// TODO: Replace div containing fake user icon with real user icons and
// placeholder images
export const AccessLogs: FunctionComponent<AccessLogsProps> = (_props) => {
  return (
    <Fragment>
      <div className="flex max-w-3xl mx-auto mb-4 mt-8  z-0;">
        <input
          className="k_search flex-1"
          type="search"
          placeholder="Search for a specific date or user"
        ></input>
      </div>
      <div>
        <Card isActive={false} isStacked={true}>
          {/* TODO: Update URL below */}
          <div>
            This page lists details about account access for all members of your
            project. It is only accessible to administrators. Individual members
            can view their own access logs at{" "}
            <a href="#">https://koto.team/account/logs</a>.
          </div>
        </Card>
        <Card isActive={false} isStacked={true}>
          <div className="k_cardSectionTitle mb-6">Wednesday, April 15th</div>
          <div className="flex items-top mb-6">
            <div className="flex-initial flex w-8 h-8 bg-teal-400 border border-teal-600 text-center items-center rounded">
              <span className="flex-1 text-gray-800 font-bold text-sm">KH</span>
            </div>
            <div className="flex-1 ml-4 leading-tight">
              <a href="#" className="block mb-1 no-underline hover:underline">
                Kavi Harshawat
              </a>
              <div className="k_cardQuestionDescription">
                3:54 with the Koto Web App
              </div>
            </div>
          </div>
          <div className="flex items-top mb-6">
            <div className="flex-initial flex w-8 h-8 bg-teal-400 border border-teal-600 text-center items-center rounded">
              <span className="flex-1 text-gray-800 font-bold text-sm">KH</span>
            </div>
            <div className="flex-1 ml-4 leading-tight">
              <a href="#" className="block mb-1 no-underline hover:underline">
                Kavi Harshawat
              </a>
              <div className="k_cardQuestionDescription">
                3:54 with the Koto Web App
              </div>
              <div className="k_cardAnnotation">
                IP 0.0.0.0. There were 2 additional logins from this device,
                with the most recent at 3:54 PM.
              </div>
            </div>
          </div>
          <div className="flex items-top mb-6">
            <div className="flex-initial flex w-8 h-8 bg-teal-400 border border-teal-600 text-center items-center rounded">
              <span className="flex-1 text-gray-800 font-bold text-sm">KH</span>
            </div>
            <div className="flex-1 ml-4 leading-tight">
              <a href="#" className="block mb-1 no-underline hover:underline">
                Kavi Harshawat
              </a>
              <div className="k_cardQuestionDescription">
                3:54 with the Koto Web App
              </div>
            </div>
          </div>
        </Card>
        <Card isActive={false} isStacked={true}>
          <div className="k_cardSectionTitle mb-6">Wednesday, April 14th</div>
          <div className="flex items-top mb-6">
            <div className="flex-initial flex w-8 h-8 bg-teal-400 border border-teal-600 text-center items-center rounded">
              <span className="flex-1 text-gray-800 font-bold text-sm">KH</span>
            </div>
            <div className="flex-1 ml-4 leading-tight">
              <a href="#" className="block mb-1 no-underline hover:underline">
                Kavi Harshawat
              </a>
              <div className="k_cardQuestionDescription">
                3:54 with the Koto Web App
              </div>
            </div>
          </div>
          <div className="flex items-top mb-6">
            <div className="flex-initial flex w-8 h-8 bg-teal-400 border border-teal-600 text-center items-center rounded">
              <span className="flex-1 text-gray-800 font-bold text-sm">KH</span>
            </div>
            <div className="flex-1 ml-4 leading-tight">
              <a href="#" className="block mb-1 no-underline hover:underline">
                Kavi Harshawat
              </a>
              <div className="k_cardQuestionDescription">
                3:54 with the Koto Web App
              </div>
              <div className="k_cardAnnotation">
                IP 0.0.0.0. There were 2 additional logins from this device,
                with the most recent at 3:54 PM.
              </div>
            </div>
          </div>
          <div className="flex items-top mb-6">
            <div className="flex-initial flex w-8 h-8 bg-teal-400 border border-teal-600 text-center items-center rounded">
              <span className="flex-1 text-gray-800 font-bold text-sm">KH</span>
            </div>
            <div className="flex-1 ml-4 leading-tight">
              <a href="#" className="block mb-1 no-underline hover:underline">
                Kavi Harshawat
              </a>
              <div className="k_cardQuestionDescription">
                3:54 with the Koto Web App
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};
