import { NavLink, useHistory, useParams } from "react-router-dom";
import {
  PROJECTS_CREATE_ROUTE,
  buildFormsListPath,
  buildProjectPath,
  buildRecordsListPath,
  buildRemindersPath,
} from "./AppRoutes";
import {
  Project,
  addAndStoreRecordSet,
  getRecordSetName,
} from "../types/Project";
import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
} from "react";
import { loadProjects, projectsSlice } from "../Store/projectsSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { AppKotoContext } from "../App/AppKotoContext";
import { KotoCRDT } from "../types/KotoTypes";
import { RootState } from "../Store/store";
import { SideNavigation } from "../components/SideNavigation";
import { SidebarRecordSetCreator } from "../components/RecordSetMenus";
import { notEmpty } from "../utils/utils";
import { urlParams } from "../App/AppRoutes";
import { viewSlice } from "../Store/viewSlice";

interface AppSidebarRecordSetCreatorProps {
  project: KotoCRDT<Project>;
}
const AppSidebarRecordSetCreator: FunctionComponent<AppSidebarRecordSetCreatorProps> = (
  props
) => {
  const { kContext } = useContext(AppKotoContext);

  const dispatch = useDispatch();
  const history = useHistory();

  const recordSetAdder = (
    name: string,
    description: string,
    isPrivate: boolean
  ) => {
    const [newProj, newRecordSetId] = addAndStoreRecordSet(
      kContext,
      props.project,
      name,
      description,
      isPrivate
    );

    dispatch(projectsSlice.actions.updateProject(newProj));

    history.push(buildRecordsListPath(newProj.crdt.id, newRecordSetId));
  };

  return <SidebarRecordSetCreator recordSetAdder={recordSetAdder} />;
};

export const AppNav: FunctionComponent<{}> = (_props) => {
  const dispatch = useDispatch();

  const isMobile = useSelector((state: RootState) => {
    return state.view.isMobile;
  });

  const closeNav = () => {
    if (isMobile) {
      dispatch(viewSlice.actions.setNavVisible(false));
    }
  };
  const { kContext } = useContext(AppKotoContext);

  const visible = useSelector(
    (state: RootState) => state.view.navigation.visible
  );

  const auth = useSelector((state: RootState) => state.auth, shallowEqual);
  const projects = useSelector((state: RootState) => {
    return state.projects.projects;
  }, shallowEqual);

  const { projectId } = useParams<urlParams>();

  const currentProject = projects[projectId];

  useEffect(() => {
    dispatch(loadProjects(kContext));
  }, [kContext]);

  return !currentProject ? (
    <div></div>
  ) : (
    <Fragment>
      <SideNavigation
        kContext={kContext}
        projectName={currentProject.crdt.name.toString()}
        userDisplayName={auth.firebaseUser?.displayName || "No Name"}
        expanded={visible}
        setNotExpanded={() => {
          dispatch(viewSlice.actions.setNavVisible(false));
        }}
        projects={Object.values(projects).sort(
          (a, b) => a.createdTimeInMilliseconds - b.createdTimeInMilliseconds
        )}
        activeProject={projectId}
        projectsCreateLink={PROJECTS_CREATE_ROUTE}
        projectLinkBuilder={buildProjectPath}
        projectsReloader={() => dispatch(loadProjects(kContext))}
        // TODO: Pass in projectURL to be displayed in sidebar app menu
      >
        <li>
          <NavLink
            to={buildFormsListPath(projectId)}
            activeClassName="block text-white font-semibold flex rounded hover:bg-gray-900 py-2 px-4 my-1 no-underline bg-gray-900 hover:text-gray-200"
            className="text-gray-400 font-light flex rounded hover:bg-gray-900 py-2 px-4 my-1 no-underline hover:text-gray-300 items-center"
            onClick={closeNav}
          >
            <i className="far fa-file-alt fa-fw mr-4"></i>Forms
          </NavLink>
        </li>
        <li>
          <NavLink
            to={buildRemindersPath(projectId)}
            activeClassName="block text-white font-semibold flex rounded hover:bg-gray-900 py-2 px-4 my-1 no-underline bg-gray-900 hover:text-gray-200"
            className="text-gray-400 font-light flex rounded hover:bg-gray-900 py-2 px-4 my-1 no-underline hover:text-gray-300 items-center"
            onClick={closeNav}
          >
            <i className="fas fa-tasks fa-fw mr-4"></i>Tasks
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            to={buildDashboardPath(projectId)}
            activeClassName="block text-white font-semibold flex rounded hover:bg-gray-900 py-2 px-4 my-1 no-underline bg-gray-900 hover:text-gray-200"
            className="text-gray-400 font-light flex rounded hover:bg-gray-900 py-2 px-4 my-1 no-underline hover:text-gray-300 items-center"
            onClick={closeNav}
          >
            <i className="fas fa-chart-line fa-fw mr-4"></i>Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink
            to={buildReportsPath(projectId)}
            activeClassName="block text-white font-semibold flex rounded hover:bg-gray-900 py-2 px-4 my-1 no-underline bg-gray-900 hover:text-gray-200"
            className="text-gray-400 font-light flex rounded hover:bg-gray-900 py-2 px-4 my-1 no-underline hover:text-gray-300 items-center"
            onClick={closeNav}
          >
            <i className="far fa-book fa-fw mr-4"></i>Reports
          </NavLink>
        </li> */}

        <li>
          <Fragment>
            <div className="text-gray-400 flex rounded py-2 my-1 no-underline items-center ml-4 mr-2">
              <div className="text-gray-400 p-0 flex flex-grow text-left items-center text-xs uppercase font-normal tracking-wide">
                <span className="flex-grow">Collections</span>
              </div>
              <AppSidebarRecordSetCreator project={currentProject} />
            </div>
            {/* TODO: List out records sets for project as li below */}
            <ul className="mb-6">
              {Object.entries(currentProject.crdt.recordSets)
                .sort((a, b) => {
                  return a[0] < b[0] ? -1 : 0;
                })
                .map(([recordSetId, setParams]) => {
                  const name = getRecordSetName(setParams);
                  if (setParams.deleted || setParams.archived) {
                    return null;
                  } else {
                    return (
                      <li key={recordSetId}>
                        <NavLink
                          to={buildRecordsListPath(projectId, recordSetId)}
                          activeClassName="block text-white font-semibold flex rounded hover:bg-gray-900 py-2 px-4 my-1 no-underline bg-gray-900 hover:text-gray-200"
                          className="text-gray-400 font-light flex rounded hover:bg-gray-900 py-2 px-4 my-1 no-underline hover:text-gray-300 items-center"
                          onClick={closeNav}
                        >
                          {/* TODO: if not active, use fa-folder instead */}
                          <i className="far fa-folder-open fa-fw mr-4"></i>
                          {name}
                        </NavLink>
                      </li>
                    );
                  }
                })
                .filter(notEmpty)}
            </ul>
          </Fragment>
        </li>
      </SideNavigation>
    </Fragment>
  );
};
