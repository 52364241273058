import { KotoContext, createContext } from "../types/KotoContext";
import React, { useContext } from "react";

export interface AppKotoContext {
  kContext: KotoContext;
}

export const AppKotoContext = React.createContext<AppKotoContext>({
  kContext: createContext(""),
});

export const useKContext = () => {
  const { kContext } = useContext(AppKotoContext);
  return kContext;
};
