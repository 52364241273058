import * as K from "../types/KotoOb";

import {
  MobileActionBar,
  FilterMenuItemList,
  Menu,
  MenuItem,
  MenuItemIcon,
  MenuItemList,
  SidebarMenuButton,
} from "../components/Buttons";
import { Block, subscribeBlocks } from "../types/Block";
import { ConfirmationModal, ShareRecordModal } from "../components/Modals";
import { KotoCRDT, KotoKey } from "../types/KotoTypes";
import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Record,
  archiveRecord,
  deleteRecord,
  subscribeRecord,
} from "../types/Record";
import { RecordView, RecordViews } from "../components/Record";
import {
  buildFormFillerForRecordPath,
  buildRecordEditPath,
  buildRecordPath,
  buildRecordsListPath,
  urlParams,
} from "./AppRoutes";
import { createReminder, subscribeRemindersForRecord } from "../types/Reminder";
import { loadRemindersForRecord, recordSlice } from "../Store/recordSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";

import { AppKotoContext } from "./AppKotoContext";
import { ObjectViewNav } from "../components/TopNavigation";
import { RootState } from "../Store/store";
import { loadForms } from "../Store/formListSlice";
import { useQuery } from "../types/KotoReact";
import { viewSlice } from "../Store/viewSlice";

export const AppRecordView: FunctionComponent<{}> = (_props) => {
  const history = useHistory();
  const { kContext } = useContext(AppKotoContext);
  const { projectId, recordId, recordSetId } = useParams<urlParams>();

  const queryParms = useQuery();

  const [moreMenuButtonOpen, setMoreMenuButtonOpen] = useState(false);

  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareModalActiveTabLabel, setShareModalActiveTabLabel] = useState(
    "url"
  );
  const handleBackButton = () => {
    // TODO: Check to see that this works if you enter another recordSetId
    history.push(`/p/${projectId}/records/${recordSetId}`);
  };

  const dispatch = useDispatch();

  const record = useSelector((state: RootState) => {
    return state.record.record;
  });

  const blocks = useSelector((state: RootState) => {
    return state.record.blocks;
  });

  const recordView = useSelector((state: RootState) => state.record.recordView);

  const handleSelectTab = (view: RecordViews) => {
    dispatch(recordSlice.actions.setRecordView(view));
  };

  const [blockCrdts, setBlockCrdts] = useState([] as KotoCRDT<Block>[]);

  useEffect(() => {
    const unsubRecord = subscribeRecord(
      kContext,
      projectId,
      recordId,
      (recordResult) => {
        if (recordResult.type !== "Success") {
          console.log(
            `Could't load record ${recordId}. Got error: ${recordResult.type}: ${recordResult.msg}`
          );
          return;
        } else {
          dispatch(recordSlice.actions.setRecord(recordResult.data));
        }
      },
      (err) => {
        console.log(`Error getting Record: ${err}`);
      }
    );

    const unsubBlocks = subscribeBlocks(
      kContext,
      projectId,
      recordId,
      (blockResults) => {
        if (blockResults.type !== "Success") {
          console.log(
            `Couldn't load blocks for recordId ${recordId}. Got Error: ${blockResults.type}: ${blockResults.msg}`
          );
        } else {
          setBlockCrdts(blockResults.data);

          dispatch(recordSlice.actions.setBlocks(blockResults.data));
        }
      },
      (err) => {
        console.log(`Error getting Blocks: ${err}`);
      }
    );
    return () => {
      unsubBlocks();
      unsubRecord();
    };
  }, [kContext, recordId, projectId]);

  const availableForms = useSelector(
    (state: RootState) => state.formList.formsList
  );

  useEffect(() => {
    dispatch(loadForms(kContext, projectId));
  }, [projectId, kContext]);

  useEffect(() => {
    const unSub = subscribeRemindersForRecord(
      kContext,
      projectId,
      recordId,
      (remindersResults) => {
        if (remindersResults.type === "Error") {
          throw new Error(`Could not load reminders for ${recordId}.`);
        } else if (remindersResults.type === "NotFound") {
          dispatch(recordSlice.actions.setReminderList([]));
        } else {
          dispatch(
            recordSlice.actions.setReminderList(
              remindersResults.data.map((r) => {
                const formName =
                  availableForms
                    .find((form) => {
                      return form.key === r.crdt.reminderInfo.formKey;
                    })
                    ?.crdt.title.toString() || "Unknown Form";

                const displayDate = r.crdt.completed
                  ? new Date(r.crdt.completedTimestamp).toLocaleString()
                  : r.crdt.dueDate?.toDateString() || "no due date";

                return {
                  formTitle: formName,
                  formKey: r.crdt.reminderInfo.formKey,
                  date: displayDate,
                  status: r.crdt.completed ? "complete" : "new",
                };
              })
            )
          );
        }
      },
      (err) => {
        console.error("Could not subscribe to reminders. ", err.message);
      }
    );

    return () => {
      dispatch(recordSlice.actions.setReminderList([]));
      unSub();
    };
  }, [projectId, recordId, kContext, availableForms]);

  const [addInfoMenuOpen, setAddInfoMenuOpen] = useState(false);

  const handleAddInfo = () => {};

  const [confirmationModalInfo, setConfirmationModalInfo] = useState({
    open: false,
    setOpen: (val: boolean) =>
      setConfirmationModalInfo({ ...confirmationModalInfo, open: val }),
    modalType: "",
    entityString: "",
    confirmationFunc: () => {},
  });

  const createDeleteRecordHandler = (record: KotoCRDT<Record>) => {
    return async () => {
      setConfirmationModalInfo({
        open: true,
        setOpen: confirmationModalInfo.setOpen,
        modalType: "delete",
        entityString: record.crdt.id,
        confirmationFunc: async () => {
          const deletedForm = deleteRecord(kContext, record);
          await K.storeCrdt(kContext, deletedForm);
          confirmationModalInfo.setOpen(false);
          history.push(buildRecordsListPath(projectId, recordSetId));
        },
      });
    };
  };

  const createArchiveRecordHandler = (record: KotoCRDT<Record>) => {
    return async () => {
      setConfirmationModalInfo({
        open: true,
        setOpen: confirmationModalInfo.setOpen,
        modalType: "archive",
        entityString: record.crdt.id,
        confirmationFunc: async () => {
          const archivedForm = archiveRecord(kContext, record);
          await K.storeCrdt(kContext, archivedForm);
          confirmationModalInfo.setOpen(false);
          history.push(buildRecordsListPath(projectId, recordSetId));
        },
      });
    };
  };

  const reminderList = useSelector((state: RootState) => {
    return state.record.reminderList;
  });

  const handleAddReminder = async (
    formKey: KotoKey,
    dueDate: Date,
    assignee: string
  ) => {
    // TODO: make this check better
    if (formKey === "") {
      alert("Did not choose form!");
      return;
    }
    if (record) {
      const reminder = createReminder(
        kContext,
        record,
        { reminderType: "form", formKey: formKey },
        dueDate,
        assignee
      );
      await K.storeCrdt(kContext, reminder);
      dispatch(
        loadRemindersForRecord(kContext, projectId, recordId, availableForms)
      );
    } else {
      console.log("Can't add reminder to null record.");
    }
  };

  const handleSaveChanges = async (blocks: KotoCRDT<Block>[]) => {
    const saves = blocks.map((block) => {
      return K.storeCrdt(kContext, block);
    });
    await Promise.all(saves);
    setBlockCrdts(blocks);
    dispatch(recordSlice.actions.setBlocks(blocks));
  };

  const editMode = queryParms.get("mode") === "edit";

  const handleEditModeToggle = async () => {
    if (editMode) {
      queryParms.delete("mode");
    } else {
      queryParms.set("mode", "edit");
    }

    if (kContext.config.inlineRecordEdit) {
      history.push({
        pathname: buildRecordPath(projectId, recordSetId, recordId),
        search: `?${queryParms.toString()}`,
      });
    } else {
      history.push(buildRecordEditPath(projectId, recordId, recordId));
    }
  };

  const location = useLocation();

  const currentUrl = window.location ? window.location.href : location.pathname;

  return (
    <Fragment>
      {/* TODO: Insert record actions here */}
      <ObjectViewNav
        title={recordId}
        titleEditable={false}
        setSideNavExpanded={() => {
          dispatch(viewSlice.actions.setNavVisible(true));
        }}
        handleBackButton={handleBackButton}
      >
        <Menu menuOpen={addInfoMenuOpen} setMenuOpen={setAddInfoMenuOpen}>
          <button className="btn btn-teal mr-2" onClick={handleAddInfo}>
            <i className="fas fa-plus"></i>
            <span className="ml-2 hidden lg:inline-block">Add info</span>
          </button>
          <FilterMenuItemList
            position="bottom-right"
            extraClassNames="sm:min-w-xs text-left"
          >
            {/* TODO: If num of forms == 1, change the add record button to just open that form */}
            {availableForms.map((form) => {
              return (
                <MenuItem
                  key={form.key}
                  linkTo={buildFormFillerForRecordPath(
                    projectId,
                    form.crdt.id,
                    recordSetId,
                    recordId
                  )}
                >
                  {form.crdt.title.toString()}
                </MenuItem>
              );
            })}
          </FilterMenuItemList>
        </Menu>

        <button
          className="btn btn-gray-200 mr-2"
          onClick={handleEditModeToggle}
        >
          <i className="fas fa-pencil"></i>
          <span className="ml-2 hidden lg:inline-block">
            {editMode ? "View" : "Edit"}
          </span>
        </button>
        <button
          className="btn btn-gray-200"
          onClick={() => setShareModalOpen(true)}
        >
          <i className="fas fa-share"></i>
          <span className="ml-2 hidden lg:inline-block">Share</span>
        </button>

        <Menu
          menuOpen={moreMenuButtonOpen}
          setMenuOpen={setMoreMenuButtonOpen}
          extraClassNames="inline-block text-left"
        >
          <button className="btn btn-gray-200 btn-no-shadow ml-2">
            <i className="fa-fw far fa-ellipsis-h"></i>
          </button>
          {record && (
            <MenuItemList position="bottom-right">
              <MenuItem>
                <MenuItemIcon fontAwesomeClassNames="far fa-alarm-plus" />
                Add a reminder
              </MenuItem>

              <MenuItem
                extraClassNames="border-t border-gray-300"
                onClick={createArchiveRecordHandler(record)}
              >
                <MenuItemIcon fontAwesomeClassNames="far fa-archive" />
                Archive record
              </MenuItem>

              <MenuItem
                danger={true}
                onClick={createDeleteRecordHandler(record)}
              >
                <MenuItemIcon fontAwesomeClassNames="far fa-trash-alt" />
                Delete record
              </MenuItem>
            </MenuItemList>
          )}
        </Menu>

        <ShareRecordModal
          title="Share record"
          modalOpen={shareModalOpen}
          setModalOpen={setShareModalOpen}
          activeView={shareModalActiveTabLabel}
          selectTab={(label) => {
            setShareModalActiveTabLabel(label);
          }}
          url={currentUrl}
        ></ShareRecordModal>
      </ObjectViewNav>

      <MobileActionBar extraClassNames="md:hidden">
        <SidebarMenuButton
          extraClassNames="flex-1"
          setSideNavExpanded={() => {
            dispatch(viewSlice.actions.setNavVisible(true));
          }}
        >
          <i className="fa-fw fas fa-bars" />
          <div className="block">Menu</div>
        </SidebarMenuButton>

        <button className="btn btn-teal flex-1">
          <i className="fa-fw fas fa-plus" />
          <div className="block">Add Info</div>
        </button>

        <button className="btn btn-ab flex-1 ml-2">
          <Menu
            menuOpen={moreMenuButtonOpen}
            setMenuOpen={setMoreMenuButtonOpen}
            extraClassNames="inline-block text-left"
          >
            <div className="flex-1 text-center">
              <i className="fa-fw far fa-ellipsis-h" />
              <div className="block">More</div>
            </div>
            {record && (
              <MenuItemList position="top-right">
                <MenuItem onClick={handleEditModeToggle}>
                  <MenuItemIcon fontAwesomeClassNames="fas fa-pencil" />
                  {editMode ? "View Record" : "Edit Record"}
                </MenuItem>
                <MenuItem onClick={() => setShareModalOpen(true)}>
                  <MenuItemIcon fontAwesomeClassNames="far fas fa-share" />
                  Share
                </MenuItem>
                <MenuItem>
                  <MenuItemIcon fontAwesomeClassNames="far fa-alarm-plus" />
                  Add a reminder
                </MenuItem>

                <MenuItem
                  extraClassNames="border-t border-gray-300"
                  onClick={createArchiveRecordHandler(record)}
                >
                  <MenuItemIcon fontAwesomeClassNames="far fa-archive" />
                  Archive record
                </MenuItem>

                <MenuItem
                  danger={true}
                  onClick={createDeleteRecordHandler(record)}
                >
                  <MenuItemIcon fontAwesomeClassNames="far fa-trash-alt" />
                  Delete record
                </MenuItem>
              </MenuItemList>
            )}
          </Menu>
        </button>
      </MobileActionBar>

      {record && (
        <RecordView
          kContext={kContext}
          record={record}
          blocks={blocks}
          activeView={recordView}
          selectTab={handleSelectTab}
          reminderList={reminderList}
          addReminder={handleAddReminder}
          availableForms={availableForms}
          blockCrdts={blockCrdts}
          setBlockCrdts={handleSaveChanges}
          editMode={editMode}
        />
      )}
      <ConfirmationModal
        modalOpen={confirmationModalInfo.open}
        setModalOpen={confirmationModalInfo.setOpen}
        confirmationType={confirmationModalInfo.modalType}
        entityString={confirmationModalInfo.entityString}
        confirmationFunc={confirmationModalInfo.confirmationFunc}
      />
    </Fragment>
  );
};
