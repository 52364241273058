import "core-js/stable";
import "regenerator-runtime/runtime";
import "./main.scss";

import * as firebaseui from "firebaseui";

import { App } from "./App/App";
import { AppAnalyticsPageView } from "./App/AppAnalytics";
import { AppFirebaseContext } from "./App/AppFirebaseContext";
import { BrowserRouter } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import React from "react";
import { createBrowserHistory } from "history";
import { firebaseSetup } from "./firebaseSetup";
import { getNewStore } from "./Store/store";
import { initializeAuth } from "./App/AppAuthentication";
import { render } from "react-dom";

export const history = createBrowserHistory();

const { store } = getNewStore(history);

const [firebaseApp, firestoreDb] = firebaseSetup();
const firebaseAuthUi = new firebaseui.auth.AuthUI(firebaseApp.auth());
initializeAuth(store.dispatch, firebaseApp.auth(), firestoreDb, firebaseAuthUi);

const container = document.getElementById("app");

render(
  <AppFirebaseContext.Provider
    value={{ firebaseApp, firestoreDb, firebaseAuthUi }}
  >
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <BrowserRouter>
          <AppAnalyticsPageView />
          <App db={firestoreDb} />
        </BrowserRouter>
      </ConnectedRouter>
    </Provider>
  </AppFirebaseContext.Provider>,
  container
);
