import * as K from "../../types/KotoOb";

import {
  BackingStore,
  QueryParams,
  QueryParamsWithKey,
} from "../../types/KotoStore";
import {
  KotoBaseDoc,
  KotoCRDT,
  KotoStorable,
  isCrdt,
} from "../../types/KotoTypes";

export class SmartStore extends BackingStore {
  name = "SmartStore";

  private uid: string;
  private cache: Map<string, any>;
  private localBackends: BackingStore[];
  private remoteBackends: BackingStore[];

  private stats: {
    peerRequestsInFlight: number;
    successfulPeerRequests: number;
    failedPeerRequests: number;
  };

  constructor(
    uid: string,
    localBackends: BackingStore[],
    remoteBackends: BackingStore[]
  ) {
    super();

    this.uid = uid;
    this.localBackends = localBackends;
    this.remoteBackends = remoteBackends;
    this.cache = new Map<string, any>();
    this.stats = {
      peerRequestsInFlight: 0,
      successfulPeerRequests: 0,
      failedPeerRequests: 0,
    };
  }

  async store<T extends KotoStorable>(ob: T): Promise<K.KotoResult<T>> {
    const requests = this.localBackends.map((backend) => {
      return backend.store<T>(ob);
    });

    const results = await Promise.all(requests);
    if (
      results.every((val) => {
        return val.type === "Success";
      })
    ) {
      if (isCrdt(ob)) {
        this.cache.set(ob.key, ob);
        const serializedChanges = K.serializeChanges(
          this.uid,
          ob as KotoCRDT<KotoBaseDoc>
        );

        const changeRequests = this.remoteBackends.map((peer) => {
          return peer.store(serializedChanges);
        });

        (async () => {
          this.stats.peerRequestsInFlight += changeRequests.length;
          const peerResults = await Promise.all(requests);
          this.stats.peerRequestsInFlight -= changeRequests.length;

          peerResults.forEach((result) => {
            if (result.type === "Success") {
              this.stats.successfulPeerRequests += 1;
            } else if (result.type === "Error") {
              this.stats.failedPeerRequests += 1;
            }
          });
        })().catch((reason) => {
          console.log(`Failed peer requests for storing ${ob.key}. ${reason}`);
        });
      }
      return { type: "Success", data: ob };
    } else {
      return { type: "Error", msg: JSON.stringify(results, null, 2) };
    }
  }

  subscribe<T extends KotoStorable>(
    queryParams: QueryParamsWithKey<T>,
    cb: (result: K.KotoResult<T>) => void,
    errorCb?: (err: any) => void
  ): K.SubscriptionCanceler {
    if (this.remoteBackends.length === 0) {
      throw Error("No remote backend to subscribe to");
    }

    return this.remoteBackends[0].subscribe(queryParams, cb, errorCb);
  }

  subscribeList<T extends KotoStorable>(
    queryParams: QueryParams<T>,
    cb: (result: K.KotoResult<T[]>) => void,
    errorCb?: (err: any) => void
  ): K.SubscriptionCanceler {
    if (this.remoteBackends.length === 0) {
      throw Error("No remote backend to subscribe to");
    }

    return this.remoteBackends[0].subscribeList(queryParams, cb, errorCb);
  }

  async get<T extends KotoStorable>(
    queryParams: QueryParamsWithKey<T>
  ): Promise<K.KotoResult<T>> {
    const errors: K.KotoResult<T>[] = [];

    for (const backend of [...this.localBackends, ...this.remoteBackends]) {
      const result = await backend.get<T>(queryParams);

      if (result.type === "Success") {
        return result;
      } else {
        errors.push(result);
      }
    }

    if (errors[errors.length - 1].type === "NotFound") {
      return errors[errors.length - 1];
    }

    const errMsgs = errors.map((err) => {
      return JSON.stringify(err, null, 2);
    });

    return { type: "Error", msg: errMsgs.join("\n") };
  }

  async getList<T extends KotoStorable>(
    query: QueryParams<T>
  ): Promise<K.KotoResult<T[]>> {
    const errors: K.KotoResult<T[]>[] = [];

    for (const backend of this.remoteBackends) {
      if (backend.getList !== undefined) {
        const result = await backend.getList<T>(query);
        if (result.type === "Success") {
          return result;
        } else {
          errors.push(result);
        }
      }
    }

    if (errors.length === 0) {
      return { type: "Error", msg: "Could not query any backend." };
    }

    if (errors[errors.length - 1].type === "NotFound") {
      return errors[errors.length - 1];
    }

    const errMsgs = errors.map((err) => {
      return JSON.stringify(err, null, 2);
    });

    return {
      type: "Error",
      msg: `Error performing list query ${JSON.stringify(
        query
      )}: \n ${errMsgs.join("\n")}`,
    };
  }

  async getIndex<T extends KotoStorable>(
    query: QueryParams<T>
  ): Promise<K.KotoResult<T[]>> {
    const errors: K.KotoResult<T[]>[] = [];

    for (const backend of this.remoteBackends) {
      if (backend.getIndex !== undefined) {
        const result = await backend.getIndex<T>(query);
        if (result.type === "Success") {
          return result;
        } else {
          errors.push(result);
        }
      }
    }

    if (errors.length === 0) {
      return { type: "Error", msg: "Could not query any backend." };
    }

    if (errors[errors.length - 1].type === "NotFound") {
      return errors[errors.length - 1];
    }

    const errMsgs = errors.map((err) => {
      return JSON.stringify(err, null, 2);
    });

    return {
      type: "Error",
      msg: `Error performing list query ${JSON.stringify(
        query
      )}: \n ${errMsgs.join("\n")}`,
    };
  }
}

export const createSmartStore = (
  uid: string,
  localBackends: BackingStore[],
  remoteBackends: BackingStore[]
): BackingStore => {
  return new SmartStore(uid, localBackends, remoteBackends);
};
