import { Form, loadFormsForProject } from "../types/Form";
import { KotoCRDT, KotoKey } from "../types/KotoTypes";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { AppThunk } from "./store";
import { KotoContext } from "../types/KotoContext";
import { ListUI } from "../components/FlexibleLists";
import { ProjectId } from "../types/IdTypes";

export interface FormListSlice {
  formsList: KotoCRDT<Form>[];
  formsByKey: {
    [key: string]: KotoCRDT<Form>;
  };
  ui: ListUI;
}

export const formListSlice = createSlice({
  name: "formList",
  initialState: {
    formsList: [],
    formsByKey: {},
    ui: {
      headers: ["title", "description", "creationTime", "lastUpdated"],
      friendlyHeaderNames: {
        title: "Title",
        description: "Description",
        creationTime: "Creation Date",
        lastUpdated: "Last Updated",
      },
      primaryHeaders: { primary: "title", secondary: "descripion" },
      selections: {},
    },
  } as FormListSlice,
  reducers: {
    loadList: (state, action: PayloadAction<KotoCRDT<Form>[]>) => {
      state.formsList = action.payload;
      state.formsByKey = action.payload.reduce((prev, cur) => {
        return { ...prev, [cur.key]: cur };
      }, {});
    },
    removeForm: (state, action: PayloadAction<KotoKey>) => {
      const index = state.formsList.findIndex((val) => {
        return val.key === action.payload;
      });
      state.formsList.splice(index, 1);
      delete state.formsByKey[action.payload];
    },
    setUi: (state, action: PayloadAction<ListUI>) => {
      state.ui = action.payload;
    },
  },
});

export const loadForms = (
  kContext: KotoContext,
  projectId: ProjectId
): AppThunk => {
  return async (dispatch) => {
    const results = await loadFormsForProject(kContext, projectId);

    if (results.type === "Success") {
      dispatch(formListSlice.actions.loadList(results.data));
    } else if (results.type === "NotFound") {
      dispatch(formListSlice.actions.loadList([]));
    } else {
      console.error(results);
    }
  };
};
