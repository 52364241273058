import * as K from "../types/KotoOb";

import { AppThunk, RootState } from "./store";
import { BlockSpec, loadAllBlockSpecs } from "../types/Block";
import { KotoKey, KotoOb } from "../types/KotoTypes";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProjectId, QuestionSpecId } from "../types/IdTypes";

import { KotoContext } from "../types/KotoContext";

export interface BlockSpecSliceProps {
  blockSpecMap: { [blockSpecKey: string]: KotoOb<BlockSpec> };
}

const initialState: BlockSpecSliceProps = {
  blockSpecMap: {},
  // availableQuestions: [],
};

export const blockSpecSlice = createSlice({
  name: "blockListSlice",
  initialState: initialState,
  reducers: {
    setBlockSpecMap: (state, action: PayloadAction<KotoOb<BlockSpec>[]>) => {
      const newBlockSpecMap: {
        [blockSpecKey: string]: KotoOb<BlockSpec>;
      } = {};

      action.payload.forEach((spec) => {
        newBlockSpecMap[spec.key] = spec;
      });

      state.blockSpecMap = newBlockSpecMap;
    },
    updateBlockSpec: (state, action: PayloadAction<KotoOb<BlockSpec>>) => {
      state.blockSpecMap[action.payload.key] = action.payload;
    },
  },
});

export const loadBlockSpecs = (
  kContext: KotoContext,
  projectId: ProjectId
): AppThunk => {
  return async (dispatch) => {
    const blockSpecs = await loadAllBlockSpecs(kContext, projectId);
    if (blockSpecs.type !== "Success") {
      console.log(`Could not load blockSpecs for ${projectId}`);
      return;
    }
    dispatch(
      blockSpecSlice.actions.setBlockSpecMap(
        blockSpecs.data.map((spec) => K.getKotoObFromKotoCRDT(spec))
      )
    );
  };
};

export const availableQuestionsSelector = (state: RootState) => {
  const questions: {
    blockSpecKey: KotoKey;
    questionSpecId: QuestionSpecId;
    label: string;
  }[] = [];

  Object.values(state.blockSpecSlice.blockSpecMap).forEach((spec) => {
    spec.doc.questions.forEach((question) => {
      questions.push({
        questionSpecId: question.id,
        blockSpecKey: spec.key,
        label: question.label.toString(),
      });
    });
  });

  return questions;
};
