import React, {
  useRef,
  useEffect,
  useCallback,
  FunctionComponent,
} from "react";

/**
 * An element that facilitates hiding its children when clicked outside the
 * component
 *
 * This component causes the given callback to be called when a click happens
 * outside the component. This can be used to toggle a "visibility" variable
 * which is then used to hide the component.
 *
 * @example
 * return <>{ !visible && <Hideable toggleFunc={()=>setVisible(!visible)}> }</>
 *
 * @param props toggleFunc: A function which toggles the value used for the
 * visibility of this element
 */
export const Hideable: FunctionComponent<{
  toggleFunc: () => void;
}> = (props) => {
  const node = useRef<HTMLDivElement>(null);

  const handleClick = (e: MouseEvent) => {
    if (!e.target) {
      console.trace("Received event without a target.");
      return;
    }

    if (node && node.current) {
      if (e.target instanceof Element) {
        if (!node.current.contains(e.target)) {
          // outside click
          props.toggleFunc();
          return;
        }
      } else {
        console.trace(
          "Received event with a target that isn't an element: " +
            String(e.target)
        );
      }
    } else {
      console.trace("node is null, but expected to find a parent node");
      return;
    }
  };

  const handleEscape = useCallback((e: KeyboardEvent) => {
    if (e.key === "Escape") {
      props.toggleFunc();
    }
  }, []);

  useEffect(() => {
    // add when mounted
    document.addEventListener("click", handleClick, false);
    document.addEventListener("keydown", handleEscape, false);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("click", handleClick, false);
      document.removeEventListener("keydown", handleEscape, false);
    };
  }, []);

  return <span ref={node}>{props.children}</span>;
};
