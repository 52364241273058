import * as K from "../types/KotoOb";

import React, {
  ChangeEvent,
  Fragment,
  FunctionComponent,
  useState,
} from "react";

import { Form } from "../types/Form";
import { KotoCRDT } from "../types/KotoTypes";
import { KotoContext } from "../types/KotoContext";
import { ToggleSwitch } from "./Buttons";

interface SubmissionSettingsProps {
  kContext: KotoContext;
  form: KotoCRDT<Form>;
  setForm: (form: KotoCRDT<Form>) => void;
}

export const SubmissionSettings: FunctionComponent<SubmissionSettingsProps> = (
  props
) => {
  const [makePrivate, setMakePrivate] = useState(false);
  const [acceptResponses, setAcceptResponses] = useState(true);
  const updateSubmitButtonLabel = (event: ChangeEvent<HTMLInputElement>) => {
    const newForm = K.change(props.kContext, props.form, (doc) => {
      K.performUpdateText(
        doc.submissionSettings.buttonLabel,
        event.target.value
      );
    });
    props.setForm(newForm);
    K.storeCrdt(props.kContext, newForm);
  };

  const updateConfirmationMessage = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newForm = K.change(props.kContext, props.form, (doc) => {
      K.performUpdateText(
        doc.submissionSettings.confirmationMessage,
        event.target.value
      );
    });
    props.setForm(newForm);
    K.storeCrdt(props.kContext, newForm);
  };

  return (
    <div className="k_card">
      <h2 className="k_cardSectionTitle">Make private</h2>
      <div className="k_cardQuestionDescription flex mb-4">
        <div className="flex-1 ">
          When a form is private, it can only be viewed and edited by
          invitation.
        </div>
        {/* TODO: Hook up Toggle Switch to make the project private/not private */}

        <div className="flex-initial ml-8 md:ml-24">
          <ToggleSwitch
            checked={makePrivate}
            setChecked={setMakePrivate}
          ></ToggleSwitch>
        </div>
      </div>
      {makePrivate && (
        // TODO: Hook up this button to Add collaborators modal
        <button className="btn mb-4">
          <i className="far fa-users mr-2"></i>Add collaborators
        </button>
      )}

      <h2 className="k_cardSectionTitle">Submission settings</h2>
      <h2 className="k_cardQuestionTitle">Accepting responses?</h2>
      <div className="k_cardQuestionDescription flex mb-4">
        <div className="flex-1 ">
          Choose whether the form is accepting responses or not.
        </div>
        {/* TODO: Hook up Toggle Switch to make the form collect/not collect responses */}

        <div className="flex-initial ml-8 md:ml-24">
          <ToggleSwitch
            checked={acceptResponses}
            setChecked={setAcceptResponses}
          ></ToggleSwitch>
        </div>
      </div>

      {(acceptResponses && (
        <Fragment>
          <div className="flex">
            <label className="flex-1  mb-4">
              <div className="k_cardQuestionTitle">Submission button label</div>
              <p className="k_cardQuestionDescription">
                When people fill out a form, they click a button to submit their
                responses. You can customize the label of this button below.
              </p>
              <input
                className="k_textInput"
                placeholder="Submit"
                value={props.form.crdt.submissionSettings.buttonLabel}
                onChange={updateSubmitButtonLabel}
              ></input>
            </label>
          </div>

          <div className="flex">
            <label className="flex-1 mb-4">
              <div className="k_cardQuestionTitle">Confirmation Message</div>
              <p className="k_cardQuestionDescription">
                When people submit a form, they get a confirmation message. You
                can customize this message below.
              </p>
              <textarea
                className="k_textArea"
                rows={3}
                placeholder="Thank you for your submission."
                value={props.form.crdt.submissionSettings.confirmationMessage}
                onChange={updateConfirmationMessage}
              ></textarea>
            </label>
          </div>
        </Fragment>
      )) || (
        <Fragment>
          {/* TODO: Save message to respondents to crdt */}
          <div className="flex">
            <label className="flex-1 mb-4">
              <div className="k_cardQuestionTitle">Message to respondents</div>
              <textarea
                className="k_textArea"
                rows={3}
                placeholder="This form is no longer accepting responses"
              ></textarea>
            </label>
          </div>
        </Fragment>
      )}

      {props.children}
    </div>
  );
};
