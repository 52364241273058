import { LoadingAnimation } from "../components/LoadingAnimation";
import React from "react"; // import for JSX

interface AuthProps {
  loading: boolean;
}

export const Authentication = (props: AuthProps) => {
  return (
    <div className="mt-8">
      <div className="k_card p-12">
        <img src="assets/KotoLogo.svg" className="w-20 mx-auto mb-3" />
        <div className="text-center text-2xl font-medium align-center text-gray-900 mb-4">
          Welcome Back
        </div>
        <div id="firebaseui-auth-container"></div>
        {props.loading && (
          <div className="mx-auto mt-4" id="loader">
            <LoadingAnimation />
          </div>
        )}
      </div>
    </div>
  );
};
