import * as Automerge from "automerge";
import * as K from "../types/KotoOb";

import { KotoBaseDoc, KotoOb } from "../types/KotoTypes";
import React, { FunctionComponent, useEffect, useState } from "react";

import { KotoContext } from "../types/KotoContext";

export interface KotoHistoryProps<T extends KotoBaseDoc> {
  kContext: KotoContext;
  ob: KotoOb<T>;
}
export const KotoRawHistory: FunctionComponent<KotoHistoryProps<any>> = (
  props
) => {
  const [workingHistory, setWorkingHistory] = useState(
    [] as K.KotoState<any>[]
  );

  useEffect(() => {
    const getHistory = async () => {
      const hist = await K.getHistoryofOb(props.kContext, props.ob);
      setWorkingHistory(hist);
    };
    getHistory();
  }, [props.ob]);

  const parseHistoryEntry = (entry: Automerge.State<any>) => {
    if (!entry.change.message) {
      return <pre>{JSON.stringify(entry, null, 2)}</pre>;
    }

    let message;
    try {
      message = JSON.parse(entry.change.message);
    } catch {
      return <pre>{entry.change.message}</pre>;
    }

    return `${message.uid} changed ${entry.snapshot.type} "${entry.snapshot.title}" at ${message.clientTimestamp}`;
  };

  return (
    <div>
      {workingHistory.map((entry, idx) => {
        return <div key={idx}>{parseHistoryEntry(entry)}</div>;
      })}
    </div>
  );
};
