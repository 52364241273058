import * as K from "../types/KotoOb";

import React, { ChangeEvent, Fragment, FunctionComponent } from "react";

import { Icon } from "./Buttons";
import { KotoCRDT } from "../types/KotoTypes";
import { KotoContext } from "../types/KotoContext";
import { Link } from "react-router-dom";
import { Project } from "../types/Project";
import { ProjectId } from "../types/IdTypes";
import { ProjectList } from "./NavigationList";

export interface ProjectDetailsProps {
  kotoContext: KotoContext;
  project: KotoCRDT<Project>;
  setProject: (project: KotoCRDT<Project>) => void;
  handleNext: () => void;
  handleCancel: () => void;
}

export const ProjectDetails: FunctionComponent<ProjectDetailsProps> = (
  props
) => {
  const projectCrdt = props.project;
  // const baseUrl = "koto.team/";

  const updateName = (event: ChangeEvent<HTMLInputElement>) => {
    const newProjectCrdt = K.change(props.kotoContext, projectCrdt, (doc) => {
      K.performUpdateText(doc.name, event.target.value);
    });
    props.setProject(newProjectCrdt);
    K.storeCrdt(props.kotoContext, newProjectCrdt);
  };

  return (
    <Fragment>
      <div className="sm:max-w-3xl mx-auto k_card mb-4 mt-8">
        <h2 className="k_cardSectionTitle">Add workspace details</h2>
        <label>
          <div className="k_cardQuestionTitle">Koto workspace name</div>
          <div className="k_cardQuestionDescription">
            The workspace name should be a short, memorable name that will be
            used to identify this workspace from others. Most teams use the name
            of their company or organization.
          </div>
          <input
            className="k_textInput mb-4"
            placeholder="Enter a project name"
            disabled={false}
            value={props.project.crdt.name}
            onChange={updateName}
          ></input>
        </label>

        {/* TODO: add a way to prepend koto.team/ before what the user types */}
        {/* <label>
          <div className="k_cardQuestionTitle">Koto URL</div>
          <div className="k_cardQuestionDescription">
            This will be the URL that you and others can use to access the
            project.
          </div>
          <div>
            <input
              className="k_textInput mb-4"
              placeholder="koto.team/"
              disabled={false}
              value={baseUrl + props.project.crdt.url.toString()}
              onChange={updateUrl}
            ></input>
          </div>
        </label> */}
        {/* TODO: Hook up next and cancel buttons (cancel should go back to projects page) */}
        <div className="w-full mt-2 sm:w-auto sm:flex sm:flex-row-reverse">
          <button
            onClick={props.handleNext}
            className="btn mb-2 w-full sm:w-auto sm:mb-0 btn-gray sm:flex-initial"
          >
            Next
          </button>
          <div className="sm:flex-grow"></div>
          <button
            onClick={props.handleCancel}
            className="btn mb-2 w-full sm:w-auto sm:mb-0 sm:flex-inital"
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export interface ProjectInviteProps {
  kotoContext: KotoContext;
  projectId: ProjectId;

  inviteLink: string;
  handleBack: () => void;
  handleSkip: () => void;
  handleInvite: () => void;
}

export const ProjectInvite: FunctionComponent<ProjectInviteProps> = (props) => {
  return (
    <Fragment>
      <div className="sm:max-w-3xl mx-auto k_card mb-4 mt-4">
        <h2 className="k_cardSectionTitle">Invite others to your workspace</h2>

        <div className="k_cardQuestionTitle">Email addresses</div>
        {/* TODO: add real name of project here */}
        <div className="k_cardQuestionDescription">
          We’ll send invitations to join <strong>Donut Lovers</strong> via
          email. You can always skip this and invite others later.
        </div>
        {/* TODO: Add ability to paste a long list of email addresses and automatically break up by commas or new lines */}
        <div>
          <input
            className="k_textInput mb-4"
            placeholder="name@example.com"
            disabled={false}
          ></input>
          <input
            className="k_textInput mb-4"
            placeholder="name@example.com"
            disabled={false}
          ></input>
          {/* TODO: If a user starts typing an email address here, add another input box */}
          <input
            className="k_textInput mb-4"
            placeholder="name@example.com"
            disabled={false}
          ></input>
        </div>
        {/* TODO: Add invite link here */}
        <div className="k_cardAnnotation">
          Or, you can use an <a href={props.inviteLink}>invite link</a> to share
          with others
        </div>
        {/* TODO: Hook up invite, skip, and back buttons */}
        <div className="w-full mt-4 sm:w-auto sm:flex sm:flex-row-reverse">
          <button
            onClick={props.handleInvite}
            className="btn mb-2 w-full sm:w-auto sm:mb-0 btn-gray sm:flex-initial"
          >
            Invite
          </button>
          <button
            onClick={props.handleSkip}
            className="btn btn-text-only border-gray-500 mb-2 w-full sm:border-white sm:w-auto sm:mb-0 sm:flex-inital text-center sm:mr-2"
          >
            Skip for now
          </button>
          <div className="sm:flex-grow"></div>
          <button
            className="btn mb-2 w-full sm:w-auto sm:mb-0 sm:flex-inital"
            onClick={props.handleBack}
          >
            Back
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export interface ProjectWelcomeProps {}

export const ProjectWelcome: FunctionComponent<ProjectWelcomeProps> = (
  _props
) => {
  return (
    <Fragment>
      <div className="sm:max-w-3xl mx-auto k_card mb-4 mt-4">
        <h2 className="k_cardSectionTitle">Congratulations!</h2>
        <div className="k_cardQuestionDescription">
          You’re all set! You can now use Koto to start collecting and
          organizing information. <strong>Try creating your first form </strong>
          to test things out
        </div>
        {/* TODO: Hook up invite, skip, and back buttons */}
        <div className="w-full mt-4 sm:w-auto sm:flex sm:flex-row-reverse">
          <button className="btn mb-2 w-full sm:w-auto sm:mb-0 btn-gray sm:flex-initial">
            Create a form
          </button>
          <div className="btn  text-center mb-2 w-full sm:w-auto sm:mb-0 sm:flex-inital sm:mr-2">
            Explore Koto
          </div>
          <div className="sm:flex-grow"></div>
        </div>
      </div>
    </Fragment>
  );
};

interface ProjectsPageProps {
  kContext: KotoContext;
  projects: KotoCRDT<Project>[];
  projectCreateLink: string;
  projectsReloader: () => void;
}

export const ProjectsPage: FunctionComponent<ProjectsPageProps> = (props) => {
  let body = <div></div>;
  if (props.projects.length === 0) {
    body = (
      <div className="mt-8 sm:max-w-2xl mx-auto my-8 px-4">
        <span className="mx-auto text-center">
          <div className="mx-auto text-lg font-medium mb-4">
            Get started by creating a Koto workspace ...
          </div>
          <Link to={props.projectCreateLink}>
            <button className="btn btn-violet w-full">
              <Icon fontAwesomeClassNames="fas fa-plus mr-2" />
              Create a new workspace
            </button>
          </Link>
        </span>
      </div>
    );
  } else {
    body = (
      <div className="sm:max-w-3xl mx-auto my-8 px-2">
        <h2 className="mb-2 font-medium text-gray-800 text-lg">
          Launch one of your existing workspaces
        </h2>

        <ProjectList
          projects={props.projects}
          kContext={props.kContext}
          projectsReloader={props.projectsReloader}
        ></ProjectList>

        <div className="flex">
          <div className="flex-grow"></div>
          <Link to={props.projectCreateLink}>
            <button className="flex-initial btn btn-violet inline-block  mt-4">
              <Icon fontAwesomeClassNames="fas fa-plus mr-2"></Icon>Create new
              workspace
            </button>
          </Link>
        </div>
      </div>
    );
  }

  return <Fragment>{body}</Fragment>;
};
