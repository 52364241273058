import { createSlice } from "@reduxjs/toolkit";
import { defaultTheme } from "../types/Ui";

export const styleSlice = createSlice({
  name: "style",
  initialState: defaultTheme,
  reducers: {
    setTheme: (_state, action) => {
      return action.payload;
    },
  },
});
