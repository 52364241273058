import {
  BaseQuestionSpec,
  DatePart,
  QuestionAnswer,
  QuestionDesignProps,
  QuestionFillerProps,
  QuestionViewerProps,
  createBaseQuestionSpec,
} from "../../types/Questions";
import React, { FunctionComponent } from "react";

import DatePicker from "react-datepicker";
import { registerQuestionType } from "../../types/QuestionTypeRegistry";

export interface DateQuestionSpec extends BaseQuestionSpec {
  parts: { default: DatePart };
}

export interface DateAnswer extends QuestionAnswer {
  parts: { date: Date };
}

const createDateQuestionSpec = (): DateQuestionSpec => {
  return {
    ...createBaseQuestionSpec("dateQuestion"),
    parts: {
      default: {
        date: null,
      },
    },
  };
};

const createDateAnswer = (spec: DateQuestionSpec): DateAnswer => {
  return {
    type: "dateAnswer",
    isAnswered: false,
    parts: { date: spec.parts.default.date || new Date() },
  };
};

const DateQuestionPartsDesigner: FunctionComponent<
  QuestionDesignProps<DateQuestionSpec>
> = (_props) => {
  return (
    <div className="mx-5 my-3 first:mt-5">
      <label className="flex-1  my-4">
        <DatePicker
          className="form-select k_select"
          selected={new Date()}
          onChange={() => {}}
          placeholderText="Choose a date"
        />
      </label>
    </div>
  );
};

export const makeDateUpdater = (date: Date) => {
  return (_: DateQuestionSpec, answer: DateAnswer) => {
    answer.isAnswered = true;
    answer.parts.date = date;
  };
};

export const DateQuestionPartsFiller: FunctionComponent<
  QuestionFillerProps<DateQuestionSpec, DateAnswer>
> = (props) => {
  const updateDate = (date: Date) => {
    props.setAnswer(makeDateUpdater(date));
  };

  return (
    <div className="">
      <label className="flex-1  my-4">
        <DatePicker
          className="form-select k_select"
          selected={props.answer.parts.date as Date | undefined}
          onChange={updateDate}
          placeholderText="Choose a date"
        />
      </label>
    </div>
  );
};

const DateAnswerViewer: FunctionComponent<
  QuestionViewerProps<DateQuestionSpec, DateAnswer>
> = (props) => {
  return <div>{props.answer.parts.date.toDateString()}</div>;
};

registerQuestionType(
  "dateQuestion",
  "Date Question",
  createDateQuestionSpec,
  createDateAnswer,
  DateQuestionPartsDesigner,
  DateQuestionPartsFiller,
  DateAnswerViewer
);
