import * as K from "./KotoOb";

import { KotoBaseDoc, KotoCRDT, KotoSerializedCRDT } from "./KotoTypes";
import { ProjectId, UserId } from "./IdTypes";

import { KotoContext } from "./KotoContext";
import { QueryParamsWithKey } from "./KotoStore";
import firebase from "firebase/app";

export type KotoUserInfo = firebase.User;

export interface User extends KotoBaseDoc {
  id: UserId;
  type: "User";
  userInfo: KotoUserInfo | null;
  projects: ProjectId[];
}

export const createUser = (
  kContext: KotoContext,
  userId: UserId,
  userInfo: KotoUserInfo | null
): KotoCRDT<User> => {
  return K.create(kContext, {
    id: userId,
    type: "User",
    userInfo: userInfo,
    projects: [],
  });
};

export const loadUser = async (
  kContext: KotoContext,
  userId: UserId
): Promise<K.KotoResult<KotoCRDT<User>>> => {
  const query: QueryParamsWithKey<KotoSerializedCRDT<User>> = {
    type: "User",
    params: [
      {
        fieldPath: "key",
        op: "==",
        value: userId,
      },
    ],
  };
  return K.getCrdt(kContext, query);
};

export const loadOrCreateUser = async (
  kContext: KotoContext,
  userInfo: KotoUserInfo
): Promise<KotoCRDT<User>> => {
  const loadResult = await loadUser(kContext, userInfo.uid);
  if (loadResult.type === "Success") {
    return loadResult.data;
  } else if (loadResult.type === "NotFound") {
    const user = createUser(kContext, userInfo.uid, userInfo);
    const storeResult = await K.storeCrdt(kContext, user);

    if (storeResult.type !== "Success") {
      throw Error("Could not create user.");
    }

    return user;
  } else {
    throw Error(`Error loading user. ${loadResult.msg}`);
  }
};

export const addProjectToUser = (
  kContext: KotoContext,
  projectId: ProjectId,
  user: KotoCRDT<User>
): KotoCRDT<User> => {
  return K.change(kContext, user, (doc) => {
    doc.projects.push(projectId);
  });
};

export const addProjectToUserId = async (
  kContext: KotoContext,
  projectId: ProjectId,
  userId: UserId
): Promise<K.KotoResult<KotoCRDT<User>>> => {
  const loadResult = await loadUser(kContext, userId);
  if (loadResult.type === "Success") {
    const userCrdt = loadResult.data;
    const newUser = K.change(kContext, userCrdt, (doc) => {
      doc.projects.push(projectId);
    });
    await K.storeCrdt(kContext, newUser);

    return { type: "Success", data: newUser };
  } else {
    return { type: "Error", msg: loadResult.msg };
  }
};
