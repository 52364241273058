/**
 * A Block is the primary container of information in Koto
 *
 * Each block has:
 *  1. an ID
 *  2. a specification
 *  3. a map of values
 *  4. metadata
 *
 * Each element of a block is split into separate parts.
 *
 */

import * as K from "./KotoOb";

import {
  BaseQuestionSpec,
  QuestionAnswer,
  QuestionTypeName,
  createQuestionSpecOfType,
} from "./Questions";
import {
  BlockId,
  BlockSpecId,
  FormId,
  ProjectId,
  QuestionSpecId,
  RecordId,
  RecordSetId,
  genBlockId,
  genBlockSpecId,
  genQuestionSpecId,
} from "./IdTypes";
import {
  KotoBaseDoc,
  KotoCRDT,
  KotoKey,
  KotoOb,
  KotoSerializedCRDT,
} from "./KotoTypes";
import { QueryParams, QueryParamsWithKey } from "./KotoStore";

import { KotoContext } from "./KotoContext";
import { getQuestionTypeConfig } from "./QuestionTypeRegistry";

interface QuestionAnswers {
  [questionId: string]: QuestionAnswer;
}

export interface Block extends KotoBaseDoc {
  id: BlockId;
  type: "Block";
  projectId: ProjectId;
  recordId: RecordId;
  recordSetId: RecordSetId;
  blockSpec: KotoOb<BlockSpec>;
  answers: QuestionAnswers;
  fromFormId: FormId | null;
}

const makeBlockSpecKey = (
  projectId: ProjectId,
  blockSpecId: BlockSpecId
): string => {
  return `${projectId}-${blockSpecId}`;
};

export interface BlockSpec extends KotoBaseDoc {
  id: BlockSpecId;
  type: "BlockSpec";
  title: K.KotoText;
  projectId: ProjectId;
  questions: BaseQuestionSpec[];
}

export const createBlockSpec = (
  kContext: KotoContext,
  projectId: ProjectId,
  title: string = "Sample Title",
  questions: BaseQuestionSpec[] = [
    createQuestionSpecOfType("shortTextQuestion"),
  ]
): KotoCRDT<BlockSpec> => {
  const blockSpecId = genBlockSpecId();

  return K.create(
    kContext,
    {
      id: blockSpecId,
      type: "BlockSpec",
      projectId: projectId,
      title: new K.KotoText(title),
      questions: questions,
    },
    `${projectId}-${blockSpecId}`
  );
};

export const createAndStoreBlockSpec = async (
  kContext: KotoContext,
  projectId: ProjectId,
  title: string = "Sample Title",
  questions: BaseQuestionSpec[] = [
    createQuestionSpecOfType("shortTextQuestion"),
  ]
): Promise<KotoCRDT<BlockSpec>> => {
  const blockSpec = createBlockSpec(kContext, projectId, title, questions);

  const result = await K.storeCrdt(kContext, blockSpec);

  if (result.type !== "Success") {
    throw new Error(
      `Failed to store BlockSpec ${blockSpec.key}: ${JSON.stringify(result)}`
    );
  }

  return blockSpec;
};

export const loadBlockSpec = async (
  kContext: KotoContext,
  projectId: ProjectId,
  blockSpecId: BlockSpecId
): Promise<K.KotoResult<KotoCRDT<BlockSpec>>> => {
  const queryParams: QueryParamsWithKey<KotoSerializedCRDT<BlockSpec>> = {
    type: "BlockSpec",
    params: [
      {
        fieldPath: "key",
        op: "==",
        value: makeBlockSpecKey(projectId, blockSpecId),
      },
    ],
  };

  return K.getCrdt<BlockSpec>(kContext, queryParams);
};

export const loadAllBlockSpecs = async (
  kContext: KotoContext,
  projectId: ProjectId
) => {
  const queryParams: QueryParams<KotoSerializedCRDT<BlockSpec>> = {
    type: "BlockSpec",
    params: [
      {
        fieldPath: "doc.projectId",
        op: "==",
        value: projectId,
      },
    ],
  };

  return K.getList<BlockSpec>(kContext, queryParams);
};

export const loadBlockSpecFromKey = async (
  kContext: KotoContext,
  key: KotoKey
): Promise<K.KotoResult<KotoCRDT<BlockSpec>>> => {
  const queryParams: QueryParamsWithKey<KotoSerializedCRDT<BlockSpec>> = {
    type: "BlockSpec",
    params: [
      {
        fieldPath: "key",
        op: "==",
        value: key,
      },
    ],
  };

  return K.getCrdt<BlockSpec>(kContext, queryParams);
};

export const createBlock = (
  kContext: KotoContext,
  projectId: ProjectId,
  formId: FormId | null,
  spec: KotoOb<BlockSpec>,
  recordSetId: RecordSetId,
  recordId: RecordId
): KotoCRDT<Block> => {
  const answers: QuestionAnswers = spec.doc.questions.reduce(
    (obj: QuestionAnswers, item) => {
      const answer = getQuestionTypeConfig(item.type).createQuestionAnswer(
        item
      );
      obj[item.id] = answer;
      return obj;
    },
    {}
  );

  const blockId = genBlockId();

  return K.create(
    kContext,
    {
      id: blockId,
      type: "Block",
      projectId: projectId,
      recordSetId: recordSetId,
      recordId: recordId,
      blockSpec: spec,
      answers: answers,
      fromFormId: formId,
    },
    `${projectId}-${recordId}-${blockId}`
  );
};

export const createAndStoreBlock = (
  kContext: KotoContext,
  projectId: ProjectId,
  formId: FormId,
  spec: KotoOb<BlockSpec>,
  recordSetId: RecordSetId,
  recordId: RecordId
): KotoCRDT<Block> => {
  const newBlock = createBlock(
    kContext,
    projectId,
    formId,
    spec,
    recordSetId,
    recordId
  );
  K.storeCrdt(kContext, newBlock);
  return newBlock;
};

export const updateTextAnswer = (
  context: KotoContext,
  answers: KotoCRDT<Block>,
  qid: QuestionSpecId,
  part: string,
  idx: number,
  characterList: string[]
): KotoCRDT<Block> => {
  const newAnswers = K.change(context, answers, (doc) => {
    const apart: any = doc.answers[qid].parts[part];
    if (apart !== undefined && apart instanceof K.KotoText) {
      // TODO: VSCode/typescript doesn't recognize that aPart is a KotoText and
      // so I have to force it to see the insertAt function
      apart.insertAt!(idx, ...characterList);
    } else {
      console.trace("Attempted to update a non-KotoText field.");
    }
  });

  return newAnswers;
};

const answerPartsExistInSpec = (
  spec: KotoOb<BlockSpec>,
  qid: QuestionSpecId,
  parts: string[]
): boolean => {
  const question = spec.doc.questions.find((question): boolean => {
    return qid === question.id;
  });

  if (question === undefined) {
    console.trace(`Question id ${qid} does not exist in spec ${spec.key}`);
    return false;
  }

  for (const part in parts) {
    if (!(parts[part] in question.parts)) {
      console.trace(`Question ${qid} does not have a ${part} part.`);
      return false;
    }
  }
  return true;
};

export const setAnswer = (
  context: KotoContext,
  spec: KotoOb<BlockSpec>,
  qid: QuestionSpecId,
  answer: QuestionAnswer,
  block: KotoCRDT<Block>
): KotoCRDT<Block> | Error => {
  if (!answerPartsExistInSpec(spec, qid, Object.keys(answer.parts))) {
    return Error("Part doesn't exist.");
  }

  const updated = K.change(context, block, (doc) => {
    doc.answers[qid] = answer;
  });
  return updated;
};

export const addQuestionToSpec = (
  context: KotoContext,
  blockSpec: KotoCRDT<BlockSpec>,
  questionType: QuestionTypeName
): KotoCRDT<BlockSpec> => {
  const newSpec = K.change(context, blockSpec, (doc) => {
    const newQuestionSpec = createQuestionSpecOfType(questionType);
    doc.questions.push(newQuestionSpec);
  });

  return newSpec;
};

export const deleteQuestionFromSpec = (
  context: KotoContext,
  blockSpec: KotoCRDT<BlockSpec>,
  qnum: number
): KotoCRDT<BlockSpec> => {
  return K.change(context, blockSpec, (doc) => {
    doc.questions.splice(qnum, 1);
  });
};

export const duplicateQuestionInSpec = (
  context: KotoContext,
  blockSpec: KotoCRDT<BlockSpec>,
  qnum: number
): KotoCRDT<BlockSpec> => {
  return K.change(context, blockSpec, (doc) => {
    const questionCopy = K.doDeepCopyInDoc(doc.questions[qnum]);
    questionCopy.id = genQuestionSpecId();
    doc.questions.splice(qnum + 1, 0, questionCopy);
  });
};

/**
 * Update Block with a new version of a spec
 *
 * When a BlockSpec changes then the corresponding Block may need to
 * change as well. This function looks at a new Spec and a Block and
 * updates the answers as needed.
 *
 * @param kContext A Koto Context
 * @param oldBlock The current version of the Block
 * @param newSpec New version of the BlockSpec
 *
 * @return An updated version of the Block
 */
export const updateBlock = (
  kContext: KotoContext,
  oldBlock: KotoCRDT<Block>,
  newSpec: KotoOb<BlockSpec>
): KotoCRDT<Block> => {
  // delete question answers
  const newAnswers = K.change(kContext, oldBlock, (doc) => {
    const specQids = newSpec.doc.questions.map(
      (questionSpec) => questionSpec.id
    );
    const answerQids = Object.keys(oldBlock.crdt.answers);
    const answersToDelete = answerQids.filter((qid) => !specQids.includes(qid));
    answersToDelete.map((qid) => {
      delete doc.answers[qid];
    });

    const oldSpec = oldBlock.crdt.blockSpec;

    const oldQTypeMap = oldSpec.doc.questions.reduce((prev, cur) => {
      prev[cur.id] = cur.type;
      return prev;
    }, {} as { [id: string]: string });

    newSpec.doc.questions.map((q) => {
      if (!(q.id in doc.answers) || q.type !== oldQTypeMap[q.id]) {
        doc.answers[q.id] = getQuestionTypeConfig(q.type).createQuestionAnswer(
          q
        );
      }
    });

    doc.blockSpec = newSpec;
  });
  return newAnswers;
};

export const loadBlocks = async (
  kContext: KotoContext,
  projectId: ProjectId,
  recordId: RecordId
): Promise<K.KotoResult<KotoCRDT<Block>[]>> => {
  const queryParams: QueryParams<KotoSerializedCRDT<Block>> = {
    type: "Block",
    params: [
      {
        fieldPath: "doc.projectId",
        op: "==",
        value: projectId,
      },
      {
        fieldPath: "doc.recordId",
        op: "==",
        value: recordId,
      },
    ],
  };

  return K.getList<Block>(kContext, queryParams);
};

export const subscribeBlocks = (
  kContext: KotoContext,
  projectId: ProjectId,
  recordId: RecordId,
  cb: (result: K.KotoResult<KotoCRDT<Block>[]>) => void,
  errorCb: (err: any) => void
): K.SubscriptionCanceler => {
  const queryParams: QueryParams<KotoSerializedCRDT<Block>> = {
    type: "Block",
    params: [
      {
        fieldPath: "doc.projectId",
        op: "==",
        value: projectId,
      },
      {
        fieldPath: "doc.recordId",
        op: "==",
        value: recordId,
      },
    ],
    orderBy: { field: "createdTimeInMilliseconds", direction: "desc" },
  };

  return K.subscribeList(kContext, queryParams, cb, errorCb);
};

export const updateBlockSpecTitle = (
  kContext: KotoContext,
  blockSpec: KotoCRDT<BlockSpec>,
  newTitle: string
): KotoCRDT<BlockSpec> => {
  return K.change(kContext, blockSpec, (doc) => {
    K.performUpdateText(doc.title, newTitle);
  });
};

export const updateQuestionSpec = (
  kContext: KotoContext,
  blockSpec: KotoCRDT<BlockSpec>,
  questionIndex: number,
  questionUpdater: (question: BaseQuestionSpec) => void
): KotoCRDT<BlockSpec> => {
  return K.change(kContext, blockSpec, (doc) => {
    questionUpdater(doc.questions[questionIndex]);
  });
};

export const updateQuestionAnswer = (
  kContext: KotoContext,
  block: KotoCRDT<Block>,
  questionSpecId: QuestionSpecId,
  answerUpdater: (
    questionSpec: BaseQuestionSpec,
    answer: QuestionAnswer
  ) => void
) => {
  const questionSpec = block.crdt.blockSpec.doc.questions.find((question) => {
    return question.id === questionSpecId;
  });

  if (questionSpec === undefined) {
    throw new Error(`Question Id ${questionSpecId} not found in block.`);
  }

  return K.change(kContext, block, (doc) => {
    answerUpdater(questionSpec, doc.answers[questionSpecId]);
  });
};

export const getBlocksMatchingBlockSpecs = async (
  kContext: KotoContext,
  projectId: ProjectId,
  recordId: RecordId,
  blockSpecIds: BlockSpecId[]
): Promise<K.KotoResult<KotoCRDT<Block>[]>> => {
  const queryParams: QueryParams<KotoSerializedCRDT<Block>> = {
    type: "Block",
    params: [
      {
        fieldPath: "doc.projectId",
        op: "==",
        value: projectId,
      },
      {
        fieldPath: "doc.recordId",
        op: "==",
        value: recordId,
      },
      {
        fieldPath: "doc.blockSpec.doc.id",
        op: "in",
        value: blockSpecIds,
      },
    ],
  };

  return K.getList<Block>(kContext, queryParams);
};
