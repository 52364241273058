import * as K from "../types/KotoOb";

import {
  MobileActionBar,
  Menu,
  MenuItem,
  MenuItemIcon,
  MenuItemList,
  SidebarMenuButton,
} from "../components/Buttons";
// import { CreateFormModal } from "../components/Modals";
import { ConfirmationModal, ShareFormModal } from "../components/Modals";
import {
  Form,
  archiveForm,
  deleteForm,
  subscribeToFormsForProject,
} from "../types/Form";
import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { buildFormCreatePath, buildFormDesignPath } from "./AppRoutes";
import { formListSlice, loadForms } from "../Store/formListSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { AppKotoContext } from "./AppKotoContext";
import { FlexibleList } from "../components/FlexibleLists";
import { KotoCRDT } from "../types/KotoTypes";
import { ListViewNav } from "../components/TopNavigation";
import { RootState } from "../Store/store";
import { formDesignerSlice } from "../Store/formDesignerSlice";
import { urlParams } from "../App/AppRoutes";
import { viewSlice } from "../Store/viewSlice";

export const AppFormsList: FunctionComponent<{}> = (_props) => {
  const dispatch = useDispatch();
  const { kContext } = useContext(AppKotoContext);
  const forms = useSelector((state: RootState) => state.formList.formsList);
  const ui = useSelector((state: RootState) => state.formList.ui, shallowEqual);
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const { projectId } = useParams<urlParams>();

  useEffect(() => {
    dispatch(loadForms(kContext, projectId));

    const unSub = subscribeToFormsForProject(
      kContext,
      projectId,
      false,
      false,
      (results) => {
        if (results.type === "Success") {
          dispatch(formListSlice.actions.loadList(results.data));
        } else if (results.type === "NotFound") {
          dispatch(formListSlice.actions.loadList([]));
        } else {
          console.error(results);
        }
      },
      (err) => {
        console.log(err);
      }
    );

    return () => {
      dispatch(formListSlice.actions.loadList([]));
      unSub();
    };
  }, [projectId]);

  const history = useHistory();
  // const [createFormModalOpen, setCreateFormModalOpen] = useState(false);

  const resultsString = "Showing 0 results";

  const handleAddForm = () => {
    history.push(buildFormCreatePath(projectId));
  };

  const [modalInfo, setModalInfo] = useState({
    open: false,
    setOpen: (val: boolean) => setModalInfo({ ...modalInfo, open: val }),
    modalType: "",
    entityString: "",
    confirmationFunc: () => {},
  });

  const createDeleteFormHandler = (form: KotoCRDT<Form>) => {
    return async () => {
      setModalInfo({
        open: true,
        setOpen: modalInfo.setOpen,
        modalType: "delete",
        entityString: form.crdt.title.toString(),
        confirmationFunc: async () => {
          const deletedForm = deleteForm(kContext, form);
          await K.storeCrdt(kContext, deletedForm);
          modalInfo.setOpen(false);
          dispatch(loadForms(kContext, projectId));
        },
      });
    };
  };

  const createArchiveFormHandler = (form: KotoCRDT<Form>) => {
    return async () => {
      setModalInfo({
        open: true,
        setOpen: modalInfo.setOpen,
        modalType: "archive",
        entityString: form.crdt.title.toString(),
        confirmationFunc: async () => {
          const archivedForm = archiveForm(kContext, form);
          await K.storeCrdt(kContext, archivedForm);
          modalInfo.setOpen(false);
          dispatch(loadForms(kContext, projectId));
        },
      });
    };
  };

  const createFormHistoryHandler = (form: KotoCRDT<Form>) => {
    return async () => {
      dispatch(formDesignerSlice.actions.setDesignerView("history"));
      history.push(buildFormDesignPath(projectId, form.crdt.id));
    };
  };

  const [shareModalButtonOpen, setShareModalButtonOpen] = useState(false);
  const [shareModalActiveTabLabel, setShareModalActiveTabLabel] = useState(
    "url"
  );
  const [shareModalFormId, setShareModalFormId] = useState("");
  const recordSets = useSelector((state: RootState) => {
    return state.projects.projects[projectId].crdt.recordSets;
  });

  const createShareFormHandler = (form: KotoCRDT<Form>) => {
    return async () => {
      setShareModalButtonOpen(true);
      setShareModalFormId(form.crdt.id);
    };
  };

  let disableButton: boolean;
  if (forms.length === 0) {
    disableButton = true;
  } else {
    disableButton = false;
  }

  return (
    <>
      {/* IF seclect all is used, show Delete and Archive buttons in  top nav */}
      <ListViewNav
        title="Forms"
        results={resultsString}
        setSideNavExpanded={() => {
          dispatch(viewSlice.actions.setNavVisible(true));
        }}
      >
        <button
          className="btn btn-teal  mr-2"
          onClick={handleAddForm}
          disabled={disableButton}
          data-tooltip="Add a form"
          tooltip-position="bottom"
        >
          <i className="fa-fw fas fa-plus"></i>
          <span className="hidden lg:inline-block ml-2">Create a Form</span>
        </button>
        <div>
          <button
            className="btn btn-gray-100 btn-no-shadow btn-stacked-h"
            disabled={disableButton}
            data-tooltip="Filter list"
            tooltip-position="bottom"
          >
            <i className="fa-fw fal fa-sliders-v "></i>
            <span className="hidden xl:inline-block ml-2">Filter</span>
          </button>
          <button
            className="btn btn-gray-100 btn-no-shadow btn-stacked-h"
            disabled={disableButton}
            data-tooltip="Sort list"
            tooltip-position="bottom"
          >
            <i className="fa-fw far fa-sort-amount-down-alt"></i>
            <span className="hidden xl:inline-block ml-2">Sort</span>
          </button>
        </div>
        <Menu menuOpen={moreMenuOpen} setMenuOpen={setMoreMenuOpen}>
          <button
            className="btn btn-gray-100 btn-no-shadow ml-2"
            disabled={disableButton}
            data-tooltip="More options"
            tooltip-position="bottom"
          >
            <i className="fa-fw far fa-ellipsis-h"></i>
          </button>
          <MenuItemList position="bottom-right">
            <MenuItem>
              <MenuItemIcon fontAwesomeClassNames="far fa-archive"></MenuItemIcon>
              Show archived forms
            </MenuItem>
          </MenuItemList>
        </Menu>
      </ListViewNav>

      {forms.length === 0 && (
        <Fragment>
          <div className="w-11/12 mx-auto text-center my-4 px-8 py-20 border border-gray-300 rounded bg-white">
            <div className="">
              <img src="assets/EmptyForms.svg" className="w-32 mx-auto mb-4" />
            </div>

            <h1 className="font-bold text-3xl text-violet-500 leading-loose mx-auto">
              No Forms Yet
            </h1>

            <h2 className="font-normal text-xl text-gray-600 max-w-full sm:max-w-lg mx-auto">
              Create a form to get started. Forms let you create records or add
              information to them.
            </h2>
            {/* TODO: Figure out how to pass handleAddForm through to CreateFormModal componenet */}
            {/* <CreateFormModal
              modalOpen={createFormModalOpen}
              setModalOpen={setCreateFormModalOpen}
            > */}
            <button
              className="btn btn-teal mx-auto mt-8 text-normal"
              onClick={handleAddForm}
              data-tooltip="Create a form"
              tooltip-position="bottom"
            >
              <i className="fas fa-plus mr-2"></i>Create a Form
            </button>
            {/* </CreateFormModal> */}
          </div>
        </Fragment>
      )}
      {/* // TODO: Need to be able to set danger prop on menu items
          // TODO: Need to be able to open modal when clicking on menu items */}
      {forms.length > 0 && (
        <FlexibleList
          listItems={forms.map((form) => {
            const creationDate = new Date(form.crdt.metadata.creationTimestamp);

            const lastUpdatedTime = new Date(
              form.crdt.metadata.lastUpdatedClientTimestamp
            );

            return {
              ob: {
                id: form.crdt.id,
                title: form.crdt.title.toString(),
                description: form.crdt.description.toString(),
                creationTime: creationDate.toLocaleString(),
                lastUpdated: lastUpdatedTime.toLocaleString(),
              },
              link: buildFormDesignPath(projectId, form.crdt.id),
            };
          })}
          ui={ui}
          setUi={(ui) => dispatch(formListSlice.actions.setUi(ui))}
          menuItemMap={forms
            .map((form) => {
              return {
                [form.crdt.id]: [
                  {
                    iconClass: "fa-user-plus",
                    text: "Share",
                    action: createShareFormHandler(form),
                  },
                  {
                    iconClass: "fa-history",
                    text: "History",
                    action: createFormHistoryHandler(form),
                  },
                  {
                    iconClass: "fa-archive",
                    text: "Archive",
                    action: createArchiveFormHandler(form),
                  },
                  {
                    iconClass: "fa-trash-alt",
                    text: "Delete",
                    action: createDeleteFormHandler(form),
                  },
                ],
              };
            })
            .reduce((prev, next) => {
              return Object.assign(prev, next);
            }, {})}
        />
      )}

      <MobileActionBar extraClassNames="md:hidden">
        <SidebarMenuButton
          extraClassNames="flex-1"
          setSideNavExpanded={() => {
            dispatch(viewSlice.actions.setNavVisible(true));
          }}
        >
          <i className="fa-fw fas fa-bars" />
          <div className="block">Menu</div>
        </SidebarMenuButton>

        <button className="btn btn-teal flex-1" onClick={handleAddForm}>
          <i className="fa-fw fas fa-plus" />
          <div className="block">Add</div>
        </button>
        <button className="btn btn-ab flex-1">
          <Menu
            menuOpen={moreMenuOpen}
            setMenuOpen={setMoreMenuOpen}
            extraClassNames="flex-1"
          >
            <div className="flex-auto">
              <i className="fa-fw far fa-ellipsis-h" />
              <div className="block">More</div>
            </div>
            <MenuItemList position="bottom-right">
              <MenuItem>
                <MenuItemIcon fontAwesomeClassNames="far fa-archive"></MenuItemIcon>
                Show archived forms
              </MenuItem>
            </MenuItemList>
          </Menu>
        </button>
      </MobileActionBar>

      <ConfirmationModal
        modalOpen={modalInfo.open}
        setModalOpen={modalInfo.setOpen}
        confirmationType={modalInfo.modalType}
        entityString={modalInfo.entityString}
        confirmationFunc={modalInfo.confirmationFunc}
      ></ConfirmationModal>
      <ShareFormModal
        title="Share form"
        modalOpen={shareModalButtonOpen}
        setModalOpen={setShareModalButtonOpen}
        activeView={shareModalActiveTabLabel}
        selectTab={(label) => {
          setShareModalActiveTabLabel(label);
        }}
        projectId={projectId}
        formId={shareModalFormId}
        recordSets={recordSets}
      ></ShareFormModal>
    </>
  );
};
