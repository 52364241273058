import React from "react";
import firebase from "firebase/app";

export interface AppFirebaseContextProps {
  firebaseApp: firebase.app.App | null;
  firestoreDb: firebase.firestore.Firestore | null;
  firebaseAuthUi: firebaseui.auth.AuthUI | null;
}

export const AppFirebaseContext = React.createContext<AppFirebaseContextProps>({
  firebaseApp: null,
  firestoreDb: null,
  firebaseAuthUi: null,
});
