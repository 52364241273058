import { ChangesId, IdType, UserId } from "./IdTypes";

import { Doc as AutomergeDoc } from "automerge";
import { Serialized } from "../utils/automerge";

export type KotoKey = string;

export type KotoTypes =
  | "Project"
  | "Form"
  | "Record"
  | "Reminder"
  | "Block"
  | "BlockSpec"
  | "QuestionSpec"
  | "KMetadata"
  | "Test"
  | "TestWithText"
  | "Group"
  | "Permission"
  | "User"
  | "IndexedRecord"
  | "Change";

export interface KotoBaseMetadata {
  creationTimestamp: number;
  lastUpdatedClientTimestamp: number;
  lastUpdatedUserId: string;
  [name: string]: any;
}

export interface KotoBaseDoc {
  id: IdType;
  type: KotoTypes;
  metadata?: KotoBaseMetadata;
  [name: string]: any;
}

export type KotoFullOb<T> = T & { metadata: KotoBaseMetadata };

export interface KotoStorable {
  key: KotoKey;
  readonly type: KotoTypes;
  [name: string]: any;
}

export type Version = string;

export interface KotoCRDT<T extends KotoBaseDoc> {
  key: KotoKey;
  type: KotoTypes;
  kotoObVer: 1;
  crdt: AutomergeDoc<KotoFullOb<T>>;
  createdTimeInMilliseconds: number;
}

export const isCrdt = (ob: KotoStorable): ob is KotoCRDT<KotoBaseDoc> => {
  return ob.crdt !== undefined;
};

export interface KotoOb<T extends KotoBaseDoc> {
  readonly key: KotoKey;
  readonly type: KotoTypes;
  readonly kotoObVer: 1;
  readonly doc: Serialized<T & { metadata: KotoBaseMetadata }>;
  readonly docVersion: Version;
  readonly createdTimeInMilliseconds: number;
}

export interface KotoRef<T extends KotoBaseDoc> {
  readonly key: KotoKey;
  readonly type: T["type"];
}

export interface KotoSerializedCRDT<T extends KotoBaseDoc>
  extends KotoStorable {
  readonly kotoObVer: 1;
  readonly type: T["type"];
  readonly doc: Serialized<T>;
  readonly docVersion: string;
  readonly savedCRDT: Uint8Array;
  readonly createdTimeInMilliseconds: number;
}

export const isSerializedCrdt = (
  ob: KotoStorable
): ob is KotoSerializedCRDT<KotoBaseDoc> => {
  return ob.savedCRDT !== undefined;
};

export interface KotoSerializedChange<T extends KotoBaseDoc>
  extends KotoStorable {
  id: ChangesId;
  type: "Change";
  userId: UserId;
  kotoObVer: 1;
  forKey: KotoCRDT<T>["key"];
  forId: T["id"];
  forType: T["type"];
  changes: Uint8Array[];
}

export const KOTO_WORKER_KEY = "kotoWorker";
