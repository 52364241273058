import "firebase/analytics";

import React, { Fragment, FunctionComponent, useEffect } from "react";

import firebase from "firebase/app";

const analytics = firebase.analytics;

export interface AnalyticsPageViewProps {
  appName: string;
  pathName: string;
}

export const AnalyticsPageView: FunctionComponent<AnalyticsPageViewProps> = (
  props
) => {
  useEffect(() => {
    analytics().setCurrentScreen(props.pathName);
    analytics().logEvent("screen_view" as any, {
      appName: props.appName,
      screen_name: props.pathName,
    });
  }, [props.pathName]);

  return <Fragment></Fragment>;
};
