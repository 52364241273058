import * as K from "../../types/KotoOb";

import {
  BaseQuestionSpec,
  Choices,
  MultiChoicePart,
  QuestionAnswer,
  QuestionDesignProps,
  QuestionFillerProps,
  QuestionViewerProps,
  createBaseQuestionSpec,
} from "../../types/Questions";
import React, {
  ChangeEvent,
  Fragment,
  FunctionComponent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";

import { registerQuestionType } from "../../types/QuestionTypeRegistry";

export interface CheckboxQuestionSpec extends BaseQuestionSpec {
  parts: { choices: MultiChoicePart };
}

export interface CheckboxAnswer extends QuestionAnswer {
  parts: { choices: string[] };
}

const createCheckboxQuestionSpec = (
  choices: Choices = [],
  defaultChoices: string[] = [],
  allowOther: boolean = false
): CheckboxQuestionSpec => {
  return {
    ...createBaseQuestionSpec("checkboxQuestion"),
    parts: {
      choices: {
        default: defaultChoices,
        choices: choices,
        allowOther: allowOther,
      },
    },
  };
};

const createCheckboxAnswer = (spec: CheckboxQuestionSpec): CheckboxAnswer => {
  return {
    type: "checkboxAnswer",
    isAnswered: false,
    parts: { choices: spec.parts.choices.default },
  };
};

export const makeChoiceAdder = (label?: string) => {
  return (question: CheckboxQuestionSpec) => {
    if (question.type !== "checkboxQuestion") {
      throw new Error(
        `Question must be of type 'checkboxQuestion' but got '${question.type}' instead.`
      );
    }

    const choiceCount = question.parts.choices.choices.length + 1;
    question.parts.choices.choices.push(
      new K.KotoText(label || `Option ${choiceCount}`)
    );
  };
};

export const makeChoiceRemover = (choiceNum: number) => {
  return (question: CheckboxQuestionSpec) => {
    question.parts.choices.choices.deleteAt!(choiceNum);
  };
};

export const makeChoiceTextUpdater = (choiceNum: number, text: string) => {
  return (question: CheckboxQuestionSpec) => {
    K.performUpdateText(question.parts.choices.choices[choiceNum], text);
  };
};

export const CheckboxQuestionPartsDesigner: FunctionComponent<
  QuestionDesignProps<CheckboxQuestionSpec>
> = (props) => {
  const [activeInput, setActiveInput] = useState(-1);

  const currentInput = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (
      currentInput.current &&
      currentInput.current !== document.activeElement
    ) {
      currentInput.current.focus();
      currentInput.current.select();
    }
  });

  const updateChoiceText = (
    idx: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    props.setQuestion(makeChoiceTextUpdater(idx, event.target.value));
  };

  const removeChoice = (idx: number) => {
    props.setQuestion(makeChoiceRemover(idx));
  };

  const addChoice = () => {
    setActiveInput(props.questionSpec.parts.choices.choices.length);
    props.setQuestion(makeChoiceAdder());
  };

  const handleKeyPress = (
    idx: number,
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    const choiceCount = props.questionSpec.parts.choices.choices.length;
    if (event.key == "Enter") {
      if (idx === choiceCount - 1) {
        addChoice();
      } else {
        setActiveInput(idx + 1);
      }
    } else if (event.key == "Backspace") {
      if (event.currentTarget.value == "") {
        removeChoice(idx);
        setActiveInput(idx - 1);
      }
    }
  };

  return (
    <Fragment>
      {props.questionSpec.parts.choices.choices.map((choice, idx) => {
        return (
          <div className="mx-5 my-1 first:mt-5 flex" key={idx}>
            <div className="text-gray-800 py-3 pr-3">
              <input
                type="checkbox"
                className="form-checkbox rounded border border-gray-400  bg-gray-100"
                disabled={true}
              ></input>
            </div>
            <input
              className="form-input k_textInput flex-1 bg-white py-2 px-3"
              onChange={(e) => updateChoiceText(idx, e)}
              value={choice.toString()}
              ref={activeInput === idx ? currentInput : undefined}
              onBlur={() => setActiveInput(-1)}
              onKeyDown={(e) => handleKeyPress(idx, e)}
            ></input>
            <div className="flex-initial w-10 text-center">
              <button
                className="text-gray-500 hover:text-gray-800 py-3"
                onClick={() => {
                  removeChoice(idx);
                }}
                data-tooltip="Remove option"
                tooltip-position="bottom"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        );
      })}

      <div className="mx-5 my-1 first:mt-5 flex">
        <div className="text-gray-800 mt-2 pr-3">
          <input
            type="checkbox"
            className="form-checkbox rounded border border-gray-400  bg-gray-100"
            disabled={true}
          ></input>
        </div>
        <input
          onClick={() => {
            addChoice();
          }}
          className="form-input k_textInput flex-1 bg-white py-2 px-3"
          placeholder="Add an option"
          value={""}
          readOnly={true}
        ></input>
        <div className="flex-initial w-10 text-center"></div>
      </div>
    </Fragment>
  );
};

/**
 * Check if a given value is in the list of already selected answers.
 *
 * @param answer the answer object being checked
 * @param value the value we're looking up in the answer
 */
const hasAnswerSelected = (answer: CheckboxAnswer, value: string) => {
  return (
    answer.parts.choices.find((choice) => {
      return choice === value;
    }) !== undefined
  );
};

export const makeAnswerToggler = (value: string) => {
  return (spec: CheckboxQuestionSpec, answer: CheckboxAnswer) => {
    if (
      spec.parts.choices.choices.findIndex((choice) => {
        return choice.toString() === value;
      }) === -1
    ) {
      throw new Error(
        `The value ${value} is not valid for question ${
          spec.id
        }. Valid values are: ${JSON.stringify(
          spec.parts.choices.choices.map((c) => c.toString())
        )}`
      );
    }

    if (hasAnswerSelected(answer, value)) {
      const foundIndexes = [];
      for (let i = 0; i < answer.parts.choices.length; ) {
        const idx = answer.parts.choices.indexOf(value, i);
        if (idx !== -1) {
          foundIndexes.push(idx);
          i = idx + 1;
        } else {
          break;
        }
      }
      foundIndexes.reverse();
      foundIndexes.forEach((index) => {
        answer.parts.choices.splice(index, 1);
      });
    } else {
      answer.parts.choices.push(value);
    }

    answer.isAnswered = answer.parts.choices.length !== 0;
  };
};

export const CheckboxQuestionPartsFiller: FunctionComponent<
  QuestionFillerProps<CheckboxQuestionSpec, CheckboxAnswer>
> = (props) => {
  const updateChoice = (value: string) => {
    props.setAnswer(makeAnswerToggler(value));
  };

  return (
    <Fragment>
      <div>
        {props.spec.parts.choices.choices.map((choice, idx) => {
          const itemId = `${props.spec.id}.${idx}`;
          const label = choice.toString();
          const value = choice.toString();
          const name = `${props.spec.id}.question`;
          return (
            <label className="k_choiceLabel" htmlFor={itemId} key={itemId}>
              <input
                type="checkbox"
                className="form-checkbox k_choiceInput"
                name={name}
                disabled={false}
                id={itemId}
                value={value}
                checked={hasAnswerSelected(props.answer, value)}
                onChange={() => updateChoice(value)}
              />
              <div className="k_choiceText">{label}</div>
            </label>
          );
        })}
      </div>
    </Fragment>
  );
};

export const CheckboxAnswerViewer: FunctionComponent<
  QuestionViewerProps<CheckboxQuestionSpec, CheckboxAnswer>
> = (props) => {
  return (
    <div>
      {props.answer.parts.choices.map((choice, idx) => {
        return [idx > 0 && ", ", <span key={idx}>{choice}</span>];
      })}
    </div>
  );
};

registerQuestionType(
  "checkboxQuestion",
  "Checkbox Question",
  createCheckboxQuestionSpec,
  createCheckboxAnswer,
  CheckboxQuestionPartsDesigner,
  CheckboxQuestionPartsFiller,
  CheckboxAnswerViewer
);
