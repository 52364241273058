import React, { FunctionComponent, useState, Fragment } from "react";
import { Expander, ExpanderBody, ExpanderCollapsedDescription } from "../Card";

interface AuthenticationProps {
  // TODO: Add activeSection to scroll to and expand a specific section of the settings
  //   activeSection: string;
}

// TODO: Allow each card to be expanded individually
export const Authentication: FunctionComponent<AuthenticationProps> = (
  _props
) => {
  const [twoFactorExpanded, setTwoFactorExpanded] = useState(false);
  const [sessionExpanded, setSessionExpanded] = useState(false);
  const [passwordResetExpanded, setPasswordResetExpanded] = useState(false);

  return (
    <Fragment>
      <div className="my-8">
        <Expander
          isExpanded={true}
          title="Configure an authentication method"
          hideControl={true}
        >
          <ExpanderCollapsedDescription></ExpanderCollapsedDescription>
          <ExpanderBody>
            <div className="mt-2 flex items-center">
              <div className="flex-1">
                <div className="k_cardQuestionTitle">
                  <i className="fab fa-google mr-2"></i>Google Apps
                  authentication
                </div>
                <div className="k_cardQuestionDescription mr-8">
                  Let members sign in with a Google account
                </div>
              </div>
              <div className="flex-initial btn btn-gray">Configure</div>
            </div>
            <div className="mt-4 flex items-center">
              <div className="flex-1">
                <div className="k_cardQuestionTitle">
                  <i className="far fa-key mr-2"></i>SAML authentication
                </div>
                <div className="k_cardQuestionDescription mr-8">
                  Azure, Okta, and OneLogin, or your custom SAML 2.0 solution is
                  only available on the Plus plan.
                </div>
              </div>
              <div className="flex-initial btn btn-gray">Upgrade</div>
            </div>
          </ExpanderBody>
        </Expander>
        <Expander
          isExpanded={twoFactorExpanded}
          title="Project-wide two-factor authentication"
          hideControl={false}
          toggleExpanded={() => setTwoFactorExpanded(!twoFactorExpanded)}
        >
          <ExpanderCollapsedDescription>
            <div className="k_cardQuestionDescription">
              Require each user to configure and use two-factor authentication
              for signing in.
            </div>
          </ExpanderCollapsedDescription>
          <ExpanderBody>
            <button className="btn w-full">
              Activate two-factor authentication for my project
            </button>
          </ExpanderBody>
        </Expander>
        <Expander
          isExpanded={sessionExpanded}
          title="Session duration"
          hideControl={false}
          toggleExpanded={() => setSessionExpanded(!sessionExpanded)}
        >
          <ExpanderCollapsedDescription>
            <div className="k_cardQuestionDescription">
              Once logged in, users will remain signed in until they explicitly
              sign out. This setting allows you to force users to log in after a
              certain amount of time has elapsed since their last login.
            </div>
          </ExpanderCollapsedDescription>
          <ExpanderBody>
            <label className="k_cardQuestionDescription">
              <select className="form-select k_select mb-4">
                <option value="Allow infinite sessions (default)">
                  Allow infinite sessions (default)
                </option>
                <option value="Log users out when they close the application">
                  Log users out when they close the application
                </option>
                {/* TODO: If the user chooses this option, add a text box after this select menu allowing the user to enter a specific number of hours */}
                <option value="Log users out after a specific amount of hours (minimum 12)">
                  Log users out after a specific amount of hours (minimum 12)
                </option>
              </select>
            </label>
            <button className="btn btn-gray w-full">Save</button>
          </ExpanderBody>
        </Expander>
        <Expander
          isExpanded={passwordResetExpanded}
          title="Forced Password Reset"
          hideControl={false}
          toggleExpanded={() =>
            setPasswordResetExpanded(!passwordResetExpanded)
          }
        >
          <ExpanderCollapsedDescription>
            <div className="k_cardQuestionDescription">
              You can{" "}
              <span className="font-medium">
                force a reset of all passwords for all members in your project.
              </span>{" "}
              Each member will receive an email from Koto telling them that
              you’ve triggered the reset and will contain a password reset link.
            </div>
          </ExpanderCollapsedDescription>
          <ExpanderBody>
            <div className="k_cardQuestionDescription">
              <span className="font-medium">
                You can also (optionally) force every user session to terminate
                at the same time.
              </span>{" "}
              That means that every member will be instantly signed out of all
              Slack apps, and they will not be able to sign in again until they
              have changed their password.
            </div>
            <label className="k_cardQuestionDescription">
              <select className="form-select k_select mb-4">
                <option value="-- Please choose --">-- Please choose --</option>
                <option value="Sign everyone out of all apps (6 members)">
                  Sign everyone out of all apps (6 members)
                </option>
                <option value="Do NOT sign everyone out of all apps">
                  Do NOT sign everyone out of all apps
                </option>
              </select>
            </label>
            <div className="k_cardQuestionDescription mb-4">
              In order to create a new password, each member must be able to
              receive the email we send with the reset link.
            </div>
            <button className="btn btn-red w-full">
              Reset Passwords for all Project Members
            </button>
          </ExpanderBody>
        </Expander>
      </div>
    </Fragment>
  );
};
