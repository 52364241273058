import * as K from "./KotoOb";

import { KotoBaseDoc, KotoCRDT, KotoOb } from "./KotoTypes";
import { useEffect, useState } from "react";

import { KotoContext } from "./KotoContext";
import { useLocation } from "react-router-dom";

export const useCrdt = <T extends KotoBaseDoc>(
  kContext: KotoContext,
  ob: KotoOb<T> | null
) => {
  const [crdt, setCrdt] = useState(null as KotoCRDT<T> | null);

  useEffect(() => {
    let mounted = true;
    if (ob !== null) {
      const getCrdt = async () => {
        const fetchedCrdt = await K.getKotoCrdtFromKotoOb(kContext, ob);
        if (mounted) {
          setCrdt(fetchedCrdt);
        }
      };
      getCrdt();
    }
    return () => {
      mounted = false;
    };
  }, [kContext, ob]);

  return crdt;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
