import "react-datepicker/dist/react-datepicker.css";

import { Form, getFormInfoFromKey } from "../types/Form";
import { KotoCRDT, KotoKey } from "../types/KotoTypes";
import { Modal, ModalActionButton, ModalButton, ModalContent } from "./Modals";
import { ProjectId, RecordId, RecordSetId } from "../types/IdTypes";
import React, { FunctionComponent, useState } from "react";

import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { buildFormFillerForRecordPath } from "../App/AppRoutes";

export interface ReminderListItemType {
  status: "new" | "complete" | "in-progress";
  formTitle: string;
  formKey: string;
  date: string;
}

interface ReminderListProps {
  reminderList: ReminderListItemType[];
  addReminder: (formKey: KotoKey, dueDate: Date, assignee: string) => void;
  availableForms: KotoCRDT<Form>[];
  projectId: ProjectId;
  recordSetId: RecordSetId;
  recordId: RecordId;
}

export const ReminderListItem: FunctionComponent<ReminderListItemType> = (
  props
) => {
  if (props.status === "new") {
    return (
      <button className="text-left flex w-full bg-white border border-b-0 first:rounded-t last:rounded-b last:border-b py-4 px-5 items-center hover:bg-gray-200 hover:border-gray-600">
        <div className="flex-initial">
          <i className="text-lg fal fa-circle text-gray-400"></i>
        </div>
        <div className="flex-1 sm:flex ml-4">
          <div className="flex-1 font-medium text-gray-900">
            {props.formTitle}
          </div>
          <div className="flex-initial font-light sm:mr-6  text-gray-600">
            Due {props.date}
          </div>
        </div>
        <div className="flex-initial">
          <i className="fas fa-chevron-right text-gray-900"></i>
        </div>
      </button>
    );
  } else if (props.status === "complete") {
    return (
      <button
        className="text-left flex w-full bg-white border border-b-0 first:rounded-t last:rounded-b last:border-b py-4 px-5 items-center hover:bg-gray-200 hover:border-gray-600"
        disabled={true}
      >
        <div className="flex-initial">
          <i className="text-lg fas fa-check text-gray-500"></i>
        </div>
        <div className="flex-1 sm:flex ml-4">
          <div className="block sm:flex-1 font-medium text-gray-400 italic">
            {props.formTitle}
          </div>
          <div className="block sm:flex-initial font-light sm:mr-8 text-normal text-gray-400 italic">
            Completed {props.date}
          </div>
        </div>
      </button>
    );
  } else if (props.status === "in-progress") {
    return (
      <button className="text-left flex w-full bg-white border border-b-0 first:rounded-t last:rounded-b last:border-b py-4 px-5 items-center hover:bg-gray-200 hover:border-gray-600">
        <div className="flex-initial">
          <i className="text-lg fad fa-spinner-third text-violet-500"></i>
        </div>
        <div className="flex-1 sm:flex ml-4">
          <div className="flex-1 font-medium">{props.formTitle}</div>
          <div className="flex-initial font-light sm:mr-6 text-gray-600">
            Due {props.date}
          </div>
        </div>
        <div className="flex-initial">
          <i className="fas fa-chevron-right"></i>
        </div>
      </button>
    );
  } else {
    console.log(
      "At least of the ReminderListItemTypes has an unrecognized status type."
    );
    return <div></div>;
  }
};

export const ReminderList: FunctionComponent<ReminderListProps> = (props) => {
  const [reminderModalButtonOpen, setReminderModalButtonOpen] = useState(false);

  const [formChoice, setFormChoice] = useState(
    props.availableForms.length ? props.availableForms[0].key : ""
  );

  const [assignee, setAssignee] = useState("");

  const [dateChoice, setDateChoice] = useState(new Date());

  const formsList: { key: string; value: string; text: string }[] = [
    ...props.availableForms.map((ob) => {
      return { key: ob.key, value: ob.key, text: ob.crdt.title.toString() };
    }),
  ];

  return (
    <div className="mx-2 mt-8">
      <div className="max-w-3xl mx-auto">
        {props.reminderList
          .filter((item) => {
            return item.formKey !== "";
          })
          .map((item, index) => {
            const { formId } = getFormInfoFromKey(item.formKey);
            return (
              <Link
                key={`link-${index}`}
                to={buildFormFillerForRecordPath(
                  props.projectId,
                  formId,
                  props.recordSetId,
                  props.recordId
                )}
                className="no-underline"
              >
                <ReminderListItem
                  formTitle={item.formTitle}
                  formKey={item.formKey}
                  status={item.status}
                  date={item.date}
                  key={`link-${index}`}
                ></ReminderListItem>
              </Link>
            );
          })}

        <Modal
          title="Assign a Task"
          modalOpen={reminderModalButtonOpen}
          setModalOpen={(val) => {
            setReminderModalButtonOpen(val);
          }}
        >
          <ModalButton extraClassNames="btn btn-gray flex w-full mt-4 items-center justify-center">
            <i className="far fa-alarm-clock mr-2"></i>Assign a Task
          </ModalButton>
          <ModalContent>
            <form>
              <div className="k_cardQuestionTitle">
                What form would you like to assign?
              </div>
              {/* TODO: Display list of availabe forms in the menu below */}
              <div>
                <label>
                  <select
                    value={formChoice}
                    onChange={({ target: { value } }) => {
                      setFormChoice(value);
                    }}
                    className="form-select k_select"
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Select a form from your workspace
                    </option>
                    {formsList.map((formOptions) => {
                      return (
                        <option key={formOptions.key} value={formOptions.key}>
                          {formOptions.text}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </div>
              <div className="k_cardQuestionTitle">Choose an asignee</div>

              <div>
                <label>
                  <select
                    className="form-select k_select"
                    value={assignee}
                    onChange={({ target: { value } }) => {
                      setAssignee(value);
                    }}
                  >
                    <option value="" disabled selected>
                      Select a person or group
                    </option>
                    {/* TODO: Remove fake hardcoded data */}
                    <option value="Kavi Harshawat">Kavi Harshawat</option>
                    <option value="Ron Gorodetzky">Ron Gorodetzky</option>
                    <option value="Raphael Majma">Raphael Majma</option>
                    <option value="Travel team">Travel team</option>
                    <option value="Volunteers">Volunteers</option>
                  </select>
                </label>
              </div>
              {/* TODO: Add a real date and time picker here */}
              <div className="mt-3">
                <label className="flex-1  my-4">
                  <span className="k_cardQuestionTitle">Add due date</span>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <DatePicker
                      className="form-select k_select"
                      selected={dateChoice}
                      onChange={(d) => {
                        if (d instanceof Date) {
                          setDateChoice(d);
                        }
                      }}
                      placeholderText="Choose a date and time"
                    />
                  </div>
                </label>
              </div>
            </form>
          </ModalContent>
          {/* TODO: Clicking should add a reminder trigger to the current record with the correct due date + time */}
          <ModalActionButton
            onClick={() => {
              props.addReminder(formChoice, dateChoice, assignee);
              setReminderModalButtonOpen(false);
            }}
          >
            Assign
          </ModalActionButton>
        </Modal>
      </div>
    </div>
  );
};
