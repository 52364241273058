import Automerge, { Backend } from "automerge";
import { FrontendMessage, KotoChangeRequest } from "../../types/KotoOb";

import { KotoKey } from "../../types/KotoTypes";

const cache: { [key in KotoKey]: Automerge.BackendState } = {};

interface BaseWorkerMessage {
  type: string;
  data: any;
}

export type KWorkerMessage = InitializeMessage | ApplyRequestMessage;

const getState = (key: string) => {
  const state = cache[key];

  if (state === undefined) {
    throw new Error(`Could not find key ${key} in backend state.`);
  }

  return state;
};

export interface InitializeMessage extends BaseWorkerMessage {
  type: "initialize";
  data: { key: string };
}
export const initialize = async (message: InitializeMessage) => {
  cache[message.data.key] = Automerge.Backend.init();
};

export interface ApplyRequestMessage extends BaseWorkerMessage {
  type: "applyRequest";
  data: KotoChangeRequest;
}

export const applyLocalRequest = async (
  message: ApplyRequestMessage
): Promise<FrontendMessage> => {
  const state = getState(message.data.key);
  const [newState, patch] = Backend.applyLocalChange(
    state,
    message.data.request
  );

  cache[message.data.key] = newState;

  return { type: "patch", data: { key: message.data.key, patch: patch } };
};

export interface SaveRequestMessage extends BaseWorkerMessage {
  type: "save";
  data: { key: KotoKey };
}

export const save = async (
  message: SaveRequestMessage
): Promise<FrontendMessage> => {
  const state = getState(message.data.key);
  const encDoc = Backend.save(state);
  return {
    type: "encodedDocument",
    data: { key: message.data.key, encodedDocument: encDoc },
  };
};

export interface LoadRequestMessage extends BaseWorkerMessage {
  type: "load";
  data: { key: KotoKey; serializedData: any };
}

export const load = async (
  message: LoadRequestMessage
): Promise<FrontendMessage> => {
  const state = Backend.load(message.data.serializedData);

  cache[message.data.key] = state;

  const patch = Backend.getPatch(state);
  return {
    type: "decodedDocument",
    data: { key: message.data.key, patch: patch },
  };
};

export interface GetVersionRequestMessage extends BaseWorkerMessage {
  type: "getVersion";
  data: { key: KotoKey };
}
export const getVersion = async (
  message: GetVersionRequestMessage
): Promise<FrontendMessage> => {
  const state = getState(message.data.key) as any;
  return {
    type: "version",
    data: {
      key: message.data.key,
      version: state
        .getIn(["opSet", "states"])
        .map((seqs: any) => seqs.size)
        .toJSON(),
    },
  };
};

export const router: {
  [
    command: string
  ]: any /* (message: KWorkerMessage) => FrontendMessage | void */;
} = {
  initialize: initialize,
  applyPatch: applyLocalRequest,
};
