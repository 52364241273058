import * as K from "../types/KotoOb";

import { Icon, Menu, MenuItem, MenuItemIcon, MenuItemList } from "./Buttons";
import { Project, archiveProject, deleteProject } from "../types/Project";
import React, { Fragment, FunctionComponent, useState } from "react";

import { ConfirmationModal } from "../components/Modals";
import { KotoCRDT } from "../types/KotoTypes";
import { KotoContext } from "../types/KotoContext";
import { Link } from "react-router-dom";
import { ProjectId } from "../types/IdTypes";

export interface NavigationListItemProps {
  subtitle?: string;
  href: string;
}

export const NavigationListItem: FunctionComponent<NavigationListItemProps> = (
  props
) => {
  let subtitleClassName = "flex-initial";
  if (props.subtitle !== undefined) {
    subtitleClassName = "flex-initial font-normal text-gray-500 pr-4";
  }
  return (
    <Fragment>
      <Link
        className="w-full bg-white border border-gray-300 mx-auto -mb-px sm:max-w-3xl text-left flex text-gray-700 no-underline cursor-pointer font-medium  first:rounded-t last:rounded-b hover:border-0 k_navList"
        to={props.href}
      >
        <div className="w-full px-4 py-3 hover:z-10 hover:border hover:border-gray-500 hover:bg-gray-300 flex ">
          <span className="flex-grow">{props.children}</span>
          <span className={subtitleClassName}>{props.subtitle}</span>
          <span className="flex-initial">
            <Icon fontAwesomeClassNames="fas fa-chevron-right"></Icon>
          </span>
        </div>
      </Link>
    </Fragment>
  );
};

export interface NavigationListGroupProps {
  title?: string;
}

export const NavigationListGroup: FunctionComponent<NavigationListGroupProps> = (
  props
) => {
  let titleClassName = "";
  if (props.title !== undefined) {
    titleClassName =
      "text-gray-700 font-medium w-full mx-auto sm:max-w-3xl my-2";
  }

  return (
    <Fragment>
      <div className="mx-4 sm:mx-0 mt-4 mb-8 sm:my-8">
        <div className={titleClassName}>{props.title}</div>
        <div>{props.children}</div>
      </div>
    </Fragment>
  );
};

export interface ProjectListProps {
  kContext: KotoContext;
  projects: KotoCRDT<Project>[];
  projectsReloader: () => void;
}

export const ProjectList: FunctionComponent<ProjectListProps> = (props) => {
  const [confirmationModalInfo, setConfirmationModalInfo] = useState({
    open: false,
    setOpen: (val: boolean) =>
      setConfirmationModalInfo({ ...confirmationModalInfo, open: val }),
    modalType: "",
    entityString: "",
    confirmationFunc: () => {},
  });

  const createDeleteProjectHandler = (project: KotoCRDT<Project>) => {
    return async () => {
      setConfirmationModalInfo({
        open: true,
        setOpen: confirmationModalInfo.setOpen,
        modalType: "delete",
        entityString: project.crdt.name.toString(),
        confirmationFunc: async () => {
          const deletedProject = deleteProject(props.kContext, project);
          await K.storeCrdt(props.kContext, deletedProject);
          confirmationModalInfo.setOpen(false);
          props.projectsReloader();
        },
      });
    };
  };

  const createArchiveRecordHandler = (project: KotoCRDT<Project>) => {
    return async () => {
      setConfirmationModalInfo({
        open: true,
        setOpen: confirmationModalInfo.setOpen,
        modalType: "archive",
        entityString: project.crdt.name.toString(),
        confirmationFunc: async () => {
          const archivedProject = archiveProject(props.kContext, project);
          await K.storeCrdt(props.kContext, archivedProject);
          confirmationModalInfo.setOpen(false);
          props.projectsReloader();
        },
      });
    };
  };

  return (
    <div>
      {props.projects.map((project) => {
        return (
          <ProjectListItem
            key={project.crdt.id}
            projectTitle={project.crdt.name.toString()}
            projectId={project.crdt.id}
            archiveHandler={createArchiveRecordHandler(project)}
            deleteHandler={createDeleteProjectHandler(project)}
          />
        );
      })}
      <ConfirmationModal
        modalOpen={confirmationModalInfo.open}
        setModalOpen={confirmationModalInfo.setOpen}
        confirmationType={confirmationModalInfo.modalType}
        entityString={confirmationModalInfo.entityString}
        confirmationFunc={confirmationModalInfo.confirmationFunc}
      ></ConfirmationModal>
    </div>
  );
};

export interface ProjectListItemProps {
  projectTitle: string;
  projectId: ProjectId;
  archiveHandler: () => void;
  deleteHandler: () => void;
}

export const ProjectListItem: FunctionComponent<ProjectListItemProps> = (
  props
) => {
  const projectInitials = props.projectTitle.split(" ").map((n) => n[0]);
  const [moreMenuButtonOpen, setMoreMenuButtonOpen] = useState(false);

  return (
    <Fragment>
      <div className="relative k_projectListItem">
        <Link
          to={`/p/${props.projectId}`}
          className="flex no-underline rounded text-gray-800 hover:text-gray-900 -mb-px z-0 "
        >
          <div
            className="k_projectListIcon flex-initial items-center w-12 border border-orange-700 bg-orange-400 text-orange-900 text-center font-extrabold py-3 "
            data-tooltip={props.projectTitle}
            tooltip-position="right"
          >
            {projectInitials}
          </div>

          <div className="k_projectListName flex-grow bg-white py-2 px-4 flex border border-l-0 border-gray-300 items-center">
            <div className="flex-initial"></div>
            {props.projectTitle}
            <div className="flex-grow"></div>
            {/* <span className="flex-initial text-right">
              <Icon fontAwesomeClassNames="fas fa-chevron-right"></Icon>
            </span> */}
          </div>
        </Link>

        {/* TODO: Only allow user to archive or delete if they have permission */}
        <Menu menuOpen={moreMenuButtonOpen} setMenuOpen={setMoreMenuButtonOpen}>
          <button className="absolute bottom-0 right-0 btn btn-sm k_projectListItem-more-btn mr-4 mb-2 z-10">
            <i className="far fa-ellipsis-h"></i>
          </button>

          <MenuItemList position="bottom-right" extraClassNames="sm:w-auto">
            {/* TODO: Link to workspace settings page when available */}
            <MenuItem
              extraClassNames="border-b border-gray-300"
              linkTo={`/p/${props.projectId}/settings`}
            >
              <MenuItemIcon fontAwesomeClassNames="far fa-cog" />
              Workspace settings
            </MenuItem>
            <MenuItem onClick={props.archiveHandler}>
              <MenuItemIcon fontAwesomeClassNames="far fa-archive" />
              Archive
            </MenuItem>
            {/* TODO: Add a confirmation message here before allowing the user to delete */}
            <MenuItem danger={true} onClick={props.deleteHandler}>
              <MenuItemIcon fontAwesomeClassNames="far fa-trash" />
              Delete
            </MenuItem>
          </MenuItemList>
        </Menu>
      </div>
    </Fragment>
  );
};
