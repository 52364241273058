import React, { useState, Fragment, PropsWithChildren, useRef } from "react";

export interface Selectable {
  id: string;
  label: string;
  [k: string]: any;
}

interface PillChooserProps<T extends Selectable> {
  selections: T[];
  getFilteredSelections: (partial: string) => T[];
  placeholder: string;
  setSelections: (selections: T[]) => Promise<void>;
}

export const PillChooser = <T extends Selectable>(
  props: PropsWithChildren<PillChooserProps<T>>
) => {
  const [currentTyping, setCurrentTyping] = useState("");

  const [matchingSelections, setMatchingSelections] = useState([] as T[]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleTextInput = (value: string) => {
    setCurrentTyping(value);
    setMatchingSelections(props.getFilteredSelections(value));
  };

  const makeSelectionRemover = (idx: number) => {
    return async () => {
      const newSelection = [...props.selections];
      newSelection.splice(idx, 1);

      props.setSelections(newSelection);

      matchingSelections.push(props.selections[idx]);

      setMatchingSelections(matchingSelections);
    };
  };

  const makeSelectionAdder = (s: T) => {
    return async () => {
      props.setSelections([...props.selections, s]);
      matchingSelections.splice(
        matchingSelections.findIndex((val) => val === s),
        1
      );
      setMatchingSelections(matchingSelections);
    };
  };
  return (
    <Fragment>
      <div>
        {props.selections.map((s, idx) => {
          return (
            <div key={idx} onClick={makeSelectionRemover(idx)}>
              {s.label}
            </div>
          );
        })}
      </div>
      <input
        ref={inputRef}
        className="k_textInput form-input pl-8 "
        placeholder="Search for one or more form fields"
        value={currentTyping}
        onFocus={({ target: { value } }) => handleTextInput(value)}
        onChange={({ target: { value } }) => handleTextInput(value)}
      ></input>
      <div>
        {matchingSelections.map((s, idx) => {
          return (
            <div
              className="text-red-600"
              key={idx}
              onClick={makeSelectionAdder(s)}
            >
              {s.label}
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};
