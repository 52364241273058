import {
  ACCOUNT_ROUTE,
  BASE_PROJECT_ROUTE,
  FORMS_CREATE_ROUTE,
  FORMS_DESIGN_ROUTE,
  FORMS_FILLER_PREVIEW_ROUTE,
  FORMS_FILLER_ROUTE,
  FORMS_ROUTE,
  LOGOUT_ROUTE,
  PROJECTS_CREATE_DETAIL_ROUTE,
  PROJECTS_CREATE_INVITE_ROUTE,
  PROJECTS_CREATE_ROUTE,
  PROJECT_ADMIN_BILLING_ROUTE,
  PROJECT_ADMIN_GROUPS_ROUTE,
  PROJECT_ADMIN_MEMBERS_ROUTE,
  PROJECT_ADMIN_SETTINGS_ROUTE,
  PROJECT_PREFERENCES_LOCALE_ROUTE,
  PROJECT_PREFERENCES_THEMES_ROUTE,
  PROJECT_SETTINGS_ROUTE,
  RECORDS_LIST_ROUTE,
  RECORDS_ROUTE,
  RECORD_EDIT_ROUTE,
  RECORD_ID_ROUTE,
  REMINDERS_ROUTE,
  buildAccountsSettingsPath,
  buildRecordsListPath,
  urlParams,
} from "./AppRoutes";
import {
  AppAccountSettingsList,
  AppAccountSettingsSettings,
} from "./AppAccountSettings";
import { AppFormCreate, AppFormDesigner } from "./AppFormDesigner";
import {
  AppPreferencesLocale,
  AppPreferencesThemes,
  AppProjectAdminBilling,
  AppProjectAdminGroups,
  AppProjectAdminMembers,
  AppProjectAdminSettings,
  AppProjectSettingsList,
} from "./AppProjectSettings";
import {
  AppProjectCreate,
  AppProjectDetails,
  AppProjectInvite,
  AppProjectsPage,
} from "./AppProjectsPage";
import React, { Fragment, FunctionComponent, useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { AppFormFiller } from "./AppFormFiller";
import { AppFormPreview } from "./AppFormPreview";
import { AppFormsList } from "./AppForms";
import { AppNav } from "./AppNav";
import { AppRecordEdit } from "./AppRecordEdit";
import { AppRecordView } from "./AppRecordView";
import { AppRecordsList } from "./AppRecordsList";
import { AppRemindersList } from "./AppRemindersList";
import { LoadingAnimation } from "../components/LoadingAnimation";
import { NoMatch } from "./AppError";
import { RootState } from "../Store/store";
import { loadBlockSpecs } from "../Store/blockSpecSlice";
import { logout } from "../Store/authSlice";
import { useKContext } from "./AppKotoContext";

export const InProjectView: FunctionComponent<{}> = (_props: any) => {
  const kContext = useKContext();

  const navVisible = useSelector((state: RootState) => {
    return state.view.navigation.visible;
  });

  const { projectId } = useParams<urlParams>();
  const dispatch = useDispatch();

  const projects = useSelector((state: RootState) => {
    return state.projects.projects;
  });

  const project = projects[projectId];

  useEffect(() => {
    dispatch(loadBlockSpecs(kContext, projectId));
  }, [projectId, kContext]);

  const defaultRecordSetId = project
    ? Object.entries(project.crdt.recordSets)[0][0]
    : "default";

  return (
    <Fragment>
      <AppNav></AppNav>
      {project ? (
        <Fragment>
          <div
            className={`ml-0 pb-48 ${
              navVisible && "xl:ml-sidebar"
            } relative overflow-visible`}
            id="page-content"
          >
            <Switch>
              <Route exact path={PROJECT_ADMIN_SETTINGS_ROUTE}>
                <AppProjectAdminSettings />
              </Route>
              <Route exact path={PROJECT_ADMIN_MEMBERS_ROUTE}>
                <AppProjectAdminMembers></AppProjectAdminMembers>
              </Route>
              <Route exact path={PROJECT_ADMIN_GROUPS_ROUTE}>
                <AppProjectAdminGroups></AppProjectAdminGroups>
              </Route>
              <Route exact path={PROJECT_SETTINGS_ROUTE}>
                <AppProjectSettingsList />
              </Route>
              <Route exact path={PROJECT_ADMIN_BILLING_ROUTE}>
                <AppProjectAdminBilling />
              </Route>
              <Route exact path={PROJECT_PREFERENCES_LOCALE_ROUTE}>
                <AppPreferencesLocale />
              </Route>
              <Route exact path={PROJECT_PREFERENCES_THEMES_ROUTE}>
                <AppPreferencesThemes />
              </Route>
              <Route exact path={FORMS_ROUTE}>
                <AppFormsList />
              </Route>
              <Route path={FORMS_FILLER_ROUTE}>
                <AppFormFiller />
              </Route>
              <Route exact path={FORMS_DESIGN_ROUTE}>
                <AppFormDesigner />
              </Route>
              <Route exact path={FORMS_CREATE_ROUTE}>
                <AppFormCreate />
              </Route>
              <Route exact path={REMINDERS_ROUTE}>
                <AppRemindersList />
              </Route>
              <Route exact path={RECORDS_ROUTE}>
                <Redirect
                  to={buildRecordsListPath(projectId, defaultRecordSetId)}
                />
              </Route>

              <Route exact path={RECORD_ID_ROUTE}>
                <AppRecordView />
              </Route>
              <Route exact path={RECORDS_LIST_ROUTE}>
                <AppRecordsList />
              </Route>
              <Route exact path={RECORD_EDIT_ROUTE}>
                <AppRecordEdit />
              </Route>

              <Route exact path={BASE_PROJECT_ROUTE}>
                <Redirect
                  to={buildRecordsListPath(projectId, defaultRecordSetId)}
                />
              </Route>
              <Route>
                <NoMatch />
              </Route>
            </Switch>
          </div>
        </Fragment>
      ) : (
        <LoadingAnimation />
      )}
    </Fragment>
  );
};

interface AppLoggedInViewProps {}

export const AppLoggedInView: FunctionComponent<AppLoggedInViewProps> = (
  _props
) => {
  return (
    <Switch>
      <Route exact path="/">
        <AppProjectsPage />
      </Route>
      <Route exact path={PROJECTS_CREATE_ROUTE}>
        <AppProjectCreate />
      </Route>
      <Route exact path={PROJECTS_CREATE_DETAIL_ROUTE}>
        <AppProjectDetails />
      </Route>
      <Route exact path={PROJECTS_CREATE_INVITE_ROUTE}>
        <AppProjectInvite />
      </Route>
      <Route exact path={FORMS_FILLER_PREVIEW_ROUTE}>
        <AppFormPreview />
      </Route>
      <Route path={BASE_PROJECT_ROUTE}>
        <InProjectView />
      </Route>
      <Route exact path={buildAccountsSettingsPath("settings")}>
        <AppAccountSettingsSettings />
      </Route>
      <Route exact path={ACCOUNT_ROUTE}>
        <AppAccountSettingsList />
      </Route>
      <Route exact path={LOGOUT_ROUTE} component={AppLogoutPage} />
      <Route>
        <NoMatch />
      </Route>
    </Switch>
  );
};

export const AppLogoutPage = (_props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(logout(history));
  }, []);

  return <Fragment />;
};
