import React, { FunctionComponent, Fragment } from "react";
import { Icon } from "./Buttons";
import { Overlay } from "./Overlay";
import { createPortal } from "react-dom";

interface ReArrangeRowProps {}

export const ReArrangeRow: FunctionComponent<ReArrangeRowProps> = (props) => {
  return (
    <Fragment>
      <div className="k_rearrangeRow">
        <div className="k_rearrangeGrip ">
          <Icon fontAwesomeClassNames="fas fa-grip-vertical"></Icon>
        </div>
        <div className="k_cardQuestionTitle k_rearrangeName">
          {props.children}
        </div>
        <div className="k_rearrangeButton">
          <button
            className="btn btn-stacked-h my-2 "
            data-tooltip="Move down"
            tooltip-position="bottom"
          >
            <Icon fontAwesomeClassNames="fas fa-chevron-down"></Icon>
          </button>
          <button
            className="btn btn-stacked-h my-2 "
            data-tooltip="Move up"
            tooltip-position="bottom"
          >
            <Icon fontAwesomeClassNames="fas fa-chevron-up"></Icon>
          </button>
        </div>
      </div>
    </Fragment>
  );
};

interface ReArrangeGroupProps {}

export const ReArrangeGroup: FunctionComponent<ReArrangeGroupProps> = (
  props
) => {
  return (
    <Fragment>
      <div className="mb-6">{props.children}</div>
    </Fragment>
  );
};

interface ReArrangeModalProps {
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
}

export const ReArrangeModal: FunctionComponent<ReArrangeModalProps> = (
  props
) => {
  const toggleModal = (): void => {
    props.setModalOpen(!props.modalOpen);
  };

  const component = (
    <Fragment>
      {props.modalOpen && (
        <Overlay toggle={toggleModal}>
          <div className="mobile-modal sm:max-w-3xl sm:relative sm:rounded">
            <div className="relative">
              <div className="p-8 bg-white sticky top-0">
                <div className="k_cardSectionTitle">Re-arrange</div>
                <div className="k_cardQuestionDescription">
                  Use the arrow buttons or drag the questions and sections to
                  re-order them below.
                </div>
              </div>
              <div className="px-8">
                <div className="">{props.children}</div>
              </div>
              <div className="flex p-8 bg-white sticky bottom-0">
                <button
                  className="btn btn-gray flex-grow"
                  onClick={toggleModal}
                >
                  Close
                  {console.log(props.modalOpen)}
                </button>
              </div>
            </div>
          </div>
        </Overlay>
      )}
    </Fragment>
  );

  const modalElement = document.getElementById("modal-root");

  if (modalElement === null) {
    return <div>NO MODAL ROOT ELEMENT!!!!</div>;
  }

  return createPortal(component, modalElement);
};
