import React, { FunctionComponent, Fragment } from "react";

// TODO: Make this work with a list of Multiplayer Icons. When there are more than X Multiplayer Icons use MultiplayerMoreButton to show "+Y more"
// TODO: When a user hovers over an icon, show a tooltip with full name

interface MultiplayerIconProps {
  name: string; // Name of user
  color: string; // Color of user's icon
  tooltip: string;
  tooltipPosition: string;
}

export const MultiplayerIcon: FunctionComponent<MultiplayerIconProps> = (
  props
) => {
  const initials = props.name.split(" ").map((n) => n[0]);
  let className =
    "btn k_mpIcon border-gray-800 hover:border-gray-900 bg-gray-500 hover:bg-gray-700";

  if (props.color == "red") {
    className =
      "btn k_mpIcon border-red-800 hover:border-red-900 bg-red-500 hover:bg-red-700";
  } else if (props.color == "orange") {
    className =
      "btn k_mpIcon border-orange-800 hover:border-orange-900 bg-orange-500 hover:bg-orange-700";
  } else if (props.color == "yellow") {
    className =
      "btn k_mpIcon border-yellow-800 hover:border-yellow-900 bg-yellow-500 hover:bg-yellow-700";
  } else if (props.color == "green") {
    className =
      "btn k_mpIcon border-green-800 hover:border-green-900 bg-green-500 hover:bg-green-700";
  } else if (props.color == "teal") {
    className =
      "btn k_mpIcon border-teal-800 hover:border-teal-900 bg-teal-500 hover:bg-teal-700";
  } else if (props.color == "blue") {
    className =
      "btn k_mpIcon border-blue-800 hover:border-blue-900 bg-blue-500 hover:bg-blue-700";
  } else if (props.color == "indigo") {
    className =
      "btn k_mpIcon border-indigo-800 hover:border-indigo-900 bg-indigo-500 hover:bg-indigo-700";
  } else if (props.color == "purple") {
    className =
      "btn k_mpIcon border-purple-800 hover:border-purple-900 bg-purple-500 hover:bg-purple-700";
  } else if (props.color == "pink") {
    className =
      "btn k_mpIcon border-pink-800 hover:border-pink-900 bg-pink-500 hover:bg-pink-700";
  }

  return (
    <Fragment>
      <button
        className={className}
        data-tooltip={props.tooltip}
        tooltip-position={props.tooltipPosition}
      >
        {initials}
      </button>
    </Fragment>
  );
};

interface MultiplayerMoreButtonProps {
  number: string; // Number of add'l users hidden
}

// TODO: Expand when clicked to show additional MultiplayerIcons
export const MultiplayerMoreButton: FunctionComponent<MultiplayerMoreButtonProps> = (
  props
) => {
  return (
    <Fragment>
      <button
        className="k_mpIcon text-xs hover:text-white border-gray-800 hover:border-gray-900 bg-gray-600 hover:bg-gray-700"
        data-tooltip="Show all"
        tooltip-position="bottom"
      >
        +{props.number}
      </button>
    </Fragment>
  );
};
