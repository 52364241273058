import {
  NavigationListGroup,
  NavigationListItem,
} from "../components/NavigationList";
import React, { Fragment, FunctionComponent } from "react";
import {
  buildProjectAdminBillingPath,
  buildProjectAdminGroupsPath,
  buildProjectAdminMembersPath,
  buildProjectPreferencesLocalePath,
  buildProjectPreferencesThemesPath,
  buildProjectSettingsPath,
} from "./AppRoutes";
import { buildProjectAdminSettingsPath, urlParams } from "./AppRoutes";

import { AdministrativeSettings } from "../components/Settings/AdministrativeSettings";
import { Billing } from "../components/Settings/Billing";
import { EmptyViewNav } from "../components/TopNavigation";
import { LanguageRegion } from "../components/Settings/LanguageRegion";
import { ManageMemberSettings } from "../components/Settings/ManageMemberSettings";
import { RootState } from "../Store/store";
import { Themes } from "../components/Settings/Themes";
import { UserGroups } from "../components/Settings/UserGroups";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { viewSlice } from "../Store/viewSlice";

export const AppProjectSettingsList: FunctionComponent<{}> = (_props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { projectId } = useParams<urlParams>();
  const handleBackButton = () => {
    history.push(buildProjectSettingsPath(projectId));
  };

  return (
    <Fragment>
      <EmptyViewNav
        title="Workspace Settings"
        setSideNavExpanded={() => {
          dispatch(viewSlice.actions.setNavVisible(true));
        }}
        // TODO: Don't know how to handle back button here => what should this go to?
        handleBackButton={handleBackButton}
      ></EmptyViewNav>
      <NavigationListGroup title="Preferences">
        <NavigationListItem href={buildProjectPreferencesThemesPath(projectId)}>
          Themes
        </NavigationListItem>
        <NavigationListItem href={buildProjectPreferencesLocalePath(projectId)}>
          Language &amp; region
        </NavigationListItem>
      </NavigationListGroup>
      <NavigationListGroup title="Administration">
        <NavigationListItem href={buildProjectAdminSettingsPath(projectId)}>
          Workspace settings and permissions
        </NavigationListItem>
        <NavigationListItem href={buildProjectAdminMembersPath(projectId)}>
          Manage members
        </NavigationListItem>
        <NavigationListItem href={buildProjectAdminGroupsPath(projectId)}>
          Manage groups
        </NavigationListItem>
        <NavigationListItem href={buildProjectAdminBillingPath(projectId)}>
          Billing
        </NavigationListItem>
      </NavigationListGroup>
    </Fragment>
  );
};

export const AppProjectAdminMembers: FunctionComponent<{}> = (_props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { projectId } = useParams<urlParams>();
  const handleBackButton = () => {
    history.push(buildProjectSettingsPath(projectId));
  };
  return (
    <Fragment>
      <EmptyViewNav
        title="Manage members"
        setSideNavExpanded={() => {
          dispatch(viewSlice.actions.setNavVisible(true));
        }}
        handleBackButton={handleBackButton}
      ></EmptyViewNav>
      <ManageMemberSettings />
    </Fragment>
  );
};

export const AppProjectAdminSettings: FunctionComponent<{}> = (_props) => {
  const adminSettingsView = useSelector(
    (state: RootState) => state.adminSettings.adminSettingsView
  );

  return (
    <Fragment>
      <AdministrativeSettings adminSettingsView={adminSettingsView} />
    </Fragment>
  );
};

export const AppProjectAdminGroups: FunctionComponent<{}> = (_props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { projectId } = useParams<urlParams>();
  const handleBackButton = () => {
    history.push(buildProjectSettingsPath(projectId));
  };
  return (
    <Fragment>
      <EmptyViewNav
        title="Manage groups"
        setSideNavExpanded={() => {
          dispatch(viewSlice.actions.setNavVisible(true));
        }}
        handleBackButton={handleBackButton}
      ></EmptyViewNav>
      <UserGroups />
    </Fragment>
  );
};

export const AppPreferencesLocale: FunctionComponent<{}> = (_props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { projectId } = useParams<urlParams>();
  const handleBackButton = () => {
    history.push(buildProjectSettingsPath(projectId));
  };
  return (
    <Fragment>
      <EmptyViewNav
        title="Language &amp; region"
        setSideNavExpanded={() => {
          dispatch(viewSlice.actions.setNavVisible(true));
        }}
        handleBackButton={handleBackButton}
      ></EmptyViewNav>
      <LanguageRegion />
    </Fragment>
  );
};

export const AppPreferencesThemes: FunctionComponent<{}> = (_props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { projectId } = useParams<urlParams>();
  const handleBackButton = () => {
    history.push(buildProjectSettingsPath(projectId));
  };
  return (
    <Fragment>
      <EmptyViewNav
        title="Language &amp; region"
        setSideNavExpanded={() => {
          dispatch(viewSlice.actions.setNavVisible(true));
        }}
        handleBackButton={handleBackButton}
      ></EmptyViewNav>
      <Themes />
    </Fragment>
  );
};

export const AppProjectAdminBilling: FunctionComponent<{}> = (_props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { projectId } = useParams<urlParams>();
  const handleBackButton = () => {
    history.push(buildProjectSettingsPath(projectId));
  };
  return (
    <Fragment>
      <EmptyViewNav
        title="Billing"
        setSideNavExpanded={() => {
          dispatch(viewSlice.actions.setNavVisible(true));
        }}
        handleBackButton={handleBackButton}
      ></EmptyViewNav>
      <Billing />
    </Fragment>
  );
};
