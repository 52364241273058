import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ViewSlice {
  navigation: {
    projectName: string;
    visible: boolean;
  };
  isMobile: boolean;
  notFound: boolean;
}

export const viewSlice = createSlice({
  name: "view",
  initialState: {
    navigation: {
      projectName: "Project",
      visible: true,
    },
    isMobile: true,
    notFound: false,
  } as ViewSlice,
  reducers: {
    toggleNavView: (state) => {
      state.navigation.visible = !state.navigation.visible;
    },
    setNavVisible: (state, action: PayloadAction<boolean>) => {
      state.navigation.visible = action.payload;
    },
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      const nowIsMobile = action.payload;

      if (!nowIsMobile) {
        state.navigation.visible = true;
      } else if (!state.isMobile && nowIsMobile) {
        state.navigation.visible = false;
      }

      state.isMobile = nowIsMobile;
    },
    setNotFound: (state, action: PayloadAction<boolean>) => {
      state.notFound = action.payload;
    },
  },
});
