import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemIcon,
  MenuItemList,
} from "./Buttons";
import { Link, useHistory } from "react-router-dom";
import React, { FunctionComponent, useState } from "react";

import { produce } from "immer";
import { queryObj } from "../utils/utils";

export interface Listable {
  id: string;
  [x: string]: any;
}

export interface ListItemMenuOption {
  iconClass: string;
  text: string;
  action: string | (() => void);
}

export interface ListUI {
  headers: string[];
  friendlyHeaderNames: { [headerName: string]: string };
  primaryHeaders: { primary: string; secondary: string };
  selections: { [id: string]: boolean };
}

export interface ListItemProps {
  item: Listable;
  link: string | undefined;
  ui: ListUI;
  toggleSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
  menuOptions: ListItemMenuOption[];
  showSelector: boolean;
}

// TODO Need a date parser to display strings like 3 days ago, etc
const ListItem: FunctionComponent<ListItemProps> = (props) => {
  const history = useHistory();

  const selected = props.ui.selections[props.item.id] ? true : false;
  let rowClassNames =
    "border-b border-gray-300 last:border-b-0 hover:bg-gray-200 cursor-pointer";
  if (selected === true) {
    rowClassNames =
      "border-b border-gray-300 bg-blue-100 last:border-b-0 hover:bg-gray-200 cursor-pointer ";
  }

  const [menuOpen, setMenuOpen] = useState(false);

  const linkClick = () => {
    if (props.link) {
      history.push(props.link);
    }
  };

  return (
    <tr className={rowClassNames}>
      {props.showSelector && (
        <td className="px-4 py-4 w-8">
          <label className="ca-list-checkbox relative">
            <input
              type="checkbox"
              className="form-checkbox rounded border border-gray-400  mb-1"
              checked={selected}
              onChange={props.toggleSelected}
            ></input>
          </label>
        </td>
      )}
      {props.ui.headers.map((header, idx) => {
        if (header === props.ui.primaryHeaders.primary) {
          return (
            <td
              key={idx}
              className="pr-4 py-4 text-gray-800 font-medium table-cell items-center leading-snug  "
              onClick={linkClick}
            >
              {/* TODO: Turn entire row into a link to form rather than just the first column */}

              <p>{props.item[props.ui.primaryHeaders.primary]}</p>
              {/* TODO: This metadata <p> should show the data that the list is filtered by. */}
              <p className="sm:hidden text-sm font-light text-gray-600">
                {queryObj(props.item, props.ui.primaryHeaders.secondary)}
              </p>
            </td>
          );
        } else {
          return (
            <td
              key={idx}
              className="sm:table-cell pr-4 py-4 text-gray-700 font-light whitespace-normal hidden max-w-md"
              onClick={linkClick}
            >
              {queryObj(props.item, header)}
            </td>
          );
        }
      })}
      {props.menuOptions && (
        <td className="px-4 py-4 w-20">
          <Menu menuOpen={menuOpen} setMenuOpen={() => setMenuOpen(!menuOpen)}>
            <MenuButton extraClassNames="btn px-3 py-1 bg-white">
              <Icon fontAwesomeClassNames="far fa-ellipsis-h fa-fw"></Icon>
            </MenuButton>

            <MenuItemList position="bottom-right" extraClassNames="">
              {props.menuOptions.map((menuItem, idx) => {
                if (typeof menuItem.action === "string") {
                  return (
                    <Link
                      to={menuItem.action}
                      className="no-underline"
                      key={idx}
                    >
                      <MenuItem>
                        <MenuItemIcon
                          fontAwesomeClassNames={`far ${menuItem.iconClass}`}
                        />
                        {menuItem.text}
                      </MenuItem>
                    </Link>
                  );
                } else {
                  return (
                    <div
                      className="no-underline"
                      key={idx}
                      onClick={menuItem.action}
                    >
                      <MenuItem>
                        <MenuItemIcon
                          fontAwesomeClassNames={`far ${menuItem.iconClass}`}
                        />
                        {menuItem.text}
                      </MenuItem>
                    </div>
                  );
                }
              })}
            </MenuItemList>
          </Menu>
        </td>
      )}
    </tr>
  );
};

export interface ListProps {
  listItems: { ob: Listable; link: string | undefined }[];
  ui: ListUI;
  setUi: (ui: ListUI) => void;
  menuItemMap: { [itemId: string]: ListItemMenuOption[] };
  showSelectors?: boolean;
}

// TODO: This could take an array of columns as an argument
export const FlexibleList: FunctionComponent<ListProps> = (props) => {
  const showSelectors =
    props.showSelectors === undefined ? true : props.showSelectors;

  // TODO: Needs a function to handle select all / select none
  const [selectedStatus, setSelectedStatus] = useState(false);

  const handleToggleSelectAll = (): void => {
    const newSelections = props.listItems
      .map((item) => {
        return item.ob.id;
      })
      .reduce((prev, current) => {
        prev[current] = !selectedStatus;
        return prev;
      }, {} as ListUI["selections"]);
    const newUi = produce(props.ui, (draft) => {
      draft.selections = newSelections;
    });
    props.setUi(newUi);
    setSelectedStatus(!selectedStatus);
  };

  const toggleItemInList = (itemId: string) => {
    const newUi = produce(props.ui, (draft) => {
      draft.selections[itemId] = draft.selections[itemId] ? false : true;
    });
    props.setUi(newUi);
  };

  return (
    <div className="sm:w-11/12 mx-4 sm:mx-auto ">
      <div className="rounded my-0 sm:my-6 border border-gray-300 bg-white ">
        <table className="text-left w-full border-collapse table-auto ">
          <thead className="hidden sm:table-header-group bg-gray-100 ">
            <tr className="border-b  border-gray-300 rounded-t align-middle ">
              {showSelectors && (
                <th className="px-4 py-4 w-8">
                  <label className="ca-list-checkbox relative">
                    <input
                      type="checkbox"
                      className="form-checkbox rounded border border-gray-400  mb-1"
                      checked={selectedStatus}
                      onChange={() => {
                        handleToggleSelectAll();
                      }}
                    ></input>
                  </label>
                </th>
              )}
              {/* TODO: This should create a new column for every field of data shown */}
              <th className="pr-4 py-4 text-gray-700 font-semibold">
                {props.ui.friendlyHeaderNames[props.ui.headers[0]]}
              </th>
              {props.ui.headers.slice(1).map((header, idx) => {
                return (
                  <th
                    key={idx}
                    className="hidden sm:table-cell pr-4 py-4 text-gray-700 font-semibold"
                  >
                    {props.ui.friendlyHeaderNames[header]}
                  </th>
                );
              })}
              {Object.keys(props.menuItemMap).length !== 0 && (
                <th className="px-4 py-4 w-20"></th>
              )}
            </tr>
          </thead>
          <tbody className="">
            {props.listItems.map((item, index) => {
              return (
                <ListItem
                  item={item.ob}
                  link={item.link}
                  ui={props.ui}
                  key={index}
                  toggleSelected={() => toggleItemInList(item.ob.id)}
                  menuOptions={props.menuItemMap[item.ob.id]}
                  showSelector={showSelectors}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
