import * as K from "../types/KotoOb";

import { Block, BlockSpec } from "../types/Block";
import { BlockSpecDesigner, BlockSpecFiller } from "./Block";
import {
  Form,
  createForm,
  deleteBlockSpecFromForm,
  fillForm,
  updateFormTitle,
} from "../types/Form";
import { FormId, ProjectId, RecordId, RecordSetId } from "../types/IdTypes";
import { KotoCRDT, KotoKey, KotoOb, KotoRef } from "../types/KotoTypes";
import React, { ChangeEvent, Fragment, FunctionComponent } from "react"; // import for JSX
import {
  Tab,
  TabContent,
  TabHeader,
  TabbedView,
  TabbedViewProps,
} from "../components/Tabs";

import { KotoContext } from "../types/KotoContext";
// import { TriggerList, TriggerType } from "../components/Triggers";
import { KotoRawHistory } from "./KotoObHistory";
import { Listable } from "./FlexibleLists";
import { SubmissionSettings } from "../components/SubmissionSettings";

export interface FormInfo extends Listable {
  id: FormId;
  title: string;
  description: string;
}

const DesignerViewWrapper: FunctionComponent<{}> = (props) => {
  return (
    <div className="flex sm:w-11/12 mx-auto mb-32">
      <div className="flex-1">
        <div className="sm:block sm:w-11/12 mx-auto mt-5 ">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export type DesignerViews = "design" | "settings" | "history";

interface FormDesignerProps {
  kContext: KotoContext;
  form: KotoCRDT<Form>;
  blockSpecMap: { [key: string]: KotoCRDT<BlockSpec> };
  setForm: (form: KotoCRDT<Form>) => Promise<void>;
  setBlockSpec: (spec: KotoCRDT<BlockSpec>) => Promise<void>;
  activeBlockSpec: KotoKey;
  setActiveBlockSpec: (id: KotoKey) => void;
  activeView: DesignerViews;
  selectTab: (label: DesignerViews) => void;
}

export const FormDesigner: FunctionComponent<FormDesignerProps> = (props) => {
  const updateTitle = async (event: ChangeEvent<HTMLInputElement>) => {
    const newForm = updateFormTitle(
      props.kContext,
      props.form,
      event.target.value
    );
    await props.setForm(newForm);
  };

  const updateDescription = async (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newForm = K.change(props.kContext, props.form, (doc) => {
      K.performUpdateText(doc.description, event.target.value);
    });

    await props.setForm(newForm);
  };

  const handleDeleteBlockSpec = async (key: KotoKey): Promise<void> => {
    const newForm = deleteBlockSpecFromForm(props.kContext, props.form, key);
    await props.setForm(newForm);
  };

  // Hidden for user research
  // const [triggers, setTriggers] = useState([
  //   { type: "reminder", label: "Book Travel" },
  //   { type: "reminder", label: "Medical Exam" },
  //   { type: "reminder", label: "Legal Consult" },
  // ] as TriggerType[]);

  return (
    <Fragment>
      <TabbedView
        type="nav"
        activeView={props.activeView}
        selectTab={props.selectTab as TabbedViewProps["selectTab"]}
      >
        <Tab label="design">
          <TabHeader>Edit</TabHeader>
          <TabContent>
            <DesignerViewWrapper>
              {/* TODO: Add active state so this can be highlighted like the other question blocks */}
              <div className="k_card mb-5">
                <div className=" ">
                  <input
                    className="form-input k_textInput font-semibold bg-white text-xl rounded-b-none hidden-border-b "
                    placeholder="Form title"
                    value={props.form.crdt.title.toString()}
                    onChange={updateTitle}
                  />
                  <textarea
                    onChange={updateDescription}
                    className="k_textArea bg-white mt-0 rounded-t-none"
                    rows={3}
                    placeholder="Form description"
                    disabled={false}
                    value={props.form.crdt.description.toString()}
                  />
                </div>
              </div>
              {props.form.crdt.blockSpecRefs.map(
                (blockSpecRef: KotoRef<BlockSpec>) => {
                  const blockSpec = props.blockSpecMap[blockSpecRef.key];

                  if (!blockSpec) {
                    console.log(`Couldn't find block: ${blockSpecRef}`);
                    return (
                      <div key={blockSpecRef.key}>
                        Error finding block {blockSpecRef.key}
                      </div>
                    );
                  }

                  return (
                    <div
                      key={blockSpecRef.key}
                      onClick={() => {
                        props.setActiveBlockSpec(blockSpecRef.key);
                      }}
                    >
                      <BlockSpecDesigner
                        kContext={props.kContext}
                        spec={blockSpec}
                        setBlockSpec={props.setBlockSpec}
                        active={blockSpec.key === props.activeBlockSpec}
                        blockDeleter={() => {
                          handleDeleteBlockSpec(blockSpec.key);
                        }}
                      />
                    </div>
                  );
                }
              )}
            </DesignerViewWrapper>
          </TabContent>
        </Tab>
        <Tab label="settings">
          <TabHeader>Settings</TabHeader>
          <TabContent>
            <DesignerViewWrapper>
              <SubmissionSettings
                kContext={props.kContext}
                form={props.form}
                setForm={props.setForm}
              >
                {/* Hidden for user research */}
                {/* <TriggerList
                  triggerList={triggers}
                  setTriggerList={setTriggers}
                /> */}
              </SubmissionSettings>
            </DesignerViewWrapper>
          </TabContent>
        </Tab>
        <Tab label="activity">
          <TabHeader>Activity</TabHeader>
          <TabContent>
            <DesignerViewWrapper>
              <div className="mx-2 sm:mx-0">
                <KotoRawHistory
                  kContext={props.kContext}
                  ob={K.getKotoObFromKotoCRDT(props.form)}
                ></KotoRawHistory>
              </div>
            </DesignerViewWrapper>
          </TabContent>
        </Tab>
      </TabbedView>
    </Fragment>
  );
};

export const makeFormFillerPropsFromBlocks = (
  kContext: KotoContext,
  blocks: KotoCRDT<Block>[]
) => {
  if (blocks.length === 0) {
    throw new Error(`Must have at least one block.`);
  }

  const blockSpecs: KotoOb<BlockSpec>[] = [];
  const projectIds: ProjectId[] = [];
  const recordSetIds: RecordSetId[] = [];
  const recordIds: RecordId[] = [];

  blocks.forEach((block) => {
    blockSpecs.push(block.crdt.blockSpec);
    projectIds.push(block.crdt.projectId);
    recordSetIds.push(block.crdt.recordSetId);
    recordIds.push(block.crdt.recordId);
  });

  if (
    !projectIds.every((val) => {
      return projectIds[0] === val;
    })
  ) {
    throw new Error(
      `All blocks must have the same project Id but got the following: ${projectIds}`
    );
  }

  if (
    !recordSetIds.every((val) => {
      return recordSetIds[0] === val;
    })
  ) {
    throw new Error(
      `All blocks must have the same recordSet Id but got the following: ${recordSetIds}`
    );
  }

  if (
    !recordIds.every((val) => {
      return recordIds[0] === val;
    })
  ) {
    throw new Error(
      `All blocks must have the same record Id but got the following: ${recordIds}`
    );
  }

  const form = createForm(kContext, projectIds[0], "");

  const blockSpecRefs = blockSpecs.map((spec) => {
    return K.getRef(spec);
  });

  const formWithBlockSpecs = K.change(kContext, form, (doc) => {
    doc.blockSpecRefs = blockSpecRefs;
  });

  const blockSpecMap: {
    [key: string]: KotoOb<BlockSpec>;
  } = blockSpecs.reduce((prev: { [key: string]: KotoOb<BlockSpec> }, cur) => {
    prev[cur.key] = cur;
    return prev;
  }, {});

  const recordSetId = recordSetIds[0];
  const recordId = recordIds[0];

  return { formWithBlockSpecs, blockSpecMap, blocks, recordId, recordSetId };
};

interface FormFillerProps {
  kotoContext: KotoContext;
  form: KotoCRDT<Form>;
  blockSpecMap: { [key: string]: KotoOb<BlockSpec> };
  blocks: KotoCRDT<Block>[];
  setBlocks: (blocks: KotoCRDT<Block>[]) => void;
  recordSetId: RecordSetId;
  recordId: RecordId;
}

export const FormFiller: FunctionComponent<FormFillerProps> = (props) => {
  const handleUpdateBlock = (updatedBlock: KotoCRDT<Block>, index: number) => {
    const newBlocks = [...props.blocks];
    newBlocks[index] = updatedBlock;

    props.setBlocks(newBlocks);
  };

  if (!props.form) {
    return <div>Bad Form {props.form}</div>;
  } else {
    return (
      <div>
        {props.form.crdt.title.length > 0 && (
          <div className="k_card mt-4 mb-8">
            <div className="">
              <h1 className="flex font-bold text-3xl">
                {props.form.crdt.title.toString()}
              </h1>
              {props.form.crdt.description.length > 0 && (
                <p className="flex mt-2 font-light text-gray-700">
                  {props.form.crdt.description.toString()}
                </p>
              )}
            </div>
          </div>
        )}
        {fillForm(
          props.kotoContext,
          props.form,
          props.blockSpecMap,
          props.recordSetId,
          props.recordId,
          props.blocks
        ).map((item) => {
          return (
            <div key={item.block.key}>
              <BlockSpecFiller
                kotoContext={props.kotoContext}
                spec={item.blockSpec}
                formId={props.form.crdt.id}
                recordSetId={props.recordSetId}
                recordId={props.recordId}
                block={item.block}
                setBlock={(updatedBlock: KotoCRDT<Block>) => {
                  handleUpdateBlock(updatedBlock, item.index);
                }}
              />
            </div>
          );
        })}
      </div>
    );
  }
};
