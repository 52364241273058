import "./ShortTextQuestion";
import "./LongTextQuestion";
import "./CheckboxQuestion";
import "./MultipleChoice";
import "./DropDownChoice";
import "./DateQuestion";

import * as K from "../../types/KotoOb";

// Questions have three modes
// Design, Edit, Read
import {
  BaseQuestionSpec,
  QuestionAnswer,
  QuestionTypeName,
  makeQuestionLabelUpdater,
  makeQuestionRequiredToggler,
  makeQuestionTypeSetter,
} from "../../types/Questions";
import React, { ChangeEvent, Fragment, FunctionComponent } from "react"; // import for JSX
import {
  getQuestionTypeConfig,
  getQuestionTypeNames,
} from "../../types/QuestionTypeRegistry";

interface QuestionDesignerProps<T extends BaseQuestionSpec> {
  questionSpec: K.KotoFrozen<T>;

  // Because questions live inside blocks so they need to be edited using a function that
  // modifies the question in place rather than returning a new question. Otherwise co-editing
  // of text fields won't work.
  setQuestion: (fn: (question: T) => void) => void;
  deleteQuestion: () => void;
  duplicateQuestion: () => void;
}

// TODO: Add active class here to add outline on element when active
export const QuestionDesigner: FunctionComponent<
  QuestionDesignerProps<BaseQuestionSpec>
> = (props) => {
  const chooserOptions: {
    value: QuestionTypeName;
    label: string;
  }[] = getQuestionTypeNames();

  const updateQuestionText = (event: ChangeEvent<HTMLInputElement>) => {
    props.setQuestion(makeQuestionLabelUpdater(event.target.value));
  };

  const toggleRequired = (_event: ChangeEvent<HTMLInputElement>) => {
    props.setQuestion(makeQuestionRequiredToggler());
  };

  const setQuestionType = (event: ChangeEvent<HTMLSelectElement>) => {
    event.persist();
    props.setQuestion(
      makeQuestionTypeSetter(event.target.value as QuestionTypeName)
    );
  };

  return (
    <div className="rounded bg-gray-100 border border-gray-400 mb-4 last:mb-0">
      <div className="mx-5 mt-5">
        <input
          onChange={updateQuestionText}
          className="form-input k_textInput bg-white font-medium rounded-b-none hidden-border-b"
          placeholder="Question text"
          value={props.questionSpec.label}
        ></input>
      </div>

      {/* Type Chooser */}
      <div className="mx-5 mb-3">
        <label className="">
          <select
            value={props.questionSpec.type}
            className="form-select k_select hover:bg-white rounded-t-none mt-0"
            onChange={setQuestionType}
          >
            {chooserOptions.map((op) => (
              <option key={op.label} value={op.value}>
                {op.label}
              </option>
            ))}
          </select>
        </label>
      </div>

      <QuestionChooser
        questionSpec={props.questionSpec}
        setQuestion={props.setQuestion}
      />

      {/* Question Options */}
      <div className="flex mx-5 items-center my-5">
        <label className="flex-initial  flex items-center">
          <span className="mr-2 py-4 text-gray-700 text-sm sm:text-base">
            Required
          </span>
          <input
            onChange={toggleRequired}
            type="checkbox"
            className="form-checkbox rounded border border-gray-400 "
            checked={props.questionSpec.required}
          ></input>
        </label>
        <div className="flex-grow"></div>

        <div className="flex-initial float-right">
          <button
            onClick={props.duplicateQuestion}
            className="btn btn-stacked-h shadow-none bg-transparent font-normal text-gray-700 hover:border-gray-800"
            data-tooltip="Duplicate question"
            tooltip-position="top"
          >
            <i className="far fa-copy"></i>
            <span className="hidden md:inline-block ml-2">Duplicate</span>
          </button>
          <button
            onClick={props.deleteQuestion}
            className="btn btn-stacked-h shadow-none bg-transparent font-normal text-gray-700 hover:border-gray-800"
            data-tooltip="Delete question"
            tooltip-position="top"
          >
            <i className="far fa-trash-alt"></i>
            <span className="hidden md:inline-block ml-2">Delete</span>
          </button>
          <button
            className="btn btn-stacked-h shadow-none bg-transparent font-normal text-gray-700 hover:border-gray-800"
            data-tooltip="Question options"
            tooltip-position="top-right"
          >
            <i className="far fa-cog"></i>
            <span className="hidden md:inline-block ml-2">Options</span>
          </button>
        </div>
      </div>
    </div>
  );
};

interface QuestionConfigureProps {
  questionSpec: K.KotoFrozen<BaseQuestionSpec>;

  // Because questions live inside blocks so they need to be edited using a function that
  // modifies the question in place rather than returning a new question. Otherwise co-editing
  // of text fields won't work.
  setQuestion: (fn: (question: BaseQuestionSpec) => void) => void;
}

const QuestionChooser: FunctionComponent<QuestionConfigureProps> = (props) => {
  return (
    <Fragment>
      {React.createElement(
        getQuestionTypeConfig(props.questionSpec.type).questionDesigner,
        {
          questionSpec: props.questionSpec,
          setQuestion: props.setQuestion,
        }
      )}
    </Fragment>
  );
};

interface QuestionFillerProps {
  questionSpec: K.KotoFrozen<BaseQuestionSpec>;
  answer: K.KotoFrozen<QuestionAnswer>;
  setAnswer: (
    setter: (questionSpec: BaseQuestionSpec, answer: QuestionAnswer) => void
  ) => void;
}
export const QuestionPartsFiller: FunctionComponent<QuestionFillerProps> = (
  props
) => {
  return (
    <Fragment>
      {React.createElement(
        getQuestionTypeConfig(props.questionSpec.type).questionFiller,
        {
          spec: props.questionSpec,
          answer: props.answer,
          setAnswer: props.setAnswer,
        }
      )}
    </Fragment>
  );
};

interface AnswerViewerProps {
  questionSpec: K.KotoFrozen<BaseQuestionSpec>;
  answer: QuestionAnswer;
}
export const AnswerPartsViewer: FunctionComponent<AnswerViewerProps> = (
  props
) => {
  return (
    <Fragment>
      {React.createElement(
        getQuestionTypeConfig(props.questionSpec.type).questionViewer,
        {
          spec: props.questionSpec,
          answer: props.answer,
        }
      )}
    </Fragment>
  );
};
